import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ConditionalRelativeProps {
    isRelative: boolean;
    vertical: boolean;
    children: ReactNode;
    offset?: number;
    position?: string;
}

export const ConditionalRelative = ({
    isRelative,
    offset = 0,
    children,
    vertical,
    position,
}: ConditionalRelativeProps) => {
    if (isRelative) {
        if (vertical) {
            return (
                <RelativeDivVertical $offset={offset}>
                    {children}
                </RelativeDivVertical>
            );
        } else {
            return (
                <RelativeDivHorizontal $offset={offset} $position={position}>
                    {children}
                </RelativeDivHorizontal>
            );
        }
    }

    return children;
};

const RelativeDivVertical = styled.div<{$offset: number}>`
    position: relative;

    ::before {
        position: absolute;
        content: '';
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 7px solid rgb(var(--primary_colour));
        top: -3px;
    }

    ::after {
        position: absolute;
        content: "";
        width: 35px;
        top: 0px;
        left: -31px;
        border-top: 1px solid rgb(var(--primary_colour));
        border-right: 1px solid rgb(var(--primary_colour));
        height: 1px;
      }
    }
`;

const RelativeDivHorizontal = styled.div<{$offset: number; $position?: string}>`
    position: relative;
    height: 25px;

    ::before {
        position: absolute;
        content: "";
        top: ${({$position}) => ($position == 'bottom' ? '15px' : '0')};
        right: -3px;
        
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: ${({$position}) =>
            $position == 'bottom'
                ? 'none'
                : '7px solid rgb(var(--primary_colour))'};
        border-bottom: ${({$position}) =>
            $position == 'bottom'
                ? '7px solid rgb(var(--primary_colour))'
                : 'none'};
    }

    ::after {
        position: absolute;
        content: "";
        width: 1px;
        border-right: 1px solid rgb(var(--primary_colour));
        border-bottom: 1px solid rgb(var(--primary_colour));
        top: ${({$position}) => ($position == 'bottom' ? '21px' : '-36px')};
        left: -1px;
        height: ${({$position}) => ($position == 'bottom' ? '10px' : '37px')};
      }
    }
`;
