import React from 'react';
import {shallowEqual} from 'react-redux';
import {
    selectHasDoors,
    selectHasEdges,
    selectMaterialIndex,
    selectMaterialTypeOption,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {CurrentSelections} from 'components/customer/AdvancedMaterials/CurrentSelections/CurrentSelections';
import {useAppSelector} from 'store/customer';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {
    mapEdgeMaterial,
    mapMaterial,
} from 'components/customer/AdvancedMaterials/helpers/mappers';

const withMaterials = (Component: typeof CurrentSelections) => {
    const ComponentWidhMaterials = () => {
        const index = useAppSelector(selectMaterialIndex);
        const hasEdges = useAppSelector(selectHasEdges);
        const hasDoors = useAppSelector(selectHasDoors);
        const materialType = useAppSelector(selectMaterialTypeOption);
        const material = useAppSelector(
            (state) => getMaterial(state, materialType, index),
            shallowEqual
        ); // use correct index incase of QFP
        const edgeMaterial = useAppSelector(
            (state) => getEdge(state, materialType, index),
            shallowEqual
        ); // use correct index incase of QFP
        const door = useAppSelector(
            (state) => getDoor(state, materialType, index),
            shallowEqual
        ); // use correct index incase of QFP

        return (
            <Component
                material={mapMaterial(material)}
                edge={mapEdgeMaterial(edgeMaterial)}
                door={door}
                hasDoors={hasDoors}
                hasEdges={hasEdges}
            />
        );
    };

    return ComponentWidhMaterials;
};

export default withMaterials(CurrentSelections);
