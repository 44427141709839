import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from 'shared/helpers';

export const EyeCheckbox = ({value, values, onChange}) => {
    const [eyeValue, setEyeValue] = useState(0);
    const [eye, setEye] = useState();

    useEffect(() => {
        value && setEyeValue(value);
    }, [value]);

    useEffect(() => {
        switch (eyeValue) {
            case 0:
                setEye(<Icon iconName="Open-Eye.svg"/>);
                break;

            case 1:
                setEye(<Icon iconName="Closed-Eye.svg"/>);
                break;

            case 2:
                setEye(<Icon iconName="Crossed-Eye.svg"/>);
                break;
        }
    }, [eyeValue]);

    const clickHandler = () => {
        let tempValue = eyeValue + 1;

        if (tempValue == values) {
            tempValue = 0;
        }

        setEyeValue(tempValue);
        onChange && onChange(tempValue);
    }

    return (
        <Button className="btn btn-link" onClick={clickHandler} style={{ height: '25px', margin: '3px 0' }}>
            {eye}
        </Button>
    );
}