import React, {useState, useCallback} from 'react';
import {Button, FormControl, Spinner} from 'react-bootstrap';
import {CBCButton, genericMessageHandler} from 'shared/helpers';
import {useJobContext, useNotificationContext} from 'contexts';
import {ProductBackgroundImage} from 'components';
import {Product as ProductType} from 'IndexedDB';
import {PartialRoom} from 'shared/types/PartialRoom';

// Override extHorGrain in PartialRoom to be a number instead of a string
type Room = Partial<Omit<PartialRoom, 'extHorGrain'>> & {extHorGrain?: number};

export const Product = ({
    product,
    addToCartHandler,
    addingStatus,
    addButtonText,
    setFavourite,
    embedded = false,
}: {
    product: ProductType;
    addToCartHandler: (product: ProductType, width: number) => Promise<void>;
    addingStatus: boolean;
    addButtonText: string;
    setFavourite: (itemId: number, favourite: boolean) => Promise<void>;
    embedded: boolean;
}) => {
    const {notify} = useNotificationContext();
    const [width, setWidth] = useState<number>(product.defaultWidth);
    const {room} = useJobContext() as {room: Room};

    const favouriteHandler = useCallback(
        async (itemId: number, isNew = true) => {
            try {
                await setFavourite(itemId, isNew);

                genericMessageHandler(
                    notify,
                    {
                        message: `Item successfully ${
                            isNew ? 'added to' : 'removed from'
                        } your favourite list.`,
                    },
                    'success'
                );
            } catch (e) {
                genericMessageHandler(notify, e);
            }
        },
        []
    );

    const isFavourite = parseInt(product.favourites) > 0;

    const backgroundImage = `url("/uploads/gocabinet_materials/${
        room &&
        room.extMaterial &&
        room.extMaterial.image != '' &&
        product &&
        !product?.changedImage?.endsWith('.svg') &&
        !product?.image?.endsWith('.svg')
            ? room.extMaterial.image
            : 'default_exterior.jpg'
    }")`;

    return (
        <li
            className="quick-product"
            style={{marginRight: embedded ? '0px' : ''}}>
            <div className="contents">
                <div
                    className="imageContainer"
                    style={{width: '150px', height: '150px'}}>
                    <ProductBackgroundImage
                        image={backgroundImage}
                        transform={room.extHorGrain}
                        alt={product.text}
                        src={product.changedImage}
                        fallbackSrc={`${product.image}`}
                    />
                </div>
                <span>
                    {product.changed_name ? product.changed_name : product.text}
                </span>
            </div>

            <div className="selected selected-quick-product">
                {!embedded ? (
                    <>
                        <div>
                            <label>Width:</label>
                        </div>
                        <FormControl
                            type="number"
                            value={width}
                            onChange={(e) => {
                                const width_ = e.target.value;

                                setWidth(parseInt(width_));
                            }}
                        />
                    </>
                ) : null}
                <CBCButton
                    onClick={() => addToCartHandler(product, width)}
                    iconName="Button-Add.svg"
                    className="button-blue btn-sm"
                    style={{
                        paddingTop: '50px',
                        marginTop: embedded ? '30px' : '',
                    }}>
                    {' '}
                    {addButtonText}
                </CBCButton>
            </div>
            {addingStatus ? (
                <div className="adding-status">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : null}
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    void favouriteHandler(product.id, !isFavourite);
                }}
                variant="btn btn-link"
                className={
                    isFavourite
                        ? 'itemFavourite userFavourite'
                        : 'itemFavourite'
                }
            />
        </li>
    );
};
