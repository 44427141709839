import {useLazyGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';

export const useFetchStructure = () => {
    const {notify} = useNotificationContext();
    const [getStructure] = useLazyGetQFPProductStructureQuery();

    return async (cabinetType: number) => {
        try {
            const {
                data: structure,
                isError,
                error,
            } = await getStructure({cabinetType}, true);

            if (isError) {
                const errMsg =
                    'error' in error ? error.error : JSON.stringify(error.data);
                throw new Error(errMsg);
            }

            return structure;
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch product structure',
            });
        }
    };
};
