import {BaseQueryFn, QueryDefinition} from '@reduxjs/toolkit/dist/query';
import {LazyQueryTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
    useLazySearchMaterialsQuery,
    MaterialSearchRequest,
    useLazySearchDoorsQuery,
    useLazySearchMaterialEdgesQuery,
    useLazyGetMatchedEdgeQuery,
} from 'components/customer/Materials/store/materialApi';
import {useRef} from 'react';

type triggerType<T> = LazyQueryTrigger<
    QueryDefinition<MaterialSearchRequest, BaseQueryFn, string, T>
>;

const useAbortQuery = <T>(trigger: triggerType<T>) => {
    const abortHandler = useRef<() => void>();

    const abort = () => {
        if (typeof abortHandler.current == 'function') {
            abortHandler.current();
        }
    };

    const search = (params: MaterialSearchRequest) => {
        abort();

        const request = trigger(params, true);
        // NOTE: This is actually returned by the trigger function.
        // Not sure why this is throwing this warning.
        // check https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#uselazyquery
        // eslint-disable-next-line @typescript-eslint/unbound-method
        abortHandler.current = request.abort;

        return request;
    };

    return {search, abort};
};

export const useSearchMaterialsQueryWithAbort = () => {
    const [trigger, props] = useLazySearchMaterialsQuery();
    const handlers = useAbortQuery(trigger);

    return {
        ...props,
        ...handlers,
    };
};

export const useSearchDoorsQueryWithAbort = () => {
    const [trigger, props] = useLazySearchDoorsQuery();
    const handlers = useAbortQuery(trigger);

    return {
        ...props,
        ...handlers,
    };
};

export const useSearchEdgeQueryWithAbort = () => {
    const [trigger, props] = useLazySearchMaterialEdgesQuery();
    const handlers = useAbortQuery(trigger);

    return {
        ...props,
        ...handlers,
    };
};

export const useGetMatchedEdgeWithAbort = () => {
    const [trigger, props] = useLazyGetMatchedEdgeQuery();
    const handlers = useAbortQuery(trigger);

    return {
        ...props,
        ...handlers,
    };
};
