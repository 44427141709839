import {EyeCheckbox} from './EyeCheckbox';
import React from 'react';

export const LayoutFilter = ({tdld, tdldData}) => {
    const getValue = (value) => (value == 0 ? true : value == 1 ? null : false);

    return (
        <>
            <h2>Layout Filter</h2>
            <ul>
                <li>
                    <span>Base</span>
                    <EyeCheckbox
                        values={
                            tdldData && tdldData.filter && tdldData.filter.base
                        }
                        values={3}
                        onChange={(value) =>
                            tdld &&
                            tdld.setFilterBase &&
                            tdld.setFilterBase(getValue(value))
                        }
                    />
                </li>
                <li>
                    <span>Tall</span>
                    <EyeCheckbox
                        values={
                            tdldData && tdldData.tall && tdldData.filter.tall
                        }
                        values={3}
                        onChange={(value) =>
                            tdld &&
                            tdld.setFilterBase &&
                            tdld.setFilterTall(getValue(value))
                        }
                    />
                </li>
                <li>
                    <span>Upper</span>
                    <EyeCheckbox
                        values={
                            tdldData && tdldData.upper && tdldData.filter.upper
                        }
                        values={3}
                        onChange={(value) =>
                            tdld &&
                            tdld.setFilterBase &&
                            tdld.setFilterUpper(getValue(value))
                        }
                    />
                </li>
                <li>
                    <span>Dimension</span>
                    <EyeCheckbox
                        values={
                            tdldData &&
                            tdldData.dimensionVisible &&
                            tdldData.filter.dimensionVisible
                        }
                        values={3}
                        onChange={(value) =>
                            tdld &&
                            tdld.setFilterBase &&
                            tdld.setFilterDimensionVisible(getValue(value))
                        }
                    />
                </li>
            </ul>
        </>
    );
};
