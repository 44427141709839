export enum FormFactorIdentifier {
    SP = 'single_profile',
    DP = 'double_profile',
    NONE = 'no_profile',
}

export interface BenchtopFormFactor {
    id: number;
    identifier: FormFactorIdentifier;
    name: string;
    is_deleted: boolean;
}
