import {genericMessageHandler} from 'shared/helpers';
import {
    PredictionInterface,
    useLazyGetPredictionsQuery,
} from 'components/customer/Settings/store/settingsApi';
import {useNotificationContext} from 'contexts';

export const useGetPredictions = () => {
    const {notify} = useNotificationContext();
    const [fetchPredictions, {isLoading, isUninitialized}] =
        useLazyGetPredictionsQuery();

    const getPredictions = async (
        input: string
    ): Promise<PredictionInterface[]> => {
        try {
            const {data} = await fetchPredictions(
                {
                    input: input,
                },
                true
            );

            if (data && data.length > 0) {
                return data;
            }
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch address',
            });
        }

        return [];
    };

    return {
        isLoading,
        isUninitialized,
        getPredictions,
    };
};
