import React, {
    MouseEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
} from 'react';
import {Button, FormControl, InputGroup, Spinner} from 'react-bootstrap';
import {CBCImage} from 'shared/helpers';
import {useQuickProductBehaviour} from 'hooks';
import {SearchWhite} from 'assets';
import {ProductList} from './ProductList';
import {Product} from 'IndexedDB';

const isDescendant = (parent: Node, child: Node) => {
    for (let node = child.parentNode; node != null; node = node.parentNode) {
        if (node == parent) {
            return true;
        }
    }

    return false;
};

export const ProductSearch = ({
    disabled = false,
    disabledText = 'Unavailable',
    addButtonText = 'Add To Cart',
    embedded = false,
    callBack = null,
    setSearchCallback = null,
}: {
    disabled: boolean;
    disabledText: string;
    callBack: (product: Product, width: number) => Promise<void>;
    addButtonText: string;
    embedded: boolean;
    setSearchCallback: (ref: () => void) => void;
}) => {
    const {
        categories,
        subCategories,
        products,
        searchProducts,
        setSearchText,
        setSearchCategories,
        loading,
        productListVisible,
        resetSearch,
        showClearButton,
        popCategory,
        setFavourite,
    } = useQuickProductBehaviour(embedded);

    const clearSearchText = useCallback(() => {
        setSearchText('');
        const textField = document.querySelector('#searchQuickProducts');
        if (textField) textField.value = '';
    }, [searchProducts]);

    const hideProductSearch = useCallback(() => {
        clearSearchText();
        resetSearch();
    }, [clearSearchText, resetSearch]);

    const clickOutsideTarget = useCallback(
        (event: MouseEvent) => {
            if (event.target.classList.contains('search-btn')) {
                return;
            }

            if (event.target.id == 'product-search') {
                return;
            }

            if (
                isDescendant(
                    document.getElementById('product-search'),
                    event.target
                )
            ) {
                return;
            }

            hideProductSearch();
        },
        [hideProductSearch]
    );

    useLayoutEffect(() => {
        if (embedded) {
            return;
        }

        document.addEventListener('click', clickOutsideTarget);

        return () => {
            document.removeEventListener('click', clickOutsideTarget);
        };
    }, [clickOutsideTarget]);

    const triggerSearch = useCallback(() => {
        const text = document.querySelector('#searchQuickProducts').value;

        setSearchText(text);
    }, [setSearchText]);

    useEffect(() => {
        if (setSearchCallback) {
            setSearchCallback(triggerSearch);
        }
    }, [triggerSearch, setSearchCallback]);

    useEffect(() => {
        if (embedded) {
            triggerSearch();
        }

        return () => {
            resetSearch();
        };
    }, [embedded, resetSearch]);

    const categoryBreadcrumbRenderer = (
        index: number,
        name: string,
        isLast: boolean
    ) => {
        return (
            <div className="tdld-categories" key={index}>
                <span>{name}</span>
                {isLast ? (
                    <span
                        className="close-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            popCategory();
                        }}>
                        x
                    </span>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <ul>
            <li
                className={
                    embedded
                        ? 'product-search kdmax-product-search'
                        : 'product-search'
                }
                id="product-search">
                <section
                    className={
                        disabled
                            ? 'disabled product-search-container'
                            : 'product-search-container'
                    }>
                    <div className="input-and-category">
                        <InputGroup>
                            {searchProducts.categories.length ||
                            showClearButton ? (
                                <InputGroup.Append className="tdld-categories-container">
                                    {searchProducts.categories.length > 0 &&
                                        categoryBreadcrumbRenderer(
                                            0,
                                            searchProducts.categories[0]
                                                .styleName,
                                            searchProducts.categories.length == 1
                                        )}
                                    {searchProducts.categories.length > 1 &&
                                        categoryBreadcrumbRenderer(
                                            1,
                                            searchProducts.categories[1]
                                                .subStyleName,
                                            true
                                        )}
                                    {showClearButton ? (
                                        <div className="tdld-categories">
                                            <span>{searchProducts.text}</span>
                                            <span
                                                className="close-button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clearSearchText();
                                                }}>
                                                x
                                            </span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </InputGroup.Append>
                            ) : (
                                <></>
                            )}
                            {showClearButton ? (
                                <></>
                            ) : (
                                <FormControl
                                    id="searchQuickProducts"
                                    onFocus={triggerSearch}
                                    onKeyDown={(
                                        e: KeyboardEvent<HTMLInputElement>
                                    ) => {
                                        if (e.code == 'Enter') {
                                            setSearchText(
                                                e.currentTarget.value
                                            );
                                        }
                                    }}
                                    autoComplete="off"
                                    disabled={showClearButton || disabled}
                                    placeholder={
                                        disabled
                                            ? disabledText
                                            : 'Search Product'
                                    }
                                />
                            )}
                        </InputGroup>
                    </div>
                    <Button
                        onClick={triggerSearch}
                        className="search-btn"
                        id="productSearchButton"
                        style={{width: '30px'}}
                        disabled={disabled}>
                        <Spinner
                            animation="border"
                            size="sm"
                            className="search-btn"
                            style={{display: loading ? 'block' : 'none'}}
                        />
                        <CBCImage
                            src={SearchWhite}
                            className="search-btn"
                            style={{display: loading ? 'none' : 'initial'}}
                        />
                    </Button>
                </section>

                <ProductList
                    categories={categories}
                    subCategories={subCategories}
                    products={products}
                    selectedCategories={searchProducts.categories}
                    loading={loading}
                    productListVisible={productListVisible}
                    hideProductSearch={hideProductSearch}
                    categoryClickHandler={setSearchCategories}
                    embedded={embedded}
                    callBack={callBack}
                    addButtonText={addButtonText}
                    setFavourite={setFavourite}
                />
            </li>
        </ul>
    );
};
