/**
 * @flow
 *
 * @export
 * @class GapSize
 */
export default class Product {
    altCodes: string[];
    code: string;
    defaultWidth: number;
    doors: number;
    favourites: number;
    hidden: number;
    id: number;
    image: string;
    maxWidth: number;
    minWidth: number;
    productType: number;
    style: number;
    subStyle: string;
    subStyleId: number;
    text: string;
    changed_name: string;
    changedImage: string;
    quickProducts: number;
    isRecessedRail: number;
}
