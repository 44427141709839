import styled from 'styled-components';
import React, {useState, useEffect} from 'react';
import {useAppDispatch} from 'store/customer';
import {InputGroup, FormControl} from 'react-bootstrap';
import {copyQfp} from 'components/customer/QFPRedux/store/qfpSlice';
import {Icon} from 'shared/helpers';

interface CopyQuantityInterface {
    index: number;
    setShow: (show: boolean) => void;
}

const CustomIcon = styled((props) => <Icon {...props} />)`
    width: 20px;
    height: 20px;
`;

const CustomFormControl = styled(FormControl)`
    border: 1px solid #ced4da !important;
    border-radius: 8px !important;
    margin: 0 8px;
    height: 20px !important;
    box-sizing: border-box;
    font-size: 0.95em !important;
    text-align: center;
    font-weight: 500;
    width: 75px !important;
`;

export const CopyQuantity = ({index, setShow}: CopyQuantityInterface) => {
    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(1);

    const decrease = () => {
        setQuantity((quantity) => {
            if (quantity > 0) {
                return quantity - 1;
            }

            return quantity;
        });
    };

    const increase = () => {
        setQuantity((quantity) => {
            return quantity + 1;
        });
    };

    const quantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (value == '') return;

        setQuantity(parseInt(value));
    };

    const copyQfpAction = (e: React.MouseEvent<HTMLElement> = null) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        dispatch(copyQfp(quantity, index));
        setShow(false);
    };

    const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key == 'Enter') copyQfpAction();
    };

    useEffect(() => {
        if (quantity == 0) {
            setQuantity(1);
        }
    }, [quantity]);

    return (
        <>
            <InputGroup>
                <InputGroup.Prepend>
                    <CustomIcon
                        iconName="Button-Minus.svg"
                        style={{opacity: quantity == 1 ? 0.4 : 1}}
                        onClick={decrease}
                    />
                </InputGroup.Prepend>
                <CustomFormControl
                    onChange={quantityChange}
                    onKeyUp={keyUpHandler}
                    type="number"
                    value={quantity}
                    autoFocus={true}
                />
                <InputGroup.Append>
                    <CustomIcon
                        iconName="Button-Plus.svg"
                        onClick={increase}
                        style={{marginRight: '5px'}}
                    />
                    <CustomIcon
                        iconName="Button-Tick-Secondary.svg"
                        onClick={copyQfpAction}
                    />
                </InputGroup.Append>
            </InputGroup>
        </>
    );
};
