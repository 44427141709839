import {Side} from 'components/customer/BTM/entity/Side';

/**
 * Represents an error related to a butt join part.
 */
export interface ButtJoinPartError {
    message: string;
    index: number;
    side: Side;
}

/**
 * Represents an error related to a butt join.
 */
export class ButtJoinError extends Error {
    /**
     * Creates a new instance of ButtJoinError.
     * @param {string} message The error message.
     */
    constructor(message?: string) {
        super(message);
        this.name = 'ButtJoinError';

        Object.setPrototypeOf(this, ButtJoinError.prototype);
    }
}
