import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Path} from 'components/customer/BTM/entity/Path';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {Edge} from 'components/customer/BTM/entity/Edge';

/**
 *
 * Checks if at least one side is postformed.
 * returns true if none of the sides are postformed.
 * returns false if at least one side is postformed.
 *
 * @param {Path[]} paths
 * @param {BenchtopEdgeProfile} profile
 * @param {number} index
 * @param {Shape} shape
 *
 * @return {boolean}
 */
export const checkCurveCornerRestriction = (
    paths: Path[],
    profile: BenchtopEdgeProfile,
    index: number,
    shape: Shape
): boolean => {
    if (shape == Shape.SQR) {
        if (profile && profile.end_option && !profile.end_option.is_arc) {
            const pathA = paths.find((path) => path.side == Side.A);
            const pathC = paths.find((path) => path.side == Side.C);

            return (
                pathA &&
                pathA.edged != Edge.PROFILED &&
                pathC &&
                pathC.edged != Edge.PROFILED
            );
        }
    } else if (shape == Shape.ANG) {
        if (profile && profile.end_option && !profile.end_option.is_arc) {
            const path = paths[Number(index)];

            if (path.side == Side.B) {
                const pathA = paths.find((path) => path.side == Side.A);
                const pathC = paths.find((path) => path.side == Side.C);

                return (
                    pathA &&
                    pathA.edged != Edge.PROFILED &&
                    pathC &&
                    pathC.edged != Edge.PROFILED
                );
            } else if (path.side == Side.E) {
                const pathD = paths.find((path) => path.side == Side.D);
                const pathF = paths.find((path) => path.side == Side.F);

                return (
                    pathD &&
                    pathD.edged != Edge.PROFILED &&
                    pathF &&
                    pathF.edged != Edge.PROFILED
                );
            }
        }
    } else if (shape == Shape.USHAPE) {
        if (profile && profile.end_option && !profile.end_option.is_arc) {
            const path = paths[Number(index)];

            if (path.side == Side.C) {
                const pathB = paths.find((path) => path.side == Side.B);
                const pathD = paths.find((path) => path.side == Side.D);

                return (
                    pathB &&
                    pathB.edged != Edge.PROFILED &&
                    pathD &&
                    pathD.edged != Edge.PROFILED
                );
            } else if (path.side == Side.G) {
                const pathF = paths.find((path) => path.side == Side.F);
                const pathH = paths.find((path) => path.side == Side.H);

                return (
                    pathF &&
                    pathF.edged != Edge.PROFILED &&
                    pathH &&
                    pathH.edged != Edge.PROFILED
                );
            }
        }
    }

    return false;
};
