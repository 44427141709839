import React from 'react';
import {shallowEqual} from 'react-redux';
import {useAppSelector} from 'store/customer';
import {
    selectCorners,
    selectScale,
    selectCenter,
    selectDimension,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {CornerSizeLabel} from 'components/customer/BTM/Preview/CornerSizeLabel';

export const CornerSizeLabels = () => {
    const center = useAppSelector(selectCenter);
    const scale = useAppSelector(selectScale);
    const dimensions = useAppSelector(selectDimension);
    const corners = useAppSelector(selectCorners, shallowEqual);
    const shape = useAppSelector(selectType);

    if (typeof corners == 'undefined' || (corners && corners.length == 0))
        return null;

    return (
        <>
            {corners.map((corner, index) => {
                return (
                    <CornerSizeLabel
                        dimensions={dimensions}
                        corner={corner}
                        scale={scale}
                        center={center}
                        key={index}
                        index={index}
                        shape={shape.type}
                    />
                );
            })}
        </>
    );
};
