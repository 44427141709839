import React, {PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {AppState} from 'store/customer/storeSetup';
import {Session} from 'components/customer/Auth/store/authSlice';

export const NoAuth = ({children}: PropsWithChildren<object>) => {
    const session = useSelector((state: AppState) => state.auth.session);

    if (session == Session.LOGGED_IN) {
        return <Navigate to="/v2/" />;
    }

    return children;
};
