import {
    MaterialEdge,
    MaterialBrand,
    Type,
} from 'components/customer/Materials/entity';

export interface LegacyEdgeInterface {
    brandId: number;
    brandName: string;
    doorFilter?: string;
    door_filter?: string;
    finish: string;
    fullName: string;
    id: number;
    image: string;
    name: string;
    thickness: number;
    typeId: number;
    typeName: string;
    hidden?: boolean;
    brand?: number;
    brand_name?: string;
    type?: number;
}

export const mapMaterialEdge = (edge: LegacyEdgeInterface): MaterialEdge => {
    return <MaterialEdge>{
        id: edge.id,
        brand_id: edge.brandId,
        material_type_id: edge.typeId,
        name: edge.name,
        image: `/${edge.image}`,
        finish: edge.finish,
        thickness: edge.thickness,
        door_filter: edge.doorFilter,
        length_cost: 0,
        handling_cost: 0,
        area_handling_cost: 0,
        application_cost: 0,
        is_hidden: edge.hidden,
        brand: {
            id: edge.brandId,
            name: edge.brandName,
            is_deleted: false,
        } as MaterialBrand,
        material_type: {
            id: edge.typeId,
            name: edge.typeName,
            is_deleted: false,
            is_vinyl: false,
            is_editable: false,
        } as Type,
        displayName: `${edge.brandName} ${edge.finish} ${edge.thickness}mm ${edge.name}`,
    };
};

export const mapEdgeBack = (edge: MaterialEdge) => {
    return {
        brandId: edge.brand.id,
        brandName: edge.brand.name,
        doorFilter: edge.door_filter,
        finish: edge.finish,
        fullName: edge.displayName,
        id: edge.id,
        image: edge.image.startsWith('/') ? edge.image.slice(1) : edge.image,
        name: edge.name,
        thickness: edge.thickness,
        typeId: edge.material_type.id,
        typeName: edge.material_type.name,
        hidden: edge.is_hidden,
    } as LegacyEdgeInterface;
};
