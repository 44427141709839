import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {FormFactor} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Edge} from 'components/customer/BTM/entity/Edge';

interface Rule {
    side?: Side;
    index?: number;
    check: string;
}
export interface Restriction {
    rules: string[] | Rule[];
}

const NONE_POSTFORM_PROFILE = {
    rules: [`profile.is_postformed_profile == false`],
} as Restriction;

const getIndexedNotProfiledCheck = (indexes: string[]) => {
    return indexes.map((index) => ({
        index: index,
        check: `side.edged != ${Edge.PROFILED}`,
    }));
};

const getOppositeSideRestriction = (side: Side, indexes: string[] = []) => {
    if (indexes.length == 0) {
        return [
            NONE_POSTFORM_PROFILE,
            {
                rules: [
                    `material.formFactor == ${FormFactor.NONE_SP}`,
                    {
                        side,
                        check: `side.edged != ${Edge.PROFILED}`,
                    },
                ],
            },
            {
                rules: [
                    `material.formFactor == ${FormFactor.SP}`,
                    {
                        side,
                        check: `side.edged != ${Edge.PROFILED}`,
                    },
                ],
            },
            {
                rules: [
                    `AND(material.formFactor == ${FormFactor.NONE_DP}, material.is_blank == false)`,
                ],
            },
            {
                rules: [
                    `AND(material.formFactor == ${FormFactor.NONE_SP_DP}, material.is_blank == false)`,
                ],
            },
            {
                rules: [
                    `AND(material.formFactor == ${FormFactor.SP_DP}, material.is_blank == false)`,
                ],
            },
            {
                rules: [
                    `AND(material.formFactor == ${FormFactor.DP}, material.is_blank == false)`,
                ],
            },
        ] as Restriction[];
    }

    return [
        NONE_POSTFORM_PROFILE,
        {
            rules: [
                `OR(
                    material.formFactor == ${FormFactor.NONE_SP},
                    material.formFactor == ${FormFactor.SP}
                )`,
                {
                    side,
                    check: `side.edged != ${Edge.PROFILED}`,
                },
            ],
        },
        {
            rules: [
                `OR(
                    material.formFactor == ${FormFactor.NONE_SP},
                    material.formFactor == ${FormFactor.SP}
                )`,
                {
                    side,
                    check: `side.edged != ${Edge.PROFILED}`,
                },
                ...getIndexedNotProfiledCheck(indexes),
            ],
        },
        {
            rules: [
                `AND(
                    OR(
                        material.formFactor == ${FormFactor.NONE_DP},
                        material.formFactor == ${FormFactor.DP}
                    ),
                    material.is_blank == false
                )`,
            ],
        },
        {
            rules: [
                `AND(
                    OR(
                        material.formFactor == ${FormFactor.NONE_DP},
                        material.formFactor == ${FormFactor.DP}
                    ),
                    material.is_blank == false
                )`,
                ...getIndexedNotProfiledCheck(indexes),
            ],
        },
        {
            rules: [
                `AND(material.formFactor == ${FormFactor.NONE_SP_DP}, material.is_blank == false)`,
            ],
        },
        {
            rules: [
                `AND(material.formFactor == ${FormFactor.NONE_SP_DP}, material.is_blank == false)`,
                `profile.restrict_adjacent`,
                ...getIndexedNotProfiledCheck(indexes),
            ],
        },
        {
            rules: [
                `AND(material.formFactor == ${FormFactor.SP_DP}, material.is_blank == false)`,
            ],
        },
        {
            rules: [
                `AND(
                    material.formFactor == ${FormFactor.SP_DP},
                    material.is_blank == false
                )`,
                ...getIndexedNotProfiledCheck(indexes),
            ],
        },
    ] as Restriction[];
};

const getAdjacentSideRestriction = () => {
    return [
        {
            rules: [
                `
                OR(
                    AND(
                        profile.is_postformed_profile == false,
                        profile.restrict_adjacent == false
                    ),
                    AND(
                        profile.is_postformed_profile,
                        OR(
                            profile.restrict_adjacent == false,
                            AND(
                                profile.restrict_adjacent == true,
                                profile.is_end_roll_available
                            )
                        )
                    )
                )
            `,
            ],
        },
    ] as Restriction[];
};

export const SIDE_MAP = [
    // SQR shape side data
    {
        shape: Shape.SQR,
        side: Side.A,
        checks: getOppositeSideRestriction(Side.C, ['index - 1', 'index + 1']),
    },
    {
        shape: Shape.SQR,
        side: Side.B,
        checks: getAdjacentSideRestriction(),
    },
    {
        shape: Shape.SQR,
        side: Side.C,
        checks: getOppositeSideRestriction(Side.A, ['index - 1', 'index + 1']),
    },
    {
        shape: Shape.SQR,
        side: Side.D,
        checks: getAdjacentSideRestriction(),
    },
    // L shape side data
    {
        shape: Shape.ANG,
        side: Side.A,
        checks: getOppositeSideRestriction(Side.C, ['index + 1']),
    },
    {
        shape: Shape.ANG,
        side: Side.B,
        checks: getAdjacentSideRestriction(),
    },
    {
        shape: Shape.ANG,
        side: Side.C,
        checks: getOppositeSideRestriction(Side.A, ['index - 1']),
    },
    {
        shape: Shape.ANG,
        side: Side.D,
        checks: getOppositeSideRestriction(Side.F, ['index + 1']),
    },
    {
        shape: Shape.ANG,
        side: Side.E,
        checks: getAdjacentSideRestriction(),
    },
    {
        shape: Shape.ANG,
        side: Side.F,
        checks: getOppositeSideRestriction(Side.D, ['index - 1']),
    },
    // U shape side data
    {
        shape: Shape.USHAPE,
        side: Side.A,
        checks: getOppositeSideRestriction(Side.E),
    },
    {
        shape: Shape.USHAPE,
        side: Side.B,
        checks: getOppositeSideRestriction(Side.D, ['index + 1']),
    },
    {
        shape: Shape.USHAPE,
        side: Side.C,
        checks: getAdjacentSideRestriction(),
    },
    {
        shape: Shape.USHAPE,
        side: Side.D,
        checks: getOppositeSideRestriction(Side.B, ['index - 1']),
    },
    {
        shape: Shape.USHAPE,
        side: Side.E,
        checks: getOppositeSideRestriction(Side.A),
    },
    {
        shape: Shape.USHAPE,
        side: Side.F,
        checks: getOppositeSideRestriction(Side.H, ['index + 1']),
    },
    {
        shape: Shape.USHAPE,
        side: Side.G,
        checks: getAdjacentSideRestriction(),
    },
    {
        shape: Shape.USHAPE,
        side: Side.H,
        checks: getOppositeSideRestriction(Side.F, ['index - 1']),
    },
];
