import React from 'react';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';

interface CustomRadioProps {
    checked: boolean;
    name: string;
    className?: string;
}

const CustomRadio: React.FC<CustomRadioProps> = ({
    checked,
    name,
    className,
}) => {
    return checked ? (
        <CheckIconContainer $isSelected={checked}>
            <Icon iconName="Button-Tick-Secondary.svg" color="secondary" />
        </CheckIconContainer>
    ) : (
        <div>
            <StyledCheckbox
                type="radio"
                className={className}
                name={name}
                checked={checked}
            />
        </div>
    );
};

const StyledCheckbox = styled.input`
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    background-color: #fff;
    border: 2px solid;
    border-color: rgb(var(--primary_colour));
    border-radius: 17px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
`;

const CheckIconContainer = styled.div<{
    $isSelected?: boolean;
}>`
    img {
        width: 17px;
        display: ${({$isSelected}) => (!$isSelected ? 'none' : 'block')};
    }
`;

export default CustomRadio;
