import {Path} from 'components/customer/BTM/entity/Path';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {getCutoffType} from 'components/customer/BTM/helper/saveHelpers';
import {CornerType} from 'components/customer/BTM/entity/CornerType';

/**
 * Returns back sorrounding index of a given index.
 * For example, sorroundig index of 2 would be 1 and 3.
 *
 * @param {number} index Referencing Index
 * @param {number} length length of the array
 *
 * @return {number[]}
 */
export const getSorroundingIndexes = (index: number, length: number) => {
    const lastIndex = index == 0 ? length - 1 : index - 1;
    const nextIndex = index == length - 1 ? 0 : index + 1;

    return [lastIndex, nextIndex];
};

/**
 * Checks if the supplied path touches any cutouts in the bench
 *
 * @param {Path[]} paths All the paths in the bench see btmSlice
 * @param {BenchtopEdgeProfile} profile Currently selected edge profile
 * @param {number} index index of the path that needs checking
 *
 * @return {boolean} Restrict or not
 */
export const checkCutoutRestriction = (
    paths: Path[],
    profile: BenchtopEdgeProfile,
    index: number
) => {
    if (profile.restrict_corner_clip || profile.restrict_corner_notch) {
        const path = paths[Number(index)];
        const indexes = getSorroundingIndexes(index, paths.length);
        let restrict = false;

        if (path.side == null) {
            const type = getCutoffType(
                paths[Number(indexes[0])],
                paths[Number(indexes[1])]
            );

            if (type == CornerType.Clip && profile.is_end_only) {
                return false;
            }
            if (
                (type == CornerType.Clip && profile.restrict_corner_clip) ||
                (type == CornerType.Notch && profile.restrict_corner_notch)
            )
                return true;
        } else {
            for (const checkIndex of indexes) {
                if (restrict) break;
                const path = paths[Number(checkIndex)];

                if (path.side != null) continue;

                const [prevIndex, followingIndex] = getSorroundingIndexes(
                    checkIndex,
                    paths.length
                );
                const type = getCutoffType(
                    paths[Number(prevIndex)],
                    paths[Number(followingIndex)]
                );

                if (type) {
                    if (type == CornerType.Clip && profile.is_end_only) {
                        restrict = false;
                    } else {
                        restrict =
                            (type == CornerType.Clip &&
                                profile.restrict_corner_clip) ||
                            (type == CornerType.Notch &&
                                profile.restrict_corner_notch);
                    }
                }
            }

            return restrict;
        }
    }

    return false;
};
