import {Material, MaterialEdge} from 'components/customer/Materials/entity';
import {store} from 'store/customer/storeSetup';
import {MaterialApi} from 'components/customer/Materials/store/materialApi';

const matchBrandFinishColour = (material: Material) => (edge: MaterialEdge) =>
    material.brand.name.toLowerCase() == edge.brand.name.toLowerCase() &&
    material.finish.toLowerCase() == edge.finish.toLowerCase() &&
    material.name.toLowerCase() == edge.name.toLowerCase();

const matchBrandFinish = (material: Material) => (edge: MaterialEdge) =>
    material.brand.name.toLowerCase() == edge.brand.name.toLowerCase() &&
    material.finish.toLowerCase() == edge.finish.toLowerCase();

const matchBrandAndColour = (material: Material) => (edge: MaterialEdge) =>
    material.brand.name.toLowerCase() == edge.brand.name.toLowerCase() &&
    material.name.toLowerCase() == edge.name.toLowerCase();

const matchBrand = (material: Material) => (edge: MaterialEdge) =>
    material.brand.name.toLowerCase() == edge.brand.name.toLowerCase();

export const matchColour = (
    material: Material,
    edges: MaterialEdge[]
): MaterialEdge => {
    if (edges.length == 0) {
        return;
    }

    let matchingEdge = edges.find(matchBrandFinishColour(material));

    if (typeof matchingEdge === 'undefined') {
        matchingEdge = edges.find(matchBrandAndColour(material));
    }

    if (typeof matchingEdge === 'undefined') {
        matchingEdge = edges.find(matchBrandFinish(material));
    }

    if (typeof matchingEdge === 'undefined') {
        matchingEdge = edges.find(matchBrand(material));
    }

    if (typeof matchingEdge === 'undefined') {
        matchingEdge = edges[0];
    }

    return matchingEdge;
};

export const getEdge = async (
    cabinetType: number,
    manufacturerId: number,
    material: Material,
    edge: string,
    search?: boolean
) => {
    try {
        let materialEdge: MaterialEdge;

        if (!search) {
            const {data} = await store.dispatch(
                MaterialApi.endpoints.getMaterialEdge.initiate(
                    {
                        keywords: edge,
                        cabinetType,
                        manufacturerId,
                    },
                    {
                        forceRefetch: false,
                    }
                )
            );

            if (data && data.data) {
                materialEdge = data.data;
            }
        }

        if (typeof materialEdge === 'undefined') {
            const {data} = await store.dispatch(
                MaterialApi.endpoints.searchMaterialEdgesByCritria.initiate(
                    {
                        type: material.type.id,
                        manufacturerId,
                        cabinetType,
                        doorFilter: material.door_filter,
                    },
                    {forceRefetch: false}
                )
            );

            if (data && data.data && data.data.length > 0) {
                materialEdge = matchColour(material, data.data);
            }
        }

        return materialEdge;
    } catch (e) {
        console.log(e);
    }
};
