import React, {useCallback, useRef, useState} from 'react';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {CornerSpecs} from 'components/customer/BTM/Corners/CornerSpecs';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {cornerSet, selectCorners} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {
    DropdownToggleButton,
    EdgeDropdown,
    EdgeDropdownMenu,
    ToggleImage,
} from 'components/customer/BTM/Preview/EdgeToggle';
import {Corner} from 'components/customer/BTM/entity/Corner';

export const EndProfileCurvedCorner = ({
    endOnlyProfile,
    corner,
}: {
    endOnlyProfile: BenchtopEdgeProfile;
    corner: {
        corner: Corner;
        index: number;
    };
}) => {
    const dispatch = useAppDispatch();
    const corners = useAppSelector(selectCorners, shallowEqual);
    const dropDownToggle = useRef<HTMLButtonElement>();
    const [xValue, setXValue] = useState(
        corner.corner.depths[0] ??
            endOnlyProfile.end_option.default_radius_start
    );
    const [yValue, setYValue] = useState(
        corner.corner.depths[1] ?? endOnlyProfile.end_option.default_radius_end
    );

    const saveCorner = useCallback(() => {
        dropDownToggle.current.click();
        dispatch(
            cornerSet(
                {
                    ...corners.at(corner.index),
                    depths: [xValue, yValue],
                },
                corner.index
            )
        );
    }, [dropDownToggle, corners, corner, xValue, yValue]);

    return (
        <EdgeDropdown>
            <DropdownToggleButton ref={dropDownToggle}>
                <ToggleImage iconName="Button-Edit-Primary.svg" />
            </DropdownToggleButton>
            <EdgeDropdownMenu>
                <CornerSpecs
                    xValue={xValue}
                    yValue={yValue}
                    setXValue={setXValue}
                    setYValue={setYValue}
                    previousPage={null}
                    saveCorner={saveCorner}
                    type={CornerType.Clip}
                    error={null}
                />
            </EdgeDropdownMenu>
        </EdgeDropdown>
    );
};
