import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';

export const mapImage = <
    T extends BenchtopMaterialType | BenchtopMaterial | BenchtopEdgeProfile
>(
    benchTopMaterial: T
) => {
    if (benchTopMaterial.image) {
        let imageUrl = `/uploads/images`;
        if (
            benchTopMaterial?.image?.image_url != null &&
            benchTopMaterial?.image?.image_url != ''
        ) {
            imageUrl = `/${benchTopMaterial.image.image_url}`;
        }

        if (!imageUrl.endsWith('/')) {
            imageUrl = `${imageUrl}/`;
        }

        benchTopMaterial.image.name = `${imageUrl}${benchTopMaterial.image.name}`;
    }

    return benchTopMaterial;
};
