import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Type} from 'components/customer/AdvancedMaterials/entity/Type';
import {
    useLazyListDoorsQuery,
    useLazySearchDoorsQuery,
} from 'components/customer/Materials/store/materialApi';
import {User, useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import styled from 'styled-components';
import {Door} from 'components/customer/AdvancedMaterials/Doors/Door';
import {useAppSelector} from 'store/customer';
import {
    selectDoorSearch,
    selectHasDoors,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {Door as DoorI} from 'components/customer/Materials/entity';
import {APIResponse} from 'store/customer/api';
import AutoSizer from 'react-virtualized-auto-sizer';
import {GridPaginator} from 'components/customer/AdvancedMaterials/Materials/GridPaginator';
import {FixedSizeGrid} from 'react-window';
import {Icon} from 'shared/helpers';
import {isMobile, isTablet} from 'react-device-detect';
import {ShowAllDoors} from 'components/customer/AdvancedMaterials/Doors/ShowAllDoors';

// using 120px for door width.
const doorWidth = 120;

interface MaterialTypeProps {
    type: Type;
    selected: boolean;
    setSelected: () => void;
    unsetSelected: () => void;
    cabinetType: number;
    setHasData: (hasData: boolean) => void;
    isLast?: boolean;
    maxWidth: number;
    hideShowAllButton?: boolean;
}

export const MaterialType = ({
    type,
    cabinetType,
    selected,
    setSelected,
    unsetSelected,
    setHasData,
    isLast = false,
    maxWidth,
    hideShowAllButton = true,
}: MaterialTypeProps) => {
    const searchText = useAppSelector(selectDoorSearch);
    const hasDoors = useAppSelector(selectHasDoors);

    const [doors, setDoors] = useState<APIResponse<DoorI[]>>();
    const gridReference = useRef();

    const columns = useMemo(() => {
        if (isMobile && !isTablet) {
            return 3;
        }

        if (maxWidth && maxWidth > 0) {
            // NOTE: 10px is left and right paddings (5 + 5)
            return Math.floor(maxWidth / (doorWidth + 10));
        }

        return 7;
    }, [isMobile, isTablet, maxWidth]);

    const {data: user} = useGetUserQuery();
    const [getDoors, {isError, error, isUninitialized, isLoading}] =
        useLazyListDoorsQuery();
    const [searchDoors, {isError: isSearchError, error: searchError}] =
        useLazySearchDoorsQuery();

    const fetchDoors = useCallback(
        async (user: User, type: Type, doorName: string) => {
            if (doorName == '') {
                const {data} = await getDoors(
                    {
                        cabinetType,
                        manufacturerId: user.manufacturerId,
                        materialType: type.id,
                        currentPage: 1,
                        pageSize: columns - 1,
                    },
                    true
                );

                if (data) {
                    setDoors(data);
                    setHasData(data.data.length > 0);
                }
            } else {
                const {data} = await searchDoors(
                    {
                        cabinetType,
                        manufacturerId: user.manufacturerId,
                        materialType: type.id,
                        currentPage: 1,
                        pageSize: columns - 1,
                        doorName,
                    },
                    true
                );

                if (data) {
                    setDoors(data);
                    setHasData(data.data.length > 0);
                }
            }
        },
        [cabinetType, columns]
    );

    useEffect(() => {
        if (
            hasDoors &&
            (typeof type !== 'undefined' || typeof user !== 'undefined')
        ) {
            void fetchDoors(user, type, searchText);
        }
    }, [user, type, searchText, hasDoors]);

    if (isError || isSearchError) {
        console.log(error);
        console.log(searchError);

        return null;
    }

    return doors?.data?.length > 0 || isUninitialized || isLoading ? (
        <>
            <TitleContainer>
                <Title>
                    {type.name} Doors{' '}
                    {selected ? (
                        <Icon
                            onClick={unsetSelected}
                            iconName="Button-Cancel.svg"
                            title="Close"
                        />
                    ) : null}
                </Title>
                <Amount>({doors?.pagination?.total_count} total)</Amount>
            </TitleContainer>
            <DoorContainer $selected={selected}>
                <AutoSizer>
                    {({height, width}: {height: number; width: number}) => {
                        return (
                            <GridPaginator
                                totalRecords={doors?.pagination.total_count}
                                ref={gridReference.current}>
                                {(onItemsRendered) => {
                                    return (
                                        <FixedSizeGrid
                                            ref={gridReference}
                                            columnCount={columns}
                                            columnWidth={(width - 10) / columns}
                                            height={height}
                                            width={width}
                                            rowHeight={220}
                                            rowCount={
                                                selected
                                                    ? Math.ceil(
                                                          doors?.pagination
                                                              .total_count /
                                                              columns
                                                      )
                                                    : 1
                                            }
                                            itemData={{
                                                searchText,
                                                setSelected,
                                                selected,
                                                columns: columns,
                                                type,
                                                searchParams: {
                                                    manufacturerId:
                                                        user?.manufacturerId,
                                                    materialType: type?.id,
                                                    cabinetType,
                                                    pageSize: selected
                                                        ? 30
                                                        : columns - 1,
                                                },
                                            }}
                                            onItemsRendered={onItemsRendered}>
                                            {Door}
                                        </FixedSizeGrid>
                                    );
                                }}
                            </GridPaginator>
                        );
                    }}
                </AutoSizer>
            </DoorContainer>
            {isLast && !selected && !hideShowAllButton ? (
                <ShowAllDoors />
            ) : null}
        </>
    ) : null;
};

const DoorContainer = styled.div<{$selected: boolean}>`
    max-height: ${({$selected}) => ($selected ? 'initial' : '220px')};
    min-height: ${({$selected}) => ($selected ? 'initial' : '220px')};
    overflow: hidden;
    flex: 1;
`;

export const TitleContainer = styled.h1`
    min-height: 32px;
    background: white !important;
    margin-top: 15px;
    margin-bottom: 0;
    border-bottom: 2px solid rgb(var(--secondary_colour));
    border-radius: 0 !important;
    padding: 0 !important;
    position: relative;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        background: rgb(var(--secondary_colour)) !important;
        border: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const Title = styled.span`
    position: relative;
    padding: 5px 80px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 1em;
    font-weight: 800;
    color: white;
    background: rgb(var(--secondary_colour));
    height: 100%;

    > img {
        position: absolute;
        width: 22px !important;
        right: 5px;
        cursor: pointer;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        height: initial;
        padding: 5px;
        width: 100%;
        border-radius: 0;
        margin: 0;
        font-size: 0.85em;
        flex: 2;
        position: initial;

        > img {
            top: 5px;
            right: 4px;
        }
    }
`;

const Amount = styled.div`
    position: absolute;
    color: rgb(var(--secondary_colour));
    font-weight: 600;
    right: 5px;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        position: initial;
        color: white;
        font-size: 0.85em;
        flex: 1;
    }
`;
