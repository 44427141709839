import React, {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    doorShowAllSet,
    doorShowAllUnset,
    selectCabinetType,
    selectMaterialTypes,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {MaterialType} from 'components/customer/AdvancedMaterials/Doors/MaterialType';
import styled from 'styled-components';
import {LoadingMaterial} from 'components/customer/AdvancedMaterials/Materials/Material';
import {Spinner} from 'react-bootstrap';

export const Doors = ({container}: {container: RefObject<HTMLDivElement>}) => {
    const dispatch = useAppDispatch();
    const materialTypes = useAppSelector(selectMaterialTypes, shallowEqual);
    const cabinetType = useAppSelector(selectCabinetType);
    const [selected, setSelected] = useState<number>();
    const [hasData, setHasData] = useState<{[key: string]: boolean}>({});
    const [maxWidth, setMaxWidth] = useState<number>();

    const hideShowAllButton = useMemo(() => {
        if (materialTypes && materialTypes.length > 0) {
            return materialTypes.every((materialType) => materialType.selected);
        }

        return false;
    }, [materialTypes]);

    const handleSelection = useCallback(
        (index: number, typeId: string) => () => {
            setSelected(index);
            dispatch(doorShowAllSet(typeId));
        },
        []
    );

    const handleHasData = useCallback(
        (index: string) => (hasData: boolean) => {
            setHasData((prev) => ({...prev, [index]: hasData}));
        },
        []
    );

    const closeSelected = useCallback(() => {
        setSelected(undefined);
        dispatch(doorShowAllUnset());
    }, []);

    useEffect(() => {
        if (container && 'current' in container) {
            const data = container.current.getBoundingClientRect();

            setMaxWidth(Math.ceil(data.width));
        }
    }, [container]);

    useEffect(() => {
        return () => {
            closeSelected();
        };
    }, []);

    const showNoDataMessage = useMemo(() => {
        if (materialTypes && Object.keys(hasData).length > 0) {
            return materialTypes
                .filter((materialType) => materialType.selected)
                .map((materialType) => !!hasData[String(materialType.id)])
                .every((hasData) => !hasData);
        }

        return false;
    }, [hasData, materialTypes]);

    if (materialTypes && materialTypes.length > 0) {
        return (
            <>
                {showNoDataMessage ? (
                    <NoDoorMessage>No doors available.</NoDoorMessage>
                ) : null}
                <DoorContainer $selected={typeof selected != 'undefined'}>
                    {materialTypes
                        .filter((materialType) => materialType.selected)
                        .filter(
                            (_, index) =>
                                typeof selected === 'undefined' ||
                                (typeof selected !== 'undefined' &&
                                    index === selected)
                        )
                        .map((materialType, index) => (
                            <MaterialType
                                hideShowAllButton={hideShowAllButton}
                                selected={typeof selected !== 'undefined'}
                                setSelected={handleSelection(
                                    index,
                                    materialType.id
                                )}
                                unsetSelected={closeSelected}
                                key={materialType.id}
                                type={materialType}
                                cabinetType={cabinetType}
                                setHasData={handleHasData(materialType.id)}
                                isLast={
                                    index ===
                                    materialTypes.filter(
                                        (type) => type.selected
                                    ).length -
                                        1
                                }
                                maxWidth={maxWidth}
                            />
                        ))}
                </DoorContainer>
            </>
        );
    } else {
        return (
            <LoadingMaterial>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </LoadingMaterial>
        );
    }
};

const NoDoorMessage = styled.strong`
    margin-top: 15px;
`;

const DoorContainer = styled.div<{$selected: boolean}>`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: ${({$selected}) => ($selected ? 'initial' : 'auto')};
`;
