// @flow
import {useProductQuantity} from 'hooks';
import React, {useEffect} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {useJobContext} from 'contexts';

type ProductQuantityType = {
    value: number,
    productDetails: {
        id: number,
    },
};

export const ProductQuantity = ({
    value,
    productDetails,
}: ProductQuantityType): React$Element<any> => {
    const {
        quantity,
        decreaseQuantity,
        increaseQuantity,
        updateQuantity,
        updateProduct,
    } = useProductQuantity(value, productDetails);
    const {isPageDirty, setIsPageDirty, job} = useJobContext();

    useEffect(() => {
        if (value != quantity) {
            // Add product details in dirty ref
            const index = isPageDirty.findIndex(
                (e) => e.id === productDetails.id
            );
            if (index == -1) {
                setIsPageDirty([
                    ...isPageDirty,
                    {id: productDetails.id, dirty: true},
                ]);
            }
        } else {
            isPageDirty.length &&
                setIsPageDirty(
                    isPageDirty.filter(function (item) {
                        return item.id !== productDetails.id;
                    })
                );
        }
    }, [quantity]);

    return (
        <>
            <InputGroup className="cbc-number product-quantity">
                <InputGroup.Prepend>
                    <Icon
                        iconName="Button-Minus.svg"
                        style={{opacity: quantity == 1 ? 0.5 : 1}}
                        onClick={decreaseQuantity}
                    />
                </InputGroup.Prepend>
                <Form.Control
                    type="number"
                    name="product"
                    value={quantity}
                    onChange={updateQuantity}
                    style={{textAlign: 'center'}}
                />
                <InputGroup.Append>
                    <Icon
                        iconName="Button-Plus.svg"
                        onClick={increaseQuantity}
                    />
                    <Icon
                        disabled={
                            quantity == '' || value == quantity || job.status == 5
                        }
                        iconName="Button-Tick-Secondary.svg"
                        onClick={updateProduct}
                    />
                </InputGroup.Append>
            </InputGroup>
        </>
    );
};
