import {useListBenchesQuery} from 'components/customer/BTM/store/btmApi';
import {useEffect, useMemo, useState} from 'react';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {useAppDispatch} from 'store/customer';
import {
    dimensionSet,
    joinsSet,
    cornersSet,
    coordinatesSet,
    pathsSet,
    materialSet,
    thicknessSet,
    materialTypeSet,
    edgeProfileSet,
    variationRequestSet,
    typeSet,
    defaultMaterialLoadedSet,
    defaultEdgeProfileLoadedSet,
    clearJoins,
    allButtJoinsSet,
} from 'components/customer/BTM/store/btmSlice';
import {getOptions} from 'components/customer/BTM/helper/editHelpers';
import {cloneDeep} from 'lodash';
import {mapDisplayName} from 'components/customer/BTM/helper/materialMappers';
import {mapImage} from 'components/customer/BTM/helper/mapImage';
import {useGetBenchId} from 'components/customer/BTM/helper/useGetBenchId';
import {getSelectedEdgeProfile} from 'components/customer/BTM/helper/getSelectedEdgeProfile';
import {JoinType} from 'components/customer/BTM/entity/JoinType';

export const useEditBenchtop = ({
    benchInfo = null,
    jobId = null,
}: {
    benchInfo?: Bench;
    jobId?: number;
}) => {
    const dispatch = useAppDispatch();
    const {benchId} = useGetBenchId();

    const [loading, setLoading] = useState(benchId != null);

    const {data: {data: benches} = {data: [] as Bench[]}} = useListBenchesQuery(
        {jobId: Number(jobId)},
        {skip: jobId == null || benchId == null || benchInfo != null}
    );

    const bench = useMemo(() => {
        if (benchInfo) return benchInfo;

        if (benchId && benches.length > 0) {
            const bench = benches.find((bench) => bench.id == Number(benchId));

            return bench;
        }
    }, [benches, benchId, benchInfo]);

    const setAllData = async (bench: Bench) => {
        const {dimension, joins, corners, coordinates, paths} = getOptions(
            cloneDeep(bench)
        );

        dispatch(defaultEdgeProfileLoadedSet(true));
        dispatch(typeSet(bench.shape, false));

        if (dimension) {
            dispatch(dimensionSet(dimension));
        }

        if (joins) {
            dispatch(clearJoins());
            const buttJoins = joins.filter(
                (join) => join.joinType == JoinType.BUTT_JOIN
            );
            const otherJoins = joins.filter(
                (join) => join.joinType != JoinType.BUTT_JOIN
            );
            dispatch(joinsSet(otherJoins));
            dispatch(allButtJoinsSet(buttJoins));
        }

        if (corners) {
            dispatch(cornersSet(corners));
        }

        if (coordinates) {
            dispatch(coordinatesSet(coordinates));
        }

        const {profile} = await getSelectedEdgeProfile(bench.material, paths);

        if (profile) {
            dispatch(edgeProfileSet(profile));
        } else {
            dispatch(edgeProfileSet(undefined));
        }

        if (bench.material) {
            let material = cloneDeep(bench.material);
            material = mapDisplayName(material);
            material = mapImage(material);
            if (bench.type) {
                material.type = bench.type;
            }

            dispatch(materialTypeSet(material.type));
            dispatch(materialSet(material));
            dispatch(thicknessSet(material.thickness));
            dispatch(defaultMaterialLoadedSet(true));
        }

        if (paths) dispatch(pathsSet(paths));

        if (bench.comment && bench.comment.length > 0) {
            dispatch(variationRequestSet(bench.comment));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (bench) {
            void setAllData(bench);
        }
    }, [bench]);

    return {
        loading,
    };
};
