import {
    Material,
    MaterialType,
    MaterialBrand,
    Type,
    MaterialPrefix,
} from 'components/customer/Materials/entity';

export interface LegacyMaterialInterface {
    brandId: number;
    brandName: string;
    customColour: string | boolean;
    doorFilter: string;
    doubleSided: boolean;
    is_double_sided: boolean;
    finish: string;
    fullName: string;
    horGrain: boolean;
    is_grained: boolean;
    id: number;
    image: string;
    length: number;
    matchingEdgeColourId?: number;
    minJobArea: number;
    name: string;
    prefixId: number;
    prefixName: string;
    substrate: string;
    thickness: number;
    typeId: number;
    typeName: string;
    width: number;
    hidden?: boolean;
    type_name?: string;
    door_filter?: string;
    default_length?: number;
    default_width?: number;
    double_sided?: number;
    hor_grain?: number;
    type?: number;
    type_id?: number;
    prefix_name?: string;
    prefix_id?: number;
    brand_name?: string;
    brand?: number;
}

export const mapMaterial = (material: LegacyMaterialInterface): Material => {
    return <Material>{
        id: material.id,
        manufacturer_id: 0,
        prefix_id: material.prefixId,
        brand_id: material.brandId,
        type_id: material.typeId,
        name: material.name,
        image: `/${material.image}`,
        finish: material.finish,
        substrate: material.substrate,
        thickness: material.thickness,
        prefix_name: material.prefixName,
        door_filter: material.doorFilter,
        is_grained: material.horGrain,
        is_double_sided: material.doubleSided,
        double_sided_cost: 0,
        handling_cost: 0,
        area_cost: 0,
        area_assembly_cost: 0,
        min_job_area: material.minJobArea,
        length: material.length,
        width: material.width,
        gfp_name: '',
        gfp_brand_id: 0,
        gfp_type_id: 0,
        gfp_finish_id: 0,
        is_deleted: false,
        is_hidden: material.hidden,
        brand: {
            id: material.brandId,
            name: material.brandName,
            is_deleted: false,
        } as MaterialBrand,
        type: {
            id: material.typeId,
            name: material.typeName,
            is_deleted: false,
            is_vinyl: false,
            is_editable: false,
        } as Type,
        prefix: {
            id: material.prefixId,
            name: material.prefixName,
            description: '',
            is_deleted: false,
        } as MaterialPrefix,
        displayName: `${material.brandName} ${material.finish} ${material.substrate} ${material.thickness}mm ${material.name}`,
        is_custom_colour:
            material.name.toLowerCase().indexOf('custom colour') > -1,
    };
};

export const mapMaterialSetting = (
    material: Material,
    type: MaterialType = MaterialType.EXTERIOR
) => {
    if (type == MaterialType.EXTERIOR) {
        return {
            exterior_color: material.image,
            cabinet_ext_colour: {thickness: material.thickness},
        };
    } else {
        return {
            carcase_color: material.image,
            cabinet_carc_colour: {thickness: material.thickness},
        };
    }
};

export const mapMaterialBack = (material: Material) => {
    return {
        brandId: material.brand.id,
        brandName: material.brand.name,
        customColour: material.is_custom_colour,
        doorFilter: material.door_filter,
        doubleSided: material.is_double_sided,
        finish: material.finish,
        fullName: material.displayName,
        horGrain: material.is_grained,
        id: material.id,
        image: material.image.startsWith('/')
            ? material.image.slice(1)
            : material.image,
        length: material.length,
        minJobArea: material.min_job_area,
        name: material.name,
        prefixId: material.prefix_id,
        prefixName: material.prefix_name,
        substrate: material.substrate,
        thickness: material.thickness,
        typeId: material.type.id,
        typeName: material.type.name,
        width: material.width,
        hidden: material.is_hidden,
    } as LegacyMaterialInterface;
};
