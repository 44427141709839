import React, {useCallback, useMemo, useState} from 'react';
import {Card} from 'react-bootstrap';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import styled from 'styled-components';
import {AddEditAddress} from 'components/customer/Settings/address/AddEditAddress';
import {
    useDeleteDeliveryAddressMutation,
    useUpdateDefaultMutation,
} from 'components/customer/Settings/store/settingsApi';
import {Icon} from 'shared/helpers';
import {useAppContext} from 'contexts';
import {useConfirmationDialog} from 'shared';
import {useAppDispatch} from 'store/customer';
import {deliveryAddressLoaderSet} from 'components/customer/Settings/store/settingsSlice';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

export const AddressCard = ({
    address = {} as DeliveryAddress,
    deleteAddress,
    className = '',
    editButtonFunction,
    isEditButtonVisible = false,
    noButton = false,
}: {
    address?: DeliveryAddress;
    deleteAddress?: (id: null | number) => void;
    className?: string;
    editButtonFunction?: (e: React.MouseEvent<HTMLElement>) => void;
    isEditButtonVisible?: boolean;
    noButton?: boolean;
}) => {
    const {places} = useAppContext();
    const dispatch = useAppDispatch();
    const [editAddress, setEditAddress] = useState(false);
    const [deleteDeliveryAddress] = useDeleteDeliveryAddressMutation();
    const [defaultAddress] = useUpdateDefaultMutation();
    const {dialog, showDialog} = useConfirmationDialog();
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    const handleEdit = useCallback(() => {
        setEditAddress(!editAddress);
    }, [editAddress]);
    const handleClick = useCallback(() => {
        showDialog({
            title: 'Delete Address',
            message: 'Are you sure you want to delete this address?',
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Yes',
                    variant: 'danger',
                    show: true,
                    action: () => {
                        dispatch(deliveryAddressLoaderSet(true));
                        void deleteDeliveryAddress({
                            addressId: address.id,
                        })
                            .then()
                            .catch((error) => {
                                console.log(error);
                            });
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                },
            ],
        });
    }, [address, showDialog]);

    const handleDefault = useCallback(() => {
        showDialog({
            title: 'Default Address',
            message: 'Are you sure you want to set this as default address?',
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: 'Yes',
                    variant: 'danger',
                    show: true,
                    action: () => {
                        dispatch(deliveryAddressLoaderSet(true));
                        void defaultAddress({
                            addressId: address.id,
                        })
                            .then()
                            .catch((error) => {
                                console.log(error);
                            });
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                },
            ],
        });
    }, [address]);

    const currentStateName = useMemo(() => {
        if (!places.hasOwnProperty('Countries')) {
            return '';
        }

        const state = Object.values(places.Countries)
            .map((c) => c.find((s) => s.id == address.state))
            .find((s) => !!s);

        if (!state) {
            return '';
        }

        return state.name;
    }, [places, address]);

    const formattedAddress = useMemo(() => {
        if (address) {
            const components = [];

            if (Array.isArray(address.street)) {
                components.push(...address.street);
            } else {
                components.push(address.street);
            }

            if (address.city) {
                components.push(address.city);
            }

            components.push(address.suburb);

            if (currentStateName != '') {
                components.push(currentStateName);
            }

            components.push(address.postcode);

            return components.join(', ');
        }
    }, [address, currentStateName]);

    return editAddress || address.id == null ? (
        <>
            {dialog}
            <AddEditAddress
                address={address}
                handleEdit={handleEdit}
                handleDelete={address.id == null ? deleteAddress : null}
            />
        </>
    ) : address.is_default && !noButton ? (
        <>
            {dialog}
            <StyledCard
                className={className}
                key={address.id}
                $default={address.is_default}
                $stack={isSmallDevice || isTabletSize}>
                <StyledTableHeader>
                    <strong>Default</strong>
                </StyledTableHeader>
                <StyledBody>
                    <div>
                        <StyledTitle $default={address.is_default}>
                            {address.name}
                        </StyledTitle>
                        <Card.Text>{formattedAddress}</Card.Text>
                    </div>
                    <StyledDiv>
                        <Icon
                            iconName="Options-Delete.svg"
                            style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '7px',
                                cursor: 'pointer',
                            }}
                            onClick={handleClick}
                        />
                        <Icon
                            iconName="Options-Edit.svg"
                            style={{
                                width: '25px',
                                height: '25px',
                                cursor: 'pointer',
                            }}
                            onClick={handleEdit}
                        />
                    </StyledDiv>
                </StyledBody>
            </StyledCard>
        </>
    ) : (
        <>
            {dialog}
            <StyledCard
                key={address.id}
                $default={address.is_default}
                className={className}
                $stack={isSmallDevice || isTabletSize}>
                <StyledBody>
                    <div>
                        <StyledTitle $default={address.is_default}>
                            {address.name}
                        </StyledTitle>
                        <Card.Text>{formattedAddress}</Card.Text>
                    </div>
                    {noButton ? null : (
                        <StyledDiv>
                            <Icon
                                iconName="Delivery-Default.svg"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    marginRight: '7px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleDefault}
                            />
                            <Icon
                                iconName="Options-Delete.svg"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    marginRight: '7px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClick}
                            />
                            <Icon
                                iconName="Options-Edit.svg"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleEdit}
                            />
                        </StyledDiv>
                    )}
                    {isEditButtonVisible ? (
                        <StyledDiv>
                            <Icon
                                iconName="Options-Edit.svg"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    cursor: 'pointer',
                                }}
                                onClick={editButtonFunction}
                            />
                        </StyledDiv>
                    ) : null}
                </StyledBody>
            </StyledCard>
        </>
    );
};

export const StyledBody = styled(Card.Body)`
    display: flex;
    justify-content: space-between;
    padding: 15px;
`;

const StyledTableHeader = styled(Card.Header)`
    background: rgb(var(--secondary_colour));
    color: white;
    border-radius: 15px 15px 0 0 !important;
    padding: 7px 15px;
`;

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTitle = styled(Card.Title)`
    margin-bottom: 4px;
    ${(props) => {
        if (!props?.$default) {
            return `
            font-size: 17px;
            `;
        }
    }};
`;

const StyledCard = styled(Card)<{$stack?: boolean}>`
    width: ${({$stack}) => ($stack ? '100%' : '42rem')};
    margin-top: 15px;
    border-radius: 15px;
    background: #f4f4f4;
    ${(props) => {
        if (props?.$default) {
            return `
            margin-bottom: 20px;
            background: rgb(var(--table_row_primary));
            `;
        }
    }};

    &.address-selection {
        ${StyledBody} {
            padding: 0;
            padding-left: 15px;
        }

        ${StyledTitle} {
            font-weight: 600;
            font-size: 16px;
        }

        p.card-text {
            font-size: 16px;
        }
    }
`;
