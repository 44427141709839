import {Corner} from 'components/customer/BTM/entity/Corner';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Point} from 'components/customer/BTM/entity/Point';
import {cloneDeep} from 'lodash';

interface DirectionMap {
    point: number[];
    direction: Direction;
}

interface TranslationMap {
    from: Direction;
    to: Direction;
}

export const translationMap: TranslationMap[] = [
    {from: Direction.RIGHT_UP, to: Direction.RIGHT_DOWN},
    {from: Direction.RIGHT, to: Direction.DOWN},
    {from: Direction.RIGHT_DOWN, to: Direction.LEFT_DOWN},
    {from: Direction.DOWN, to: Direction.LEFT},
    {from: Direction.LEFT_DOWN, to: Direction.LEFT_UP},
    {from: Direction.LEFT, to: Direction.UP},
    {from: Direction.LEFT_UP, to: Direction.RIGHT_UP},
    {from: Direction.UP, to: Direction.RIGHT},
];

export const directionMap: DirectionMap[] = [
    {
        point: [1, -1],
        direction: Direction.RIGHT_UP,
    },
    {
        point: [1, 0],
        direction: Direction.RIGHT,
    },
    {
        point: [1, 1],
        direction: Direction.RIGHT_DOWN,
    },
    {
        point: [0, 1],
        direction: Direction.DOWN,
    },
    {
        point: [-1, 1],
        direction: Direction.LEFT_DOWN,
    },
    {
        point: [-1, 0],
        direction: Direction.LEFT,
    },
    {
        point: [-1, -1],
        direction: Direction.LEFT_UP,
    },
    {
        point: [0, -1],
        direction: Direction.UP,
    },
];

/**
 * This function determines the direction and direction vector
 * for a line. Where, direction vector just indicates how x and y
 * coordinates should move. for eg. x = 1 means it goes to the right,
 * x = 0 means it does not move and x = -1 means it goes to the left.
 *
 * @param {number[]} pointA coordinates that makes up a line
 * @param {number[]} pointB other coordinates that makes up a line
 * @return {DirectionMap}
 */
export const getDirectionalVector = (pointA: number[], pointB: number[]) => {
    const [x1, y1] = pointA;
    const [x2, y2] = pointB;

    const v1 = x2 - x1;
    const v2 = y2 - y1;

    const absV1 = v1 / Math.abs(v1);
    const absV2 = v2 / Math.abs(v2);

    const vector = [isNaN(absV1) ? 0 : absV1, isNaN(absV2) ? 0 : absV2];
    const direction = directionMap.find(
        (map) => map.point[0] == vector[0] && map.point[1] == vector[1]
    );

    if (direction) return direction;
};

/**
 * This function takes the direction of two lines that make
 * up a corner and returns the deduction that can be used to
 * determine the coordinates of the cutoffs
 *
 * @param {Direction} directionA Direction of the first line
 * @param {Direction} directionB Direction of the second line
 * @return {(number[])[]}
 */
export const getDeduction = (directionA: Direction, directionB: Direction) => {
    if (directionA == Direction.RIGHT && directionB == Direction.DOWN) {
        return [
            [-1, 0],
            [-1, 1],
            [0, 1],
        ];
    } else if (directionA == Direction.DOWN && directionB == Direction.LEFT) {
        return [
            [0, -1],
            [-1, -1],
            [-1, 0],
        ];
    } else if (directionA == Direction.LEFT && directionB == Direction.UP) {
        return [
            [1, 0],
            [1, -1],
            [0, -1],
        ];
    } else if (directionA == Direction.UP && directionB == Direction.RIGHT) {
        return [
            [0, 1],
            [1, 1],
            [1, 0],
        ];
    }
};

interface CoordinateByIndex {
    index: number;
    coordinates: Point[];
}

/**
 * This function takes direction of two lines that makes up a corner
 * and returns back the direction of the notch.
 *
 * @param {Direction} first direction of the first line
 * @param {Direction} second direction of the second line
 * @return {Direction}
 */
export const getCornerDirection = (first: Direction, second: Direction) => {
    if (first == Direction.RIGHT && second == Direction.DOWN) {
        return Direction.LEFT_DOWN;
    } else if (first == Direction.DOWN && second == Direction.LEFT) {
        return Direction.LEFT_UP;
    } else if (first == Direction.LEFT && second == Direction.UP) {
        return Direction.RIGHT_UP;
    } else if (first == Direction.UP && second == Direction.RIGHT) {
        return Direction.RIGHT_DOWN;
    }
};

interface CutoffsReturn {
    points: Point[];
    corners?: Corner[];
}

/**
 * This function basically takes corners and coordinates
 * before implementing cutoffs and calculates the new coordinates
 * and Corner information.
 *
 * @param {Corner[]} corners Corners data before implementing the cutoffs
 * @param {Point[]} points Coordinates of bench shape
 * @return {CutoffsReturn}
 */
export const cutoffs = (corners: Corner[], points: Point[]): CutoffsReturn => {
    if (typeof corners == 'undefined') {
        return {points};
    }

    const allCoordinates: CoordinateByIndex[] = [];
    const updatedCorners = cloneDeep(corners);

    corners.forEach((corner, index) => {
        const lastPoint = index - 1 < 0 ? corners.length - 1 : index - 1;
        const nextPoint = index + 1 > corners.length - 1 ? 0 : index + 1;
        const updatedCorner = updatedCorners[Number(index)];
        const side = points[Number(index)].side;

        const coordinates = [
            points[Number(lastPoint)],
            points[Number(index)],
            points[Number(nextPoint)],
        ];
        const [start, corner_, end] = coordinates.map((coordinate) => [
            Number(coordinate.x),
            Number(coordinate.y),
        ]);
        updatedCorner.point = {x: corner_[0], y: corner_[1]};

        // determining the direction of the line that is made by previous and current points
        const firstDirection = getDirectionalVector(start, corner_).direction;
        // determining the direction of the line that is made by current and next points
        const secondDirection = getDirectionalVector(corner_, end).direction;

        const direction = getCornerDirection(firstDirection, secondDirection);
        updatedCorners[Number(index)].direction = direction;

        const deductions = getDeduction(firstDirection, secondDirection);

        if (deductions) {
            updatedCorner.isJoin = false;
            const [depthX, depthY] = corner.depths;

            let cutoffCoordinates = deductions.map(
                (deduction) =>
                    ({
                        x: corner_[0] + deduction[0] * depthX,
                        y: corner_[1] + deduction[1] * depthY,
                    } as Point)
            );
            cutoffCoordinates[2].side = side;

            if (corner.type == CornerType.Clip) {
                cutoffCoordinates = cutoffCoordinates.filter((_, i) => i != 1);
            }

            allCoordinates.push({
                index,
                coordinates: cutoffCoordinates,
            });
        } else {
            updatedCorner.isJoin = true;
            allCoordinates.push({
                index,
                coordinates: [],
            });
        }
    });

    let elapsedIndex = 0;
    allCoordinates.forEach(({index: pointIndex, coordinates}) => {
        const corner = corners[Number(pointIndex)];

        if (corner.cutoff && coordinates.length > 0) {
            coordinates.reverse().forEach((coordinate) => {
                points.splice(elapsedIndex, 0, coordinate);
            });

            points.splice(elapsedIndex + coordinates.length, 1);
            elapsedIndex += coordinates.length;
        } else {
            elapsedIndex += 1;
        }
    });

    return {
        points,
        corners: updatedCorners,
    };
};
