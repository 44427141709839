import DBAbstract from "./DBAbstract";

class InfoRepository extends DBAbstract {
    constructor() {
        super();

        this.record = {
            info : 'more_info'
        };
    }

    async addInfo (data) { return await this.add(this.record.info, data, true) }

    async deleteInfo (id) { return await this.delete(this.record.info, id) }

    async getInfo (id) { return await this.readById(this.record.info, id, undefined, true); }
}

export default InfoRepository;