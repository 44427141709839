import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {
    Door,
    Material,
    MaterialType,
} from 'components/customer/Materials/entity';
import {MaterialSearchRequest} from 'components/customer/Materials/store/materialApi';
import {
    SearchLayout,
    MaterialListItem,
    DoorImage,
} from 'components/customer/Materials/SearchLayout';
import {genericMessageHandler} from 'shared/helpers';
import {useNotificationContext} from 'contexts';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useAppDispatch} from 'store/customer';
import {
    doorPageSet,
    doorsAdd,
    doorsSet,
    searchDoorSet,
} from 'components/customer/Materials/store/materialSlice';
import {isEqual, groupBy, intersection} from 'lodash';
import {useSearchParams} from 'react-router-dom';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {connect} from 'react-redux';
import {doorMapping} from 'components/customer/Materials/store/selectors/doorSelector';
import {doorSetMiddleware} from 'components/customer/Materials/store/middleware/doorSetMiddleware';
import {addAppListener} from 'store/customer/listenerMiddlewareSetup';
import {Action} from '@reduxjs/toolkit';
import {getAllDoorFilters} from 'components/customer/Materials/helper/hasSameDoorFilters';
import {useSearchDoorsQueryWithAbort} from 'components/customer/Materials/helper/useMaterialsQueryWithAbort';

export interface DoorSearchInterface {
    defaultPlaceholder: string;
    material?: Material;
    setMaterial: (
        material: Material,
        updateValue?: boolean,
        updateValueOnly?: boolean
    ) => void;
    setDoor: (door: Door) => void;
    materialType: MaterialType;
    door?: Door;
    doors?: Door[];
    pageNumber?: number;
    isDefaultLoaded?: boolean;
    isQFP?: boolean;
    index?: number;
    cabinetType?: string;
    setEdgeSearch?: (search: boolean, index?: number) => Action;
    searchDoor?: boolean;
}

const DoorSearch = ({
    defaultPlaceholder,
    material,
    setMaterial,
    setDoor,
    door: selectedDoor,
    doors,
    pageNumber: doorPageNumber,
    isDefaultLoaded,
    isQFP,
    index = 0,
    cabinetType,
    setEdgeSearch,
    searchDoor,
}: DoorSearchInterface) => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const [searchParams] = useSearchParams();

    const [keywords, setKeywords] = useState('');
    const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
    const [showPagination, setShowPagination] = useState(false);

    const doorFiltersRef = useRef<string[]>([]);
    const materialRef = useRef<Material>();
    const manualSearch = useRef(false);

    const {data: userProfile} = useGetUserQuery();
    const {isLoading, isFetching, isUninitialized, abort, search} =
        useSearchDoorsQueryWithAbort();

    const selectHandler = (door: Door) => {
        if (selectedDoor && door.id == selectedDoor.id) return;

        setPlaceholder(door.name);
        setDoor(door);
        // If new door is selected search for new edge
        dispatch(setEdgeSearch(true, index));
        dispatch(searchDoorSet(false, index));
    };

    const searchDoors = async (
        name = '',
        selectNew = false,
        currentPage = 1,
        pageSize = 20
    ) => {
        try {
            const params: MaterialSearchRequest = {
                keywords: doorFiltersRef.current.join(','),
                manufacturerId: userProfile.manufacturerId,
                currentPage,
            };

            if (name != '') {
                params.doorName = name;
                params.pageSize = pageSize;
            }

            if (searchParams.has('product')) {
                params.cabinetType = parseInt(searchParams.get('product'));
            }

            if (typeof cabinetType != 'undefined') {
                params.cabinetType = parseInt(cabinetType);
            }

            const {
                data: {data: doorsList, pagination} = {
                    data: [] as Door[],
                    pagination: {page_count: 0},
                },
                isError,
                error,
            } = await search(params);

            if (
                isError &&
                error &&
                'message' in error &&
                error?.message == 'Aborted'
            ) {
                dispatch(searchDoorSet(false, index));
                return;
            }

            if (name.length) {
                if (currentPage == 1) {
                    dispatch(doorsSet(doorsList, index));
                } else {
                    dispatch(doorsAdd(doorsList, index));
                }
                dispatch(searchDoorSet(false, index));
            } else {
                if (doorsList.length > 0) {
                    let selectedDoorInList;
                    if (selectedDoor) {
                        selectedDoorInList = (
                            currentPage == 1
                                ? doorsList
                                : [...doors, ...doorsList]
                        ).find((door) => door.id == selectedDoor.id);
                    }

                    if (
                        typeof selectedDoorInList === 'undefined' &&
                        selectNew
                    ) {
                        selectHandler(doorsList[0]);
                    } else {
                        dispatch(searchDoorSet(false, index));
                    }
                }

                if (currentPage == 1) {
                    dispatch(doorsSet(doorsList, index));
                } else {
                    dispatch(doorsAdd(doorsList, index));
                }
            }

            const hasMultiplePages = pagination.page_count > 1;
            const lastPage = currentPage == pagination.page_count;
            setShowPagination(hasMultiplePages && !lastPage);
        } catch (e) {
            dispatch(doorsSet([], index));
            genericMessageHandler(notify, {
                message: 'Could not fetch doors.',
            });
        }
    };

    const moreButtonHandler = () => {
        dispatch(doorPageSet(doorPageNumber + 1, index));
        void searchDoors(keywords, false, doorPageNumber + 1);
    };

    const doorsList = useMemo(() => {
        if (doors.length) {
            return groupBy(doors, 'suffix.name');
        }

        return {};
    }, [doors]);

    const onFocusHandler = () => {
        setPlaceholder('Type here to begin search');
        if (keywords == '') {
            void searchDoors('', false);
        }
    };

    const onBlurHandler = useCallback(() => {
        if (selectedDoor) {
            setPlaceholder(selectedDoor.name);
        }
    }, [selectedDoor]);

    useEffect(() => {
        if (doors.length == 0 && showPagination) {
            setShowPagination(false);
        }
    }, [doors]);

    useEffect(() => {
        if (selectedDoor && selectedDoor.name != placeholder) {
            setPlaceholder(selectedDoor.name);
        }
    }, [selectedDoor]);

    useEffect(() => {
        if (!searchDoor) return;
        if (!isDefaultLoaded) return;
        if (typeof material === 'undefined') return;

        const doorFilters = getAllDoorFilters(material);

        if (!isEqual(doorFilters, doorFiltersRef.current)) {
            materialRef.current = material;
            let selectNew = typeof doorFiltersRef.current != 'undefined';

            if (intersection(doorFilters, doorFiltersRef.current).length > 1) {
                selectNew = false;
            }

            doorFiltersRef.current = doorFilters;

            dispatch(doorPageSet(1, index));
            manualSearch.current = false;
            setKeywords('');

            void searchDoors('', selectNew);
        } else {
            if (material.id != materialRef?.current?.id) {
                materialRef.current = material;
                // If door filters are same but, selected materials are different search edge.
                dispatch(setEdgeSearch(true, index));
                dispatch(searchDoorSet(false, index));
            }
        }
    }, [searchDoor, isDefaultLoaded]);

    useEffect(() => {
        if (material && isDefaultLoaded) {
            doorFiltersRef.current = getAllDoorFilters(material);
        }

        if (material && material.door_filter == 'Non Supply') {
            doorFiltersRef.current = [];
        }
    }, [material, isDefaultLoaded]);

    useEffect(() => {
        if (isDefaultLoaded) {
            if (keywords.length == 0 && !manualSearch.current) {
                return;
            }

            manualSearch.current = true;
            void searchDoors(keywords);
            dispatch(doorPageSet(1, index));
        }
    }, [keywords]);

    useEffect(() => {
        if (isDefaultLoaded) {
            const unsubscribe = dispatch(
                addAppListener(doorSetMiddleware(setMaterial, index))
            );

            return () => {
                if (unsubscribe instanceof Function) unsubscribe();
            };
        }
    }, [isDefaultLoaded]);

    return (
        <SearchLayout
            onSearchClear={abort}
            data={selectedDoor}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            showTopMargin={false}
            className="door_search"
            placeholder={placeholder}
            isLoading={isLoading || isFetching}
            showPagination={showPagination}
            onClickPagination={moreButtonHandler}
            onSearchTextChange={setKeywords}
            label="Door"
            inlineSearch={isQFP}
            fieldName={`door_search_${index}`}
            inlinePreviewImage={
                isQFP && selectedDoor && selectedDoor.image?.name
            }
            inlinePreviewImageBorder={false}>
            {({setShow}) => (
                <>
                    {Object.keys(doorsList).length ||
                    isLoading ||
                    isFetching ||
                    isUninitialized ? (
                        Object.keys(doorsList).map((key, index) => {
                            const doors = doorsList[String(key)].map(
                                (door, index) => {
                                    const doorImage = door.image?.name;
                                    return (
                                        <MaterialListItem
                                            key={index}
                                            $active={
                                                selectedDoor &&
                                                door.id == selectedDoor.id
                                            }
                                            onClick={() => {
                                                setShow(false);
                                                selectHandler(door);
                                            }}>
                                            <div>
                                                {doorImage ? (
                                                    <DoorImage
                                                        src={doorImage}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div>{door.name}</div>
                                        </MaterialListItem>
                                    );
                                }
                            );

                            return (
                                <React.Fragment key={index}>
                                    {key !== 'undefined' ? (
                                        <MaterialListItem $title={true}>
                                            {parseHtmlString(key)}
                                        </MaterialListItem>
                                    ) : (
                                        <></>
                                    )}

                                    {doors}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <MaterialListItem>
                            <strong>
                                No matches found, Please try updating search
                                keywords
                            </strong>
                        </MaterialListItem>
                    )}
                </>
            )}
        </SearchLayout>
    );
};

export default connect(doorMapping)(DoorSearch);
