import {useAppDispatch} from 'store/customer';
import {
    useLazyGetDoorQuery,
    useLazyListDoorsQuery,
    useLazySearchMaterialsQuery,
} from 'components/customer/Materials/store/materialApi';
import {
    Material,
    MaterialEdge,
    Door,
    MaterialType,
} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {useProductContext} from 'contexts';
import {useRef} from 'react';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {
    filterHiddenDoor,
    stripSizeFromString,
} from 'components/customer/Materials/helper/index';
import {validationDataSet} from 'components/customer/QFPRedux/store/qfpSlice';
import {getMaterial} from 'components/customer/Materials/helper/getMaterial';
import {getEdge} from 'components/customer/Materials/helper/getEdge';
import {
    setValidationDataType,
    ActionType,
} from 'components/customer/Materials/helper/useDispatchMaterial';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';

interface DefaultMaterialsInterface {
    hasExterior: boolean;
    hasCarcase: boolean;
    hasDoor?: boolean;
    exteriorColour?: string;
    edgeExteriorColour?: string;
    carcaseColour?: string;
    edgeCarcaseColour?: string;
    door?: string;
    cabinetType?: number;
    updateMeta?: boolean;
}
interface LoadDefaultInterface {
    door?: Door;
    exterior?: Material;
    carcase?: Material;
    exteriorEdge?: MaterialEdge;
    carcaseEdge?: MaterialEdge;
}
interface UseDefaultMaterialsReturnInterface {
    loadDefaultMaterialData: (
        options: DefaultMaterialsInterface
    ) => Promise<LoadDefaultInterface>;
}

export const useDefaultMaterials = (
    page: Page = Page.PRODUCT,
    index?: number
): UseDefaultMaterialsReturnInterface => {
    const dispatch = useAppDispatch();
    const [getDoor] = useLazyGetDoorQuery();
    const [listDoors] = useLazyListDoorsQuery();
    const dispatchMaterial = useDispatchWithFormik({page, index});
    const {data: userProfile} = useGetUserQuery();
    const {setValidationData} = useProductContext<{
        setValidationData: setValidationDataType;
    }>(page);

    const doorFiltersRef = useRef<Material[]>([]);
    const selectedExtMaterial = useRef<Material>();
    const selectedExtEdgeMaterial = useRef<MaterialEdge>();
    const selectedCarcMaterial = useRef<Material>();
    const selectedCarcEdgeMaterial = useRef<MaterialEdge>();
    const [search] = useLazySearchMaterialsQuery();

    const getDoorCB = async (
        keywords: string,
        cabinetType: number,
        manufacturerId: number,
        cache = true
    ) => await getDoor({keywords, cabinetType, manufacturerId}, cache).unwrap();

    const setMaterialAndEdge = async (
        material: string,
        edge: string,
        cabinetType: number,
        materialType: MaterialType = MaterialType.EXTERIOR
    ) => {
        const colorAction =
            materialType == MaterialType.EXTERIOR
                ? ActionType.ExteriorColour
                : ActionType.CarcaseColour;
        const edgeAction =
            materialType == MaterialType.EXTERIOR
                ? ActionType.ExteriorEdgeColour
                : ActionType.CarcaseEdgeColour;
        let selectedMaterial: Material;

        try {
            const materialData = await getMaterial(
                material,
                cabinetType,
                userProfile.manufacturerId,
                index
            );

            if (materialData) {
                // NOTE: Kill this code when door_filter is sorted out
                // as this is sending redundant network request just
                // to fetch door filters.
                let doorFilters = materialData?.door_filters || [];
                if (
                    colorAction == ActionType.ExteriorColour &&
                    doorFilters.length == 0
                ) {
                    const options = {
                        keywords: stripSizeFromString(
                            materialData?.displayName
                        ),
                        currentPage: 1,
                        materialType: 0,
                    };

                    const {data} = await search(options, true).unwrap();
                    doorFilters = data[0]?.door_filters || [];

                    if (doorFilters.length) {
                        doorFiltersRef.current = doorFilters;
                    }
                } else {
                    doorFiltersRef.current = [materialData];
                }

                dispatchMaterial(
                    colorAction,
                    {...materialData, door_filters: doorFilters},
                    true,
                    false,
                    page != Page.QFP
                );
                selectedMaterial = materialData;
                if (materialType == MaterialType.EXTERIOR) {
                    selectedExtMaterial.current = selectedMaterial;
                } else {
                    selectedCarcMaterial.current = selectedMaterial;
                }

                const edgeMaterial = await getEdge(
                    cabinetType,
                    userProfile.manufacturerId,
                    selectedMaterial,
                    edge,
                    Number(material) != selectedMaterial.id
                );

                if (edgeMaterial) {
                    if (materialType == MaterialType.EXTERIOR)
                        selectedExtEdgeMaterial.current = edgeMaterial;
                    else selectedCarcEdgeMaterial.current = edgeMaterial;

                    dispatchMaterial(
                        edgeAction,
                        edgeMaterial,
                        true,
                        false,
                        page != Page.QFP
                    );
                }
            }
        } catch (e) {
            throw new Error('Could not find material');
        }
    };

    const loadDefaultMaterialData = async ({
        hasExterior,
        hasCarcase,
        hasDoor = true,
        exteriorColour,
        edgeExteriorColour,
        carcaseColour,
        edgeCarcaseColour,
        door,
        cabinetType,
        updateMeta = true,
    }: DefaultMaterialsInterface): Promise<LoadDefaultInterface> => {
        if (hasExterior) {
            await setMaterialAndEdge(
                exteriorColour,
                edgeExteriorColour,
                cabinetType
            );
        }

        if (hasCarcase) {
            await setMaterialAndEdge(
                carcaseColour,
                edgeCarcaseColour,
                cabinetType,
                MaterialType.CARCASE
            );
        }

        if (hasDoor && userProfile) {
            let defaultDoor: Door;
            let roomDefaultDoor: Door;
            try {
                const {data} = await getDoorCB(
                    door,
                    cabinetType,
                    userProfile.manufacturerId
                );
                roomDefaultDoor = data;
            } catch (e) {
                // failed or restricted
            }

            if (
                roomDefaultDoor &&
                [roomDefaultDoor].filter(filterHiddenDoor).length > 0 &&
                roomDefaultDoor.filter_name ==
                    selectedExtMaterial.current.door_filter
            ) {
                defaultDoor = roomDefaultDoor;
                dispatchMaterial(
                    ActionType.Door,
                    defaultDoor,
                    updateMeta,
                    false,
                    page != Page.QFP,
                    true
                );
            } else {
                const {data: availableDoors} = await listDoors(
                    {
                        keywords: selectedExtMaterial.current.door_filter,
                        manufacturerId: userProfile.manufacturerId,
                        pageSize: 100,
                        cabinetType,
                    },
                    true
                ).unwrap();

                if (availableDoors.length) {
                    defaultDoor = roomDefaultDoor;
                    let getFromList = false;

                    if (typeof defaultDoor === 'undefined') {
                        getFromList = true;
                    }

                    // Checking if the doorFilters of exterior color
                    // and selected door matches.
                    if (doorFiltersRef.current.length > 0 && !getFromList) {
                        const door = doorFiltersRef.current.find(
                            (door) =>
                                door.door_filter == defaultDoor.filter_name
                        );
                        // If door filters does not match, assign a door from
                        // availableDoors list at that data is queries using
                        // exterior materials door filter/filters
                        if (typeof door === 'undefined') {
                            getFromList = true;
                        }
                    }

                    if (getFromList) defaultDoor = availableDoors[0];

                    dispatchMaterial(
                        ActionType.Door,
                        defaultDoor,
                        updateMeta,
                        false,
                        page != Page.QFP,
                        true
                    );
                }
            }

            return {
                door: defaultDoor,
                exterior: selectedExtMaterial.current,
                carcase: selectedCarcMaterial.current,
                exteriorEdge: selectedExtEdgeMaterial.current,
                carcaseEdge: selectedCarcEdgeMaterial.current,
            };
        } else {
            if (page == Page.QFP) {
                dispatch(
                    validationDataSet({cabinet_door: {advanced: false}}, index)
                );
            }

            setValidationData &&
                setValidationData({
                    type: 'single',
                    key: 'cabinet_door',
                    value: {
                        id: parseInt(door),
                        advanced: false,
                    },
                });

            return {
                exterior: selectedExtMaterial.current,
                carcase: selectedCarcMaterial.current,
                exteriorEdge: selectedExtEdgeMaterial.current,
                carcaseEdge: selectedCarcEdgeMaterial.current,
            };
        }
    };

    return {
        loadDefaultMaterialData,
    };
};
