import {PartialRoom} from 'shared/types/PartialRoom';
import {Product} from 'components/customer/Product/entity';
import {Door} from 'components/customer/Materials/entity';
import {isValidNonNegativeNumber as check} from 'shared/helpers/isValidNonNegativeNumber';

export interface PartialFormField {
    border_width_top: number;
    border_width_left: number;
    border_width_right: number;
    border_width_bottom: number;
    hori_height: number;
    vert_width: number;

    drawer_border_width_top: number;
    drawer_border_width_left: number;
    drawer_border_width_right: number;
    drawer_border_width_bottom: number;
    drawer_hori_height: number;
}

const DISABLED = 0;
const USE_ROOM_DEFAULTS = 3;

/**
 * Returns true if form field info exists and is 0 or 3
 * else returns false.
 *
 * @param {PartialFormField} object Form fields information
 * @param {string} key field name to check
 * @return {boolean}
 */
export const checkIfDisabledOrRoomDefaultValue = (
    object: PartialFormField,
    key: keyof PartialFormField
) => {
    if (key in object) {
        return (
            object.hasOwnProperty(key) &&
            (object[key] == DISABLED || object[key] == USE_ROOM_DEFAULTS)
        );
    }

    return false;
};

/**
 *
 * @param {Door} door Selected door object
 * @param {PartialFormField} formField Form fields information
 * @param {PartialRoom} room Current room's information
 * @return {Partial<Product>}
 */
export const getBorderWidths = (
    door: Door,
    formField: PartialFormField,
    room: PartialRoom
) => {
    const props: Partial<Product> = {};

    const borderWidthTopFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'border_width_top'
    );
    const borderWidthLeftFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'border_width_left'
    );
    const borderWidthBottomFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'border_width_bottom'
    );
    const borderWidthRightFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'border_width_right'
    );
    const horizontalHeightFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'hori_height'
    );
    const verticalWidthFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'vert_width'
    );

    const drawerBorderWidthTopFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'drawer_border_width_top'
    );
    const drawerBorderWidthLeftFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'drawer_border_width_left'
    );
    const drawerBorderWidthBottomFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'drawer_border_width_bottom'
    );
    const drawerBorderWidthRightFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'drawer_border_width_right'
    );
    const drawerHorizontalHeightFormField = checkIfDisabledOrRoomDefaultValue(
        formField,
        'drawer_hori_height'
    );

    // Checking and adding drawer border values
    if (drawerBorderWidthTopFormField) {
        if (room.doorStyle == door.id) {
            props.drawer_border_width_top = room.doorBorderWidthTop;
        } else if (check(door.default_border_width_top)) {
            props.drawer_border_width_top = door.default_border_width_top;
        }
    }

    if (drawerBorderWidthLeftFormField) {
        if (room.doorStyle == door.id) {
            props.drawer_border_width_left = room.doorBorderWidthLeft;
        } else if (check(door.default_border_width_left)) {
            props.drawer_border_width_left = door.default_border_width_left;
        }
    }

    if (drawerBorderWidthBottomFormField) {
        if (room.doorStyle == door.id) {
            props.drawer_border_width_bottom = room.doorBorderWidthBottom;
        } else if (check(door.default_border_width_bottom)) {
            props.drawer_border_width_bottom = door.default_border_width_bottom;
        }
    }

    if (drawerBorderWidthRightFormField) {
        if (room.doorStyle == door.id) {
            props.drawer_border_width_right = room.doorBorderWidthRight;
        } else if (check(door.default_border_width_right)) {
            props.drawer_border_width_right = door.default_border_width_right;
        }
    }

    if (drawerHorizontalHeightFormField) {
        if (room.doorStyle == door.id) {
            props.drawer_hori_height = room.doorAdvancedRailHeight;
        } else if (check(door.default_rails_horizontal_height)) {
            props.drawer_hori_height = door.default_rails_horizontal_height;
        }
    }
    // Checking and adding drawer border values

    // Checking and adding door border values
    if (borderWidthTopFormField) {
        if (room.doorStyle == door.id) {
            props.border_width_top = room.doorBorderWidthTop;
        } else if (check(door.default_border_width_top)) {
            props.border_width_top = door.default_border_width_top;
        }
    }

    if (borderWidthLeftFormField) {
        if (room.doorStyle == door.id) {
            props.border_width_left = room.doorBorderWidthLeft;
        } else if (check(door.default_border_width_left)) {
            props.border_width_left = door.default_border_width_left;
        }
    }

    if (borderWidthBottomFormField) {
        if (room.doorStyle == door.id) {
            props.border_width_bottom = room.doorBorderWidthBottom;
        } else if (check(door.default_border_width_bottom)) {
            props.border_width_bottom = door.default_border_width_bottom;
        }
    }

    if (borderWidthRightFormField) {
        if (room.doorStyle == door.id) {
            props.border_width_right = room.doorBorderWidthRight;
        } else if (check(door.default_border_width_right)) {
            props.border_width_right = door.default_border_width_right;
        }
    }

    if (horizontalHeightFormField) {
        if (room.doorStyle == door.id) {
            props.hori_height = room.doorAdvancedRailHeight;
        } else if (check(door.default_rails_horizontal_height)) {
            props.hori_height = door.default_rails_horizontal_height;
        }
    }

    if (verticalWidthFormField) {
        if (room.doorStyle == door.id) {
            props.vert_width = room.doorAdvancedRailWidth;
        } else if (check(door.default_rails_vertical_width)) {
            props.vert_width = door.default_rails_vertical_width;
        }
    }
    // Checking and adding door border values

    return props;
};
