import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {BlueBold} from './StyledElements';
import {useAppSelector} from 'store/customer';
import {selectParentApplicationDetails} from 'store/customer/brandingSlice';

const Container = styled.div<{$width?: string}>`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 auto;
    @media (max-width: 1024px) {
        width: calc(100% - 30px);
    }
    @media (min-width: 1025px) and (max-width: 1440px) {
        width: 375px;
    }
`;

const Footer = styled.div`
    text-align: left;
    margin-top: 50px;
`;

interface NoCustomerLoginProps {
    setAdminLogin: React.Dispatch<React.SetStateAction<boolean>>;
    withAdminLogin?: boolean;
}

const NoCustomerLogin = ({
    setAdminLogin,
    withAdminLogin = true,
}: NoCustomerLoginProps) => {
    const navigate = useNavigate();
    const {name: appName = 'parent application', url: appUrl} = useAppSelector(
        selectParentApplicationDetails
    );
    const onClickHandler = useCallback(() => {
        setAdminLogin(true);
        navigate('/v2/login');
    }, []);
    return (
        <Container>
            <div>
                To log in, please access this application from your account on{' '}
                {appName}.
            </div>
            <div>
                <BlueBold
                    style={{fontSize: 16}}
                    inline
                    underline
                    $pointer
                    as="a"
                    href={appUrl}>
                    {appUrl}
                </BlueBold>
            </div>
            {withAdminLogin ? (
                <Footer>
                    <BlueBold
                        style={{fontSize: 14}}
                        inline
                        $pointer
                        as="a"
                        onClick={onClickHandler}>
                        Admin Login
                    </BlueBold>
                </Footer>
            ) : null}
        </Container>
    );
};

export default NoCustomerLogin;
