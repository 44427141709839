import React, {useEffect, useMemo, useState} from 'react';
import {FormikHelpers} from 'formik';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import SetPasswordForm, {
    SetPasswordInterface,
} from 'components/customer/Auth/SetPasswordForm';
import {
    useSetPasswordMutation,
    useVerifyTokenSetPasswordMutation,
} from 'components/customer/Auth/store/accountSlice';
import {Alert, Col, Row} from 'react-bootstrap';
import {useAppDispatch} from 'store/customer';
import {messageSet} from 'components/customer/Auth/store/authSlice';

export const SetPassword = (): JSX.Element => {
    const isSmallDevice = isDeviceSmall();
    const [error, setError] = useState<string>(null);
    const [message, setMessage] = useState<string>(null);
    const [updatePassword, {isLoading, isError, isSuccess}] =
        useSetPasswordMutation();
    const [
        verifyToken,
        {data: accounts, isLoading: verifyingToken, isSuccess: tokenVerified},
    ] = useVerifyTokenSetPasswordMutation();

    const [queryParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isError) {
            setError('Unable to set password. Please try again.');
        }

        if (isSuccess) {
            setError(null);
            navigate('/v2/login');
            dispatch(
                messageSet(
                    'Your password has been successfully saved. You may now use your new password to log in.'
                )
            );
        }
    }, [isError, isSuccess]);

    useEffect(() => {
        const token = queryParams.get('token');
        verifyToken(token)
            .unwrap()
            .catch(() => {
                setError(
                    'Unable to set password for this account. Please ensure the link you have used is correct and try again.'
                );
            });
    }, []);

    const initialValues = useMemo<SetPasswordInterface>(() => {
        let values = {
            id: '',
            password: '',
            confirmPassword: '',
        };
        if (tokenVerified && !!accounts?.length) {
            values = {...values, id: `${accounts[0].id}`};
        }
        return values;
    }, [accounts, tokenVerified]);

    const onSubmitHandler = async (
        values: SetPasswordInterface,
        actions: FormikHelpers<SetPasswordInterface>
    ) => {
        const token = queryParams.get('token');
        const {id, password, confirmPassword} = values;
        await updatePassword({id, password, token, confirmPassword})
            .unwrap()
            .then(() => {
                actions.resetForm({
                    values: {
                        password: '',
                        confirmPassword: '',
                    },
                });
            });
    };

    if (verifyingToken) {
        return (
            <Row
                style={{
                    textAlign: 'center',
                    padding: isSmallDevice ? '0 15px' : 0,
                }}>
                <Col md={{offset: 3, span: 6}}>
                    <Alert variant="info">
                        Validating your password set token
                    </Alert>
                </Col>
            </Row>
        );
    }

    return (
        <SetPasswordForm
            initialValues={initialValues}
            error={error}
            message={message}
            isLoading={isLoading}
            isSuccess={isSuccess}
            headerTitle="Set your Password"
            submitButtonName="Set Password"
            cancelButtonName="Go to Login"
            onSubmit={onSubmitHandler}
        />
    );
};
