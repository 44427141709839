import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {genericMessageHandler} from 'shared/helpers';
import {Material, MaterialType} from 'components/customer/Materials/entity';
import {stripSizeFromString} from 'components/customer/Materials/helper';
import {MaterialSearchRequest} from 'components/customer/Materials/store/materialApi';
import {groupBy} from 'lodash';
import {
    MaterialListItem,
    SearchLayout,
} from 'components/customer/Materials/SearchLayout';
import {useNotificationContext} from 'contexts';
import {useSearchParams} from 'react-router-dom';
import {useAppDispatch} from 'store/customer';
import {connect} from 'react-redux';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {materialMapping} from 'components/customer/Materials/store/selectors/materialSelector';
import {Action} from '@reduxjs/toolkit';
import {ColorSwatch} from 'components/customer/Materials/ColorSwatch';
import {searchDoorSet} from 'components/customer/Materials/store/materialSlice';
import {useSearchMaterialsQueryWithAbort} from 'components/customer/Materials/helper/useMaterialsQueryWithAbort';

export interface MaterialSearchInterface {
    defaultPlaceholder: string;
    setMaterial: (
        material: Material,
        updateValue?: boolean,
        updateValueOnly?: boolean
    ) => void;
    materialType: MaterialType;
    material?: Material;
    materials?: Material[];
    pageNumber?: number;
    materialPageSet?: (page: number, index?: number) => Action;
    materialsAdd?: (materials: Material[], index?: number) => Action;
    materialsSet?: (materials: Material[], index?: number) => Action;
    isQFP?: boolean;
    index?: number;
    cabinetType?: string;
    hasDoor: boolean;
    setEdgeSearch?: (search: boolean, index?: number) => Action;
    setMaterialSearch?: (search: boolean, index?: number) => Action;
}

const MaterialSearch = ({
    defaultPlaceholder,
    setMaterial,
    materialType,
    material,
    materials,
    pageNumber,
    materialPageSet,
    materialsAdd,
    materialsSet,
    index = 0,
    isQFP,
    cabinetType,
    hasDoor,
    setEdgeSearch,
    setMaterialSearch,
}: MaterialSearchInterface) => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const [searchParams] = useSearchParams();

    const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
    const [showPagination, setShowPagination] = useState(false);
    const [keywords, setKeywords] = useState('');

    const {search, abort, isLoading, isFetching, isUninitialized} =
        useSearchMaterialsQueryWithAbort();

    const selectHandler = (selectedMaterial: Material) => {
        if (material && selectedMaterial.id == material.id) return;

        setPlaceholder(selectedMaterial.displayName);

        dispatch(setMaterialSearch(true, index));
        setMaterial(selectedMaterial);
        if (materialType == MaterialType.EXTERIOR && hasDoor) {
            dispatch(searchDoorSet(true, index));
        } else {
            // If product does not have door, search edge on material change.
            dispatch(setEdgeSearch(true, index));
        }
        dispatch(setMaterialSearch(false, index));
    };

    const dispatchMaterialSet = (materials: Material[]) => {
        dispatch(materialsSet(materials, index));
    };

    const dispatchMaterialAdd = (materials: Material[]) => {
        dispatch(materialsAdd(materials, index));
    };

    const dispatchPageNumber = (page: number) => {
        dispatch(materialPageSet(page, index));
    };

    const searchMaterialsByKeywords = async () => {
        try {
            if (keywords == '') {
                return;
            }

            const params: MaterialSearchRequest = {
                keywords: stripSizeFromString(keywords),
                currentPage: pageNumber,
                materialType,
            };

            if (searchParams.has('product')) {
                params.cabinetType = parseInt(searchParams.get('product'));
            }

            if (typeof cabinetType != 'undefined') {
                params.cabinetType = parseInt(cabinetType);
            }

            const {
                data: {data: materials, pagination} = {
                    data: [] as Material[],
                    pagination: {page_count: 0},
                },
                isError,
                error,
            } = await search(params);

            if (
                isError &&
                error &&
                'message' in error &&
                error?.message == 'Aborted'
            ) {
                return;
            }

            if (materials.length) {
                const hasMultiplePages = pagination.page_count >= 1;
                const lastPage = pageNumber == pagination.page_count;

                if (hasMultiplePages) {
                    setShowPagination(!lastPage);
                }
            } else {
                setShowPagination(false);
            }

            if (pageNumber == 1) {
                dispatchMaterialSet(materials);
            } else {
                dispatchMaterialAdd(materials);
            }
        } catch (e) {
            dispatchMaterialSet([]);
            genericMessageHandler(notify, {
                message: 'Could not fetch materials.',
            });
        }
    };

    const moreButtonHandler = () => {
        dispatchPageNumber(pageNumber + 1);
    };

    const onFocusHandler = () => {
        if (keywords == '' && material) {
            setKeywords(stripSizeFromString(material.displayName));
        }
    };

    const onBlurHandler = useCallback(() => {
        if (material) {
            setPlaceholder(material.displayName);
        }
    }, [material]);

    useEffect(() => {
        if (materials.length == 0 && showPagination) setShowPagination(false);
    }, [materials]);

    const groupedMaterials = useMemo(() => {
        if (materials.length) {
            return groupBy(materials, 'type.name');
        } else return {};
    }, [materials]);

    useEffect(() => {
        void searchMaterialsByKeywords();
    }, [keywords, pageNumber]);

    useEffect(() => {
        if (material && material.displayName != placeholder) {
            setKeywords('');
            setPlaceholder(material.displayName);
        }
    }, [material]);

    return (
        <SearchLayout
            onSearchClear={abort}
            data={material}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            className="material_search"
            keywordsDefault={keywords}
            placeholder={placeholder}
            showPagination={showPagination}
            onClickPagination={moreButtonHandler}
            isLoading={isLoading || isFetching}
            onSearchTextChange={(keywords: string) => {
                dispatchPageNumber(1);
                setKeywords(keywords);
            }}
            label="Material"
            inlineSearch={isQFP}
            fieldName={`material_search_${index}`}
            inlinePreviewImage={isQFP && material && material.image}
            hasData={Object.keys(materials).length > 0 || !isUninitialized}>
            {({setShow}) => (
                <>
                    {Object.keys(materials).length ||
                    isUninitialized ||
                    isLoading ||
                    isFetching ? (
                        Object.keys(groupedMaterials).map((key, index) => {
                            const colours = groupedMaterials[String(key)].map(
                                (thisMaterial, index) => (
                                    <MaterialListItem
                                        key={index}
                                        $active={
                                            material &&
                                            thisMaterial.id == material.id
                                        }
                                        onClick={() => {
                                            setShow(false);
                                            selectHandler(thisMaterial);
                                        }}>
                                        <div>
                                            <ColorSwatch
                                                src={thisMaterial.image}
                                            />
                                        </div>
                                        <div>
                                            {parseHtmlString(
                                                thisMaterial.highlightedDisplayName
                                            )}
                                        </div>
                                    </MaterialListItem>
                                )
                            );

                            return (
                                <React.Fragment key={index}>
                                    <MaterialListItem $title={true}>
                                        {parseHtmlString(key)}
                                    </MaterialListItem>
                                    {colours}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <MaterialListItem>
                            <strong>
                                No matches found, Please try updating search
                                keywords
                            </strong>
                        </MaterialListItem>
                    )}
                </>
            )}
        </SearchLayout>
    );
};

export default connect(materialMapping)(MaterialSearch);
