import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {Position} from 'components/customer/BTM/entity/Position';
import {PositionInterface} from 'components/customer/BTM/helper/useLabelPosition';
import {FormControl, FormLabel} from 'react-bootstrap';
import {Side, getSideSpec} from 'components/customer/BTM/entity/Side';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    buttJoinPartErrorSet,
    buttJoinsSet,
    dimensionErrorSet,
    selectButtJoinPartError,
    selectDimension,
    selectJoins,
    selectMaterial,
    selectMessageBySide,
    selectType,
    sideSet,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {ConditionalRelative} from 'components/customer/BTM/Preview/ConditionalRelative';
import {cloneDeep, isEqual} from 'lodash';
import {getDimensionError} from 'components/customer/BTM/store/middleware/dimensionUpdateMiddleware';
import {useButtJoin} from 'components/customer/BTM/helper/useButtJoin';
import {ButtJoinError} from 'components/customer/BTM/entity/ButtJoinPartError';

interface DimensionElementInterface {
    $vertical: boolean;
    $value: number;
    $scale: number;
    $deduct: number;
    $positionData: PositionInterface;
    $arrowStart: boolean;
    $arrowEnd: boolean;
    $isInvalid: boolean;
    $adjustValue?: boolean;
    $reduceOpacity?: boolean;
}

interface DimensionViewInterface {
    vertical: boolean;
    scale: number;
    positionData: PositionInterface;
    arrowStart: boolean;
    arrowEnd: boolean;
    textOnly: boolean;
    position: Position;
    side: Side;
    readOnly: boolean;
    value: number;
    adjustValue?: boolean;
    index?: number;
    benchParts?: {value: number; index: number}[];
}

const INPUT_GROUP_WIDTH = 70;
const INPUT_GROUP_HEIGHT = 25;

const getLabelLimits = (length: number, vertical: boolean, scale: number) => {
    let labelStart = length / 2 - INPUT_GROUP_HEIGHT / scale / 2;
    let labelEnd = length / 2 + INPUT_GROUP_HEIGHT / scale / 2;

    if (!vertical) {
        labelStart = length / 2 - INPUT_GROUP_WIDTH / scale / 2;
        labelEnd = length / 2 + INPUT_GROUP_WIDTH / scale / 2;
    }

    return [labelStart, labelEnd];
};

export const DimensionView = ({
    vertical,
    positionData,
    scale,
    arrowStart,
    arrowEnd,
    textOnly,
    readOnly,
    position,
    side,
    value,
    adjustValue = false,
    index,
    benchParts,
}: DimensionViewInterface) => {
    const dispatch = useAppDispatch();

    const material = useAppSelector(selectMaterial, shallowEqual);
    const shape = useAppSelector(selectType, shallowEqual);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const dimensions = useAppSelector(selectDimension, shallowEqual);

    const message = useAppSelector(
        (state) => selectMessageBySide(state, side),
        shallowEqual
    );
    const buttJoinPartError = useAppSelector(
        (state) => selectButtJoinPartError(state, side, index),
        shallowEqual
    );

    const materialRef = useRef(material);

    const [localValue, setLocalValue] = useState(value);
    const [invalidMessage, setInvalidMessage] = useState('');
    const [focus, setFocus] = useState(false);

    const {updateSide, validateParts} = useButtJoin();

    const disabled = useMemo(() => {
        if (typeof index !== 'undefined') {
            return benchParts && index == benchParts.length - 1;
        }

        return false;
    }, [index, benchParts]);

    const title = useMemo(() => {
        if (invalidMessage == '') {
            if (disabled) {
                return `Please use part beside to update this size`;
            }

            return `${String(localValue)}mm`;
        } else {
            return invalidMessage;
        }
    }, [localValue, invalidMessage, disabled]);

    const checkForLabelCollision = useCallback(() => {
        if (typeof index == 'undefined') {
            const partJoins = joins.filter((join) => join.benchSide == side);

            if (partJoins && partJoins.length > 0) {
                const length = dimensions[Number(side)];
                const [labelStart, labelEnd] = getLabelLimits(
                    length,
                    vertical,
                    scale
                );

                let previousLength = 0;
                return partJoins
                    .map((join) => {
                        const length = join.value - previousLength;
                        const [start, end] = getLabelLimits(
                            length,
                            vertical,
                            scale
                        );
                        const joinLabelStart = start + previousLength;
                        const joinLabelEnd = end + previousLength;
                        previousLength += join.value;

                        return (
                            (joinLabelStart <= labelStart &&
                                joinLabelEnd >= labelStart) ||
                            (joinLabelStart <= labelEnd &&
                                joinLabelEnd >= labelEnd)
                        );
                    })
                    .some(Boolean);
            }
        }
    }, [index, joins, side, shape, dimensions, vertical, scale]);

    const vOffset = useMemo(() => {
        if (position == Position.RightInner) {
            return -90;
        } else {
            if (vertical && checkForLabelCollision()) {
                return INPUT_GROUP_HEIGHT * -4;
            }
        }
    }, [position, shape, joins, vertical]);

    const hOffset = useMemo(() => {
        if (!vertical && checkForLabelCollision()) {
            return INPUT_GROUP_WIDTH * -2;
        }
    }, [joins, vertical]);

    const deduct = useMemo(() => {
        if (position == Position.Inner) {
            return 20;
        }

        return [
            Position.LeftInner,
            Position.RightInner,
            Position.BottomInner,
        ].includes(position)
            ? 10
            : 0;
    }, [position]);

    const useAbsolutePosition = useMemo(() => {
        if ([Shape.SQR, Shape.ANG].includes(shape.type)) {
            if (
                [Side.A, Side.E].includes(side) &&
                value * scale < INPUT_GROUP_WIDTH
            ) {
                return true;
            } else if (
                [Side.F, Side.B].includes(side) &&
                value * scale < INPUT_GROUP_HEIGHT
            ) {
                return true;
            }
        } else if (shape.type == Shape.USHAPE) {
            if ([Side.A, Side.C, Side.G].includes(side)) {
                return value * scale < INPUT_GROUP_WIDTH;
            } else if ([Side.B, Side.H].includes(side)) {
                return value * scale < INPUT_GROUP_HEIGHT;
            }
        }

        return false;
    }, [side, value, scale, shape]);

    const inputFieldPosition = useMemo(() => {
        if (
            (shape.type == Shape.USHAPE && [Side.C, Side.G].includes(side)) ||
            (shape.type == Shape.ANG && side == Side.E)
        ) {
            return 'bottom';
        }
    }, [shape, side]);

    const dispatchValue = useCallback(
        (value: number, side: Side, updateButtJoin = true) => {
            if (value % 1 === 0) {
                dispatch(sideSet(value, side, updateButtJoin));
            } else {
                dispatch(
                    sideSet(parseFloat(value.toFixed(2)), side, updateButtJoin)
                );
            }
        },
        []
    );

    const setMessage = useCallback(
        (message: string, index: number | null = null) => {
            if (index != null) {
                dispatch(
                    buttJoinPartErrorSet({
                        message,
                        index,
                        side,
                    })
                );
            } else {
                dispatch(dimensionErrorSet(message, side));
            }
        },
        [message, side]
    );

    const clearMessage = useCallback(() => {
        dispatch(dimensionErrorSet('', side));
    }, [side]);

    const updateValue = useCallback(() => {
        setFocus(false);
        try {
            if (isNaN(localValue)) {
                setLocalValue(value);
                return;
            }

            if (localValue != value || invalidMessage != '') {
                if (benchParts && benchParts.length > 0) {
                    clearMessage();
                    const part = benchParts[Number(index)];
                    if (part) {
                        const {joins: updatedJoins, errors} = updateSide(
                            index,
                            localValue,
                            side
                        );

                        for (let i = 0; i <= updatedJoins.length; i++) {
                            const error = errors.find(
                                (error) => error.index == i
                            );

                            if (error) {
                                setMessage(error.message, i);
                            } else {
                                setMessage('', i);
                            }
                        }
                        if (errors.length) {
                            errors.forEach((error) => {
                                setMessage(error.message, error.index);
                            });
                        } else {
                        }

                        dispatch(buttJoinsSet(updatedJoins, side));
                    } else {
                        dispatchValue(localValue, side);
                    }
                } else {
                    dispatchValue(localValue, side);
                    // validate the adjacent part based on depth
                    validateParts(localValue, side);
                }
            }
        } catch (e) {
            if (e instanceof ButtJoinError) {
                setMessage(e.message, index);
            } else if (e instanceof Error) {
                setMessage(e.message);
            }
        }
    }, [localValue, value, side, index, setMessage, invalidMessage]);

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value != '') {
                setLocalValue(Number(value));
            }
        },
        []
    );

    const handleOnFocus = useCallback(() => {
        setFocus(true);
    }, []);

    useEffect(() => {
        if (message) {
            setInvalidMessage(message);
            return;
        } else if (buttJoinPartError) {
            setInvalidMessage(buttJoinPartError?.message);
            return;
        }

        setInvalidMessage('');
    }, [message, buttJoinPartError]);

    useEffect(() => {
        if (localValue != value) {
            setLocalValue(value);
        }
    }, [value]);

    useEffect(() => {
        // no need to update this for bench parts
        if (material && typeof index === 'undefined') {
            if (isEqual(material, materialRef.current)) return;

            materialRef.current = material;
            const dimensionsCopy = cloneDeep(dimensions);
            dimensionsCopy[Number(side)] = localValue;

            const error = getDimensionError(
                side,
                shape.type,
                joins,
                material,
                dimensionsCopy,
                localValue
            );

            if (error == null) {
                dispatchValue(localValue, side);
            } else {
                setMessage(error);
            }
        }
    }, [material]);

    return (
        <DimensionElement
            $adjustValue={adjustValue}
            $vertical={vertical}
            $value={value}
            $positionData={positionData}
            $deduct={deduct}
            $scale={scale}
            $arrowStart={arrowStart}
            $arrowEnd={arrowEnd}
            $reduceOpacity={!textOnly && index >= 0 && !focus}
            $isInvalid={invalidMessage != ''}>
            {textOnly ? (
                <TextContainer
                    $length={value * scale}
                    $position={position}
                    $positioning={positionData}
                    $arrowStart={arrowStart}
                    $arrowEnd={arrowEnd}>
                    <div>
                        <label>
                            {getSideSpec(side).key}
                            {typeof index !== 'undefined' ? index + 1 : ''}:
                            {localValue}
                        </label>
                    </div>
                </TextContainer>
            ) : (
                <>
                    <HorizontalLine
                        $vertical={vertical}
                        $arrowStart={arrowStart}>
                        <div />
                    </HorizontalLine>
                    <ConditionalRelative
                        position={inputFieldPosition}
                        vertical={vertical}
                        offset={(value * scale) / 2}
                        isRelative={useAbsolutePosition}>
                        <BTMInputGroup
                            $inputFieldPosition={inputFieldPosition}
                            $offset={(value * scale) / 2}
                            $isAbsolute={useAbsolutePosition}
                            $side={side}
                            $position={position}
                            $vertical={vertical}
                            $vOffset={vOffset}
                            $hOffset={hOffset}
                            $hasError={invalidMessage != ''}>
                            <FormLabel>
                                {getSideSpec(side).key}
                                {typeof index !== 'undefined' ? index + 1 : ''}
                            </FormLabel>
                            <FormControl
                                type="number"
                                min={1}
                                step={0}
                                title={title}
                                readOnly={
                                    disabled ||
                                    readOnly ||
                                    typeof material == 'undefined'
                                }
                                value={localValue}
                                onChange={handleOnChange}
                                onBlur={updateValue}
                                onFocus={handleOnFocus}
                            />
                        </BTMInputGroup>
                    </ConditionalRelative>
                    <HorizontalLine $vertical={vertical} $arrowEnd={arrowEnd}>
                        <div />
                    </HorizontalLine>
                </>
            )}
        </DimensionElement>
    );
};

const DimensionElement = styled.div<DimensionElementInterface>`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    top: ${({$positionData}) => $positionData.y}px;
    left: ${({$positionData}) => $positionData.x}px;

    ${({
        $vertical,
        $value,
        $scale,
        $deduct,
        $arrowStart = false,
        $arrowEnd = false,
        $adjustValue = false,
    }) => {
        if ($vertical) {
            return `
                flex-direction: column;
                border-top: ${
                    $arrowStart ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                border-bottom: ${
                    $arrowEnd ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                height: ${$value * $scale - $deduct}px;
                width: 25px;
            `;
        } else {
            return `
                flex-direction: row;
                border-right: ${
                    $arrowEnd ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                border-left: ${
                    $arrowStart ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                width: ${$value * $scale - $deduct + ($adjustValue ? 1 : 0)}px;
            `;
        }
    }}

    .form-control {
        background: ${({$isInvalid}) =>
            $isInvalid ? '#F4CFC0' : 'white'} !important;
    }

    ${({$reduceOpacity = false}) => {
        if ($reduceOpacity) {
            return `
                opacity: 0.5;

                &:hover,
                &:focus,
                &:active {
                    opacity: 1;
                }
            `;
        }
    }}
`;

export const BTMInputGroup = styled.div<{
    $vertical: boolean;
    $vOffset: number;
    $hOffset: number;
    $position: Position;
    $side: Side;
    $isAbsolute: boolean;
    $offset?: number;
    $inputFieldPosition?: string;
    $hasError?: boolean;
}>`
    position: ${({$isAbsolute}) => ($isAbsolute ? 'absolute' : 'initial')};

    left: ${({$vertical, $inputFieldPosition}) => {
        if ($vertical) {
            return '-60px';
        } else {
            if ($inputFieldPosition == 'bottom') {
            }

            return `calc(50% - ${INPUT_GROUP_WIDTH / 2}px)`;
        }
    }};

    top: ${({$vertical, $inputFieldPosition}) => {
        if ($vertical) return `calc(50% - 13px)`;
        else {
            if ($inputFieldPosition == 'bottom') {
                return '30px';
            }

            return '-60px';
        }
    }};

    width: ${({$vertical}) =>
        !$vertical ? `${INPUT_GROUP_WIDTH}px` : 'inherit'};

    margin-top: ${({$vOffset = 0}) => `${$vOffset}px`};
    z-index: 1;
    margin-left: ${({$vertical, $position, $hOffset = 0}) => {
        if ($vertical) {
            if (
                $position == Position.RightInner ||
                $position == Position.Left
            ) {
                return '-40px';
            } else if ($position == Position.LeftInner) {
                return '0px';
            } else if ($position == Position.Right) {
                return '0';
            }

            return '-25px';
        }
        return `${$hOffset}px`;
    }};

    display: grid;
    grid-template-columns: 25px 45px;
    gap: 0px 0px;

    > label {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 25px;
        text-align: center;
        background: rgb(var(--primary_colour));
        color: white;
        font-weight: bold;
        border: 0;
        padding: 3px 2px;
        font-size: 0.75em;
        margin: 0;
    }

    > .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid rgb(var(--primary_colour));
        background: white;
        padding: 2px;
        height: 25px;
        text-align: center;
        font-size: 0.78em;
        font-weight: 500;
        min-width: initial;
    }

    > .form-control[readonly] {
        background: ${({$hasError = false}) =>
            $hasError ? 'rgb(244, 207, 192)' : '#adadad'} !important;
        cursor: not-allowed;
        color: #363636;
    }
`;

export const HorizontalLine = styled.div<{
    $vertical: boolean;
    $arrowStart?: boolean;
    $arrowEnd?: boolean;
}>`
    display: flex;
    flex: 1;

    ${({$vertical, $arrowStart = false, $arrowEnd = false}) => {
        if ($vertical) {
            return `
                ${
                    $arrowStart
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-bottom: 7px solid rgb(var(--primary_colour));
                        left: 10px;
                        top: -2px;
                    }
                `
                        : ``
                }

                ${
                    $arrowEnd
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-top: 7px solid rgb(var(--primary_colour));
                        left: 11px;
                        bottom: -2px;
                    }
                `
                        : ``
                }

                > div {
                    width: 55%;
                    border-right: 1px solid rgb(var(--primary_colour));
                }
            `;
        } else {
            return `
                ${
                    $arrowStart
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-top: 3px solid transparent;
                        border-bottom: 3px solid transparent;
                        border-right: 7px solid rgb(var(--primary_colour));
                        top: 8px;
                        left: -2px;
                    }
                `
                        : ``
                }

                ${
                    $arrowEnd
                        ? `
                    position: relative;

                    ::after {
                        position: absolute;
                        content: '';
                        border-top: 3px solid transparent;
                        border-bottom: 3px solid transparent;
                        border-left: 7px solid rgb(var(--primary_colour));
                        top: 8px;
                        right: -2px;
                    }
                `
                        : ``
                }

                > div {
                    height: 45%;
                    border-bottom: 1px solid rgb(var(--primary_colour));
                    flex: 1;
                }
            `;
        }
    }}
`;

const TextContainer = styled.div<{
    $position: Position;
    $positioning: PositionInterface;
    $arrowStart?: boolean;
    $arrowEnd?: boolean;
    $length?: number;
}>`
    position: relative;
    flex: 1;
    height: 20px;

    > div {
        position: absolute;
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;

        > label {
            width: max-content;
        }

        ${({$positioning, $position, $length}) => {
            if ($positioning.placement == 'TOP') {
                if (
                    $length - ($position == Position.RightInner ? 10 : 0) <
                    45
                ) {
                    return `
                        height: 25px;
                        top: -14px;
                        width: 100%;
                        border-bottom: 1px solid rgb(var(--primary_colour));

                        display: flex;
                        justify-content: flex-end;
                        padding-right: 2px;
                    `;
                } else {
                    return `
                        height: 16px;
                        top: -5px;
                        width: 100%;
                        border-bottom: 1px solid rgb(var(--primary_colour));
                    `;
                }
            } else if ($positioning.placement == 'BOTTOM') {
                if (
                    $length - ($position == Position.RightInner ? 10 : 0) <
                    45
                ) {
                    return `
                    top: 10px;
                    width: 100%;
                    border-top: 1px solid rgb(var(--primary_colour));
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    > label {
                        margin: 0;
                    }
                    `;
                } else {
                    return `
                    top: 10px;
                    width: 100%;
                    border-top: 1px solid rgb(var(--primary_colour));
                    `;
                }
            } else if ($positioning.placement == 'RIGHT') {
                return `
                height: ${
                    $length - ($position == Position.RightInner ? 10 : 0) < 45
                        ? 28
                        : 16
                }px;
                left: 12px;
                width: ${
                    $length - ($position == Position.LeftInner ? 10 : 0)
                }px;
                border-bottom: 1px solid rgb(var(--primary_colour));
                transform: rotate(90deg) translate(0, -100%);
                transform-origin: top left;
                `;
            } else if ($positioning.placement == 'LEFT') {
                return `
                height: ${
                    $length - ($position == Position.RightInner ? 10 : 0) < 45
                        ? 28
                        : 16
                }px;
                padding-left: ${
                    $length - ($position == Position.RightInner ? 10 : 0) < 45
                        ? '5px'
                        : 'initial'
                };
                right: 13px;
                width: ${
                    $length - ($position == Position.RightInner ? 10 : 0)
                }px;
                border-bottom: 1px solid rgb(var(--primary_colour));
                transform: rotate(-90deg) translate(0, -100%);
                transform-origin: top right;
                `;
            }
        }}

        ${({$positioning, $arrowStart = false, $arrowEnd = false}) => {
            if ($positioning.placement == 'RIGHT') {
                return `
                    ${
                        $arrowStart
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-bottom: 7px solid rgb(var(--primary_colour));
                            left: -4px;
                            top: -2px;
                            transform: rotate(-90deg) translate(-21px, 0);
                            transform-origin: top left;
                        }
                    `
                            : ``
                    }
    
                    ${
                        $arrowEnd
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-top: 7px solid rgb(var(--primary_colour));
                            left: -4px;
                            bottom: -2px;
                        }
                    `
                            : ``
                    }
                `;
            } else if ($positioning.placement == 'LEFT') {
                return `
                ${
                    $arrowStart
                        ? `    
                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-bottom: 7px solid rgb(var(--primary_colour));
                        right: -4px;
                        top: -2px;
                        transform: rotate(90deg) translate(20px, 0);
                        transform-origin: top right;
                    }
                `
                        : ``
                }
                
                ${
                    $arrowEnd
                        ? `    
                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-top: 7px solid rgb(var(--primary_colour));
                        right: -4px;
                        bottom: -2px;
                    }
                `
                        : ``
                }
            `;
            } else {
                return `
                    ${
                        $arrowStart
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-right: 7px solid rgb(var(--primary_colour));
                            top: -3px;
                            left: -2px;
                        }
                    `
                            : ``
                    }
    
                    ${
                        $arrowEnd
                            ? `
                        ::after {
                            position: absolute;
                            content: '';
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-left: 7px solid rgb(var(--primary_colour));
                            top: -3px;
                            right: -2px;
                        }
                    `
                            : ``
                    }
                `;
            }
        }}
    }
`;
