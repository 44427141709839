import React, {useCallback} from 'react';
import {Brand} from 'components/customer/AdvancedMaterials/entity/Brand';
import {Finish} from 'components/customer/AdvancedMaterials/entity/Finish';
import {connect} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import {
    selectBrands,
    selectFinishes,
    selectThickness,
    clearFilters,
    selectBrowserType,
    BrowserType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {Icon} from 'shared/helpers';
import {useSearchText} from 'components/customer/AdvancedMaterials/helpers/useSearchText';
import {Filters} from 'components/customer/AdvancedMaterials/entity/Filters';

interface NoDataProps {
    thickness?: string;
    brands?: Brand[];
    finishes?: Finish[];
}

const NoData = ({thickness, brands, finishes}: NoDataProps) => {
    const dispatch = useAppDispatch();
    const {searchText, clearSearchText} = useSearchText();

    const browserType = useAppSelector(selectBrowserType);

    const handleClearAll = useCallback(() => {
        dispatch(clearFilters());
    }, [dispatch]);

    const clearFinishes = useCallback(() => {
        dispatch(clearFilters(Filters.FINISH));
    }, [dispatch]);

    const clearBrands = useCallback(() => {
        dispatch(clearFilters(Filters.BRAND));
    }, [dispatch]);

    return (
        <Container>
            <Message>
                Could not find any materials based on selected filters.{' '}
                <Button onClick={handleClearAll}>Clear all</Button>
            </Message>
            <ul>
                {searchText && searchText.length > 0 ? (
                    <li>
                        <strong>Search keywords: </strong>
                        {searchText}
                        <DeleteFilter
                            iconName="Button-Error-Gray.svg"
                            onClick={clearSearchText}
                        />
                    </li>
                ) : null}
                {browserType == BrowserType.BENCHTOP ? (
                    <li>
                        <strong>Thickness : </strong>
                        {thickness}mm
                    </li>
                ) : null}
                {brands && brands.length ? (
                    <li>
                        <strong>Brands: </strong>
                        <DeleteFilter
                            iconName="Button-Error-Gray.svg"
                            onClick={clearBrands}
                        />
                        <ul>
                            {brands.map((brand) => (
                                <li key={brand.id}>{brand.name}</li>
                            ))}
                        </ul>
                    </li>
                ) : null}
                {finishes && finishes.length ? (
                    <li>
                        <strong>Finishes: </strong>
                        <DeleteFilter
                            iconName="Button-Error-Gray.svg"
                            onClick={clearFinishes}
                        />
                        <ul>
                            {finishes.map((finish) => (
                                <li key={finish.id}>{finish.name}</li>
                            ))}
                        </ul>
                    </li>
                ) : null}
            </ul>
        </Container>
    );
};

export default connect((state: AppState) => ({
    thickness: selectThickness(state),
    brands: selectBrands(state),
    finishes: selectFinishes(state),
}))(NoData);

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
const DeleteFilter = styled((props) => <Icon {...props} />)`
    width: 15px !important;
    margin-left: 5px;
    cursor: pointer;
`;

const Container = styled.div`
    overflow-y: auto;
`;

const Message = styled.div`
    font-size: 1.3em;
    margin-bottom: 15px;
`;

const Button = styled.button`
    border: none;
    background: rgb(var(--primary_colour));
    color: white;
    border-radius: 8px;
    font-size: 0.75em;
`;
