import {Material} from 'components/customer/AdvancedMaterials/entity/Material';

export const matchColor = (color: Material, materials: Material[]) => {
    const sameBrandFinish = materials.find(
        (material) =>
            material.brand.name === color.brand.name &&
            material.finish.name === color.finish.name
    );

    if (sameBrandFinish) {
        return sameBrandFinish;
    }

    const sameBrand = materials.find(
        (material) => material.brand.name === color.brand.name
    );

    if (sameBrand) {
        return sameBrand;
    }

    return materials[0];
};
