import {useAppSelector} from 'store/customer';
import {
    selectDimension,
    selectJoins,
    selectMaterial,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {useCallback} from 'react';
import {Side} from 'components/customer/BTM/entity/Side';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {hasMasonsJoin} from 'components/customer/BTM/helper/updateButtJoin';
import {JoinOrientation} from 'components/customer/BTM/entity/Join';

export const useCorner = () => {
    const joins = useAppSelector(selectJoins, shallowEqual);
    const dimensions = useAppSelector(selectDimension, shallowEqual);
    const material = useAppSelector(selectMaterial, shallowEqual);

    const checkHasButtJoin = useCallback(
        (
            side: Side,
            joinConditions: {
                joinOrientation: JoinOrientation;
                adjacentSide: Side;
                joinSide: JoinSide;
            }[]
        ) => {
            if (material) {
                const length = dimensions[Number(side)];
                const materialLength = material.max_length;

                let adjacentLength = 0;

                joinConditions.forEach(
                    ({joinOrientation, joinSide, adjacentSide}) => {
                        const hasJoin = hasMasonsJoin(
                            joins,
                            joinOrientation,
                            joinSide
                        );

                        if (hasJoin) {
                            if (adjacentSide == Side.I) {
                                adjacentLength =
                                    dimensions[Number(Side.H)] -
                                    dimensions[Number(Side.F)];
                            } else {
                                adjacentLength +=
                                    dimensions[Number(adjacentSide)];
                            }
                        }
                    }
                );

                return length > adjacentLength + materialLength;
            }
        },
        [joins, dimensions, material]
    );

    return {
        checkHasButtJoin,
    };
};
