import {useFormikContext} from 'formik';
import {
    DispatchMaterialInterface,
    useDispatchMaterial,
} from 'components/customer/Materials/helper/useDispatchMaterial';

export const useDispatchWithFormik = (props: DispatchMaterialInterface) => {
    const formik = useFormikContext();

    props.formikContext = formik;

    return useDispatchMaterial(props);
};
