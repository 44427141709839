import React, {useCallback, useEffect, useRef} from 'react';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Path} from 'components/customer/BTM/entity/Path';
import {useEdgeTogglePosition} from 'components/customer/BTM/helper/useEdgeTogglePosition';
import {EdgeToggle} from 'components/customer/BTM/Preview/EdgeToggle';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    profiledSet,
    selectEdgeProfile,
    selectMaterial,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {isEqual} from 'lodash';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {setDefaultProfiles} from 'components/customer/BTM/helper/setDefaultProfiles';
import {checkCutoutRestriction} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {resetPostformedProfile} from 'components/customer/BTM/helper/resetPostformedProfile';
import {Tab} from 'components/customer/BTM/entity/Tab';
import {useFetchEdgeProfile} from 'components/customer/BTM/helper/useFetchEdgeProfile';
import {Side} from 'components/customer/BTM/entity/Side';

interface EdgeToggleInterface {
    dimension: number[];
    shape: Shape;
    paths: Path[];
    currentTab?: Tab;
}

export const EdgeToggles = ({
    dimension,
    shape,
    paths,
    currentTab,
}: EdgeToggleInterface) => {
    const dispatch = useAppDispatch();
    const positions = useEdgeTogglePosition(dimension, shape, paths);

    // INFO: Need to use useRef as React always calls effect at least once when Component
    // renders. But, we only want to run our effect if selected edge profile changes.
    const edgeProfile = useAppSelector(selectEdgeProfile, shallowEqual);
    const edgeProfileRef = useRef<BenchtopEdgeProfile>(edgeProfile);
    const {edgeProfiles} = useFetchEdgeProfile();
    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);

    const shapeSideMap = useRef([
        {
            shape: Shape.SQR,
            sides: [Side.B, Side.D],
        },
        {
            shape: Shape.ANG,
            sides: [Side.B, Side.E],
        },
        {
            shape: Shape.USHAPE,
            sides: [Side.C, Side.G],
        },
    ]);

    const getEdgeProfile = useCallback(
        (id: number) => {
            if (edgeProfiles.length > 0) {
                return edgeProfiles.find((profile) => profile.id === id);
            }
        },
        [edgeProfiles]
    );

    useEffect(() => {
        // we can do this as edgeProfile does not change if customer
        // is in overview tab and this block of code seems to interfere
        // with overview preview as well.
        if (currentTab != Tab.EDGE_PROFILE) return;

        if (edgeProfile && !isEqual(edgeProfileRef.current, edgeProfile)) {
            edgeProfileRef.current = edgeProfile;

            if (edgeProfile.is_postformed_profile) {
                setDefaultProfiles(edgeProfile, paths, shape);
            }
            const sideMap = shapeSideMap.current.find(
                (map) => map.shape == shape
            );

            paths.forEach((path, index) => {
                if (
                    selectedMaterial.is_blank &&
                    !sideMap.sides.includes(path.side)
                ) {
                    return;
                }

                if (path.edged == Edge.EDGED) {
                    if (!edgeProfile.is_postformed_profile) {
                        const pathEdgeProfile = getEdgeProfile(path.profile);

                        if (
                            !checkCutoutRestriction(
                                paths,
                                edgeProfile,
                                index
                            ) &&
                            !pathEdgeProfile.is_end_only
                        ) {
                            dispatch(
                                profiledSet(
                                    Edge.EDGED,
                                    edgeProfile,
                                    index,
                                    path.side
                                )
                            );
                        }
                    }
                } else if (path.edged == Edge.PROFILED) {
                    if (edgeProfile.is_postformed_profile) {
                        resetPostformedProfile(
                            path,
                            edgeProfile,
                            index,
                            path.side
                        );
                    } else {
                        if (
                            !checkCutoutRestriction(paths, edgeProfile, index)
                        ) {
                            dispatch(
                                profiledSet(
                                    Edge.EDGED,
                                    edgeProfile,
                                    index,
                                    path.side
                                )
                            );
                        } else {
                            dispatch(
                                profiledSet(
                                    Edge.NOT_EDGED,
                                    null,
                                    index,
                                    path.side
                                )
                            );
                        }
                    }
                }
            });
        }
    }, [edgeProfile]);

    if (positions) {
        return positions.map((position) => (
            <EdgeToggle
                key={`${position.index}-${position.side}`}
                position={position}
                paths={paths}
                currentTab={currentTab}
                shape={shape}
            />
        ));
    }

    return null;
};
