import React, {useState, useEffect, useCallback} from 'react';
import {Col, Form, Spinner, Row} from 'react-bootstrap';
import {useAppContext} from 'contexts';
import {Dropdown} from 'shared';
import {useFormikContext} from 'formik';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import styled from 'styled-components';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

export const DefaultDeliveryAddress = ({
    displayOnly = true,
    loading = false,
    isNameVisible = true,
}: {
    displayOnly?: boolean;
    loading?: boolean;
    isNameVisible?: boolean;
}) => {
    const {userProfile, places, stateLabel} = useAppContext();
    const isSmallDevice = isDeviceSmall();

    const [states, setStates] = useState([]);
    const [currentState, setCurrentState] = useState({
        id: null,
        name: '',
    });
    const {values, setFieldValue, handleChange} =
        useFormikContext<DeliveryAddress>();

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
        },
        []
    );

    useEffect(() => {
        if (userProfile) {
            const country = userProfile.countryName;
            setStates(
                places.hasOwnProperty('Countries') &&
                    places.Countries.hasOwnProperty(country)
                    ? places.Countries[country]
                    : []
            );
        }
    }, [userProfile, places]);

    useEffect(() => {
        if (states.length > 0) {
            setCurrentState(states.find((state) => state.id == values.state));
        }
    }, [states, values.state]);

    return (
        <div>
            {!displayOnly ? (
                <Row>
                    <Col md={12} sm={12}>
                        <StyledForm
                            className="cbc-form reduced-pb"
                            style={{paddingLeft: '0px'}}
                            $isMobile={isSmallDevice}
                            onSubmit={handleSubmit}>
                            {isNameVisible ? (
                                <Form.Group as={Row} controlId="name_">
                                    <Form.Label
                                        column
                                        md={{offset: 0, span: 2}}>
                                        Name:{' '}
                                    </Form.Label>
                                    <Col md="10">
                                        <StyleFormControl
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Optional"
                                        />
                                    </Col>
                                </Form.Group>
                            ) : null}
                            <Form.Group as={Row} controlId="address_">
                                <Form.Label column md={{offset: 0, span: 2}}>
                                    Address:{' '}
                                </Form.Label>
                                <Col md="10">
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        value={values.street}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            {userProfile.countryName != 'Australia' ? (
                                <Form.Group as={Row} controlId="city_">
                                    <Form.Label
                                        column
                                        md={{offset: 0, span: 2}}>
                                        City:{' '}
                                    </Form.Label>
                                    <Col md="10">
                                        <Form.Control
                                            type="text"
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                            ) : null}
                            <Form.Group as={Row} controlId="suburb_">
                                <Form.Label column md={{offset: 0, span: 2}}>
                                    Suburb:{' '}
                                </Form.Label>
                                <Col md="10">
                                    <Form.Control
                                        type="text"
                                        name="suburb"
                                        value={values.suburb}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="postcode_">
                                <Form.Label column md={{offset: 0, span: 2}}>
                                    Postcode:{' '}
                                </Form.Label>
                                <Col md={{offset: 0, span: 4}} sm={5} xs={7}>
                                    <Form.Control
                                        type="number"
                                        name="postcode"
                                        value={values.postcode}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Form.Label
                                    column
                                    md={{offset: 0, span: 2}}
                                    sm={8}
                                    xs={6}
                                    style={
                                        !isSmallDevice
                                            ? {textAlign: 'right'}
                                            : {}
                                    }>
                                    {stateLabel ? stateLabel : 'State'}:
                                </Form.Label>
                                <Col md={{offset: 0, span: 4}} sm={5} xs={7}>
                                    <Dropdown
                                        options={states}
                                        name="state"
                                        value={values.state}
                                        selectHandler={setFieldValue}
                                    />
                                </Col>
                            </Form.Group>
                        </StyledForm>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <StyledDiv />
                    <Col md={12} sm={12}>
                        <StyledForm
                            className="cbc-form reduced-pb"
                            style={{
                                paddingLeft: '0px',
                            }}
                            $isMobile={isSmallDevice}
                            onSubmit={handleSubmit}>
                            {isNameVisible ? (
                                <Form.Group as={Row} controlId="name_">
                                    <Form.Label
                                        column
                                        md={{offset: 0, span: 2}}>
                                        Name:{' '}
                                    </Form.Label>
                                    <Col md="10">
                                        <StyleFormControl
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder="Optional"
                                        />
                                    </Col>
                                </Form.Group>
                            ) : null}
                            <Form.Group as={Row} controlId="address_">
                                <Form.Label
                                    column
                                    md={{offset: 0, span: 2}}
                                    style={{maxWidth: '100%'}}>
                                    Address:{' '}
                                </Form.Label>
                                <Col md="9" style={{alignSelf: 'center'}}>
                                    {values.street ? `${values.street},` : ''}{' '}
                                    {values.city ? `${values.city},` : ''}{' '}
                                    {values.suburb ? `${values.suburb},` : ''}{' '}
                                    {currentState
                                        ? `${currentState.name},`
                                        : ''}{' '}
                                    {values.postcode ? values.postcode : ''}
                                    {loading ? (
                                        <StyledSpinner
                                            animation="border"
                                            size="sm"
                                        />
                                    ) : null}
                                </Col>
                            </Form.Group>
                        </StyledForm>
                    </Col>
                </Row>
            )}
        </div>
    );
};

const StyledDiv = styled.div`
    width: 100%;
    margin-top: 10px;
    border: 1px solid #ced4da;
`;

const StyledSpinner = styled(Spinner)`
    margin-left: 10px;
`;

const StyleFormControl = styled(Form.Control)`
    background: #fff;
    ::placeholder {
        font-style: italic;
    }
`;

const StyledForm = styled(Form)<{$isMobile?: boolean}>`
    ${({$isMobile}) =>
        $isMobile
            ? ` margin-top: 5px;
                .form-group {
                    margin-bottom: 0;
                }`
            : ''}
`;
