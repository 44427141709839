import {useGetTypesQuery} from 'components/customer/Materials/store/materialApi';

export const useMaterialTypes = (cabinetType: number) => {
    const {
        data: materialTypes,
        isLoading,
        isError,
    } = useGetTypesQuery({cabinetType});

    return {
        isLoading,
        isError,
        materialTypes,
    };
};
