import React, {useCallback, useState} from 'react';
import {Popover} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import styled from 'styled-components';
import {isMobile, isTablet} from 'react-device-detect';

interface NewSelectionPopoverProps {
    children: React.ReactNode;
    image?: string;
    title?: string;
    description?: string;
    wide?: boolean;
}

export const NewSelectionPopover = ({
    children,
    wide = true,
    image,
    title,
    description,
}: NewSelectionPopoverProps) => {
    const [show, setShow] = useState(false);

    const handleClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();

        setShow((show) => !show);
    }, []);

    const handleMouseOut = useCallback(() => {
        setShow(false);
    }, []);

    if (isMobile || isTablet) {
        return null;
    }

    return (
        <PopoverContainer onClick={handleClick} onMouseLeave={handleMouseOut}>
            {children}
            <LocalPopover
                placement="bottom"
                id={`${title}-popover`}
                $show={show}>
                <Title>
                    <strong>{title}</strong> - {parseHtmlString(description)}
                </Title>
                <Content>
                    <Image src={image} $wide={wide} />
                </Content>
            </LocalPopover>
        </PopoverContainer>
    );
};

const PopoverContainer = styled.div`
    cursor: pointer;
    position: relative;
`;

const LocalPopover = styled(Popover)<{$show: boolean}>`
    display: ${({$show}) => ($show ? 'block' : 'none')};
    top: 20px;
    max-width: fit-content;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 0;

    > .arrow {
        z-index: 2;
        top: -8px;

        &::before {
            top: 2px;
            border-bottom-color: rgb(var(--primary_colour));
        }

        &::after {
            top: 2px;
            border-bottom-color: rgb(var(--primary_colour));
        }
    }
`;

const Title = styled(Popover.Title)`
    background: rgb(var(--primary_colour));
    color: white;
    font-weight: 500;
    font-size: 1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
`;

const Content = styled(Popover.Content)`
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
`;

const Image = styled.img<{$wide: boolean}>`
    width: ${({$wide}) => (!$wide ? '200px' : '400px')} !important;
    height: ${({$wide}) => (!$wide ? '250px' : '400px')} !important;
`;
