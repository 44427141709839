import {
    BenchtopMaterialSearchInterface,
    useLazySearchBTMMaterialsQuery,
} from 'components/customer/BTM/store/btmApi';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Pagination} from 'store/customer/api';

export const useSearchMaterials = () => {
    const {notify} = useNotificationContext();
    const [search, {isLoading, isFetching, isUninitialized}] =
        useLazySearchBTMMaterialsQuery();

    /**
     * Generic function to search for the materials by type,
     * thickness and keywords
     *
     * @param {number} typeId Id of the selected type
     * @param {number} thickness Thickness selected
     * @param {number} [currentPage] current page for pagination
     * @param {string} [keywords] search keywords
     * @return {{materials: BenchtopMaterial[], pagination: Pagination}}
     */
    const searchMaterials = async (
        typeId: number,
        thickness: number,
        currentPage = 1,
        keywords: string = null
    ) => {
        const parameters: BenchtopMaterialSearchInterface = {
            type: typeId,
            thickness,
            currentPage,
        };

        if (keywords != null) parameters.keywords = keywords;

        try {
            const {
                data: {data: materials, pagination},
            } = await search(parameters, true);

            return {materials, pagination};
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch material',
            });
        }

        return {
            materials: [] as BenchtopMaterial[],
            pagination: {} as Pagination,
        };
    };

    return {
        isLoading,
        isFetching,
        isUninitialized,
        searchMaterials,
    };
};
