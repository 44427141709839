import {useEffect} from 'react';
import {useConfirmationDialog} from 'shared';
import {useRequestedDeliveryDateHandler} from 'shared/helpers/useRequestedDeliveryDateHandler';

export const useRequestedDeliveryDatePrompt = () => {
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();

    const {action} = useRequestedDeliveryDateHandler(
        showDialog,
        hideDialog,
        true
    ) as {action: () => Promise<void>};

    useEffect(() => {
        void action();
    }, [action]);

    return {
        dialog,
    };
};
