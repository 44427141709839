export const MATERIAL_NAMES = {
    MATERIAL_TYPE_DROPDOWN: 'material_type_dropdown',
    MATERIAL_BRAND_DROPDOWN: 'material_brand_dropdown',
    MATERIAL_STYLE_DROPDOWN: 'material_style_dropdown',
    CABINET_EXT_BRAND: 'cabinet_ext_brand',
    CABINET_EXT_FINISH: 'cabinet_ext_finish',
    CABINET_EXT_SUBSTRATE: 'cabinet_ext_substrate',
    CABINET_EXT_EDGE_BRAND: 'cabinet_ext_edge_brand',
    CABINET_EXT_EDGE_FINISH: 'cabinet_ext_edge_finish',
    CARC_MATERIAL_TYPE_DROPDOWN: 'carc_material_type_dropdown',
    CABINET_CARC_BRAND: 'cabinet_carc_brand',
    CABINET_CARC_FINISH: 'cabinet_carc_finish',
    CABINET_CARC_SUBSTRATE: 'cabinet_carc_substrate',
    CABINET_CARC_EDGE_BRAND: 'cabinet_carc_edge_brand',
    CABINET_CARC_EDGE_FINISH: 'cabinet_carc_edge_finish',
};

export const relevantFieldsExterior = [
    [MATERIAL_NAMES.MATERIAL_TYPE_DROPDOWN, 'type'],
    [MATERIAL_NAMES.MATERIAL_BRAND_DROPDOWN, 'doorBrand'],
    [MATERIAL_NAMES.MATERIAL_STYLE_DROPDOWN, 'category'],
    ['cabinet_door', 'doorStyle'],
    [MATERIAL_NAMES.CABINET_EXT_BRAND, 'brand'],
    [MATERIAL_NAMES.CABINET_EXT_FINISH, 'finish'],
    [MATERIAL_NAMES.CABINET_EXT_SUBSTRATE, 'substrate'],
    ['cabinet_ext_colour', 'colour'],
    [MATERIAL_NAMES.CABINET_EXT_EDGE_BRAND, 'edgeBrand'],
    [MATERIAL_NAMES.CABINET_EXT_EDGE_FINISH, 'edgeFinish'],
    ['cabinet_ext_edge_colour', 'edgeColour'],
];

export const relevantFieldsCarcase = [
    [MATERIAL_NAMES.CARC_MATERIAL_TYPE_DROPDOWN, 'type'],
    [MATERIAL_NAMES.CABINET_CARC_BRAND, 'brand'],
    [MATERIAL_NAMES.CABINET_CARC_FINISH, 'finish'],
    [MATERIAL_NAMES.CABINET_CARC_SUBSTRATE, 'substrate'],
    ['cabinet_carc_colour', 'colour'],
    [MATERIAL_NAMES.CABINET_CARC_EDGE_BRAND, 'edgeBrand'],
    [MATERIAL_NAMES.CABINET_CARC_EDGE_FINISH, 'edgeFinish'],
    ['cabinet_carc_edge_colour', 'edgeColour'],
];

export const MATERIAL_TYPE = {
    EXTERIOR_COLOUR: 'ext',
    CARCASE_COLOUR: 'carc',
};

export const MATERIAL_NAMES_MAP = {
    [MATERIAL_NAMES.MATERIAL_TYPE_DROPDOWN]: 'ext_material.type',
    [MATERIAL_NAMES.MATERIAL_BRAND_DROPDOWN]: 'ext_material.brand',
    [MATERIAL_NAMES.MATERIAL_STYLE_DROPDOWN]: 'door_suffix_id',
    [MATERIAL_NAMES.CABINET_EXT_BRAND]: 'ext_material.brand',
    [MATERIAL_NAMES.CABINET_EXT_FINISH]: 'ext_material.finish',
    [MATERIAL_NAMES.CABINET_EXT_SUBSTRATE]: 'ext_material.substrate',
    [MATERIAL_NAMES.CABINET_EXT_EDGE_BRAND]: 'ext_edge.brand',
    [MATERIAL_NAMES.CABINET_EXT_EDGE_FINISH]: 'ext_edge.finish',
    [MATERIAL_NAMES.CARC_MATERIAL_TYPE_DROPDOWN]: 'carc_material.type',
    [MATERIAL_NAMES.CABINET_CARC_BRAND]: 'carc_material.brand',
    [MATERIAL_NAMES.CABINET_CARC_FINISH]: 'carc_material.finish',
    [MATERIAL_NAMES.CABINET_CARC_SUBSTRATE]: 'carc_material.substrate',
    [MATERIAL_NAMES.CABINET_CARC_EDGE_BRAND]: 'carc_edge.brand',
    [MATERIAL_NAMES.CABINET_CARC_EDGE_FINISH]: 'carc_edge.finish',
};
