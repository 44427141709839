import {Product} from 'components/customer/Product/entity';
import {
    ProductStatus,
    savingSet,
    selectDeleted,
    selectPersisted,
    selectProduct,
    selectTriggerSave,
    selectUpdated,
    selectWasDeleted,
    ValidationDataInterface,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {useFetchStructure} from 'components/customer/QFPRedux/helpers/useFetchStructure';
import {useCallback, useEffect, useRef, useState} from 'react';
import {validateProducts} from 'shared';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useDebouncedCallback} from 'use-debounce';
import {useFormikContext} from 'formik';
import {shallowEqual} from 'react-redux';

interface AutoSaveInterface {
    validationData: ValidationDataInterface;
    isActive: boolean;
    index: number;
}

export const useAutoSave = ({
    validationData,
    isActive,
    index,
}: AutoSaveInterface) => {
    const dispatch = useAppDispatch();
    const getStructure = useFetchStructure();
    const saveTrigger = useAppSelector(selectTriggerSave);
    const undoTrigger = useAppSelector((state) =>
        selectWasDeleted(state, index)
    );
    const [localSaveTrigger, setLocalSaveTrigger] = useState(false);
    const {setTouched} = useFormikContext();
    const isSaving = useRef(false);
    const isEditing = useRef(false);

    const product = useAppSelector(
        (state) => selectProduct(state, index),
        shallowEqual
    );
    const persisted = useAppSelector((state) => selectPersisted(state, index));
    const deleted = useAppSelector((state) => selectDeleted(state, index));
    const updated = useAppSelector((state) => selectUpdated(state, index));

    const saveProduct = useCallback(
        useDebouncedCallback(
            async (product: Partial<Product>, updated, persisted) => {
                if (updated && !persisted) {
                    if (!isSaving.current) {
                        isSaving.current = true;
                        const structure = await getStructure(
                            product.cabinet_type
                        );
                        const heightField = structure.heightField;
                        const widthField = structure.widthField;

                        const errors = validateProducts(
                            {
                                ...product,
                                [heightField]: product.height,
                                [widthField]: product.width,
                            },
                            structure.validation,
                            validationData,
                            true
                        );

                        if (errors.length == 0) {
                            await setTouched({});
                            dispatch(savingSet(ProductStatus.SAVING, index));
                        }
                        isSaving.current = false;
                        setLocalSaveTrigger(false);
                    }
                }
            },
            1000,
            {
                leading: false,
                trailing: true,
                maxWait: 1000,
            }
        ),
        []
    );

    useEffect(() => {
        if (!isActive && isEditing.current) {
            setLocalSaveTrigger(true);
        }

        isEditing.current = isActive;
    }, [isActive]);

    useEffect(() => {
        if ((saveTrigger || localSaveTrigger || undoTrigger) && !deleted) {
            void saveProduct(product, updated, persisted);
        }
    }, [
        saveTrigger,
        localSaveTrigger,
        undoTrigger,
        deleted,
        product,
        updated,
        persisted,
    ]);
};
