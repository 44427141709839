import {
    CovedSplashbackSolidTriangle,
    CovedSplashback,
    DropdownFrontSolidTriangle,
    DropdownFront,
    EdgesToBePostFormedSolidCircle,
    EdgesToBePostFormed,
    EdgesToBeSquareLaminated,
    LaminatedEdgeSolidSquare,
    LaminatedEdge,
    P,
    ProfileEdges,
    RawEdge,
    SeenEdge,
    SingleLine,
    UnseenEdge,
} from 'assets';

const symbols = [
    {
        id: 'CovedSplashbackSolidTriangle',
        name: CovedSplashbackSolidTriangle,
    },
    {
        id: 'CovedSplashback',
        name: CovedSplashback,
    },
    {
        id: 'DropdownFrontSolidTriangle',
        name: DropdownFrontSolidTriangle,
    },
    {
        id: 'DropdownFront',
        name: DropdownFront,
    },
    {
        id: 'EdgesToBePostFormedSolidCircle',
        name: EdgesToBePostFormedSolidCircle,
    },
    {
        id: 'EdgesToBePostFormed',
        name: EdgesToBePostFormed,
    },
    {
        id: 'EdgesToBeSquareLaminated',
        name: EdgesToBeSquareLaminated,
    },
    {
        id: 'LaminatedEdgeSolidSquare',
        name: LaminatedEdgeSolidSquare,
    },
    {
        id: 'LaminatedEdge',
        name: LaminatedEdge,
    },
    {id: 'P', name: P},
    {
        id: 'ProfileEdges',
        name: ProfileEdges,
    },
    {id: 'RawEdge', name: RawEdge},
    {id: 'SeenEdge', name: SeenEdge},
    {id: 'SingleLine', name: SingleLine},
    {id: 'UnseenEdge', name: UnseenEdge},
];

export default symbols;
