import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    clearFilters,
    currentPageSet,
    doorSearchSet,
    edgeSearchSet,
    materialSearchSet,
    searchTypeSet,
    selectDoorSearch,
    selectEdgeSearch,
    selectHasDoors,
    selectMaterialSearch,
    selectMenu,
    selectSearchType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {useCallback, useEffect, useMemo} from 'react';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {Filters} from 'components/customer/AdvancedMaterials/entity/Filters';
import {SearchType} from 'components/customer/AdvancedMaterials/entity/SearchType';

export const useSearchText = () => {
    const dispatch = useAppDispatch();
    const selectedMenu = useAppSelector(selectMenu);

    const searchMaterial = useAppSelector(selectMaterialSearch);
    const searchDoor = useAppSelector(selectDoorSearch);
    const searchEdge = useAppSelector(selectEdgeSearch);
    const hasDoors = useAppSelector(selectHasDoors);

    const searchType = useAppSelector(selectSearchType);

    const placeholder = useMemo(() => {
        switch (searchType) {
            case SearchType.COLOR:
                return 'Search colours';

            case SearchType.DOOR:
                return 'Search doors';

            case SearchType.EDGE_COLOR:
                return 'Search edge colours';
        }
    }, [searchType]);

    const searchText = useMemo(() => {
        switch (searchType) {
            case SearchType.COLOR:
                return searchMaterial;

            case SearchType.DOOR:
                return searchDoor;

            case SearchType.EDGE_COLOR:
                return searchEdge;
        }
    }, [searchMaterial, searchDoor, searchEdge, searchType]);

    const clearSearchText = useCallback(() => {
        switch (searchType) {
            case SearchType.COLOR:
                dispatch(clearFilters(Filters.SEARCH_MATERIAL));
                break;

            case SearchType.DOOR:
                dispatch(clearFilters(Filters.SEARCH_DOOR));
                break;

            case SearchType.EDGE_COLOR:
                dispatch(clearFilters(Filters.SEARCH_EDGE));
                break;
        }
    }, [dispatch, searchType]);

    const setSearchText = useCallback(
        (text: string) => {
            dispatch(currentPageSet(1));
            switch (searchType) {
                case SearchType.COLOR:
                    dispatch(materialSearchSet(text));
                    break;
                case SearchType.DOOR:
                    dispatch(doorSearchSet(text));
                    break;
                case SearchType.EDGE_COLOR:
                    dispatch(edgeSearchSet(text));
                    break;
            }
        },
        [dispatch, searchType]
    );

    useEffect(() => {
        if (selectedMenu == Menu.MATERIAL || selectedMenu == Menu.THICKNESSES) {
            dispatch(searchTypeSet(SearchType.COLOR));
        } else if (selectedMenu == Menu.DOOR_TYPE) {
            if (hasDoors) {
                dispatch(searchTypeSet(SearchType.DOOR));
            }
        } else if (selectedMenu == Menu.EDGE_MATERIAL) {
            dispatch(searchTypeSet(SearchType.EDGE_COLOR));
        }
    }, [selectedMenu, hasDoors]);

    return {
        searchType,
        placeholder,
        searchText,
        selectedMenu,
        setSearchText,
        clearSearchText,
    };
};
