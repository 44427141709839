import React from 'react';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Dimension as Label} from 'components/customer/BTM/Preview/Dimension';
import {Side} from 'components/customer/BTM/entity/Side';
import {Position} from 'components/customer/BTM/entity/Position';
import {
    selectMaterial,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';

interface DimensionsInterface {
    textOnly?: boolean;
}

export const Dimensions = ({textOnly = false}: DimensionsInterface) => {
    const shape = useAppSelector(selectType, shallowEqual);
    const material = useAppSelector(selectMaterial, shallowEqual);

    if (typeof shape == 'undefined') return null;

    if (shape.type == Shape.SQR) {
        return (
            <>
                <Label
                    side={Side.A}
                    position={Position.Top}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.A}
                        position={Position.Top}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.B}
                    position={Position.Right}
                    textOnly={textOnly}
                />
            </>
        );
    } else if (shape.type == Shape.ANG) {
        return (
            <>
                <Label
                    side={Side.A}
                    position={Position.Top}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.A}
                        position={Position.Top}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.B}
                    position={Position.Right}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.F}
                    position={Position.Left}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.F}
                        position={Position.Left}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.E}
                    position={Position.Bottom}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.C}
                    position={Position.BottomInner}
                    arrowStart={true}
                    readOnly={true}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.D}
                    position={Position.LeftInner}
                    arrowStart={true}
                    readOnly={true}
                    textOnly={textOnly}
                />
            </>
        );
    } else if (shape.type == Shape.USHAPE) {
        return (
            <>
                <Label
                    side={Side.A}
                    position={Position.Top}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.A}
                        position={Position.Top}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.B}
                    position={Position.Right}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.B}
                        position={Position.Right}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.C}
                    position={Position.BottomRight}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.D}
                    position={Position.RightInner}
                    arrowStart={true}
                    readOnly={true}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.E}
                    position={Position.Inner}
                    arrowStart={true}
                    arrowEnd={true}
                    readOnly={true}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.F}
                    position={Position.LeftInner}
                    arrowStart={true}
                    readOnly={true}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.G}
                    position={Position.BottomLeft}
                    textOnly={textOnly}
                />
                <Label
                    side={Side.H}
                    position={Position.Left}
                    textOnly={textOnly}
                />
                {material && material.allowButtJoin ? (
                    <Label
                        side={Side.H}
                        position={Position.Left}
                        textOnly={textOnly}
                        isButtJoin={true}
                    />
                ) : null}
                <Label
                    side={Side.I}
                    position={Position.Left}
                    textOnly={textOnly}
                />
            </>
        );
    }
};
