import React from 'react';
import {Image} from 'react-bootstrap';
import {Uploads} from 'assets';
import styled from 'styled-components';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

interface FileSelectorProps {
    fileHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileFormats: string[];
}

const FileSelector: React.FC<FileSelectorProps> = ({
    fileHandler,
    fileFormats,
}) => {
    const isSmallDevice = isDeviceSmall();

    return (
        <FileSelectorContainer $isMobile={isSmallDevice}>
            <div className="info">
                <Image style={{height: 70}} src={Uploads} alt="Uploads.svg" />
                <div>
                    {!isSmallDevice && (
                        <>
                            <p>
                                <strong>Drag and drop</strong>
                            </p>
                            <p>or</p>
                        </>
                    )}

                    <p>
                        <strong>Browse</strong> files to upload
                    </p>
                </div>
            </div>
            <input
                type="file"
                name="files"
                onChange={fileHandler}
                multiple
                id="fileSelectorField"
                accept={fileFormats.join(',')}
            />
        </FileSelectorContainer>
    );
};

const FileSelectorContainer = styled.div<{$isMobile?: boolean}>`
    border: 2px dashed #c5c9ca;
    border-radius: 8px;
    background: #ebeff0;
    box-sizing: border-box;
    position: relative;
    height: ${({$isMobile}) => ($isMobile ? '150px' : '200px')};

    input {
        width: 100%;
        height: inherit;
        opacity: 0;
    }

    .info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        vertical-align: middle;
        text-align: center;
        padding-top: 20px;
        font-weight: 500;
        color: #9e9e9e;
    }

    p {
        margin: 0;
        padding: 0;
        strong {
            color: #8d8f90;
        }
    }
`;

export default FileSelector;
