import {EyeCheckbox} from './EyeCheckbox';
import React from 'react';
import {Number} from 'shared';
import {Icon} from 'shared/helpers';

export const GridOptions = ({tdld, tdldData}) => {
    return (
        <>
            <h2>Grid</h2>
            <ul>
                <li className="form_element100">
                    <span className="tdld_label50 floatL">Width</span>
                    <div
                        className="form_element50 tdld_control_container"
                        style={{display: 'flex', flexDirection: 'column'}}>
                        <Number
                            name="width"
                            value={
                                tdldData && tdldData.filter
                                    ? tdldData.filter.gridCellWidth
                                    : 0
                            }
                            min={0}
                            max={9999999}
                            selectHandler={(name, value) =>
                                tdld && tdld.setFilterGridCellWidth(value)
                            }
                            hasFormik={false}
                            increaseIcon={<Icon iconName="Plus.svg" />}
                            decreaseIcon={<Icon iconName="Minus.svg" />}
                            reverseIcons={true}
                            className="tdld_number_input"
                        />
                    </div>
                </li>
                <li className="form_element100">
                    <span className="tdld_label50 floatL">Depth</span>
                    <div
                        className="form_element50 tdld_control_container"
                        style={{display: 'flex', flexDirection: 'column'}}>
                        <Number
                            name="height"
                            value={
                                tdldData && tdldData.filter
                                    ? tdldData.filter.gridCellHeight
                                    : 0
                            }
                            min={0}
                            max={9999999}
                            selectHandler={(name, value) =>
                                tdld && tdld.setFilterGridCellHeight(value)
                            }
                            hasFormik={false}
                            increaseIcon={<Icon iconName="Plus.svg" />}
                            decreaseIcon={<Icon iconName="Minus.svg" />}
                            reverseIcons={true}
                            className="tdld_number_input"
                        />
                    </div>
                </li>
                <li>
                    <span>Visible</span>
                    <EyeCheckbox
                        values={2}
                        onChange={(value) =>
                            tdld && tdld.setFilterGridVisible(value == 0)
                        }
                    />
                </li>
            </ul>
        </>
    );
};
