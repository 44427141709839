import React from 'react';
import Preview from 'components/customer/QFPRedux/Preview';
import FormFields from 'components/customer/QFPRedux/FormFields';

interface ActiveQFPInterface {
    index: number;
    isActive: boolean;
}

export const ActiveQFP = ({index, isActive}: ActiveQFPInterface) => {
    return (
        <tr className="product-specs">
            <td colSpan={5}>
                <Preview index={index} isActive={isActive} />
            </td>
            <td colSpan={8}>
                <FormFields index={index} isActive={isActive} />
            </td>
        </tr>
    );
};
