import {
    Session,
    UserAccount,
    sessionSet,
} from 'components/customer/Auth/store/authSlice';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {Loader} from 'shared/helpers';
import {redirectUser} from 'shared/helpers/AuthHelpers';
import {useAppDispatch} from 'store/customer';

export const WaitForUser = ({
    children,
    allowRedirect = true,
}: PropsWithChildren<{
    allowRedirect?: boolean;
}>) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {data, isError} = useGetUserQuery();

    useEffect(() => {
        if (isError) {
            // If there is error, that generally means the user is not logged in or something happen.
            // In any case hide loader.
            setIsLoading(false);
        }
    }, [isError]);

    useEffect(() => {
        if (data) {
            const userAccount: UserAccount = {
                user_type: data.user_type,
                name: data.name,
                email: data.email,
                domain: data.domain,
                redirectTo: '',
                id: null,
                logo: null,
                passwordReset: null,
            };

            if (allowRedirect && redirectUser(userAccount, true)) {
                return;
            }

            dispatch(sessionSet(Session.LOGGED_IN));
            setIsLoading(false);
        }
    }, [data]);

    if (isLoading) {
        return <Loader loader={true} />;
    }

    return children;
};
