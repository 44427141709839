import React from 'react';
import {shallowEqual} from 'react-redux';
import {
    selectHasDoors,
    selectHasEdges,
    selectMaterial,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {CurrentSelections} from 'components/customer/AdvancedMaterials/CurrentSelections/CurrentSelections';
import {useAppSelector} from 'store/customer';

const withBenchtop = (Component: typeof CurrentSelections) => {
    const ComponentWithBenchtop = () => {
        const hasEdges = useAppSelector(selectHasEdges);
        const hasDoors = useAppSelector(selectHasDoors);
        const material = useAppSelector(selectMaterial, shallowEqual);

        return (
            <Component
                material={material}
                hasDoors={hasDoors}
                hasEdges={hasEdges}
            />
        );
    };

    return ComponentWithBenchtop;
};

export default withBenchtop(CurrentSelections);
