import {useEffect, useState} from 'react';
import {EdgeTogglePosition} from 'components/customer/BTM/helper/useEdgeTogglePosition';
import {useAppSelector} from 'store/customer';
import {
    selectDimension,
    selectMaterial,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';

export const useBlankMaterialProfileRestriction = (
    position: EdgeTogglePosition,
    shape: Shape
) => {
    const [showToggle, setShowToggle] = useState(true);
    const material = useAppSelector(selectMaterial, shallowEqual);
    const dimension = useAppSelector(selectDimension, shallowEqual);

    useEffect(() => {
        if (material && material.is_blank) {
            // if material is blank and
            // depth of the respective side is equals to material max depth
            // customers cannot update profile on that side.
            const depthCalculations = [
                {
                    shape: Shape.SQR,
                    sides: [
                        {
                            side: Side.A,
                            depth: (dimension: number[]) => dimension[1],
                        },
                    ],
                },
                {
                    shape: Shape.ANG,
                    sides: [
                        {
                            side: Side.A,
                            depth: (dimension: number[]) => dimension[1],
                        },
                        {
                            side: Side.F,
                            depth: (dimension: number[]) => dimension[4],
                        },
                    ],
                },
                {
                    shape: Shape.USHAPE,
                    sides: [
                        {
                            side: Side.A,
                            depth: (dimension: number[]) =>
                                dimension[1] - dimension[3],
                        },
                        {
                            side: Side.B,
                            depth: (dimension: number[]) => dimension[2],
                        },
                        {
                            side: Side.H,
                            depth: (dimension: number[]) => dimension[6],
                        },
                    ],
                },
            ];

            // if position are these shape and side and material is blank
            // then they are locked, i.e. customers cannot change them
            const noToggleSides = [
                {
                    shape: Shape.SQR,
                    sides: [Side.C],
                },
                {
                    shape: Shape.ANG,
                    sides: [Side.C, Side.D],
                },
                {
                    shape: Shape.USHAPE,
                    sides: [Side.E, Side.D, Side.F],
                },
            ];

            const noToggleSide = noToggleSides.find(
                (noToggle) => noToggle.shape == shape
            );

            if (noToggleSide.sides.includes(position.side)) {
                setShowToggle(false);
            } else {
                const depthCalculation = depthCalculations.find(
                    (calculation) => calculation.shape == shape
                );

                if (depthCalculation) {
                    const depthCalculator = depthCalculation.sides.find(
                        (calculator) => calculator.side == position.side
                    );

                    if (depthCalculator) {
                        const depth = depthCalculator.depth(dimension);

                        setShowToggle(depth < material.max_depth);
                    }
                }
            }
        } else {
            setShowToggle(true);
        }
    }, [material, position, dimension, shape]);

    return {
        showToggle,
    };
};
