import React from 'react';
import {Materials} from 'components/customer/AdvancedMaterials/Materials';
import {useAppSelector} from 'store/customer';
import {selectHasDoors} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {useEdges} from 'components/customer/AdvancedMaterials/helpers/useEdges';

const withEdge = (Component: React.FC) => {
    const Materials = () => {
        const hasDoors = useAppSelector(selectHasDoors);
        useEdges(hasDoors);

        return <Component />;
    };

    return Materials;
};

export default withEdge(Materials);
