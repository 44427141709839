import {Corner} from 'components/customer/BTM/entity/Corner';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';

// NOTE, this value will probably come from join database from backend later
// this is 30mm offset for mason mitre joins
const UI_MASONS_OFFSET = 70;

// This only works for right angled triangle and also assumes that first Point
// is the point what has 45 degree angle
// For. Eg ?|\
//          | \
//         b|__\ a
// Because we might have to look at triangle from numerous perspective we need to
// pass in deduction as well.
const calculateThirdCoordinate = (a: Point, b: Point, deduction: Point) => {
    const {x: x1, y: y1} = a;
    const {x: x2, y: y2} = b;

    const dx = Number(x2) - Number(x1);
    const dy = Number(y2) - Number(y1);

    // Calculate the distance between the two coordinates
    const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

    const x = Number(x1) + Number(deduction.x) * distance;
    const y = Number(y1) + Number(deduction.y) * distance;

    return {x, y};
};

const getDiagonalJoin = (
    right: boolean,
    point: Point,
    bottomWidth: number,
    midWidth: number,
    width: number,
    offset: Point
): Join => {
    let joinType = JoinType.FULL_MITRE;
    // offset.x = Number(offset.x) + 20;
    // offset.y = Number(offset.y) + 20;

    if (bottomWidth == midWidth) {
        if (right) {
            return {
                side: JoinSide.RIGHT,
                joinType,
                selected: false,
                points: [point, {x: width - Number(offset.x), y: offset.y}],
            };
        } else {
            return {
                side: JoinSide.LEFT,
                joinType,
                selected: false,
                points: [point, {x: offset.x, y: offset.y}],
            };
        }
    } else {
        joinType = JoinType.DOG_LEG_MITRE;

        if (right) {
            let pointA: Point = {
                x: Number(point.x) + UI_MASONS_OFFSET,
                y: point.y,
            };
            let pointB: Point = {
                x: width - UI_MASONS_OFFSET,
                y: 0,
            };

            if (bottomWidth > midWidth) {
                pointA = {
                    x: point.x,
                    y: midWidth - UI_MASONS_OFFSET,
                };
                pointB = {
                    x: width,
                    y: UI_MASONS_OFFSET,
                };
            }

            return {
                side: JoinSide.RIGHT,
                joinType,
                selected: false,
                points: [
                    point,
                    calculateThirdCoordinate(point, pointA, {x: 1, y: -1}),
                    calculateThirdCoordinate({x: width, y: 0}, pointB, {
                        x: -1,
                        y: 1,
                    }),
                    {x: width, y: 0},
                ],
            };
        } else {
            let pointA: Point = {
                x: Number(point.x) - UI_MASONS_OFFSET,
                y: point.y,
            };
            let pointB: Point = {x: UI_MASONS_OFFSET, y: 0};

            if (bottomWidth > midWidth) {
                pointA = {
                    x: point.x,
                    y: midWidth - UI_MASONS_OFFSET,
                };
                pointB = {
                    x: 0,
                    y: UI_MASONS_OFFSET,
                };
            }

            return {
                side: JoinSide.LEFT,
                joinType,
                selected: false,
                points: [
                    point,
                    calculateThirdCoordinate(point, pointA, {x: -1, y: -1}),
                    calculateThirdCoordinate({x: 0, y: 0}, pointB, {
                        x: 1,
                        y: 1,
                    }),
                    {x: 0, y: 0},
                ],
            };
        }
    }
};

export const lineIntersect = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    x3: number,
    y3: number,
    x4: number,
    y4: number
) => {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return;
    }

    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
        return;
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return;
    }

    // Return a object with the x and y coordinates of the intersection
    const x = x1 + ua * (x2 - x1);
    const y = y1 + ua * (y2 - y1);

    return {x, y};
};

const depthCalculator = (
    corner: Corner,
    point: Point,
    width: number,
    right: boolean
): Point => {
    const [x, y] = corner.depths;

    if (x == y) {
        if (corner.type == CornerType.Notch) {
            return {x, y: x};
        } else {
            return {x: x / 2, y: y / 2};
        }
    } else {
        if (corner.type == CornerType.Notch) {
            if (x > y) return {x: y, y};
            else return {x: x, y: x};
        } else {
            if (right) {
                const intersection = lineIntersect(
                    point.x,
                    point.y,
                    width,
                    0,
                    width - x,
                    0,
                    width,
                    y
                );

                if (intersection)
                    return {x: width - intersection.x, y: intersection.y};
            } else {
                return lineIntersect(0, y, x, 0, 0, 0, point.x, point.y);
            }
        }
    }

    return {x: 0, y: 0};
};

const getMasonMitreJoin = (point: Point, right: boolean, width?: number) => {
    const joinType = JoinType.MASONS_MITRE;
    if (right) {
        return [
            {
                side: JoinSide.RIGHT,
                joinType,
                selected: false,
                orientation: JoinOrientation.HORIZONTAL,
                points: [
                    point,
                    {
                        x: point.x + UI_MASONS_OFFSET,
                        y: point.y - UI_MASONS_OFFSET,
                    },
                    {x: width, y: point.y - UI_MASONS_OFFSET},
                ],
            },
            {
                side: JoinSide.RIGHT,
                joinType,
                selected: false,
                orientation: JoinOrientation.VERTICAL,
                points: [
                    point,
                    {
                        x: point.x + UI_MASONS_OFFSET,
                        y: point.y - UI_MASONS_OFFSET,
                    },
                    {x: point.x + UI_MASONS_OFFSET, y: 0},
                ],
            },
        ];
    } else {
        return [
            {
                side: JoinSide.LEFT,
                joinType,
                selected: false,
                orientation: JoinOrientation.HORIZONTAL,
                points: [
                    point,
                    {
                        x: point.x - UI_MASONS_OFFSET,
                        y: point.y - UI_MASONS_OFFSET,
                    },
                    {x: 0, y: point.y - UI_MASONS_OFFSET},
                ],
            },
            {
                side: JoinSide.LEFT,
                joinType,
                selected: false,
                orientation: JoinOrientation.VERTICAL,
                points: [
                    point,
                    {
                        x: point.x - UI_MASONS_OFFSET,
                        y: point.y - UI_MASONS_OFFSET,
                    },
                    {x: point.x - UI_MASONS_OFFSET, y: 0},
                ],
            },
        ];
    }
};

export const getJoins = (
    joins: Join[],
    corners: Corner[],
    dimensions: number[],
    shape: Shape
) => {
    if (shape == Shape.SQR) return;
    else if (shape == Shape.USHAPE) {
        const [
            width,
            rightHeight,
            rightBottom,
            innerRightHeight,
            ,
            innerLeftHeight,
            leftBottom,
            leftHeight,
        ] = dimensions;

        const joinCorners = corners.filter((corner) => corner.isJoin);
        const leftSelected = joins.findIndex(
            (join) => join.side == JoinSide.LEFT && join.selected
        );
        const rightSelected = joins.findIndex(
            (join) => join.side == JoinSide.RIGHT && join.selected
        );

        if (joinCorners.length == 2) {
            const rightCorner = corners[1];
            const leftCorner = corners[0];

            const rightOffset = depthCalculator(
                rightCorner,
                joinCorners[0].point,
                width,
                true
            );
            const leftOffset = depthCalculator(
                leftCorner,
                joinCorners[1].point,
                width,
                false
            );

            return [
                getDiagonalJoin(
                    true,
                    joinCorners[0].point,
                    rightBottom,
                    rightHeight - innerRightHeight,
                    width,
                    rightOffset
                ),
                ...getMasonMitreJoin(joinCorners[0].point, true, width),
                getDiagonalJoin(
                    false,
                    joinCorners[1].point,
                    leftBottom,
                    leftHeight - innerLeftHeight,
                    width,
                    leftOffset
                ),
                ...getMasonMitreJoin(joinCorners[1].point, false),
            ].map((join, index) => {
                if (join.side == JoinSide.LEFT) {
                    if (leftSelected > -1) {
                        if (index == leftSelected) {
                            join.selected = true;
                        }
                    } else {
                        if (
                            join.joinType == JoinType.MASONS_MITRE &&
                        join.orientation == JoinOrientation.VERTICAL
                        ) {
                            join.selected = true;
                        }
                    }
                }
                if (join.side == JoinSide.RIGHT) {
                    if (rightSelected > -1) {
                        if (index == rightSelected) {
                            join.selected = true;
                        }
                    } else {
                        if (
                            join.joinType == JoinType.MASONS_MITRE &&
                            join.orientation == JoinOrientation.VERTICAL
                        ) {
                            join.selected = true;
                        }
                    }
                }

                return join;
            });
        }
    } else if (shape == Shape.ANG) {
        const [width, rightHeight, , , bottomWidth] = dimensions;

        const join = corners.find((corner) => corner.isJoin);
        const selectedIndex = joins.findIndex((join) => join.selected);

        if (join) {
            const offset = depthCalculator(
                corners[0],
                join.point,
                width,
                false
            );

            return [
                getDiagonalJoin(
                    false,
                    join.point,
                    bottomWidth,
                    rightHeight,
                    width,
                    offset
                ),
                ...getMasonMitreJoin(join.point, false),
            ].map((join, index) => {
                if (selectedIndex > -1) {
                    if (index == selectedIndex) join.selected = true;
                } else {
                    if (
                        join.joinType == JoinType.MASONS_MITRE &&
                        join.orientation == JoinOrientation.HORIZONTAL
                    )
                        join.selected = true;
                }

                return join;
            });
        }
    }
};
