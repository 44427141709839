export const getOrigin = (
    dimension: number[],
    center: number[],
    scale: number
) => {
    const [x, y] = center;
    let width = 0;
    let height = 0;

    if (dimension.length == 2) {
        [width, height] = dimension;
    } else if (dimension.length == 6) {
        [width, , , , , height] = dimension;
    } else if (dimension.length == 8) {
        const [top, leftHeight, , , , , , rightHeight] = dimension;

        height = leftHeight > rightHeight ? leftHeight : rightHeight;
        width = top;
    }
    return [x - (width / 2) * scale, y - (height / 2) * scale];
};
