import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Join} from 'components/customer/BTM/entity/Join';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    buttJoinSet,
    buttJoinsSet,
    selectCenter,
    selectDimension,
    selectJoins,
    selectMaterial,
    selectMinButtJoinPartSize,
    selectScale,
    selectTopOffset,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import styled from 'styled-components';
import {shallowEqual} from 'react-redux';
import {useButtJoin} from 'components/customer/BTM/helper/useButtJoin';

interface ButtJoinProps {
    join: Join;
    readOnly: boolean;
}

export const ButtJoin = ({join, readOnly}: ButtJoinProps) => {
    const dispatch = useAppDispatch();

    const scale = useAppSelector(selectScale);
    const center = useAppSelector(selectCenter, shallowEqual);
    const dimension = useAppSelector(selectDimension, shallowEqual);
    const shape = useAppSelector(selectType, shallowEqual);
    const material = useAppSelector(selectMaterial, shallowEqual);
    const minimumEdgeDistance = useAppSelector(selectMinButtJoinPartSize);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const topOffset = useAppSelector(selectTopOffset);

    const {updateJoinPosition} = useButtJoin();

    const [value, setValue] = useState(join.value);

    const position = useMemo(() => {
        const X = center[0];
        const Y = center[1];

        const width = dimension[Side.A];
        let height = dimension[Side.B];

        if (shape.type === Shape.ANG) {
            height = dimension[Side.F];
        } else if (shape.type === Shape.USHAPE) {
            height = Math.max(dimension[Side.B], dimension[Side.H]);
        }

        let x = X - (width / 2) * scale;
        let y = Y - (height / 2) * scale + topOffset;

        const point = join.points.reduce(
            (acc, point) => ({
                x: Number(acc.x) + Number(point.x),
                y: Number(acc.y) + Number(point.y),
            }),
            {x: 0, y: 0}
        );

        x = x + (Number(point.x) / 2) * scale - 25;
        y = y + (Number(point.y) / 2) * scale - 12.5;

        return {
            left: x,
            top: y,
        };
    }, [scale, center, join, dimension, shape, topOffset]);

    const {isInvalid, message} = useMemo(() => {
        return {
            isInvalid: join.error && join.error !== '',
            message: join.error,
        };
    }, [join]);

    const updateValue = useCallback(
        (event: ChangeEvent) => {
            const value = Number((event.target as HTMLInputElement).value);

            setValue(value);
        },
        [join]
    );

    const updateJoin = useCallback(() => {
        try {
            const updatedJoins = updateJoinPosition(join, value);

            dispatch(buttJoinsSet(updatedJoins, join.benchSide));
        } catch (error) {
            if (error instanceof Error) {
                dispatch(
                    buttJoinSet(
                        {...join, error: String(error.message)},
                        join.order
                    )
                );
            } else {
                // console.log(error);
            }
        }
    }, [value, join, material, minimumEdgeDistance, dimension, shape, joins]);

    useEffect(() => {
        if (join.value !== value) {
            setValue(join.value);
        }
    }, [join]);

    return (
        <JoinEditor
            readOnly={readOnly}
            $isInvalid={isInvalid}
            title={message}
            type="number"
            style={position}
            value={value}
            onChange={updateValue}
            onBlur={updateJoin}
        />
    );
};

const JoinEditor = styled.input<{$isInvalid: boolean}>`
    position: absolute;
    height: 25px;
    width: 50px;
    border-radius: 8px;
    font-size: 0.8em;
    font-weight: 500;
    color: #495057;
    text-align: center;
    border: 1px solid rgb(var(--primary_colour));
    background: ${({$isInvalid}) =>
        $isInvalid ? 'rgb(244, 207, 192)' : 'white'};
`;
