import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAppSelector} from 'store/customer';
import {
    selectSession,
    Session,
    selectRedirectTo,
} from 'components/customer/Auth/store/authSlice';

export const RequireAuth = ({
    children,
}: {
    checkProcessingStatus?: boolean;
    children: JSX.Element;
}): JSX.Element => {
    const session = useAppSelector(selectSession);
    const redirectTo = useAppSelector(selectRedirectTo);

    const url = window.location.protocol + '//' + window.location.host;
    if (
        session == Session.LOGGED_OUT &&
        redirectTo &&
        !redirectTo.startsWith(url)
    ) {
        window.location.href = redirectTo;
        return;
    }

    if (session == Session.LOGGED_OUT) {
        return <Navigate to="/v2/login" />;
    }

    return children;
};
