import React, {useCallback} from 'react';
import {Button} from 'react-bootstrap';
import {OverlayTrigger} from 'shared';
import {Icon, genericMessageHandler} from 'shared/helpers';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useQFPContext} from 'components/customer/QFPRedux/QFPContext';
import {
    deleteQfp,
    selectSaving,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {
    invalidateSidebarAndCost,
    useDeleteCabinetMutation,
} from 'components/customer/Product/store/productApi';
import {Product} from 'components/customer/Product/entity';
import {useJobContext, useNotificationContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';

export const DeleteAction = ({
    product,
    index,
}: {
    product: Product;
    index: number;
}) => {
    const {updateTotalProductCountManual} = useJobContext() as PartialJob;
    const {notify} = useNotificationContext();
    const {fieldIndex} = useQFPContext();
    const dispatch = useAppDispatch();
    const [deleteCabinet] = useDeleteCabinetMutation();
    const isSaving = useAppSelector((state) => selectSaving(state, index));

    const deleteHandler = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            dispatch(deleteQfp(fieldIndex, fieldIndex));

            if ('job_cabinet_id' in product && product.job_cabinet_id) {
                let hasError = false;
                try {
                    const response = await deleteCabinet({
                        cabinetId: product.job_cabinet_id,
                    });

                    if ('error' in response) {
                        hasError = true;
                    } else {
                        dispatch(invalidateSidebarAndCost());
                    }

                    updateTotalProductCountManual(false, false);
                } catch (e) {
                    hasError = true;
                } finally {
                    if (hasError) {
                        genericMessageHandler(
                            notify,
                            {
                                message: [
                                    'Error: Could not delete the product.',
                                    'Sorry, we encountered an issue while trying to delete the product. This could be due to various reasons, such as a network problem or a server error.',
                                    'Please, save your changes, refresh the page and try again later.',
                                ],
                            },
                            'danger',
                            true,
                            10000
                        );
                    }
                }
            }
        },
        [product, updateTotalProductCountManual]
    );

    // Disable the button if saving
    return (
        <OverlayTrigger placement="top" overlay="Delete Product">
            <Button
                disabled={isSaving}
                title="Delete Product"
                variant="link"
                onClick={deleteHandler}
                className="delete-qfp">
                <Icon iconName="Options-Delete.svg" />
            </Button>
        </OverlayTrigger>
    );
};
