import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    activeSet,
    selectIdle,
    selectSaving,
    ValidationDataInterface,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {ProductType} from 'components/customer/QFPRedux/ProductType';
import {useDefaultProduct} from 'components/customer/QFPRedux/helpers/useDefaultProduct';
import {Field} from 'components/customer/QFPRedux/Field';
import {Note} from 'components/customer/QFPRedux/Note';
import {useFormikContext} from 'formik';
import {Price} from 'components/customer/QFPRedux/Price';
import {CopyAction} from 'components/customer/QFPRedux/CopyAction';
import {DeleteAction} from 'components/customer/QFPRedux/DeleteAction';
import {Material} from 'components/customer/QFPRedux/Material';
import {
    Product as ProductInterface,
    ValidStructureInterface,
} from 'components/customer/Product/entity';
import {ActiveQFP} from 'components/customer/QFPRedux/ActiveQFP';
import FormikReduxConnectComponent from 'components/customer/QFPRedux/FormikReduxConnect';
import {useAutoSave} from 'components/customer/QFPRedux/helpers/useAutoSave';
import {MaterialInterface} from 'components/customer/Materials/store/materialSlice';
import styled from 'styled-components';
import {Icon, getDoorInfo} from 'shared/helpers';
import AdvancedMaterials from 'components/customer/AdvancedMaterials/AdvancedMaterials';
import {MaterialType} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {useAppContext} from 'contexts';

const SavedColumn = styled.td`
    color: #1e8e3e !important;
    font-weight: bold;
`;

export interface ProductComponentInterface {
    index?: number;
    isActive?: boolean;
    product?: ProductInterface;
    validationData?: ValidationDataInterface;
    isValid?: boolean;
    persisted?: boolean;
    deleted?: boolean;
    updated?: boolean;
    structure?: ValidStructureInterface;
    material?: MaterialInterface;
}

// NOTE: implement memoized mapper here for qfp and isActive
export const ProductComponent = ({
    index,
    isActive,
    product,
    persisted,
    deleted,
    structure,
    validationData,
    material,
}: ProductComponentInterface) => {
    const dispatch = useAppDispatch();
    const {userProfile} = useAppContext();

    useDefaultProduct(product, persisted, index);
    const isSuccess = useAppSelector((state) => selectIdle(state, index));
    useAutoSave({
        validationData,
        index,
        isActive,
    });
    const isSaving = useAppSelector((state) => selectSaving(state, index));
    const [showSaved, setShowSaved] = useState<boolean>(false);
    const {values} = useFormikContext();

    const hasDoor = useMemo(
        () =>
            getDoorInfo({
                structure: structure?.originalStructure || [],
            }),
        [structure]
    );

    const initialRender = useRef(true);

    // NOTE: Disabled here as we really don't need setter.
    // eslint-disable-next-line react/hook-use-state
    const [style] = useState<React.CSSProperties>({
        width: '16px',
        marginLeft: '5px',
    });

    const setActive = useCallback(() => {
        if (!isActive) {
            dispatch(activeSet(index));
        }
    }, [index, isActive]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else if (isSuccess) {
            setShowSaved(true);

            setTimeout(() => {
                setShowSaved(false);
            }, 1000);
        }
    }, [isSuccess]);

    if (deleted) {
        return null;
    }

    if (Object.keys(product).length == 3 || Object.keys(values).length == 3) {
        return (
            <tr>
                <td colSpan={9}>Loading...</td>
            </tr>
        );
    }

    return (
        <>
            <FormikReduxConnectComponent index={index} />
            <tr
                onClick={setActive}
                className={`quick-product ${isActive ? 'active' : ''}`}>
                <td>
                    <strong>{product.room_cab_number}</strong>
                    {persisted ? (
                        <Icon
                            style={style}
                            iconName="Button-Tick-Secondary.svg"
                            color="#6dc067"
                        />
                    ) : null}
                    {isSaving ? (
                        <Icon style={style} iconName="tail-spin.svg" />
                    ) : null}
                </td>
                <ProductType
                    index={index}
                    colorSwatchImageUrl={material?.material?.image}
                />
                <Field
                    index={index}
                    name="cabinet_quantity"
                    autoFocus={isActive}
                />
                <Field
                    index={index}
                    name="height"
                    formField={structure.heightField}
                />
                <Field
                    index={index}
                    formField={structure.widthField}
                    name="width"
                />
                <td>
                    <AdvancedMaterialsQFP
                        materialType={MaterialType.EXTERIOR}
                        cabinetType={product.cabinet_type}
                        index={index}
                        page={Page.QFP}
                        hasDoors={hasDoor.ext}
                    />
                </td>
                <Material key={index} product={product} index={index} />
                {showSaved ? (
                    <SavedColumn>Saved to cart</SavedColumn>
                ) : (
                    <Note
                        isActive={isActive}
                        setActive={setActive}
                        name="cabinet_note"
                        index={index}
                    />
                )}

                {userProfile.show_pricing ? (
                    <>
                        <Price product={product} index={index} />
                        <Price
                            product={product}
                            index={index}
                            unitPrice={false}
                        />
                    </>
                ) : null}
                <td>
                    <CopyAction />
                    <DeleteAction product={product} index={index} />
                </td>
            </tr>
            {isActive ? <ActiveQFP index={index} isActive={isActive} /> : null}
        </>
    );
};

const AdvancedMaterialsQFP = styled(AdvancedMaterials)`
    width: calc(100% - 4px) !important;
    border-radius: 4px;
    margin: 2px !important;
    font-size: 1em !important;
    line-height: 13px;

    > img {
        display: none !important;
    }
`;
