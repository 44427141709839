import {useCallback, useEffect, useMemo} from 'react';
import {useAppSelector} from 'store/customer';
import {
    Loader,
    selectDoor,
    selectLoading,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useFetchEdges} from 'components/customer/AdvancedMaterials/helpers/useEdges';

export const useDoor = (hasDoor: boolean, cabinetType: number) => {
    const {data: user} = useGetUserQuery();

    const loader = useAppSelector(selectLoading, shallowEqual);
    const door = useAppSelector(selectDoor, shallowEqual);

    const {triggerQueryAndSetState} = useFetchEdges();

    const getLockedEdge = useCallback(() => {
        if (hasDoor) {
            void triggerQueryAndSetState(
                {
                    cabinetType,
                    id: door?.default_material_id,
                    manufacturerId: user?.manufacturerId,
                    pageSize: 1,
                    currentPage: 1,
                },
                true
            );
        }
    }, [door, hasDoor, cabinetType, user]);

    const isDoorLocked = useMemo(() => {
        return (
            hasDoor &&
            door?.default_material_id != null &&
            door?.id != undefined
        );
    }, [door, hasDoor]);

    useEffect(() => {
        if (isDoorLocked && loader != Loader.LOADING_INITIAL) {
            getLockedEdge();
        }
    }, [isDoorLocked]);
};
