import {useAppContext} from 'contexts';
import React from 'react';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import styled from 'styled-components';

const DispatchNote: React.FC = () => {
    const {userProfile} = useAppContext();

    return (
        <FormNote>
            <section>
                <Title>Note:</Title>
                {parseHtmlString(userProfile?.manufacturerFreightText)}
            </section>
        </FormNote>
    );
};

const FormNote = styled.div`
    border: 1px solid #ffe0b2;
    box-sizing: border-box;
    font-size: 0.75em;
    background: #fff3e0;
    padding: 12px;
    border-radius: 8px;
    color: #6b6f70;
    margin-bottom: 15px;

    ul {
        padding-left: 18px;
        margin-bottom: 0;
    }
`;

const Title = styled.p`
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
`;

export default DispatchNote;
