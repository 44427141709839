import React, {useMemo} from 'react';
import {isMobile} from 'react-device-detect';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {ProductBackgroundImage} from 'components';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {parseHtmlString} from 'shared/helpers/HTMLParser';

export const BenchtopDetail = ({bench}: {bench: Bench}) => {
    const isSmallDevice = isDeviceSmall();
    const [exteriorLabel] = useMemo(() => {
        return [!isMobile ? 'Exterior Material:' : 'Ext:'];
    }, [isMobile]);

    const imageContainerStyle = useMemo(() => {
        const style = {overflow: 'hidden'};

        if (isMobile) {
            return {
                height: 'auto',
                maxHeight: '125px',
                minHeight: '100px',
                width: '100px',
                ...style,
            };
        }

        return style;
    }, [isMobile]);

    return (
        <div className="product-detail">
            <div>
                <div className="image-container" style={imageContainerStyle}>
                    <ProductBackgroundImage
                        image={bench.shape.image}
                        src={`${bench.shape.image}`}
                        alt={bench.shape.name}
                    />
                </div>
            </div>
            <div style={isSmallDevice ? {} : {minWidth: '200px'}}>
                <h3>
                    <strong>{bench.shape.name} Bench</strong>
                </h3>

                {bench.material ? (
                    <>
                        <h3 style={{fontSize: '12px', marginBottom: '0px'}}>
                            {exteriorLabel}{' '}
                        </h3>
                        <p>{parseHtmlString(bench.material.displayName)}</p>
                    </>
                ) : (
                    <p style={{color: 'red'}}>
                        No Material found. Please edit this benchtop and select
                        a new material.
                    </p>
                )}

                {bench.comment ? (
                    <p>
                        <strong>Variation: </strong>
                        <span style={{color: '#FF0000'}}>{bench.comment} </span>
                    </p>
                ) : null}
            </div>
        </div>
    );
};
