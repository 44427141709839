export enum Menu {
    FAVOURUTES = 'favourites',
    DOOR_TYPE = 'door_types',
    MATERIAL = 'materials',
    EDGE_MATERIAL = 'edge_materials',
    THICKNESSES = 'thicknesses',
}

export interface MenuItem {
    id?: string;
    name: string;
    selected?: boolean;
    disabled?: boolean;
    hidden?: boolean;
}
