// @flow
/**
 *
 *
 * @export
 * @class ProductSubStyle
 */
export default class ProductSubStyle {
    subStyleId: number;
    subStyleImage: string;
    subStyleName: string;
}
