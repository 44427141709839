import React from 'react';
import {JobTable} from 'hooks';
import {useAppContext, useDashboardContext} from 'contexts';
import {DashboardStatusDefaults, JobStatusEnum} from 'shared/JobStatus';

type DashboardJobListProps = {
    jobs: object;
};

const DashboardJobList = ({jobs}: DashboardJobListProps) => {
    const {
        isQuotedJobsVisibleMobile,
        isPendingConfirmationJobsVisibleMobile,
        isProductionJobsVisibleMobile,
        isPendingJobsVisibleMobile,
        isPendingPaymentJobsVisibleMobile,
        isCompletedJobsVisibleMobile,
    } = useDashboardContext();
    const {
        userProfile: {jobStatuses},
    } = useAppContext();
    const getSectionName = (jobStatus: JobStatusEnum): string => {
        const status = jobStatuses?.find((item) => item.status === jobStatus);
        if (!!status && status?.custom_label) {
            return status.custom_label;
        }
        return DashboardStatusDefaults[jobStatus as number];
    };

    const countTables = Object.entries(jobs).filter((obj) => obj[1].length > 0);
    let tableOrder = 0;

    return (
        <>
            <JobTable
                title={getSectionName(JobStatusEnum.PROCESSING)}
                jobData={jobs.quotedJobs}
                className={`quoted-jobs`}
                columns={['id', 'name', 'cost', 'dateUpdatedDateTime']}
                link={`/v2/jobs/processing`}
                mobileRender={isQuotedJobsVisibleMobile}
                tableOrder={
                    jobs.quotedJobs != undefined
                        ? jobs.quotedJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />

            <JobTable
                title={getSectionName(JobStatusEnum.PENDING_CONFIRMATION)}
                jobData={jobs.pendingConfirmationJobs}
                className={`pending-confirmation-jobs`}
                columns={['id', 'name', 'cost', 'dateUpdatedDateTime']}
                link={`/v2/jobs/pending-confirmation-jobs`}
                mobileRender={isPendingConfirmationJobsVisibleMobile}
                tableOrder={
                    jobs.pendingConfirmationJobs != undefined
                        ? jobs.pendingConfirmationJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />

            <JobTable
                title={getSectionName(JobStatusEnum.PENDING_APPROVAL)}
                jobData={jobs.pendingApprovalJobs}
                className={`pending-approval-jobs`}
                columns={['id', 'name', 'cost', 'dateSubmittedDateTime']}
                link={`/v2/jobs/pending-approval`}
                mobileRender={isPendingJobsVisibleMobile}
                tableOrder={
                    jobs.pendingApprovalJobs != undefined
                        ? jobs.pendingApprovalJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />

            <JobTable
                title={getSectionName(JobStatusEnum.PENDING_PAYMENT)}
                jobData={jobs.pendingPaymentJobs}
                className={`pending-payment-jobs`}
                columns={['id', 'name', 'cost', 'dateAcceptedDateTime']}
                link={`/v2/jobs/pending-payment`}
                mobileRender={isPendingPaymentJobsVisibleMobile}
                tableOrder={
                    jobs.pendingPaymentJobs != undefined
                        ? jobs.pendingPaymentJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />

            <JobTable
                title={getSectionName(JobStatusEnum.PRODUCTION)}
                jobData={jobs.productionJobs}
                className={`production-jobs`}
                columns={['id', 'name', 'cost', 'dateDeliveryDateTime']}
                link={`/v2/jobs/production`}
                mobileRender={isProductionJobsVisibleMobile}
                tableOrder={
                    jobs.productionJobs != undefined
                        ? jobs.productionJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />

            <JobTable
                title={getSectionName(JobStatusEnum.COMPLETED)}
                jobData={jobs.completedJobs}
                className={`completed-jobs`}
                columns={['id', 'name', 'cost', 'dateUpdatedDateTime']}
                link={`/v2/jobs/job-completed`}
                mobileRender={isCompletedJobsVisibleMobile}
                tableOrder={
                    jobs.completedJobs != undefined
                        ? jobs.completedJobs.length > 0
                            ? tableOrder++
                            : 0
                        : 0
                }
                tablesWithValue={countTables.length}
                fromApi={false}
                api=""
            />
        </>
    );
};

export {DashboardJobList};
