import React, {useMemo} from 'react';
import {Row, Col} from 'react-bootstrap';
import {setRoomLayoutStatus} from 'service';
import {Number} from 'shared';
import {Icon} from 'shared/helpers';

const FieldInput = ({name, value, ...props}) => {
    return (
        <Number
            name={name}
            value={value}
            min={0}
            max={9999999}
            selectHandler={(name, value_) => {
                tdld &&
                    value_ != value &&
                    tdld.roomShapeParameterChange(name, value_);
            }}
            hasFormik={false}
            increaseIcon={<Icon iconName="Plus.svg" />}
            decreaseIcon={<Icon iconName="Minus.svg" />}
            reverseIcons={true}
            className="tdld_number_input"
            {...props}
        />
    );
};

export const ShapeOptions = ({tdld, tdldState, roomId}) => {
    const {tdldData, customData} = useMemo(() => {
        const tdldData = tdldState.tdldData;
        if (
            tdldData.shape &&
            tdldData.shape.data.code == 'custom' &&
            tdldData.dimension
        ) {
            if (tdldData.dimension.length) {
                const data = {};

                tdldData.dimension.forEach((pointData) => {
                    const dataKey = pointData.name.slice(-2);
                    const key = pointData.name.replace('#', '');

                    if (!data.hasOwnProperty(dataKey)) data[dataKey] = {};

                    data[dataKey][key] = pointData.value;
                });

                return {
                    tdldData,
                    customData: Object.values(data),
                };
            } else {
                return {
                    tdldData,
                    customData: [
                        {
                            custom_start_x_0: 0,
                            custom_start_y_0: 0,
                            custom_length_0: 0,
                            custom_end_x_0: 0,
                            custom_end_y_0: 0,
                            custom_angle_0: 0,
                        },
                    ],
                };
            }
        }

        return {
            tdldData,
            customData: [],
        };
    }, [tdldState]);

    const getValueByName = (name) => {
        if (tdldData.dimension) {
            const valueObject = tdldData.dimension.find(
                (value) => value.name == `#${name}`
            );

            if (valueObject) {
                return valueObject.value;
            }
        }

        return 0;
    };

    return (
        <div id="layer_room_shapes">
            {tdldData.data &&
            tdldData.data.status <= 1 &&
            tdldData.shape &&
            tdldData.shape.data.code == 'rect' ? (
                <fieldset className="room_layout_fields room_layout_rect">
                    <h2 className="form_header" style={{marginTop: 15 + 'px'}}>
                        <span className="floatL notbold">Rectangular Room</span>
                    </h2>

                    <ul className="form_element100">
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="rect_length_0">
                                Width:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    name="rect_length_0"
                                    value={
                                        tdldData.data && tdldData.data.width
                                            ? tdldData.data.width
                                            : 0
                                    }
                                />
                            </div>
                        </li>
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="rect_length_1">
                                Length:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    name="rect_length_1"
                                    value={
                                        tdldData.data && tdldData.data.length
                                            ? tdldData.data.length
                                            : 0
                                    }
                                />
                            </div>
                        </li>
                    </ul>
                </fieldset>
            ) : (
                <></>
            )}

            {tdldData.data &&
            tdldData.data.status <= 1 &&
            tdldData.shape &&
            tdldData.shape.data.code == 'lshape' ? (
                <fieldset className="room_layout_fields room_layout_lshape">
                    <h2 className="form_header" style={{marginTop: 15 + 'px'}}>
                        <span className="floatL notbold">L Shape Room</span>
                    </h2>
                    <ul className="form_element100">
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="lshape_length_5">
                                Left Width:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    id="lshape_length_5"
                                    name="lshape_length_5"
                                    value={getValueByName('lshape_length_5')}
                                />
                            </div>
                        </li>
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="lshape_length_0">
                                Right Width:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    id="lshape_length_0"
                                    name="lshape_length_0"
                                    value={getValueByName('lshape_length_0')}
                                />
                            </div>
                        </li>
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="lshape_length_1">
                                Left Depth:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    id="lshape_length_1"
                                    name="lshape_length_1"
                                    value={getValueByName('lshape_length_1')}
                                />
                            </div>
                        </li>
                        <li className="form_element60 floatL">
                            <span
                                className="label40 floatL"
                                htmlFor="lshape_length_4">
                                Right Depth:
                            </span>

                            <div
                                className="form_element50 tdld_control_container"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FieldInput
                                    id="lshape_length_4"
                                    name="lshape_length_4"
                                    value={getValueByName('lshape_length_4')}
                                />
                            </div>
                        </li>
                    </ul>
                </fieldset>
            ) : (
                <></>
            )}

            {tdldData.data &&
            tdldData.data.status <= 1 &&
            tdldData.shape &&
            tdldData.shape.data.code == 'custom' ? (
                <fieldset className="room_layout_fields room_layout_custom">
                    <h2 className="form_header" style={{marginTop: 15 + 'px'}}>
                        <span className="floatL notbold">Custom Room</span>
                    </h2>
                    <div className="form_element100">
                        <div className="note" style={{fontSize: '.8em'}}>
                            Place points that make up the room by clicking in
                            the area on the right.
                        </div>
                        <div className="note" style={{fontSize: '.8em'}}>
                            Place the points clockwise.
                        </div>
                    </div>

                    <table
                        id="custom_room_info"
                        className="cbc-table custom_room_info">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Start Point</th>
                                <th>End Point</th>
                                <th>Length</th>
                                <th>Angle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customData &&
                                customData.map((dataPoint, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <tr
                                                className={
                                                    index % 2 == 0
                                                        ? 'even'
                                                        : 'odd'
                                                }>
                                                <td
                                                    rowSpan={2}
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}>
                                                    <strong
                                                        style={{
                                                            marginLeft: '5px',
                                                        }}>
                                                        {index + 1}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_start_x_${index}`}
                                                        id={`custom_start_x_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_start_x_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_end_x_${index}`}
                                                        id={`custom_end_x_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_end_x_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_length_${index}`}
                                                        id={`custom_length_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_length_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_angle_${index}`}
                                                        id={`custom_angle_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_angle_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                className={
                                                    index % 2 == 0
                                                        ? 'even'
                                                        : 'odd'
                                                }>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_start_y_${index}`}
                                                        id={`custom_start_y_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_start_y_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                                <td>
                                                    <FieldInput
                                                        name={`custom_end_y_${index}`}
                                                        id={`custom_end_y_${index}`}
                                                        value={
                                                            dataPoint[
                                                                `custom_end_y_${index}`
                                                            ]
                                                        }
                                                        noSpinners={true}
                                                    />
                                                </td>
                                                <td colSpan="2"></td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                        </tbody>
                    </table>
                </fieldset>
            ) : (
                <></>
            )}

            <div className="room_shape_buttons">
                <Row>
                    {tdldData.data &&
                    tdldData.data.status <= 1 &&
                    tdldData.shape &&
                    tdldData.shape.data.code == 'custom' ? (
                        <Col>
                            <button
                                id="btn_delete_point"
                                className="button-danger form_100_button tdld_button_red"
                                onClick={() =>
                                    tdld && tdld.removeRoomShapePoint.call(tdld)
                                }>
                                Delete Point
                            </button>
                        </Col>
                    ) : (
                        <></>
                    )}
                    {tdldData.data && tdldData.data.status <= 1 ? (
                        <Col
                            md={{
                                offset:
                                    tdldData.data &&
                                    tdldData.data.status == 1 &&
                                    tdldData.data.layoutRoomShapeId == 3
                                        ? 3
                                        : 0,
                            }}>
                            <button
                                id="btn_confirm_shape"
                                className="button-blue form_100_button"
                                onClick={() => {
                                    tdld && tdld.onConfirmShape.call(tdld);
                                    roomId && setRoomLayoutStatus(roomId, true);
                                }}>
                                Confirm Shape
                            </button>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            </div>
        </div>
    );
};
