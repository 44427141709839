import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLazyGetEdgeFinishesQuery} from 'components/customer/EdgeFinishes/store/edgeFinishApi';
import {getCabinetFields} from 'shared/helpers';
import {useFormikContext} from 'formik';
import {
    clearSelectedEdgesByIndex,
    edgeFinishLockByIndex,
    edgeLockSet,
    selectEdgeFinish,
    selectedEdgesByIndex,
} from 'components/customer/EdgeFinishes/store/edgeFinishSlice';
import {shallowEqual} from 'react-redux';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {
    selectDefaultLoaded,
    materialsLoading,
} from 'components/customer/Materials/store/materialSlice';
import {MaterialType} from 'components/customer/Materials/entity';
import {useDebouncedCallback} from 'use-debounce';
import {useAppDispatch, useAppSelector} from 'store/customer';
import SelectField from 'shared/components/SelectField';
import {DrawerPanelEdge} from 'hooks/Product/DrawerPanelEdge';
import {useAppContext} from 'contexts';
import {EdgeFinish} from 'components/customer/EdgeFinishes/Entity/EdgeFinish';

interface EdgeFinishesInterface {
    index: number;
    fieldId?: string;
    fieldName: string;
    selectHandler: (name: string, value: string | number) => void;
    setFieldValue: (name: string, value: string | number) => void;
    isQFP?: boolean;
    fieldsetName?: string;
    fieldSetIndex?: number;
    value?: number;
}

interface PartialFormData {
    cabinet_ext_colour: string;
    cabinet_ext_edge_colour: string;
    cabinet_carc_colour: string;
    cabinet_carc_edge_colour: string;
    cabinet_door: string;
    drawer_panel_edges?: DrawerPanelEdge[];
    colour: string;
    edge_colour: string;
    cabinet_type: number;
    [key: string]: string | number | DrawerPanelEdge[];
}

export const EdgeFinishes = ({
    fieldName,
    selectHandler,
    setFieldValue,
    index,
    isQFP,
    fieldsetName,
    fieldSetIndex,
}: EdgeFinishesInterface): JSX.Element => {
    const defaultLoaded = useAppSelector(
        (state) => selectDefaultLoaded(state, MaterialType.EXTERIOR, index),
        shallowEqual
    );
    const edgeFinishLock = useAppSelector(
        (state) => edgeFinishLockByIndex(state, index),
        shallowEqual
    );
    const areMaterialsLoading = useAppSelector((state) =>
        materialsLoading(state, MaterialType.EXTERIOR, index)
    );
    const {userProfile} = useAppContext();
    const dispatch = useAppDispatch();
    const {values} = useFormikContext<PartialFormData>();
    const [query, {data: edgeFinishes}] = useLazyGetEdgeFinishesQuery();

    const selectedEdgeFinishes = useAppSelector(
        (state) => selectedEdgesByIndex(state, index),
        shallowEqual
    );

    const {data: productForm} = useGetQFPProductStructureQuery({
        cabinetType: values.cabinet_type,
    });
    const [autoSelect, setAutoSelect] = useState(false);
    const updateToDefaultOnDisable = useRef(false);

    const isLocked = useMemo(
        () => edgeFinishLock && edgeFinishLock.locked,
        [edgeFinishLock]
    );

    const {hasDoorStyle, adjacentFields, adjacentFieldsRestrictToOne} =
        useMemo(() => {
            if (!productForm) {
                return {
                    hasDoorStyle: true,
                    adjacentFields: [],
                    adjacentFieldsRestrictToOne: [],
                };
            }

            let {
                adjacentFields,
                hasExteriorDoor,
                adjacentFieldsRestrictToOne,
            }: {
                adjacentFields: string[];
                hasExteriorDoor: boolean;
                adjacentFieldsRestrictToOne: string[];
            } = getCabinetFields(
                productForm.originalStructure,
                false,
                fieldName
            );

            // drawer_panel_edges restriction
            const panelEdgeFields = ['panel_edge_left', 'panel_edge_right'];
            if (
                userProfile.allowDrawerFaceEdgeFinish &&
                Number(values?.drawer_amount || 0) > 1
            ) {
                const drawerPanelEdgesLength =
                    values?.drawer_panel_edges?.length || 0;
                const newAdjacentFields: string[] = [];

                const addDrawerAdjacent = (suffixes: string[]) => {
                    for (let i = 0; i < drawerPanelEdgesLength; i++) {
                        suffixes.forEach((suffix) => {
                            newAdjacentFields.push(`${i}${suffix}`);
                        });
                    }
                    adjacentFieldsRestrictToOne = [
                        ...(adjacentFieldsRestrictToOne || []),
                        ...newAdjacentFields,
                    ];
                };

                if (panelEdgeFields.includes(fieldName)) {
                    adjacentFields = adjacentFields.filter(
                        (field) => field !== 'panel_edge_join'
                    );
                    addDrawerAdjacent([
                        'drawer_edge_top',
                        'drawer_edge_bottom',
                    ]);
                    adjacentFields = [...adjacentFields, ...newAdjacentFields];
                } else if (fieldName.includes('drawer_edge_bottom')) {
                    addDrawerAdjacent(['drawer_edge_top']);
                } else if (fieldName.includes('drawer_edge_top')) {
                    addDrawerAdjacent(['drawer_edge_bottom']);
                }
            }

            return {
                adjacentFields,
                adjacentFieldsRestrictToOne,
                hasDoorStyle: hasExteriorDoor,
            };
        }, [
            productForm,
            fieldName,
            userProfile,
            values?.drawer_amount,
            values?.drawer_panel_edges,
        ]);

    const adjacentValues = useMemo<(string | number)[]>(() => {
        return adjacentFields.map((field: string) => {
            if (
                field.endsWith('drawer_edge_top') ||
                field.endsWith('drawer_edge_bottom')
            ) {
                const match = field.match(/^(\d+)/);
                const index = match ? Number(match[1]) : 0;
                const drawerEdge = values.drawer_panel_edges;

                return drawerEdge[Number(index)]
                    ? drawerEdge[Number(index)][
                          String(field).replace(/^\d+/, '')
                      ]
                    : undefined;
            } else {
                return values[String(field)];
            }
        });
    }, [values, adjacentFields]);

    const fetchEdgeFinishes = useDebouncedCallback(
        async (materialId: number, edgeId: number, doorId: number) => {
            try {
                const {isError, error} = await query(
                    {
                        materialId,
                        edgeId,
                        doorId,
                    },
                    true
                );

                if (isError) {
                    const errMsg =
                        'error' in error
                            ? error.error
                            : JSON.stringify(error.data);
                    throw new Error(errMsg);
                }
            } catch (e) {
                console.error(e);
            }
        },
        1000
    );

    const options = useMemo(() => {
        if (typeof edgeFinishes === 'undefined') {
            return [];
        }
        // adjacent restriction
        const restrictions = edgeFinishes.map((edgeFinish) => {
            if (edgeFinish.restrict_adjacent) {
                if (edgeFinish.restrictions.adjacent) {
                    return (
                        adjacentValues
                            .map(Number)
                            .map(
                                (val) =>
                                    typeof val === 'undefined' ||
                                    edgeFinish.restrictions.adjacent.includes(
                                        val
                                    )
                            )
                            .filter(Boolean).length != adjacentValues.length
                    );
                }

                return false;
            }

            return false;
        });

        const checkEdgeFinishRestrictions = (
            edgeFinish: EdgeFinish,
            filterCondition: (selectedEdge: EdgeFinish) => boolean,
            getEdgeFilters: (selectedEdge: EdgeFinish) => number[]
        ) => {
            let restrict = false;

            adjacentFieldsRestrictToOne?.some((adjacentField) => {
                let fieldKey = adjacentField;
                // drawer_edging adjacent restriction
                if (
                    userProfile.allowDrawerFaceEdgeFinish &&
                    Number(values?.drawer_amount || 0) > 1
                ) {
                    const formattedAdjacentField = adjacentField.replace(
                        /^(\d+)/,
                        '$1.'
                    );
                    if (adjacentField.includes('drawer_edge')) {
                        fieldKey = `drawer_panel_edges[${formattedAdjacentField}]`;
                    } else {
                        fieldKey = formattedAdjacentField;
                    }
                }

                if (selectedEdgeFinishes[String(fieldKey)]) {
                    const selectedEdge = edgeFinishes.find(
                        (e) =>
                            e.id ==
                            Number(
                                selectedEdgeFinishes[String(fieldKey)]
                                    .edgeFinishId
                            )
                    );

                    if (selectedEdge && filterCondition(selectedEdge)) {
                        const edgeFilters = getEdgeFilters(selectedEdge);
                        restrict = !edgeFilters.includes(edgeFinish.id);

                        if (restrict) return true;
                    }
                }

                return false;
            });

            return restrict;
        };

        // other edges restriction
        const checkOtherEdgesRestrictions = (edgeFinish: EdgeFinish) => {
            return checkEdgeFinishRestrictions(
                edgeFinish,
                (selectedEdge) =>
                    selectedEdge.filters?.OtherEdgeFinishes?.length > 0,
                (selectedEdge) => [
                    ...(selectedEdge.filters?.OtherEdgeFinishes || []),
                    ...(selectedEdge.restrictions?.defaultIds || []),
                ]
            );
        };

        // restrictToOne restriction
        const checkRestrictToOneRestrictions = (edgeFinish: EdgeFinish) => {
            return checkEdgeFinishRestrictions(
                edgeFinish,
                (selectedEdge) => selectedEdge.restrict_to_one_edge,
                (selectedEdge) => selectedEdge.restrictions?.defaultIds || []
            );
        };

        const options = edgeFinishes.map((edge, index) => {
            const adjacentRestriction = restrictions[Number(index)];
            const otherEdgesRestriction = checkOtherEdgesRestrictions(edge);
            const restrictToOneRestriction =
                checkRestrictToOneRestrictions(edge);

            const disabled =
                adjacentRestriction ||
                otherEdgesRestriction ||
                restrictToOneRestriction;

            return {
                ...edge,
                disabled,
            };
        });

        return options;
    }, [
        adjacentValues,
        edgeFinishes,
        adjacentFieldsRestrictToOne,
        selectedEdgeFinishes,
    ]);

    const parameters = useMemo(() => {
        updateToDefaultOnDisable.current = false;
        let door;

        if (hasDoorStyle) {
            door = parseInt(values.cabinet_door);
        }

        const color = parseInt(
            values.cabinet_ext_colour || values.cabinet_carc_colour
        );
        const edge = parseInt(
            values.cabinet_ext_edge_colour || values.cabinet_carc_edge_colour
        );

        return {
            color,
            edge,
            door,
        };
    }, [
        values.cabinet_ext_colour,
        values.cabinet_carc_colour,
        values.cabinet_ext_edge_colour,
        values.cabinet_carc_edge_colour,
        values.cabinet_door,
        hasDoorStyle,
    ]);

    useEffect(() => {
        if (typeof defaultLoaded !== 'undefined' && !defaultLoaded) return;
        if (areMaterialsLoading) return;

        if (parameters.edge && parameters.color) {
            setAutoSelect(false);
            void fetchEdgeFinishes(
                parameters.color,
                parameters.edge,
                parameters.door
            );
        }
    }, [parameters, defaultLoaded, areMaterialsLoading]);

    useEffect(() => {
        if (!areMaterialsLoading && edgeFinishes && edgeFinishes.length) {
            const selected = edgeFinishes.find(
                (edgeFinish) => edgeFinish.id == values[String(fieldName)]
            );

            if (typeof selected === 'undefined') {
                setAutoSelect(true);

                if (isLocked) {
                    dispatch(
                        edgeLockSet({
                            locked: false,
                            index,
                        })
                    );
                }
            }
        }
    }, [edgeFinishes]);

    const dropdownSelectHandler = useCallback(
        (name: string, value: string | number) => {
            if (selectHandler) {
                selectHandler(name, value);
            } else {
                setFieldValue(name, value);
            }
            updateToDefaultOnDisable.current = true;

            const efinish = edgeFinishes?.find((e) => e.id == value);
            const otherEdgeFilters = efinish?.filters?.OtherEdgeFinishes || [];
            const restrictedToOne = efinish?.restrict_to_one_edge || false;

            dispatch(
                selectEdgeFinish({
                    fieldName: name,
                    edgeFinishId: value,
                    hasOtherEdges: otherEdgeFilters.length > 0,
                    restrictedToOne: restrictedToOne,
                    index,
                })
            );
        },
        [selectHandler, setFieldValue, edgeFinishes]
    );

    useEffect(() => {
        if (
            values.hasOwnProperty('panel_edge_join') &&
            Number(values?.drawer_amount) < 2
        ) {
            dropdownSelectHandler('panel_edge_join', 0);
        }
    }, [values?.drawer_amount, values?.panel_edge_join]);

    const name = useMemo(
        () =>
            fieldsetName == 'drawer_panel_edges'
                ? `${fieldsetName}[${fieldSetIndex}.${fieldName}]`
                : fieldName,
        [fieldsetName]
    );

    useEffect(() => {
        dispatch(clearSelectedEdgesByIndex(index));
    }, [values.cabinet_type, index, parameters]);

    useEffect(() => {
        const isEmpty =
            !selectedEdgeFinishes ||
            Object.keys(selectedEdgeFinishes).length === 0;
        const fieldValue =
            fieldsetName == 'drawer_panel_edges'
                ? values.drawer_panel_edges[Number(fieldSetIndex)][
                      String(fieldName)
                  ]
                : values[String(fieldName)];

        if (isEmpty && edgeFinishes?.length > 0 && fieldValue !== undefined) {
            dropdownSelectHandler(name, Number(fieldValue));
        }
    }, [
        values,
        fieldName,
        dropdownSelectHandler,
        selectedEdgeFinishes,
        edgeFinishes,
    ]);

    return (
        <SelectField
            id={name}
            name={name}
            options={options?.map(({id, name, disabled}) => ({
                label: name,
                value: String(id),
                disabled,
            }))}
            defaultFirstValue={autoSelect}
            customOnChange={dropdownSelectHandler}
            isDisabled={isLocked}
            isQFP={isQFP}
            placeholder="Loading ..."
            updateToDefaultOnDisable={updateToDefaultOnDisable.current}
        />
    );
};
