import {useJobContext} from 'contexts';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {getQuickProducts} from 'service';
import {PartialJob} from 'shared/types/PartialJob';
import {Product} from 'shared/types/PartialRoom';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {Event} from 'shared/events/Event';

export const useRecessRailsValidation = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void
): Event => {
    const {job, room} = useJobContext() as PartialJob;
    const navigate = useNavigate();

    const checkForRecessedRailsValidation = useCallback(async () => {
        const quickProducts = await getQuickProducts();
        const railsProducts = quickProducts.filter(
            (item) => item.isRecessedRail == 1 && item.hidden == 0
        );

        if (railsProducts.length == 0) {
            return {
                groupedCabinets: new Map<string, Product[]>(),
                groupedRails: new Map<string, Product[]>(),
                totalRailsAmountByExtColour: '',
                totalAmountByExtColour: '',
                lessRecessedHandle: false,
            };
        }

        const groupedCabinets = new Map<string, Product[]>();
        job?.rooms.forEach((room) => {
            room.jobCabinets.forEach((cabinet) => {
                const {fingerPull, extColour, type} = cabinet;
                if (fingerPull !== null) {
                    const key = `${extColour}-${type}`;
                    const group = groupedCabinets.get(key) || [];
                    group.push(cabinet);
                    groupedCabinets.set(key, group);
                }
            });
        });

        const groupedRails = new Map<string, Product[]>();
        job?.rooms.forEach((room) => {
            room?.jobCabinets
                ?.filter((rail) => rail.cabinet.isRecessedRail)
                .forEach((rail) => {
                    const key = `${rail.extColour}`;
                    const group = groupedRails.get(key) || [];
                    group.push(rail);
                    groupedRails.set(key, group);
                });
        });

        const totalProducts = new Map<string, number>();
        groupedCabinets.forEach((cabinetGroup) => {
            const extColour = cabinetGroup[0].extMaterial;
            const keyMaterial = `${extColour.name} - ${extColour.thickness}mm`;

            const totalExterior = cabinetGroup.reduce((total, item) => {
                const railsForItem =
                    (item.cabinet.doors > 0 ? 1 : item.drawers.length) *
                    item.width;
                return total + railsForItem * item.quantity;
            }, 0);

            if (totalProducts.has(keyMaterial)) {
                const previousTotal = totalProducts.get(keyMaterial) || 0;
                totalProducts.set(keyMaterial, previousTotal + totalExterior);
            } else {
                totalProducts.set(keyMaterial, totalExterior);
            }
        });

        let totalAmountByExtColour = '';
        totalProducts.forEach((totalRails, extColour) => {
            totalAmountByExtColour += `Total for ${extColour} = ${totalRails}mm <br/>`;
        });

        const dimensionValue = (item: Product) => {
            if (item.dimensions.includes('x')) {
                const dimensionsArray = item.dimensions.split('x');
                if (dimensionsArray.length == 3) {
                    return item.width;
                }
            }

            return item.height;
        };

        const totalRailProducts = new Map<string, number>();
        groupedRails.forEach((railGroup) => {
            const extColour = railGroup[0].extMaterial;
            const keyMaterial = `${extColour.name} - ${extColour.thickness}mm`;
            const totalRails = railGroup.reduce((total, item) => {
                return total + dimensionValue(item) * item.quantity;
            }, 0);

            totalRailProducts.set(keyMaterial, totalRails);
        });

        let totalRailsAmountByExtColour = '';
        totalRailProducts.forEach((totalRails, extColour) => {
            totalRailsAmountByExtColour += `Total for ${extColour} = ${totalRails}mm <br/>`;
        });

        const comparisonResult = new Map<string, boolean>();
        totalProducts.forEach((_, key) => {
            if (totalRailProducts.has(key) && totalProducts.has(key)) {
                comparisonResult.set(
                    key,
                    totalRailProducts.get(key) >= totalProducts.get(key)
                );
            } else {
                comparisonResult.set(key, false);
            }
        });

        const lessRecessedHandle = Array.from(
            comparisonResult.values()
        ).includes(false);

        return {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        };
    }, [job]);

    const submitJobRailsDialog = useCallback(
        (
            groupedCabinets: Map<string, Product[]>,
            groupedRails: Map<string, Product[]>,
            totalRailsAmountByExtColour: string,
            totalAmountByExtColour: string,
            resolve: (value: boolean) => void
        ) => {
            showDialog({
                title: 'Recessed Handle Options Selected',
                message: `
            <div>
            <p>Your job contains less than the recommended amount of rails added for products which contain recessed handle options:</p>
            <table width="100%">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Product</th>
                        <th>Material</th>
                        <th>Rail Required</th>
                    </tr>
                </thead>

                <tbody>
                ${Array.from(groupedCabinets.values())
                    .map((cabinetGroup) => {
                        const rows = cabinetGroup
                            .map((item) => {
                                const railsForItem =
                                    (item.cabinet.doors > 0
                                        ? 1
                                        : item.drawers.length) * item.width;

                                const material = item.extMaterial;

                                return `
                                <tr>
                                    <td>${item.productNumber}</td>
                                    <td>${item.quantity} x ${item.typeName}</td>
                                    <td>${material.name} - ${
                                    material.thickness
                                }mm</td>
                                    <td>${railsForItem * item.quantity}mm</td>
                                </tr>`;
                            })
                            .join('');

                        return rows;
                    })
                    .join('')}
                </tbody>
            </table>
            <p><br/>${totalAmountByExtColour}</p>
            <p>Rails added to job:</p>

            ${
                groupedRails.size === 0
                    ? '<p><strong>NO RAILS HAVE BEEN ADDED</strong></p>'
                    : `
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Rail</th>
                                    <th>Material</th>
                                    <th>Dimensions</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${Array.from(groupedRails.values())
                                    .map((railGroup) => {
                                        const rows = railGroup
                                            .map((item) => {
                                                const material =
                                                    item.extMaterial;
                                                return `
                                                <tr>
                                                    <td>${item.productNumber}</td>
                                                    <td>${item.quantity} x ${item.typeName}</td>
                                                    <td>${material.name} - ${material.thickness}mm</td>
                                                    <td>${item.dimensions}mm</td>
                                                </tr>`;
                                            })
                                            .join('');

                                        return rows;
                                    })
                                    .join('')}
                            </tbody>
                        </table>
            `
            }
            <p><br/>${totalRailsAmountByExtColour}</p>
        </div>
            `,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Back',
                        show: true,
                        action: () => {
                            hideDialog();
                            resolve(false);
                        },
                    },
                    {
                        name: 'Submit Job Anyway',
                        show: true,
                        action: () => {
                            resolve(true);
                        },
                    },
                    {
                        name: 'Add More Rails',
                        show: true,
                        variant: 'primary-color',
                        action: () => {
                            resolve(false);
                            navigate(
                                `/v2/job/${job.displayId}/room/${room.id}/product?rails=true`
                            );
                        },
                    },
                ],
            });
        },
        [job, room]
    );

    const action = useCallback(async (): Promise<boolean | void> => {
        const {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        } = await checkForRecessedRailsValidation();

        if (lessRecessedHandle) {
            return new Promise((resolve) => {
                submitJobRailsDialog(
                    groupedCabinets,
                    groupedRails,
                    totalRailsAmountByExtColour,
                    totalAmountByExtColour,
                    resolve
                );
            });
        } else {
            return true;
        }
    }, [checkForRecessedRailsValidation, submitJobRailsDialog]);

    return {
        action,
    };
};
