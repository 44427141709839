import React, {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    currentPageSet,
    materialBrandSelect,
    selectLoading,
    selectMaterialBrands,
    Loader,
    selectBrowserType,
    BrowserType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import styled from 'styled-components';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';
import {Brand} from 'components/customer/AdvancedMaterials/entity/Brand';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';

export const Brands = ({asDropdown = false}: {asDropdown?: boolean}) => {
    const dispatch = useAppDispatch();
    const {selectedMenu} = useSelectedMenu();
    const materialBrands = useAppSelector(selectMaterialBrands, shallowEqual);
    const loader = useAppSelector(selectLoading, shallowEqual);
    const colorBrowserType = useAppSelector(selectBrowserType);

    const selectBrand = useCallback(
        (brand: Brand) => () => {
            if (loader != Loader.IDLE) {
                return;
            }

            dispatch(currentPageSet(1));
            dispatch(materialBrandSelect(brand.id));
        },
        [loader]
    );

    if (
        !asDropdown &&
        colorBrowserType == BrowserType.BENCHTOP &&
        [Menu.MATERIAL, Menu.EDGE_MATERIAL, Menu.THICKNESSES].includes(
            selectedMenu
        )
    ) {
        return (
            <BrandContainer>
                {materialBrands
                    ? materialBrands.map((brand) => {
                          return (
                              <MiniRadio
                                  key={brand.id}
                                  $checked={brand.selected}
                                  $disabled={loader != Loader.IDLE}>
                                  {brand.name}
                                  <input
                                      type="checkbox"
                                      onChange={selectBrand(brand)}
                                  />
                              </MiniRadio>
                          );
                      })
                    : null}
            </BrandContainer>
        );
    }
};

const BrandContainer = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 15px 0;
    gap: 0 25px;
    flex-wrap: wrap;

    > label {
        font-weight: 500;
        font-size: 1.4em;
        user-select: none;
        margin: 0;
    }

    > label::before {
        top: 50%;
        margin-top: -8px;
    }

    > label::after {
        top: 50%;
        margin-top: -4px;
    }
`;
