import {forwardRef, useCallback, useEffect} from 'react';
import {FixedSizeGrid, GridOnItemsRenderedProps} from 'react-window';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    currentPageSet,
    selectCurrentPage,
    selectTotalPages,
    selectMaterials,
    selectMaxIndexSeen,
    maxIndexSeenSet,
    selectLoading,
    Loader,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';

type onItemsRenderedType = (props: GridOnItemsRenderedProps) => void;

interface GridPaginatorProps {
    totalRecords: number;
    children: (onItemsRendered: onItemsRenderedType) => JSX.Element;
}

export const GridPaginator = forwardRef<FixedSizeGrid, GridPaginatorProps>(
    ({children, totalRecords}, ref) => {
        const dispatch = useAppDispatch();
        const currentPage = useAppSelector(selectCurrentPage);
        const materials = useAppSelector(selectMaterials, shallowEqual);
        const totalPages = useAppSelector(selectTotalPages);
        const maxIndexSeen = useAppSelector(selectMaxIndexSeen);
        const loading = useAppSelector(selectLoading);

        const onItemsRendered = useCallback(
            (props: GridOnItemsRenderedProps) => {
                const {overscanRowStopIndex, visibleColumnStopIndex} = props;
                const displayedRecordsIndex =
                    (overscanRowStopIndex + 1) * (visibleColumnStopIndex + 1);

                let maxIndexSeen = displayedRecordsIndex;
                if (totalRecords < displayedRecordsIndex)
                    maxIndexSeen = totalRecords;

                dispatch(maxIndexSeenSet(maxIndexSeen));
            },
            [totalRecords]
        );

        useEffect(() => {
            if (
                currentPage >= totalPages ||
                loading == Loader.LOADING_DATA ||
                loading == Loader.LOADING_PAGINATION
            ) {
                return;
            }

            if (materials.length < maxIndexSeen) {
                dispatch(currentPageSet(currentPage + 1));
            }
        }, [maxIndexSeen, materials, totalRecords, totalPages, loading]);

        useEffect(() => {
            if (currentPage == 1) {
                if (
                    ref &&
                    'scrollToItem' in ref &&
                    typeof ref.scrollToItem == 'function'
                ) {
                    ref.scrollToItem({columnIndex: 0, rowIndex: 0});
                }
            }
        }, [currentPage]);

        return children(onItemsRendered);
    }
);
GridPaginator.displayName = 'GridPaginator';
