import {useLazyListBTMMaterialsQuery} from 'components/customer/BTM/store/btmApi';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';

export const useGetMaterials = () => {
    const {notify} = useNotificationContext();
    const [fetchMaterials, {isLoading, isUninitialized}] =
        useLazyListBTMMaterialsQuery();

    /**
     * Fetches materials based on material type and thickness
     *
     * @param {number} typeId id of the type selected
     * @param {number} thickness thickness selected for the material
     * @return {BenchtopMaterial[]}
     */
    const getMaterials = async (
        typeId: number,
        thickness: number
    ): Promise<BenchtopMaterial[]> => {
        try {
            const {
                data: {data},
            } = await fetchMaterials(
                {
                    type: typeId,
                    thickness,
                },
                true
            );

            if (data && data.length > 0) {
                return data;
            }
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch material',
            });
        }

        return [];
    };

    return {
        isLoading,
        isUninitialized,
        getMaterials,
    };
};
