import React, {useMemo} from 'react';
import {shallowEqual} from 'react-redux';
import {
    selectCenter,
    selectDimension,
    selectScale,
    selectSelectedJoins,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {getJoinLabelPosition} from 'components/customer/BTM/helper/getJoinLabelPosition';
import styled from 'styled-components';
import {useAppSelector} from 'store/customer';

const JoinLabel = styled.div<{$left: number; $top: number}>`
    position: absolute;
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
    background: rgb(var(--primary_colour));
    color: white;
    padding: 0px 5px;
    border-radius: 8px;
    font-size: 0.85em;
    font-weight: bold;
    opacity: 0.5;
`;

export const JoinLabels = () => {
    const scale = useAppSelector(selectScale);
    const center = useAppSelector(selectCenter);
    const dimension = useAppSelector(selectDimension);
    const shape = useAppSelector(selectType);
    const joins = useAppSelector(
        (state) => selectSelectedJoins(state),
        shallowEqual
    );

    const labels = useMemo(() => {
        if (joins) {
            return joins.map(
                getJoinLabelPosition(scale, center, dimension, shape)
            );
        }

        return [];
    }, [joins, scale, center, dimension, shape]);

    if (labels.length > 0) {
        return (
            <>
                {labels.map((label) => {
                    if (label.text !== '') {
                        return (
                            <JoinLabel
                                key={label.text}
                                $top={Number(label?.position?.y)}
                                $left={Number(label?.position?.x)}>
                                {label.text}
                            </JoinLabel>
                        );
                    }
                })}
            </>
        );
    }

    return null;
};
