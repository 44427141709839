import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    CornerButton,
    Container,
    CornerModificationInterface,
} from 'components/customer/BTM/Corners/CornerModification';
import {Title} from 'components/customer/BTM/Material';
import {Form} from 'react-bootstrap';
import {CornerType} from 'components/customer/BTM/entity/CornerType';

export const DEFAULT_MINIMUM_CORNER_DEPTH = 20;

const RowContainer = styled.div<{$inline?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    ${({$inline = false}) => {
        if (!$inline) {
            return 'width: 100%;';
        }
    }}
`;

const FormRow = styled.div<{$inline: boolean}>`
    display: flex;
    align-items: center;
    max-width: 150px;

    > input {
        margin: 0 0.5em;
    }

    ${({$inline = false}) => {
        if (!$inline) {
            return 'margin-bottom: 10px;';
        }
    }}
`;

const Label = styled(Form.Label)`
    margin: 0 !important;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
`;

const CheckboxInput = styled.input`
    margin-right: 5px;
`;

const CornerInput = styled(Form.Control)<{$invalid: boolean}>`
    border: 2px solid rgb(var(--primary_colour));
    border-radius: 8px;
    padding: 3px;
    min-width: 40px;
    font-size: 0.9em;

    ${({$invalid}) => {
        if ($invalid) {
            return `background: #F4CFC0`;
        }
    }}
`;

const SizeLabel = styled(Label)`
    padding-left: 0;
    padding-right: 0;
`;

const SpecTitle = styled(Title)`
    padding: 0;
`;

const Arrows = styled.div`
    width: 50px;
    text-align: right;
    font-size: 2em;

    > div {
        line-height: 20px;
    }
`;

export const CornerSpecs = ({
    xValue,
    setXValue,
    yValue,
    setYValue,
    previousPage,
    saveCorner,
    inline,
    type,
    error,
}: CornerModificationInterface) => {
    const [isEqualChecked, setIsEqualChecked] = useState(xValue == yValue);

    const handleXChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setXValue(Number(e.target.value));
    };

    const handleYChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYValue(Number(e.target.value));
    };

    const handleEqualCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsEqualChecked(e.target.checked);

        if (e.target.checked) {
            setYValue(xValue);
        }
    };

    useEffect(() => {
        setIsEqualChecked(xValue == yValue);
    }, [xValue, yValue]);

    return (
        <Container $inline={inline}>
            {inline ? (
                <div>
                    <SpecTitle>Depth</SpecTitle>
                </div>
            ) : null}
            <FormRow $inline={inline}>
                <Label htmlFor="xValue">X</Label>
                <CornerInput
                    type="number"
                    id="xValue"
                    plaintext
                    min={DEFAULT_MINIMUM_CORNER_DEPTH}
                    value={xValue}
                    $invalid={error && error.x != ''}
                    title={error && error.x}
                    onChange={handleXChange}
                    disabled={type == CornerType.None}
                />
                <SizeLabel>mm</SizeLabel>
                <Arrows>
                    <div>&larr;</div>
                    <div>&rarr;</div>
                </Arrows>
            </FormRow>
            <RowContainer $inline={inline}>
                <CheckboxLabel>
                    <CheckboxInput
                        type="checkbox"
                        checked={isEqualChecked}
                        onChange={handleEqualCheckboxChange}
                    />
                    Equal
                </CheckboxLabel>
            </RowContainer>
            <FormRow $inline={inline}>
                <Label htmlFor="yValue">Y</Label>
                <CornerInput
                    type="number"
                    id="yValue"
                    plaintext
                    min={DEFAULT_MINIMUM_CORNER_DEPTH}
                    value={yValue}
                    $invalid={error && error.y != ''}
                    title={error && error.y}
                    onChange={handleYChange}
                    disabled={type == CornerType.None}
                />
                <SizeLabel>mm</SizeLabel>
                <Arrows>
                    <div>&uarr;</div>
                    <div>&darr;</div>
                </Arrows>
            </FormRow>

            {!inline ? (
                <RowContainer>
                    {previousPage ? (
                        <CornerButton onClick={previousPage}>Back</CornerButton>
                    ) : null}

                    <CornerButton onClick={saveCorner}>OK</CornerButton>
                </RowContainer>
            ) : null}
        </Container>
    );
};
