import {
    useLazyGetCabinetsQuery,
    useLazyGetProductDataQuery,
} from 'components/customer/Product/store/productApi';
import {
    initialisedSet,
    loadingSet,
    productSet,
    updateSet,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {genericMessageHandler} from 'shared/helpers';
import {useJobContext, useNotificationContext} from 'contexts';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {Product} from 'components/customer/Product/entity';
import {getDefaultProduct} from 'components/customer/QFPRedux/helpers/getDefaultProduct';
import {cloneDeep} from 'lodash';
import {useFetchStructure} from 'components/customer/QFPRedux/helpers/useFetchStructure';
import {getMaterialsWithRestrictions} from 'components/customer/QFPRedux/helpers/materialHelpers';
import {defaultLoadedExteriorSet} from 'components/customer/Materials/store/materialSlice';
import {ActionType} from 'components/customer/Materials/helper/useDispatchMaterial';
import {Page} from 'store/customer/entity/Page';
import {useFormikContext} from 'formik';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';
import {useGetProductDefaultQuery} from 'components/customer/Product/helpers/useGetProductDefaultQuery';
import {PartialRoom} from 'shared/types/PartialRoom';
import {AppState} from 'store/customer/storeSetup';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {MaterialType} from 'components/customer/Materials/entity';
import {shallowEqual} from 'react-redux';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';

export const useDefaultProduct = (
    product: Product,
    persisted: boolean,
    index: number
) => {
    const {notify} = useNotificationContext();
    const dispatch = useAppDispatch();
    const {room} = useJobContext() as {room: PartialRoom};
    const dispatchMaterials = useDispatchWithFormik({page: Page.QFP, index});
    const [getProductDefault] = useLazyGetProductDataQuery();
    const fetchStructure = useFetchStructure();
    const {fetchProductDefaultValues} = useGetProductDefaultQuery();
    const [getCabinets] = useLazyGetCabinetsQuery();

    const selectedDoor = useAppSelector(
        (state: AppState) => getDoor(state, MaterialType.EXTERIOR, index),
        shallowEqual
    );
    const selectedMaterial = useAppSelector((state: AppState) =>
        getMaterial(state, MaterialType.EXTERIOR, index)
    );
    const selectedEdge = useAppSelector((state: AppState) =>
        getEdge(state, MaterialType.EXTERIOR, index)
    );

    const {roomId} = useParams();
    const typeRef = useRef<number>();

    if (
        typeof typeRef.current == 'undefined' &&
        product &&
        product.cabinet_type
    ) {
        typeRef.current = product.cabinet_type;
    }

    const {setValues} = useFormikContext();

    const fetchProduct = async (roomId: number, cabinetId: number) => {
        try {
            const {
                data: products,
                isError,
                error,
            } = await getCabinets(
                {
                    roomId,
                },
                true
            );

            if (isError) {
                const errMsg =
                    'error' in error ? error.error : JSON.stringify(error.data);
                throw new Error(errMsg);
            }

            return products.find((product) => product.id == cabinetId);
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch default product',
            });
        }
    };

    const fetchProductDefault = async (
        cabinetType: number,
        materialId?: number,
        doorId?: number,
        edgeId?: number,
        doorFilter?: string
    ) => {
        try {
            const {
                data: product,
                isError,
                error,
            } = await getProductDefault(
                {
                    cabinetType,
                    roomId: parseInt(roomId),
                    materialId,
                    doorId,
                    edgeId,
                    doorFilter,
                },
                true
            );

            if (isError) {
                const errMsg =
                    'error' in error ? error.error : JSON.stringify(error.data);
                throw new Error(errMsg);
            }

            return product;
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch room default product',
            });
        }
    };

    const initProduct = async (
        cabinetType: number,
        typeChange = false,
        persist = false
    ) => {
        if (product.copy && !typeChange) {
            dispatch(productSet({product: {copy: false}}, index));
            return;
        }

        dispatch(loadingSet(true, index));
        const validStructure = await fetchStructure(cabinetType);

        let productDefault;
        let defaultValues;
        if (persisted && !typeChange) {
            productDefault = await fetchProduct(product.room_id, product.id);
        } else {
            if (typeChange) {
                productDefault = await fetchProductDefault(
                    cabinetType,
                    selectedMaterial.id,
                    selectedDoor.id,
                    selectedEdge.id,
                    selectedDoor.filter_name
                );
            } else {
                productDefault = await fetchProductDefault(cabinetType);
            }

            defaultValues = await fetchProductDefaultValues(cabinetType);
        }

        const heightField = validStructure.heightField;
        const widthField = validStructure.widthField;

        const isHidden = [];
        const materials = getMaterialsWithRestrictions(
            productDefault,
            typeChange
        );

        const cabinet = getDefaultProduct(
            productDefault,
            defaultValues,
            heightField,
            widthField,
            materials,
            persisted,
            room,
            validStructure.hasDrawerFaceHeight
        );

        if (materials.exterior) {
            isHidden.push(materials.exterior.is_hidden);

            dispatchMaterials(
                ActionType.ExteriorColour,
                materials.exterior,
                true,
                false,
                false
            );
        }

        if (materials.edgeExterior) {
            isHidden.push(materials.edgeExterior.is_hidden);
            dispatchMaterials(
                ActionType.ExteriorEdgeColour,
                materials.edgeExterior,
                true,
                false,
                false
            );
        }

        if (materials.door) {
            isHidden.push(materials.door.is_hidden);
            dispatchMaterials(
                ActionType.Door,
                materials.door,
                true,
                false,
                false
            );
        }

        if (persist) {
            cabinet.height = product.height == '' ? '' : product.height;
            cabinet.width = product.width == '' ? '' : product.width;
            cabinet[String(heightField)] = cabinet.height;
            cabinet[String(widthField)] = cabinet.width;
            cabinet.quantity = product.cabinet_quantity;
            cabinet.cabinet_quantity = product.cabinet_quantity;
            cabinet.cabinet_comment = product.cabinet_comment;
            cabinet.cabinet_note = product.cabinet_note;

            if (
                typeof product.hor_grain !== 'undefined' &&
                product.hor_grain !== null
            ) {
                cabinet.hor_grain = product.hor_grain;
            }

            if (
                typeof product.double_sided !== 'undefined' &&
                product.double_sided !== null
            ) {
                cabinet.double_sided = product.double_sided;
            }

            if (
                typeof product.custom_colour !== 'undefined' &&
                product.custom_colour !== null
            ) {
                cabinet.custom_colour = product.custom_colour;
            }

            if (product.cabinet_door >= 0) {
                const productFormField = defaultValues['cabinet_form_fields'];
                if (product.cabinet_door == cabinet.cabinet_door) {
                    if (
                        product.border_width_bottom &&
                        product.border_width_bottom >= 0 &&
                        productFormField.border_width_bottom == 0
                    ) {
                        cabinet.border_width_bottom =
                            product.border_width_bottom;
                    }

                    if (
                        product.border_width_top &&
                        product.border_width_top >= 0 &&
                        productFormField.border_width_top == 0
                    ) {
                        cabinet.border_width_top = product.border_width_top;
                    }

                    if (
                        product.border_width_right &&
                        product.border_width_right >= 0 &&
                        productFormField.border_width_right == 0
                    ) {
                        cabinet.border_width_right = product.border_width_right;
                    }

                    if (
                        product.border_width_left &&
                        product.border_width_left >= 0 &&
                        productFormField.border_width_left == 0
                    ) {
                        cabinet.border_width_left = product.border_width_left;
                    }

                    if (
                        product.vert_width &&
                        product.vert_width >= 0 &&
                        productFormField.vert_width == 0
                    ) {
                        cabinet.vert_width = product.vert_width;
                    }

                    if (
                        product.hori_height &&
                        product.hori_height >= 0 &&
                        productFormField.hori_height == 0
                    ) {
                        cabinet.hori_height = product.hori_height;
                    }
                }
            }
        }

        const modifiedCabinet = cloneDeep(cabinet);
        modifiedCabinet.initial = !typeChange;
        if (product.room_cab_number) {
            modifiedCabinet.room_cab_number = product.room_cab_number;
        }

        if (isHidden.length > 0 && isHidden.some(Boolean)) {
            dispatch(updateSet(true, index));
        }

        dispatch(initialisedSet(true, index));
        void setValues(modifiedCabinet);
        modifiedCabinet.type_change = false;
        dispatch(productSet({product: modifiedCabinet, validStructure}, index));
        dispatch(loadingSet(false, index));
        dispatch(defaultLoadedExteriorSet(true, index));
    };

    useEffect(() => {
        // NOTE: using type_change to determine that event is fired by ProductType
        // component and not anywhere else.
        if (product.cabinet_type && product.type_change) {
            if (typeRef.current == product.cabinet_type) return;

            typeRef.current = product.cabinet_type;
            void initProduct(product.cabinet_type, true, true);
        }
    }, [product.cabinet_type]);

    useEffect(() => {
        if (product.cabinet_type && Object.keys(product).length === 3) {
            typeRef.current = product.cabinet_type;
            void initProduct(product.cabinet_type);
        }
    }, [product]);
};
