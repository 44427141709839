import styled, {DefaultTheme} from 'styled-components';
import {InputGroup as InputGroupOG} from 'react-bootstrap';

export const getDevicePixelRatio = () => window.devicePixelRatio;

const angle = 17;
const defaultHeight = 130;
const ratio = getDevicePixelRatio();
const computedHeight = 200 - 45 * ratio;
const computedTop = 200 - 20 * ratio;
const headerHeight =
    computedHeight > defaultHeight ? defaultHeight : computedHeight;
const headerTop = computedTop > defaultHeight ? defaultHeight : computedTop;

interface BlueBoldInterface {
    flex?: boolean;
    inline?: boolean;
    underline?: boolean;
    $pointer?: boolean;
}

export const LoginHeader = styled.div`
    box-shadow: inset 0px -10px 13px -7px rgba(0, 0, 0, 0.57);
    ${({theme}) => {
        const background = theme.colors.loginHeaderColor.main;

        if (!theme.device.centeredLoginThreshold && ratio < 2.5) {
            return `
            background: ${background};
            height: ${ratio && ratio > 1 ? `${headerHeight}px` : '130px'}`;
        } else {
            return 'height: 0';
        }
    }}
`;

export const LoginBody = styled.section`
    background-repeat: no-repeat;
    background-size: contain;
    ${({theme}) => {
        if (!theme.device.centeredLoginThreshold && ratio < 2.5) {
            let backgroundSize = 'contain';
            const aspectRatio = screen?.width / screen?.height;

            if (
                theme.device.isMedium ||
                theme.device.isSmallMedium ||
                aspectRatio < 1.5
            ) {
                backgroundSize = 'cover';
            }

            let height = `calc(100% - ${headerHeight}px)`;
            // auto scroll for smaller desktop screens
            if (screen?.availHeight <= 800) {
                height = 'calc(100% + 50px)';
                backgroundSize = 'cover';
            }

            return `
            background-image: url("${theme.images.loginBackground}");
            height: ${height};
            background-position-x: right;
            background-size: ${backgroundSize};
            position: relative;`;
        } else {
            return `
            background-image: url("${theme.images.loginBackground}");
            background-size: cover;
            min-height: 100%;
            height: auto;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            `;
        }
    }}
`;

export const AngleOverlay = styled.section`
    ${({theme}) => {
        if (theme.device.centeredLoginThreshold || ratio >= 2.5) {
            return `
                height: 100%;
                padding: 15px 20px;
                display: flex;
                align-self: center;
                width: 100%;
            `;
        } else {
            const computedWidth = 70 * (ratio / 2);
            let width =
                ratio && ratio > 1
                    ? computedWidth < 70
                        ? 70
                        : computedWidth
                    : 70;
            let left = 350;

            if (theme.device.isMedium) {
                width = 100;
                left = 450;
            }

            if (theme.device.isSmallMedium) {
                width = 121;
                left = 550;
            }

            if (screen?.availHeight <= 800) {
                width = 80;
                left = 450;
            }

            return `background: rgba(255, 255, 255, 0.5);
            width: ${width}%;
            -moz-transform: skew(-${angle}deg, 0deg);
            -webkit-transform: skew(-${angle}deg, 0deg);
            -o-transform: skew(-${angle}deg, 0de  g);
            -ms-transform: skew(-${angle}deg, 0deg);
            transform: skew(-${angle}deg, 0deg);
            position: absolute;
            top: -${headerTop}px;
            bottom: 0;
            left: -${left}px;
            padding-left: 350px;
            padding-top: ${headerTop}px;
            overflow: hidden;`;
        }
    }}
`;

export const LoginWrapper = styled.section`
    ${({theme}) => {
        if (!theme.device.centeredLoginThreshold && ratio < 2.5) {
            return `
                height: 100%;
                background: white;
                display: flex;
            `;
        } else {
            return `
                background: rgba(255, 255, 255, 0.95);
                margin: auto;
                padding: 45px 15px;
                border-radius: 10px;
                width: ${theme.device.isSmall ? '100%' : '480px'};
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            `;
        }
    }}
`;

export const LoginContent = styled.section`
    ${({theme}) => {
        if (theme.device.centeredLoginThreshold || ratio >= 2.5) {
            return `
                text-align: center;
            `;
        } else {
            let width = 100;
            let paddingLeft = 0;

            if (theme.device.isSmallMedium) {
                width = 87;
                paddingLeft = 206;
            }

            return `-moz-transform: skew(${angle}deg, 0deg);
            -webkit-transform: skew(${angle}deg, 0deg);
            -o-transform: skew(${angle}deg, 0deg);
            -ms-transform: skew(${angle}deg, 0deg);
            transform: skew(${angle}deg, 0deg);
            text-align: center;
            width: ${width}%;
            padding-left: ${paddingLeft}px;
            background: #FFF;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            flex: 1;
            `;
        }
    }};
`;

export const FormControl = styled.input<{$isInvalid?: boolean}>`
    ${({theme}) => {
        return `border: 1px solid ${theme.colors.primary.main};`;
    }}
    height: 40px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    background: ${({$isInvalid = false, theme}) =>
        $isInvalid ? theme.colors.error.main : 'white'};
    box-sizing: border-box;
    padding: 0 15px;
    flex: 1;

    :focus {
        outline: none;
    }
`;

export const CBCRadio = styled.div<{checked: boolean}>`
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-right: 8px;
    position: relative;

    ${({checked, theme}: {checked: boolean; theme: DefaultTheme}) => {
        if (checked) {
            return `
            border: 1px solid ${theme.colors.primary.main};
            &:after {
                position: absolute;
                width: 8px;
                height: 8px;
                top: 4px;
                left: 4px;
                background: ${theme.colors.primary.main};
                content: '';
            }`;
        } else {
            return `border: 1px solid ${theme.colors.primary.main}`;
        }
    }}
`;

export const BlueBold = styled.div<BlueBoldInterface>`
    ${({theme}) => {
        return `color: ${theme.colors.primary.main};`;
    }}
    font-weight: 500;
    text-align: ${(props) =>
        props.hasOwnProperty('$align-right')
            ? 'right'
            : props.hasOwnProperty('$align-center')
            ? 'center'
            : 'left'};
    font-size: 0.8em;
    display: ${(props) =>
        props.flex ? 'flex' : props.inline ? 'inline' : 'block'};
    cursor: ${(props) => (props.$pointer ? 'pointer' : 'initial')};
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`;

export const CBCButton = styled.button`
    ${({theme}) => {
        return `
            background: ${theme.colors.secondary.main};
            :hover {
                background: ${theme.colors.primary.main};
                color: white;
                text-decoration: none;
            }
        `;
    }}
    :disabled {
        cursor: not-allowed;
    }
    font-size: 1rem;
    text-align: center;
    border: 0;
    border-radius: 20px;
    color: white;
    box-shadow: none;
    width: 100%;
    height: 40px;
    margin-top: 15px;
    font-weight: 500;
    display: block;
    line-height: 39px;
`;

export const CBCListItem = styled.div<{index?: string}>`
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid #e1e1e1;
    margin: 0 15px;

    :last-of-type {
        margin-bottom: 15px;
        border: 0;
    }

    ${({index}) => {
        if (index) {
            return `
                :before {
                    content: "${index} -";
                    font-weight: bold;
                    margin-right: 7px;
                }
            `;
        }
    }}
`;

export const Title = styled.h1`
    ${({theme}) => {
        return `
            background: ${theme.colors.primary.main} !important;
        `;
    }}
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 15px 0;
    border-bottom-right-radius: 0 !important;
    box-sizing: border-box;
`;

export const InputGroup = styled(InputGroupOG)`
    margin-bottom: 15px;

    input {
        margin: 0;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-append {
        ${({theme}) => {
            return `
                border-color: ${theme.colors.primary.main} !important;
            `;
        }}
        border-width: 1px !important;
        border-style: solid !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
        user-select: none;

        span {
            padding: 0 0.5em;
        }

        img {
            width: 20px;
        }
    }
`;

export const ImageSelector = styled.div`
    display: flex;
    align-items: center;
    height: 55px;

    img {
        width: 55px;
        margin-right: 15px;
    }

    div:last-child {
        flex: 1;
    }
`;

export const LogoWrapper = styled.div`
    align-self: center;
    max-height: 100px;
    margin-bottom: 30px;
`;
