import React, {useMemo} from 'react';
import {useAppSelector} from 'store/customer';
import {selectJoins} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {ButtJoin} from 'components/customer/BTM/Preview/ButtJoin';

export const ButtJoins = ({readOnly = false}: {readOnly: boolean}) => {
    const joins = useAppSelector(selectJoins, shallowEqual);

    const buttJoins = useMemo(() => {
        if (joins && joins.length > 0) {
            return joins
                .map((join, index) => ({...join, order: index}))
                .filter((join) => join.joinType === JoinType.BUTT_JOIN);
        }
    }, [joins]);

    if (buttJoins && buttJoins.length > 0) {
        return buttJoins.map((join) => (
            <ButtJoin
                readOnly={readOnly}
                join={join}
                key={`${Number(join.benchSide)}_${join.index}`}
            />
        ));
    }

    return null;
};
