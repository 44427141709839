import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {Side} from 'components/customer/BTM/entity/Side';

export enum JoinOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export interface Join {
    index?: number;
    joinType: JoinType;
    points?: Point[];
    selected?: boolean;
    orientation?: JoinOrientation;
    side?: JoinSide;
    image?: string;
    disabled?: boolean;
    value?: number;
    benchSide?: Side;
    order?: number;
    touched?: boolean;
    error?: string;
}
