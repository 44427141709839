import {Point} from 'components/customer/BTM/entity/Point';
import {Path} from 'components/customer/BTM/entity/Path';
import {
    getDirectionalVector,
    translationMap,
    directionMap,
} from 'components/customer/BTM/helper/corner';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {cloneDeep} from 'lodash';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';

const modifierString = (value: number) => {
    if (value >= 0) {
        return `+ ${Math.abs(value)}`;
    } else {
        return `- ${Math.abs(value)}`;
    }
};

const modifyPoint = (
    point: Point,
    modificationMatrix: number[],
    modifyBy: number
): Point => {
    let x;
    let y;

    const xMod = modificationMatrix[0] * modifyBy;
    const yMod = modificationMatrix[1] * modifyBy;

    if (typeof point.x == 'string') {
        x = `${point.x} ${modifierString(xMod)}`;
    } else {
        x = point.x + xMod;
    }

    if (typeof point.y == 'string') {
        y = `${point.y} ${modifierString(yMod)}`;
    } else {
        y = point.y + yMod;
    }

    return {
        x,
        y,
    };
};

const squarePath = (lineA: Path, lineB: Path) => {
    let modificationMatrix = [0, 0];
    let modificationMatrixSecondLine = [0, 0];
    if (lineA.direction == Direction.RIGHT && lineB.direction == Direction.UP) {
        modificationMatrix = [1, 0];
        modificationMatrixSecondLine = [0, 1];
    } else if (
        lineA.direction == Direction.DOWN &&
        lineB.direction == Direction.RIGHT
    ) {
        modificationMatrix = [0, 1];
        modificationMatrixSecondLine = [-1, 0];
    } else if (
        lineA.direction == Direction.LEFT &&
        lineB.direction == Direction.DOWN
    ) {
        modificationMatrix = [-1, 0];
        modificationMatrixSecondLine = [0, -1];
    } else if (
        lineA.direction == Direction.UP &&
        lineB.direction == Direction.LEFT
    ) {
        modificationMatrix = [0, -1];
        modificationMatrixSecondLine = [1, 0];
    }

    // We need original points later for line coordinates
    // and length derivation as well
    lineA.squaredPoints = cloneDeep(lineA.points);
    lineB.squaredPoints = cloneDeep(lineB.points);

    lineA.squaredPoints[1] = modifyPoint(
        lineA.squaredPoints[1],
        modificationMatrix,
        6
    );
    lineA.squaredPoints[2] = modifyPoint(
        lineA.squaredPoints[2],
        modificationMatrix,
        6
    );
    lineB.squaredPoints[3] = modifyPoint(
        lineB.squaredPoints[3],
        modificationMatrixSecondLine,
        6
    );
};

export const getPathsByCoordinates = (
    coordinates: Point[],
    currentPaths: Path[] = undefined,
    edgeProfile: BenchtopEdgeProfile = undefined
) => {
    const paths = coordinates.map((coordinate, index) => {
        const to = index == coordinates.length - 1 ? 0 : index + 1;
        const toPoint = coordinates[Number(to)];
        const side = coordinates[Number(index)].side;

        const direction = getDirectionalVector(
            [Number(coordinate.x), Number(coordinate.y)],
            [Number(toPoint.x), Number(toPoint.y)]
        );

        const translation = translationMap.find(
            (translation) => translation.from == direction.direction
        );
        const translationVector = directionMap.find(
            (direction) => direction.direction == translation.to
        );

        let edgeThickness = 6;
        if (
            direction.direction == Direction.RIGHT_UP ||
            direction.direction == Direction.RIGHT_DOWN ||
            direction.direction == Direction.LEFT_DOWN ||
            direction.direction == Direction.LEFT_UP
        ) {
            edgeThickness = 4;
        }

        const translationPoint = [
            translationVector.point[0] * edgeThickness,
            translationVector.point[1] * edgeThickness,
        ];
        const translatedPointA = {
            x: `${coordinate.x} * scale ${modifierString(translationPoint[0])}`,
            y: `${coordinate.y} * scale ${modifierString(translationPoint[1])}`,
        } as Point;
        const translatedPointB = {
            x: `${coordinates[Number(to)].x} * scale ${modifierString(
                translationPoint[0]
            )}`,
            y: `${coordinates[Number(to)].y} * scale ${modifierString(
                translationPoint[1]
            )}`,
        } as Point;

        let edge: Edge;
        let profile: number;
        let edgeHighlight: boolean;

        if (side >= 0 && currentPaths) {
            const path = currentPaths.find((path) => path.side == side);

            if (path) {
                edge = path.edged;
                profile = path.profile;
                edgeHighlight = path.edgeHighlight;
            }
        } else if (currentPaths && currentPaths[Number(index)]) {
            let path: Path | null = null;

            if (currentPaths.length == coordinates.length) {
                path = currentPaths[Number(index)];
            } else {
                path = currentPaths.find(
                    (path) =>
                        path.points &&
                        path.points[0] &&
                        path.points[0].x == coordinate.x &&
                        path.points[0].y == coordinate.y
                );
            }

            if (path) {
                edge = path.edged;
                profile = path.profile;
                edgeHighlight = path.edgeHighlight;
            } else {
                edge = Edge.NOT_EDGED;
                edgeHighlight = false;
            }
        } else {
            edge = Edge.NOT_EDGED;
            edgeHighlight = false;
        }

        if (edgeProfile && edgeProfile.id == profile) {
            edgeHighlight = edgeProfile.edge_highlight;
        }

        // NOTE: This needs to be persited
        return {
            edged: edge,
            profile,
            points: [
                coordinate,
                coordinates[Number(to)],
                translatedPointB,
                translatedPointA,
                coordinate,
            ],
            edgeHighlight: edgeHighlight,
            direction: direction.direction,
            side: side >= 0 ? side : null,
        } as Path;
    });

    return paths.map((path, index) => {
        const to = index == paths.length - 1 ? 0 : index + 1;

        if (path.edged && paths[Number(to)].edged) {
            squarePath(path, paths[Number(to)]);
        }

        return path;
    });
};
