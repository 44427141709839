import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';

export interface Rule {
    check: string;
    message: {
        text: string;
        [key: string]: string;
    };
}

interface ValidationAndUpdate {
    shape: Shape;
    side: Side;
    rules: Rule[];
    updates?: {
        side: Side;
        value: string;
    }[];
}

/**
 * Validation rule for each side based on shapes
 * Validation rules needs to be true for valid value
 * The conditions inside rules[].check are calculated using
 * shared/Excel. Proper scope needs to be passed for correct
 * calculation. E.g. length <= maxLength will require
 * {length: 200, material: {max_length: 400}} object as scope.
 *
 * Some of the sides also have `updates` property. Which just means
 * when the side is updated, update the sides mentioned in the
 * updates property with respective calculated value. Again
 * shared/Excel with proper scope is used to calculate the value.
 */
const SIDE_MAP: ValidationAndUpdate[] = [
    // SQR shape validation data
    {
        shape: Shape.SQR,
        side: Side.A,
        rules: [
            {
                check: `OR(
                    AND(
                        material.allowButtJoin,
                        length <= ((material.maxButtJoin + 1) * maxLength)
                    ),
                    length <= maxLength
                )`,
                message: {
                    text: 'Length (A) cannot be greater than {value}mm',
                    value: `FORMULA_IF(
                        material.allowButtJoin,
                        (material.maxButtJoin + 1) * maxLength,
                        maxLength
                    )`,
                },
            },
            {
                check: 'length > 0',
                message: {
                    text: 'Length (A) cannot be 0',
                },
            },
        ],
    },
    {
        shape: Shape.SQR,
        side: Side.B,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (B) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (B) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                        AND(
                            NOT(material.is_blank),
                            length >= 100
                        ),
                        AND(
                            material.is_blank,
                            length >= material.minSingleProfileDepth
                        )
                    )`,
                message: {
                    text: 'Depth (B) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
    },
    // ANG Shape validation data
    {
        shape: Shape.ANG,
        side: Side.A,
        rules: [
            {
                check: `OR(
                    AND(
                        ISUNDEFINED(join),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength)
                            ),
                            length <= maxLength
                        )
                    ), 
                    AND(
                        NOT(ISUNDEFINED(join)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + bottom
                            ),
                            length <= bottom + maxLength
                        )
                    )
                )`,
                message: {
                    text: 'Length (A) cannot be greater than {value}mm',
                    value: `FORMULA_IF(
                        ISUNDEFINED(join),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength,
                            maxLength
                        ),
                        
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength + bottom,
                            bottom + maxLength
                        )
                    )`,
                },
            },
            {
                check: 'length > bottom',
                message: {
                    text: 'Length (A) cannot be less than length of (E) - {value}mm',
                    value: 'bottom',
                },
            },
        ],
        updates: [
            {
                side: Side.C,
                value: 'ROUND(length - bottom, 2)',
            },
        ],
    },
    {
        shape: Shape.ANG,
        side: Side.B,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (B) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: 'length < height',
                message: {
                    text: 'Depth (B) cannot be greater than or equal to length of (F) - {value}mm',
                    value: 'height',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (B) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                        AND(
                            NOT(material.is_blank),
                            length >= 100
                        ),
                        AND(
                            material.is_blank,
                            length >= material.minSingleProfileDepth
                        )
                    )`,
                message: {
                    text: 'Depth (B) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
        updates: [
            {
                side: Side.D,
                value: 'ROUND(height - length, 2)',
            },
        ],
    },
    {
        shape: Shape.ANG,
        side: Side.E,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (E) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: 'length < width',
                message: {
                    text: 'Length (E) cannot be greater than or equal to length of (A) - {value}mm',
                    value: 'width',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (E) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                    AND(
                        NOT(material.is_blank),
                        length >= 100
                    ),
                    AND(
                        material.is_blank,
                        length >= material.minSingleProfileDepth
                    )
                )`,
                message: {
                    text: 'Depth (E) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
        updates: [
            {
                side: Side.C,
                value: 'ROUND(width - length, 2)',
            },
        ],
    },
    {
        shape: Shape.ANG,
        side: Side.F,
        rules: [
            {
                check: `OR(
                    AND(
                        ISUNDEFINED(join),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength)
                            ),
                            length <= maxLength
                        )
                    ), 
                    AND(
                        NOT(ISUNDEFINED(join)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + bottom
                            ),
                            length <= bottom + maxLength
                        )
                    )
                )`,
                message: {
                    text: 'Length (F) cannot be greater than {value}mm',
                    value: `FORMULA_IF(
                        ISUNDEFINED(join),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength,
                            maxLength
                        ),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength + bottom,
                            bottom + maxLength
                        )
                    )`,
                },
            },
            {
                check: 'length > right',
                message: {
                    text: 'Length (F) cannot be less than length of (B) - {value}mm',
                    value: 'right',
                },
            },
        ],
        updates: [
            {
                side: Side.D,
                value: 'ROUND(length - right, 2)',
            },
        ],
    },
    // ANG Shape validation data
    {
        shape: Shape.USHAPE,
        side: Side.A,
        rules: [
            {
                check: `OR(
                    AND(
                        NOT(ISUNDEFINED(leftJoin)),
                        NOT(ISUNDEFINED(rightJoin)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + leftBottom + rightBottom
                            ),
                            length <= maxLength + leftBottom + rightBottom
                        )
                    ),
                    AND(
                        NOT(ISUNDEFINED(leftJoin)),
                        ISUNDEFINED(rightJoin),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + leftBottom
                            ),
                            length <= maxLength + leftBottom
                        )
                    ),
                    AND(
                        ISUNDEFINED(leftJoin),
                        NOT(ISUNDEFINED(rightJoin)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + rightBottom
                            ),
                            length <= maxLength + rightBottom
                        )
                    ),
                    AND(
                        ISUNDEFINED(leftJoin),
                        ISUNDEFINED(rightJoin),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength)
                            ),
                            length <= maxLength
                        )
                    )
                )`,
                message: {
                    text: 'Length (A) cannot be greater than {value}mm',
                    value: `
                        IFS(
                            AND(
                                ISUNDEFINED(leftJoin),
                                ISUNDEFINED(rightJoin)
                            ),
                            FORMULA_IF(
                                material.allowButtJoin,
                                (material.maxButtJoin + 1) * maxLength,
                                maxLength
                            ),
                            AND(
                                NOT(ISUNDEFINED(leftJoin)),
                                ISUNDEFINED(rightJoin)
                            ),
                            FORMULA_IF(
                                material.allowButtJoin,
                                (material.maxButtJoin + 1) * maxLength + leftBottom,
                                maxLength + leftBottom
                            ),
                            AND(
                                ISUNDEFINED(leftJoin),
                                NOT(ISUNDEFINED(rightJoin))
                            ),
                            FORMULA_IF(
                                material.allowButtJoin,
                                (material.maxButtJoin + 1) * maxLength + rightBottom,
                                maxLength + rightBottom
                            ),
                            AND(
                                NOT(ISUNDEFINED(leftJoin)),
                                NOT(ISUNDEFINED(rightJoin))
                            ),
                            FORMULA_IF(
                                material.allowButtJoin,
                                (material.maxButtJoin + 1) * maxLength + leftBottom + rightBottom,
                                maxLength + leftBottom + rightBottom
                            )
                        )
                    `,
                },
            },
            {
                check: 'length > rightBottom + leftBottom',
                message: {
                    text: 'Length (A) cannot be less than sum of (C) - {rightBottom}mm and (G) - {leftBottom}mm',
                    rightBottom: 'rightBottom',
                    leftBottom: 'leftBottom',
                },
            },
        ],
        updates: [
            {
                side: Side.E,
                value: 'ROUND(length - rightBottom - leftBottom, 2)',
            },
        ],
    },
    {
        shape: Shape.USHAPE,
        side: Side.B,
        rules: [
            {
                check: `OR(
                    AND(
                        ISUNDEFINED(join),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength)
                            ),
                            length <= maxLength
                        )
                    ),
                    AND(
                        NOT(ISUNDEFINED(join)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + rightHeight - rightInner
                            ),
                            length <= maxLength + rightHeight - rightInner
                        )
                    )
                )`,
                message: {
                    text: 'Length (B) cannot be greater than {value}mm',
                    value: `FORMULA_IF(
                        ISUNDEFINED(join),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength,
                            maxLength
                        ),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength + rightHeight - rightInner,
                            (rightHeight - rightInner) + maxLength
                        )
                    )`,
                },
            },
            {
                check: 'length > rightHeight - rightInner',
                message: {
                    text: 'Length (B) cannot be less than or equal to (I) {value}mm',
                    value: 'rightHeight - rightInner',
                },
            },
        ],
        updates: [
            {
                side: Side.D,
                value: 'ROUND(rightInner + (length - rightHeight), 2)',
            },
        ],
    },
    {
        shape: Shape.USHAPE,
        side: Side.H,
        rules: [
            {
                check: `OR(
                    AND(
                        ISUNDEFINED(join),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength)
                            ),
                            length <= maxLength
                        )
                    ),
                    AND(
                        NOT(ISUNDEFINED(join)),
                        OR(
                            AND(
                                material.allowButtJoin,
                                length <= ((material.maxButtJoin + 1) * maxLength) + leftHeight - leftInner
                            ),
                            length <= maxLength + leftHeight - leftInner
                        )
                    )
                )`,
                message: {
                    text: 'Length (H) cannot be greater than {value}mm',
                    value: `FORMULA_IF(
                        ISUNDEFINED(join),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength,
                            maxLength
                        ),
                        FORMULA_IF(
                            material.allowButtJoin,
                            (material.maxButtJoin + 1) * maxLength + leftHeight - leftInner,
                            (leftHeight - leftInner) + maxLength
                        )
                    )`,
                },
            },
            {
                check: 'length > leftHeight - leftInner',
                message: {
                    text: 'Length (H) cannot be less than or equal to (I) {value}mm',
                    value: 'leftHeight - leftInner',
                },
            },
        ],
        updates: [
            {
                side: Side.F,
                value: 'ROUND(leftInner + (length - leftHeight), 2)',
            },
        ],
    },
    {
        shape: Shape.USHAPE,
        side: Side.C,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (C) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: 'length < width - leftBottom',
                message: {
                    text: 'Length (C) cannot be greater {value}mm',
                    value: 'width - leftBottom',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (C) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                        AND(
                            NOT(material.is_blank),
                            length >= 100
                        ),
                        AND(
                            material.is_blank,
                            length >= material.minSingleProfileDepth
                        )
                    )`,
                message: {
                    text: 'Depth (C) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
        updates: [
            {
                side: Side.E,
                value: 'ROUND(width - leftBottom - length, 2)',
            },
        ],
    },
    {
        shape: Shape.USHAPE,
        side: Side.G,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (G) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: 'length < width - rightBottom',
                message: {
                    text: 'Length (C) cannot be greater {value}mm',
                    value: 'width - rightBottom',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (G) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                        AND(
                            NOT(material.is_blank),
                            length >= 100
                        ),
                        AND(
                            material.is_blank,
                            length >= material.minSingleProfileDepth
                        )
                    )`,
                message: {
                    text: 'Depth (G) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
        updates: [
            {
                side: Side.E,
                value: 'ROUND(width - rightBottom - length, 2)',
            },
        ],
    },
    {
        shape: Shape.USHAPE,
        side: Side.I,
        rules: [
            {
                check: 'length <= maxDepth',
                message: {
                    text: 'Depth (I) cannot be greater than {value}mm',
                    value: 'maxDepth',
                },
            },
            {
                check: `OR(
                        NOT(material.is_blank),
                        AND(
                            material.is_blank,
                            OR(
                                length == maxDepth,
                                length <= material.maxSingleProfileDepth
                            )
                        )
                    )`,
                message: {
                    text: 'Depth (I) cannot be greather than {maxSingleProfileDepth}mm, but can be exactly {maxDepth}mm',
                    maxSingleProfileDepth: 'material.maxSingleProfileDepth',
                    maxDepth: 'maxDepth',
                },
            },
            {
                check: `OR(
                        AND(
                            NOT(material.is_blank),
                            length >= 100
                        ),
                        AND(
                            material.is_blank,
                            length >= material.minSingleProfileDepth
                        )
                    )`,
                message: {
                    text: 'Depth (I) must be greater or equal to {value}mm',
                    value: 'IF(material.is_blank, material.minSingleProfileDepth, 100)',
                },
            },
        ],
        updates: [
            {
                side: Side.F,
                value: 'ROUND(leftHeight - length, 2)',
            },
            {
                side: Side.D,
                value: 'ROUND(rightHeight - length, 2)',
            },
        ],
    },
];

/**
 * Just a simple function to filter and return validation and update rules.
 *
 * @param {Shape} shape Shape of the bench Rectangle, L or U shape
 * @param {Side} side Side of the bench to get validation rule for
 *
 * @return {ValidationAndUpdate} The validation rule with optional update information
 */
export const getValidationRules = (shape: Shape, side: Side) =>
    SIDE_MAP.find(
        (validation) => validation.shape == shape && validation.side == side
    );
