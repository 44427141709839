import React, {useEffect} from 'react';
import {useDashboardContext, DashboardProvider} from 'contexts';
import {Col, Row} from 'react-bootstrap';
import {CBCButton, Loader} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useTimeOfDay, useDashboardTableConfig} from 'hooks';
import {DashboardJobList} from 'components';
import {
    UpdateCenter,
    MobileMenu,
    MobileRender,
    MobileContainerDecorator,
    LeadTimes,
    LastJobButton,
} from 'components';
import styled from 'styled-components';

import './Dashboard.scss';

export const DashboardContents = (): React.ReactElement => {
    const isSmallDevice = isDeviceSmall();
    const timeOfDay: string = useTimeOfDay();

    const {
        isUpdateCenterJobsVisibleMobile,
        isLeadTimeVisibleMobile,
        userProfile,
        setIsQuotedJobsVisibleMobile,
        setIsUpdateCenterJobsVisibleMobile,
    } = useDashboardContext();

    const {loader, userName, jobs, dialog, isNewCustomer} =
        useDashboardTableConfig();

    useEffect(() => {
        if (
            typeof jobs.quotedJobs !== 'undefined' &&
            jobs.quotedJobs?.length > 0
        ) {
            setIsQuotedJobsVisibleMobile(true);
            setIsUpdateCenterJobsVisibleMobile(false);
        }
    }, [jobs.quotedJobs]);

    return (
        <>
            <Loader loader={loader} hideInitially={true} />
            <div className="dashboard">
                <Row>
                    <Col
                        className="greeting"
                        lg={isNewCustomer ? {span: 6, offset: 3} : 7}
                        md={isNewCustomer ? {span: 6, offset: 3} : 6}
                        xs={12}>
                        <Row>
                            <Col md={12}>
                                <Jumbotron className="jumbotron">
                                    <h1>
                                        Good {timeOfDay}, {userName}
                                    </h1>
                                    <section>
                                        <div>
                                            <CBCButton
                                                id="create-new-job-button"
                                                iconName="Button-Add.svg"
                                                asLink={
                                                    !userProfile.inActiveManufacturer
                                                }
                                                disabled={
                                                    userProfile.inActiveManufacturer
                                                }
                                                to={'/v2/job'}>
                                                Create a New Job
                                            </CBCButton>
                                        </div>
                                        <LastJobButton />
                                    </section>
                                </Jumbotron>
                            </Col>
                            <Col className="lead-times">
                                <MobileRender
                                    isVisible={isLeadTimeVisibleMobile}>
                                    <LeadTimes />
                                </MobileRender>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        className="update-center"
                        lg={isNewCustomer ? {span: 6, offset: 3} : 5}
                        md={isNewCustomer ? {span: 6, offset: 3} : 6}
                        xs={12}>
                        <MobileRender
                            isVisible={isUpdateCenterJobsVisibleMobile}>
                            <UpdateCenter />
                        </MobileRender>
                    </Col>
                </Row>

                <DashboardJobListRow $isMobile={isSmallDevice}>
                    <DashboardJobList jobs={jobs} />
                </DashboardJobListRow>

                {isSmallDevice ? <MobileMenu /> : null}
                <br />
                {dialog}
            </div>
        </>
    );
};

export const Dashboard = (): React.ReactElement => {
    return (
        <DashboardProvider>
            <MobileContainerDecorator>
                <DashboardContents />
            </MobileContainerDecorator>
        </DashboardProvider>
    );
};

const DashboardJobListRow = styled(Row)<{$isMobile?: boolean}>`
    ${({$isMobile}) => ($isMobile ? 'margin: 0;' : '')}
`;

const Jumbotron = styled.div`
    background: #fff;
    margin: 0;
    padding: 2.5rem 0;
`;
