export enum Filters {
    NONE,
    SEARCH_MATERIAL,
    SEARCH_DOOR,
    SEARCH_EDGE,
    BRAND,
    BRAND_EDGE,
    FINISH,
    FINISH_EDGE,
    DOOR_TYPE,
}
