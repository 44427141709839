import {object, ref, string} from 'yup';
const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/gm;

export const getPasswordSchema = () =>
    object().shape({
        password: string()
            .required('Please enter your new password')
            .matches(passwordRegex, 'Please enter a valid password'),
        confirmPassword: string()
            .oneOf([ref('password')], 'Password does not match!')
            .required('Please enter your confirmation password')
            .matches(passwordRegex, 'Please enter a valid password'),
    });
