import {useJobContext} from 'contexts';
import {useProductOptions} from 'hooks';
import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Boolean, Number} from 'shared';
import {CBCButton, Icon, Loader} from 'shared/helpers';
import {ProductBackgroundImage} from 'components';
import {useAppDispatch} from 'store/customer';

const FieldInput = ({name, value, tdld, tdldData, angle, ...props}) => {
    const increaseIcon = angle ? 'RotateRight.svg' : 'Plus.svg';
    const decreaseIcon = angle ? 'RotateLeft.svg' : 'Minus.svg';

    return (
        <Number
            name={name}
            value={parseInt(value)}
            min={0}
            max={angle ? 360 : 9999999}
            selectHandler={(name, value_) => {
                value != value_ &&
                    tdld &&
                    tdldData &&
                    tdldData.cabinet &&
                    tdld.setComponentValue(tdldData.cabinet, name, value_);
            }}
            hasFormik={false}
            increaseIcon={<Icon iconName={increaseIcon} />}
            decreaseIcon={<Icon iconName={decreaseIcon} />}
            reverseIcons={true}
            step={angle ? 90 : 1}
            cycle={true}
            {...props}
        />
    );
};

export const ProductOptions = ({tdld, tdldState}) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {job, room} = useJobContext();
    const tdldData = useMemo(() => tdldState.tdldData, [tdldState]);
    const {style, productFields, loading} = useProductOptions({
        tdld,
        tdldState,
    });

    const tdldEditButtonClicked = useCallback(() => {
        navigate(
            `/v2/job/${job.displayId}/room/${room.id}/product?product=${tdldData.cabinet.type}&cabinetId=${tdldData.cabinet.jobCabinetId}&goto=tdld`
        );
    }, [job, room, tdldData]);

    if (
        !tdldData.hasOwnProperty('cabinet') ||
        (tdldData.hasOwnProperty('cabinet') &&
            typeof tdldData.cabinet == 'undefined')
    ) {
        return null;
    }

    const backgroundImage = `url("/uploads/gocabinet_materials/${
        tdldData.cabinet.exteriorImage || 'default_exterior.jpg'
    }")`;

    return (
        <>
            <Loader loader={loading} relative={true} />
            <div className="selectedComponentOptions">
                <h2 className="form_header" style={{marginTop: 15 + 'px'}}>
                    <span className="floatL notbold">
                        <span>{`${tdldData.cabinet.name} ${
                            tdldData.cabinet.text &&
                            tdldData.cabinet.text.trim()
                                ? `${tdldData.cabinet.text.trim()}.`
                                : ''
                        }${tdldData.cabinet.index}`}</span>
                    </span>
                </h2>

                {tdldData.cabinet.image ? (
                    <div className="tdld_image_container">
                        <ProductBackgroundImage
                            alt={tdldData?.cabinet?.name}
                            image={backgroundImage}
                            transform={tdldData.cabinet.extHorGrain}
                            src={tdldData.cabinet.changedImage}
                            fallbackSrc={tdldData.cabinet.image}
                        />
                    </div>
                ) : (
                    <></>
                )}

                <ul className="tdld_settings_container">
                    {productFields &&
                        productFields.map((field, index) => {
                            if (!field.display) {
                                return (
                                    <React.Fragment
                                        key={index}></React.Fragment>
                                );
                            }

                            if (field.type === 'label') {
                                return (
                                    <li key={index} className="cbc-tdld-label">
                                        <label>{field.value}</label>
                                    </li>
                                );
                            }

                            return (
                                <li className="form_element100" key={index}>
                                    <span className="tdld_label50 floatL">
                                        {field.label}
                                    </span>
                                    {field.type === 'boolean' ? (
                                        <Boolean
                                            name={field.name}
                                            value={field.value == 1}
                                            type="checkbox"
                                            alignRight={true}
                                            setFieldValue={(name, value) => {
                                                tdld &&
                                                    tdldData &&
                                                    tdldData.cabinet &&
                                                    tdld.setComponentValue(
                                                        tdldData.cabinet,
                                                        name,
                                                        value ? 1 : 0
                                                    );
                                            }}
                                        />
                                    ) : Array.isArray(field.name) ? (
                                        <div
                                            className="form_element50 tdld_control_container"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            {field.name.map(
                                                (individualField, index) => {
                                                    return (
                                                        <FieldInput
                                                            key={index}
                                                            name={
                                                                individualField
                                                            }
                                                            value={
                                                                field.value[
                                                                    index
                                                                ]
                                                            }
                                                            tdld={tdld}
                                                            tdldData={tdldData}
                                                            noSpinners={false}
                                                            className="tdld_number_input"
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : (
                                        <div className="form_element50 tdld_control_container">
                                            <FieldInput
                                                name={field.name}
                                                value={field.value}
                                                tdld={tdld}
                                                tdldData={tdldData}
                                                noSpinners={false}
                                                className="tdld_number_input"
                                                angle={field.type === 'angle'}
                                            />
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            </div>

            {tdldData && tdldData.cabinet && tdldData.cabinet.jobCabinetId ? (
                <section>
                    <CBCButton
                        iconName="Button-Edit.svg"
                        onClick={tdldEditButtonClicked}
                        className="button-blue btn-sm btn-layout"
                        style={{marginTop: '15px', width: '100%'}}>
                        Edit Product
                    </CBCButton>
                </section>
            ) : (
                <></>
            )}
        </>
    );
};
