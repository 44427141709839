import {useField} from 'formik';
import React from 'react';

interface CBCCheckboxInterface {
    name: string;
    label: string;
}

export const CBCCheckbox = ({label, name}: CBCCheckboxInterface) => {
    const [field] = useField({name, type: 'checkbox'});

    return (
        <div className="form-check">
            <input
                {...field}
                id={`checkbox-id-${name}`}
                className="form-check-input"
                type="checkbox"
            />
            <label htmlFor={`checkbox-id-${name}`} className="form-check-label">
                {label}
            </label>
        </div>
    );
};
