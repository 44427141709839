import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {PasswordResetRequestForm, PasswordResetForm} from 'components';

export const PasswordReset = (): JSX.Element => {
    const [searchParams] = useSearchParams();

    if (searchParams.get('token')) {
        return <PasswordResetForm />;
    } else {
        return <PasswordResetRequestForm />;
    }
};
