import React from 'react';
import {
    Material,
    Door,
    MaterialEdge,
    MaterialType,
} from 'components/customer/Materials/entity';
import {connect} from 'react-redux';
import {materialDetailsMapping} from 'components/customer/Materials/store/selectors/materialDetailsSelector';
import {Col, Row} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';

export interface MaterialDetailsInterface {
    material?: Material;
    edge?: MaterialEdge;
    door?: Door;
    index?: number;
    materialType: MaterialType;
    hasDoor?: boolean;
}

const MaterialDetails = ({
    material,
    edge,
    door,
    materialType,
    hasDoor,
}: MaterialDetailsInterface) => {
    return (
        <Row style={{marginTop: '15px'}}>
            <Col className="align-self-center">
                <>
                    <div style={{fontSize: '.9em'}}>
                        <strong className="primary-colour">Type : </strong>
                        {material && material.type.name}
                    </div>

                    {materialType == MaterialType.EXTERIOR && hasDoor ? (
                        <div style={{fontSize: '.9em'}}>
                            <strong className="primary-colour">Door : </strong>
                            {door && door.name}
                        </div>
                    ) : (
                        <></>
                    )}

                    <div style={{fontSize: '.9em'}}>
                        <strong className="primary-colour">Materials : </strong>
                        {material && parseHtmlString(material.displayName)}
                    </div>
                    <div style={{fontSize: '.9em'}}>
                        <strong className="primary-colour">
                            Edge Materials :{' '}
                        </strong>
                        {edge && parseHtmlString(edge.displayName)}
                    </div>
                </>
            </Col>
        </Row>
    );
};

export default connect(materialDetailsMapping)(MaterialDetails);
