export enum Position {
    Top,
    Bottom,
    Left,
    Right,
    LeftInner,
    RightInner,
    Inner,
    BottomLeft,
    BottomRight,
    BottomInner,
}
