import React from 'react';
import {UserAccount} from 'IndexedDB';
import {ImageSelector} from './StyledElements';
import {useFormikContext} from 'formik';
import {Dropdown} from 'react-bootstrap';

interface ManuSelectorType {
    userAccount: UserAccount;
    setAccount: (account: UserAccount) => void;
    userAccounts: UserAccount[];
    primaryColour: string;
    id: string | number;
}

interface Account {
    id: number;
    name: string;
    logo: string;
}

export const ManuSelector = ({
    userAccount,
    setAccount,
    userAccounts,
    primaryColour,
    ...props
}: ManuSelectorType): JSX.Element => {
    const {setFieldValue} = useFormikContext();

    const handleClick = (account: Account) => () => {
        void setFieldValue('id', account.id);
        setAccount(account);
    };

    return (
        <div {...props} style={{marginBottom: '15px', textAlign: 'left'}}>
            <input type="hidden" name="id" value={userAccount.id} />
            <Dropdown>
                <Dropdown.Toggle
                    title={userAccount.name}
                    style={{
                        background: 'white',
                        border: `1px solid ${primaryColour}`,
                        color: 'black',
                    }}>
                    {userAccount.name}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    style={{
                        maxHeight: '210px',
                        overflowY: 'hidden',
                    }}>
                    <div style={{maxHeight: '210px', overflowY: 'auto'}}>
                        {userAccounts.map((account: Account) => {
                            return (
                                <Dropdown.Item
                                    as={'div'}
                                    key={account.id}
                                    onClick={handleClick(account)}>
                                    <ImageSelector style={{cursor: 'pointer'}}>
                                        <img
                                            alt={account.name}
                                            src={account.logo}
                                        />
                                        <div>{account.name}</div>
                                    </ImageSelector>
                                </Dropdown.Item>
                            );
                        })}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
