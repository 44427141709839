import {useAppSelector} from 'store/customer';
import {
    selectHasDoors,
    selectMenu,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {useEffect, useState} from 'react';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';

export const useSelectedMenu = () => {
    const selectedMenu = useAppSelector(selectMenu);
    const hasDoors = useAppSelector(selectHasDoors);
    const [selectedMenuLocal, setSelectedMenuLocal] = useState(selectedMenu);

    useEffect(() => {
        if (hasDoors || (!hasDoors && selectedMenu != Menu.DOOR_TYPE)) {
            setSelectedMenuLocal(selectedMenu);
        }
    }, [selectedMenu, hasDoors]);

    return {
        selectedMenu: selectedMenuLocal,
    };
};
