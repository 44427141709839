import DBAbstract from './DBAbstract';

/**
 *
 *
 * @class RoomRepository
 * @extends {DBAbstract}
 */
class RoomRepository extends DBAbstract {
    /**
     * Creates an instance of RoomRepository.
     * @memberof RoomRepository
     */
    constructor() {
        super();

        this.record = {
            manufacturerOptions: 'manufacturer_hardware_options',
            gapSizeDefaults: 'gap_sizes_defaults',
            productSizeDefaults: 'product_sizes_defaults',
            cabinetTop: 'cabinet_top',
        };
    }

    /**
     *
     *
     * @return {*}
     * @memberof RoomRepository
     */
    async countManufacturerOptions() {
        return await this.count(this.record.manufacturerOptions);
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof RoomRepository
     */
    async addManuHardwareOptions(data) {
        return await this.add(this.record.manufacturerOptions, data);
    }

    /**
     *
     *
     * @return {*}
     * @memberof RoomRepository
     */
    async getManuHardwareOptions() {
        return await this.readAll(this.record.manufacturerOptions);
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof RoomRepository
     */
    async addGapSizeDefaults(data) {
        return await this.add(this.record.gapSizeDefaults, data);
    }

    /**
     *
     *
     * @return {*}
     * @memberof RoomRepository
     */
    async getGapSizeDefaults() {
        return await this.readAll(this.record.gapSizeDefaults);
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof RoomRepository
     */
    async addProductSizeDefaults(data) {
        return await this.add(this.record.productSizeDefaults, data);
    }

    /**
     *
     *
     * @return {*}
     * @memberof RoomRepository
     */
    async getProductSizeDefaults() {
        return await this.readAll(this.record.productSizeDefaults);
    }

    /**
     *
     *
     * @param {*} id
     * @return {*}
     * @memberof RoomRepository
     */
    async deleteProductSizeDefaults(id) {
        return await this.delete(this.record.productSizeDefaults, id);
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof RoomRepository
     */
    async addDrawerSystem(data) {
        return await this.add(this.record.drawerSystem, data, true);
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof RoomRepository
     */
    async addCabinetTop(data) {
        return await this.add(this.record.cabinetTop, data, true);
    }

    /**
     *
     *
     * @return {*}
     * @memberof RoomRepository
     */
    async getCabinetTop() {
        return await this.readAll(this.record.cabinetTop);
    }
}

export default RoomRepository;
