import {Side} from 'components/customer/BTM/entity/Side';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {getSorroundingIndexes} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {cornerSet} from 'components/customer/BTM/store/btmSlice';
import {store} from 'store/customer';
import {cloneDeep} from 'lodash';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Point} from 'components/customer/BTM/entity/Point';

const CORNER_MAP = [
    {
        shape: Shape.SQR,
        side: Side.A,
        corners: ['DA', 'AB'],
    },
    {
        shape: Shape.SQR,
        side: Side.B,
        corners: ['AB', 'BC'],
    },
    {
        shape: Shape.SQR,
        side: Side.C,
        corners: ['BC', 'CD'],
    },
    {
        shape: Shape.SQR,
        side: Side.D,
        corners: ['CD', 'DA'],
    },
    {
        shape: Shape.ANG,
        side: Side.A,
        corners: [undefined, 'AB'],
    },
    {
        shape: Shape.ANG,
        side: Side.B,
        corners: ['AB', 'BC'],
    },
    {
        shape: Shape.ANG,
        side: Side.F,
        corners: ['EF'],
    },
    {
        shape: Shape.ANG,
        side: Side.E,
        corners: ['DE', 'EF'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.B,
        corners: [undefined, 'BC'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.C,
        corners: ['BC', 'CD'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.D,
        corners: ['CD'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.F,
        corners: [undefined, 'FG'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.G,
        corners: ['FG', 'GH'],
    },
    {
        shape: Shape.USHAPE,
        side: Side.H,
        corners: ['GH'],
    },
];

const depth = 35;

export const setCorners = (side: Side, index: number, shape: Shape) => {
    const state = store.getState();
    const paths = state.btm.paths;
    const corners = state.btm.corners;

    if (paths) {
        const sorroundingPaths = getSorroundingIndexes(index, paths.length).map(
            (index) => paths[Number(index)]
        );

        const cornerMap = CORNER_MAP.find(
            (map) => map.side == side && map.shape == shape
        );
        if (cornerMap) {
            const cornerNames = cloneDeep(cornerMap).corners;
            if (sorroundingPaths[0].edged != Edge.PROFILED) {
                delete cornerNames[0];
            }

            if (sorroundingPaths[1].edged != Edge.PROFILED) {
                delete cornerNames[1];
            }

            if (cornerNames.filter(String).length > 0) {
                cloneDeep(corners).forEach((corner, index) => {
                    if (cornerNames.includes(corner.name)) {
                        corner.cutoff = true;
                        corner.type = CornerType.Clip;
                        corner.depths = [depth, depth];

                        store.dispatch(cornerSet(corner, index));
                    }
                });
            }
        }
    }
};

export const setClippedCornersFromEndProfile = (
    index: number,
    points: Point,
    depth: number | number[],
    direction: Direction,
    name: string,
    type: CornerType = CornerType.Clip,
    cutoff = true,
    isArc = false,
    relatedCornerIndex: number = null,
    hideCornerInPreview = false
) => {
    store.dispatch(
        cornerSet(
            {
                cutoff,
                type,
                depths: Array.isArray(depth) ? depth : [depth, depth],
                direction,
                disabled: {notch: true, clip: true},
                isJoin: false,
                name,
                addedThroughEndProfile: true,
                point: points,
                isArc,
                relatedCornerIndex,
                hideCornerInPreview,
            },
            index
        )
    );
};
