import React from 'react';
import {Chart} from 'react-google-charts';
import {useLeadTime} from 'hooks';
import {Loader} from 'shared/helpers';

export const LeadTimes = () => {
    const {loader, graphData, options} = useLeadTime();

    return (
        <>
            <Loader loader={loader} />
            {graphData && graphData.length ? (
                <>
                    <h2>Estimated Lead Times</h2>
                    <div>
                        <Chart
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            height={'396px'}
                            data={graphData}
                            options={options}
                            legendToggle
                        />
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};
