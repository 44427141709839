import React, {useState} from 'react';
import styled from 'styled-components';
import {Title} from 'components/customer/BTM/Material';
import {Thickness} from 'components/customer/BTM/Material/Thickness';
import {useFetchThickness} from 'components/customer/BTM/helper/useFetchThickness';
import {Icon} from 'shared/helpers';

const Container = styled.section`
    display: flex;
`;

const PaginationArrow = styled(Icon)`
    width: 20px;
    margin-left: 10px;
    cursor: pointer;
`;

export const Thicknesses = () => {
    const [page, setPage] = useState(0);
    const [size] = useState(5);
    const {thicknesses} = useFetchThickness();

    return (
        <div>
            <Container>
                <Title>Select thickness:</Title>
                <Container>
                    <PaginationArrow
                        title="Previous thicknesses"
                        onClick={() => setPage((page) => page - 1)}
                        disabled={page <= 0}
                        iconName="Button-Previous-Solid.svg"
                    />
                    <PaginationArrow
                        title="More Thicknesses"
                        onClick={() => setPage((page) => page + 1)}
                        disabled={
                            thicknesses &&
                            !(page < Math.floor(thicknesses.length / size))
                        }
                        iconName="Button-Next-Solid.svg"
                    />
                </Container>
            </Container>
            <Container>
                {thicknesses &&
                    thicknesses
                        .filter(
                            (_, index) =>
                                index >= page * size &&
                                index < (page + 1) * size
                        )
                        .map((thickness, index) => {
                            return <Thickness size={thickness} key={index} />;
                        })}
            </Container>
        </div>
    );
};
