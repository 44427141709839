import React, {createContext, useContext, useEffect, useRef} from 'react';
import {
    ValidationDataInterface,
    selectActive,
    selectDeleted,
    selectPersisted,
    selectProduct,
    selectUpdated,
    selectValidationData,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {
    ProductComponentInterface,
    ProductComponent,
} from 'components/customer/QFPRedux/Product';
import {useLazyGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {useValidate} from 'components/customer/QFPRedux/helpers/useValidate';
import {Formik} from 'formik';
import {AppState} from 'store/customer/storeSetup';
import {connect} from 'react-redux';
import {selectMaterialByIndex} from 'components/customer/Materials/store/materialSlice';

interface QFPContextInterface {
    fieldIndex?: number;
    validationData?: ValidationDataInterface;
}

export const QFPContext = createContext<QFPContextInterface>({});

export const useQFPContext = () => useContext(QFPContext);

const ProductProvider = ({
    index,
    isActive,
    product,
    validationData,
    persisted,
    updated,
    deleted,
    material,
}: ProductComponentInterface) => {
    const productRef = useRef<number>();
    const [
        trigger,
        {data: structure, isLoading, isUninitialized, isError, error},
    ] = useLazyGetQFPProductStructureQuery();
    const validate = useValidate(index, structure, updated, validationData);

    useEffect(() => {
        if (
            product.cabinet_type &&
            productRef.current != product.cabinet_type
        ) {
            productRef.current = product.cabinet_type;

            void trigger({cabinetType: product.cabinet_type}, true);
        }
    }, [product.cabinet_type]);

    if (isLoading || isUninitialized || isError || error) {
        return (
            <tr>
                <td colSpan={9}>Loading...</td>
            </tr>
        );
    }

    return (
        <QFPContext.Provider value={{fieldIndex: index, validationData}}>
            <Formik
                onSubmit={() => {
                    // TODO: implement this
                }}
                validate={validate}
                initialValues={product}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnBlur={true}
                initialErrors={{}}>
                {() => (
                    <ProductComponent
                        index={index}
                        isActive={isActive}
                        product={product}
                        persisted={persisted}
                        deleted={deleted}
                        structure={structure}
                        validationData={validationData}
                        material={material}
                    />
                )}
            </Formik>
        </QFPContext.Provider>
    );
};

export const Product = connect(
    (state: AppState, {index}: ProductComponentInterface) => ({
        validationData: selectValidationData(state, index),
        product: selectProduct(state, index),
        isActive: selectActive(state, index),
        persisted: selectPersisted(state, index),
        updated: selectUpdated(state, index),
        deleted: selectDeleted(state, index),
        material: selectMaterialByIndex(state, index),
    })
)(ProductProvider);
