import {Path} from 'components/customer/BTM/entity/Path';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {BenchOption} from 'components/customer/BTM/entity/BenchOption';
import {IBenchOption} from 'components/customer/BTM/entity/IBenchOption';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinInfo} from 'components/customer/BTM/entity/JoinInfo';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {Side} from 'components/customer/BTM/entity/Side';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Bench} from 'components/customer/BTM/entity/Bench';
import {lineIntersect} from 'components/customer/BTM/helper/joins';
import {isPointOnPath} from 'components/customer/BTM/helper/joinRestrictions';

export interface SaveDimension extends IBenchOption {
    length: number;
    from: [number, number];
    to: [number, number];
}

export interface SaveJoin extends IBenchOption {
    joinType: JoinType;
    orientation: JoinOrientation;
    direction: JoinOrientation;
    points: Point[];
    selected: boolean;
    side: JoinSide;
    joinIntersection: [number, number] | null;
    identifier: 'masons_mitre' | 'dog_leg' | 'full_mitre';
    disabled: boolean;
    value?: number;
    benchSide?: Side;
    index?: number;
}

export interface SavePath extends IBenchOption {
    length: number;
    side: Side;
    profile_id: number;
    edged: Edge;
    direction: Direction;
    from: [number, number] | null;
    to: [number, number] | null;
    points: Point[];
    squaredPoints: Point[];
    cutoff: CornerType;
}

const getPrimaryKey = (name: string, bench: Bench = null) => {
    if (bench != null) {
        const option = bench.options.find((option) => option.name == name);

        if (option) {
            return option.id;
        }
    }
};

export const getDimensions = (
    paths: Path[],
    dimensions: number[],
    bench: Bench = null
) => {
    // building dimension array
    const name = 'dimensions';
    const sides = paths.filter((path) => path.side >= 0);
    const dimensionObject = dimensions.map((dimension, order) => {
        const path = sides[Number(order)];
        const from = path.points[0];
        const to = path.points[1];

        return {
            order,
            length: dimension,
            from: [Number(from.x), Number(from.y)],
            to: [Number(to.x), Number(to.y)],
        };
    });

    const option = {
        type: 'json',
        name,
        value: dimensionObject,
    } as BenchOption<SaveDimension>;

    const pk = getPrimaryKey(name, bench);
    if (pk) option.id = pk;

    return option;
};

export const getCorners = (corners: Corner[], bench: Bench = null) => {
    const name = 'corners';
    const option = {
        type: 'json',
        name,
        value: corners.map((corner, order) => ({
            ...corner,
            order,
        })),
    } as BenchOption<Corner>;

    const pk = getPrimaryKey(name, bench);
    if (pk) option.id = pk;

    return option;
};

const getLinesIntersectionPoint = (
    lineAPointA: Point,
    lineAPointB: Point,
    lineBPointA: Point,
    lineBPointB: Point
) => {
    const {x: x1, y: y1} = lineAPointA;
    const {x: x2, y: y2} = lineAPointB;
    const {x: x3, y: y3} = lineBPointA;
    const {x: x4, y: y4} = lineBPointB;

    const intersection = lineIntersect(
        Number(x1),
        Number(y1),
        Number(x2),
        Number(y2),
        Number(x3),
        Number(y3),
        Number(x4),
        Number(y4)
    );

    return intersection as Point;
};

// This is a temporary function and needs to go away when
// we have implemented feature for manufacturer to add join offset
const getJoinIntersectionPoint = (
    join: Join,
    paths: Path[],
    dimensions: number[]
) => {
    let lineAPointA;
    let lineAPointB;
    let innerCorner;

    // For L Shape
    if (dimensions.length == 6) {
        const [, rightWidth, , , bottomWidth] = dimensions;

        innerCorner = {x: bottomWidth, y: rightWidth};
        lineAPointA = join.points[0];

        if (join.orientation == JoinOrientation.VERTICAL) {
            lineAPointB = {
                x: bottomWidth,
                y: 0,
            };
        } else {
            lineAPointB = {
                x: 0,
                y: rightWidth,
            };
        }
    }
    // For U Shape
    else {
        const [
            width,
            rightHeight,
            rightBottom,
            rightInner,
            ,
            leftInner,
            leftBottom,
            leftHeight,
        ] = dimensions;
        lineAPointA = join.points[0];

        // U shape left joins
        if (join.side == JoinSide.LEFT) {
            innerCorner = {
                x: leftBottom,
                y: leftHeight - leftInner,
            };

            if (join.orientation == JoinOrientation.VERTICAL) {
                lineAPointB = {
                    x: leftBottom,
                    y: 0,
                };
            } else {
                lineAPointB = {
                    x: 0,
                    y: leftHeight - leftInner,
                };
            }
        }
        // U shape right joins
        else {
            innerCorner = {
                x: width - rightBottom,
                y: rightHeight - rightInner,
            };

            if (join.orientation == JoinOrientation.VERTICAL) {
                lineAPointB = {
                    x: width - rightBottom,
                    y: 0,
                };
            } else {
                lineAPointB = {
                    x: width,
                    y: rightHeight - rightInner,
                };
            }
        }
    }

    for (const path of paths) {
        if (isPointOnPath(innerCorner, path)) {
            continue;
        }

        const intersection = getLinesIntersectionPoint(
            lineAPointA,
            lineAPointB,
            path.points[0],
            path.points[1]
        );

        if (intersection) {
            return intersection;
        }
    }
};

export const getJoins = (
    joins: Join[],
    joinsInformation: JoinInfo[],
    paths: Path[],
    dimensions: number[],
    bench: Bench = null
) => {
    const name = 'joins';

    const option = {
        type: 'json',
        name,
        value: joins.map((join, order) => {
            let intersectionPoint = join.points[join.points.length - 1];
            // NOTE remove this if calculation and entire joinIntersection
            // calculation when join offset is coming with join information
            if (join.joinType == JoinType.MASONS_MITRE) {
                intersectionPoint = getJoinIntersectionPoint(
                    join,
                    paths,
                    dimensions
                );
            }

            const joinsInfo = joinsInformation.find(
                (joinInfo) => joinInfo.joinType == join.joinType
            );

            return {
                order,
                joinType: join.joinType,
                identifier: joinsInfo.identifier,
                orientation: join.orientation ? join.orientation : null,
                direction: join.orientation ? join.orientation : null,
                points: join.points,
                selected: join.selected,
                disabled: join.disabled,
                side: join.joinType == JoinType.BUTT_JOIN ? null : join.side,
                joinIntersection: intersectionPoint
                    ? [intersectionPoint.x, intersectionPoint.y]
                    : null,
                index: join.index,
                value: join.value,
                benchSide: join.benchSide,
            };
        }),
    } as BenchOption<SaveJoin>;

    const pk = getPrimaryKey(name, bench);
    if (pk) option.id = pk;

    return option;
};

export const getCutoffType = (previousPath: Path, nextPath: Path) => {
    if (previousPath.side == null || nextPath.side == null) {
        return CornerType.Notch;
    } else return CornerType.Clip;
};

const getDistance = (from: Point, to: Point) => {
    const {x: x1, y: y1} = from;
    const {x: x2, y: y2} = to;

    const dx: number = Number(x2) - Number(x1);
    const dy: number = Number(y2) - Number(y1);

    const distance: number = Math.sqrt(dx * dx + dy * dy);
    return distance;
};

export const getProfiles = (paths: Path[], bench: Bench = null) => {
    const name = 'profiles';
    const option = {
        type: 'json',
        name,
        value: paths.map((path, order) => {
            const previousIndex = order == 0 ? paths.length - 1 : order - 1;
            const nextIndex = order == paths.length - 1 ? 0 : order + 1;

            const fromPoint = path.points[0];
            const toPoint = path.points[1];

            return {
                order,
                length: getDistance(path.points[0], path.points[1]),
                side: path.side != null ? path.side : undefined,
                profile_id: path.edged == Edge.NOT_EDGED ? 0 : path.profile,
                edged: path.edged,
                direction: path.direction,
                from: fromPoint ? [fromPoint.x, fromPoint.y] : null,
                to: toPoint ? [toPoint.x, toPoint.y] : null,
                points: path.points,
                squaredPoints: path.squaredPoints ? path.squaredPoints : null,
                cutoff:
                    path.side != null
                        ? CornerType.None
                        : getCutoffType(
                              paths[Number(previousIndex)],
                              paths[Number(nextIndex)]
                          ),
            };
        }),
    } as BenchOption<SavePath>;

    const pk = getPrimaryKey(name, bench);
    if (pk) option.id = pk;

    return option;
};

export const getCoordinates = (coordinates: Point[], bench: Bench = null) => {
    const name = 'coordinates';
    const option = {
        type: 'json',
        name,
        value: coordinates,
    } as BenchOption<IBenchOption>;

    const pk = getPrimaryKey(name, bench);
    if (pk) option.id = pk;

    return option;
};
