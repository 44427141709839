import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {MaterialSwatch} from 'components/customer/BTM/Material/MaterialSwatch';
import {Search} from 'components/customer/BTM/Material/Search';
import {MaterialInformation} from 'components/customer/BTM/Material/MaterialInformation';
import {AdvancedMaterialsBenchtop} from 'components/customer/AdvancedMaterials/AdvancedMaterialsBenchtop';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    margin-left: 15px;
    margin-top: 15px;
    margin-right: -15px;
`;

export const MaterialSearch = () => {
    return (
        <div className="material-search">
            <Row style={{margin: '40px 0'}}>
                <Col xs={{span: 3}} style={{maxWidth: 'initial'}}>
                    <MaterialSwatch size={140} />
                </Col>
                <Col>
                    <Row>
                        <MaterialInformation />

                        <Container>
                            <AdvancedMaterialsBenchtop />
                            <Search />
                        </Container>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
