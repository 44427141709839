// @flow
import type ProductSubStyle from './ProductSubStyle';
/**
 *
 *
 * @export
 * @class ProductStyle
 */
export default class ProductStyle {
    id: number;
    styleImage: string;
    styleName: string;
    subStyles: ProductSubStyle[];
}
