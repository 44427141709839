import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AdvancedMaterials from 'components/customer/AdvancedMaterials';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    doorSet,
    edgeSet,
    materialSet,
    materialTypeSet,
    materialTypesSet,
    propertiesSet,
    selectMaterialTypes,
    selectedMenuSet,
    materialFinishesSet,
    materialBrandsSet,
    materialFinishesEdgeSet,
    cabinetTypeSet,
    BrowserType,
    materialBrandsEdgeSet,
    selectLoadingNewColour,
    selectLoadingMatchingEdge,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {
    Door,
    MaterialEdge,
    MaterialType,
    Material as OGMaterial,
} from 'components/customer/Materials/entity';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {shallowEqual} from 'react-redux';
import {
    mapEdgeMaterial,
    mapMaterial,
} from 'components/customer/AdvancedMaterials/helpers/mappers';
import {getEdge} from 'components/customer/Materials/store/selectors/edgeSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {useMaterialTypes} from 'components/customer/AdvancedMaterials/helpers/useMaterialTypes';
import {useMaterialBrandsAndFinishes} from 'components/customer/AdvancedMaterials/helpers/useMaterialBrandsAndFinishes';
import {useEdgeBrandsAndFinishes} from 'components/customer/AdvancedMaterials/helpers/useEdgeBrandsAndFinishes';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import {Page} from 'store/customer/entity/Page';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';
import {ActionType} from 'components/customer/Materials/helper/useDispatchMaterial';
import styled from 'styled-components';
import {useDoor} from 'components/customer/AdvancedMaterials/helpers/useDoor';

interface AdvancedMaterialsProps {
    materialType?: MaterialType;
    cabinetType?: number;
    index?: number;
    className?: string;
    page?: Page;
    hasDoors?: boolean;
    width?: number;
}

const withMaterials = (Component: typeof AdvancedMaterials) => {
    const advancedMaterialComponent = ({
        materialType = MaterialType.EXTERIOR,
        index = 0,
        cabinetType,
        className,
        page = Page.PRODUCT,
        hasDoors = true,
        width,
    }: AdvancedMaterialsProps) => {
        const [show, setShow] = useState(false);
        const dispatch = useAppDispatch();

        const dispatchMaterial = useDispatchWithFormik({index, page});

        const materialTypesFromStore = useAppSelector(
            selectMaterialTypes,
            shallowEqual
        );
        const loadingNewColour = useAppSelector(selectLoadingNewColour);
        const loadingMatchingEdge = useAppSelector(selectLoadingMatchingEdge);

        const {materialTypes} = useMaterialTypes(cabinetType);
        const {brands, finishes} = useMaterialBrandsAndFinishes(
            show,
            hasDoors,
            cabinetType
        );
        const {brands: edgeBrands, finishes: edgeFinishes} =
            useEdgeBrandsAndFinishes(show, hasDoors, cabinetType);
        useDoor(hasDoors, cabinetType);

        // Getting the selected material, edge and door from the material store.
        const selectedMaterial = useAppSelector(
            (state) => getMaterial(state, materialType, index),
            shallowEqual
        );
        const selectedEdgeMaterial = useAppSelector(
            (state) => getEdge(state, materialType, index),
            shallowEqual
        );
        const selectedDoor = useAppSelector(
            (state) => getDoor(state, materialType, index),
            shallowEqual
        );
        // Getting the selected material, edge and door from the material store.

        const onShow = useCallback(() => {
            setShow(true);
            dispatch(cabinetTypeSet(cabinetType));
            dispatch(
                propertiesSet({
                    hasThicknesses: false,
                    hasDoors,
                    hasMaterials: true,
                    hasEdges: true,
                    hasBrands: true,
                    browserType:
                        page == Page.QFP
                            ? BrowserType.QFP
                            : BrowserType.PRODUCT,
                    materialTypeOption: materialType,
                    materialIndex: index,
                })
            );

            if (hasDoors) {
                dispatch(selectedMenuSet(Menu.DOOR_TYPE, false));
            } else {
                dispatch(selectedMenuSet(Menu.MATERIAL, false));
            }

            const mappedMaterial = mapMaterial(selectedMaterial);

            dispatch(materialSet(mappedMaterial));
            dispatch(materialTypeSet(mappedMaterial.type));
            dispatch(edgeSet(mapEdgeMaterial(selectedEdgeMaterial)));
            dispatch(doorSet(selectedDoor));
        }, [
            index,
            materialType,
            selectedMaterial,
            selectedEdgeMaterial,
            selectedDoor,
            materialTypesFromStore,
            hasDoors,
            cabinetType,
            page,
        ]);

        const onApply = useCallback(
            (
                material: Material<OGMaterial>,
                door: Door,
                edge: Material<MaterialEdge>
            ) => {
                const materialDoorFilter =
                    material.data.door_filter.toLowerCase();
                const edgeDoorFilter = edge.data.door_filter.toLowerCase();

                if (materialType == MaterialType.EXTERIOR) {
                    if (hasDoors) {
                        const doorFilter = door?.filter_name.toLowerCase();
                        if (
                            materialDoorFilter == doorFilter &&
                            edgeDoorFilter == doorFilter
                        ) {
                            dispatchMaterial(
                                ActionType.ExteriorColour,
                                material.data
                            );
                            if (selectedDoor.id != door.id) {
                                dispatchMaterial(ActionType.Door, door);
                            }
                            dispatchMaterial(
                                ActionType.ExteriorEdgeColour,
                                edge.data
                            );
                            setShow(false);
                            return true;
                        }
                    } else {
                        dispatchMaterial(
                            ActionType.ExteriorColour,
                            material.data
                        );
                        dispatchMaterial(
                            ActionType.ExteriorEdgeColour,
                            edge.data
                        );
                        setShow(false);
                        return true;
                    }
                } else {
                    if (materialDoorFilter == edgeDoorFilter) {
                        dispatchMaterial(
                            ActionType.CarcaseColour,
                            material.data
                        );
                        dispatchMaterial(
                            ActionType.CarcaseEdgeColour,
                            edge.data
                        );
                        setShow(false);
                        return true;
                    }
                }

                return false;
            },
            [materialType, index, hasDoors, selectedDoor]
        );

        const onCancel = useCallback(() => {
            setShow(false);
        }, []);

        const disableApplyButton = useMemo(() => {
            return loadingMatchingEdge || loadingNewColour;
        }, [loadingNewColour, loadingMatchingEdge]);

        useEffect(() => {
            dispatch(materialTypesSet(materialTypes));
        }, [materialTypes, show]);

        useEffect(() => {
            if (finishes) {
                dispatch(materialFinishesSet(finishes));
            }
        }, [finishes, show]);

        useEffect(() => {
            if (brands) {
                dispatch(materialBrandsSet(brands));
            }
        }, [brands, show]);

        useEffect(() => {
            if (edgeBrands) {
                dispatch(materialBrandsEdgeSet(edgeBrands));
            }
        }, [edgeBrands, show]);

        useEffect(() => {
            if (edgeFinishes) {
                dispatch(materialFinishesEdgeSet(edgeFinishes));
            }
        }, [edgeFinishes, show]);

        useEffect(() => {
            return () => {
                setShow(false);
                dispatch(propertiesSet({}));
            };
        }, []);

        useEffect(() => {
            dispatch(cabinetTypeSet(cabinetType));
        }, [cabinetType]);

        useEffect(() => {
            if (selectedDoor) {
                dispatch(doorSet(selectedDoor));
            }
        }, [selectedDoor]);

        useEffect(() => {
            if (selectedMaterial) {
                dispatch(materialTypeSet(mapMaterial(selectedMaterial).type));
            }
        }, [selectedMaterial]);

        useEffect(() => {
            if (page && page == Page.QFP) {
                dispatch(
                    propertiesSet({
                        browserType: BrowserType.QFP,
                    })
                );
            }
        }, [page]);

        return (
            <Component
                className={className}
                onShow={onShow}
                onApply={onApply}
                onCancel={onCancel}
                checkBeforeClose={true}
                disableApply={disableApplyButton}
                $width={width}
            />
        );
    };

    return advancedMaterialComponent;
};

const AdvancedMaterialWithButton = styled(AdvancedMaterials)<{$width: number}>`
    width: ${({$width = 75}) => $width}% !important;
    font-size: 0.8em !important;
    margin: 0 !important;
`;

export default withMaterials(AdvancedMaterialWithButton);
