import React from 'react';
import {useJobListData, JobTable} from 'hooks';
import {Row, Col, Dropdown, ButtonGroup, Button} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {Link} from 'react-router-dom';

export const Jobs = () => {
    const isSmallDevice = isDeviceSmall();
    const {
        allJobStatus,
        setOrderBy,
        setSortBy,
        sortBy,
        orderBy,
        selectedStatus,
        userProfile,
        isLoading,
    } = useJobListData();

    const sortByList = [
        {id: 7, name: 'Id', display: 1},
        {id: 1, name: 'Name', display: 1},
        {id: 3, name: 'Status', display: 1},
        {
            id: 8,
            name: 'Completion Date',
            display: userProfile['displayEstimatedCompletion'],
        },
        {id: 9, name: 'Last Updated', display: 1},
        {id: 2, name: 'Date Entered', display: 1},
    ];

    const orderByList = [
        {id: 'asc', name: 'Asc'},
        {id: 'desc', name: 'Desc'},
    ];

    if (isLoading) {
        return null;
    }

    return (
        <Row>
            <Col md={12} className="jobListPage">
                <Row
                    className="top-contents"
                    style={
                        isSmallDevice ? {margin: '15px 0 0', padding: '0'} : {}
                    }>
                    <Col xs={12} md={7} style={{display: 'flex'}}>
                        <Dropdown
                            as={ButtonGroup}
                            className="jobStatusDropdown">
                            <Button variant="primary" disabled>
                                Status
                            </Button>

                            <Dropdown.Toggle
                                variant="primary"
                                id="status-dropdown">
                                <label>{selectedStatus.text}</label>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {allJobStatus.map((status, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            as={Link}
                                            to={'/v2/jobs/' + status.url}>
                                            {status.text}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown
                            as={ButtonGroup}
                            className="jobStatusDropdown"
                            onSelect={setSortBy}>
                            <Button variant="primary" disabled>
                                Sort By
                            </Button>

                            <Dropdown.Toggle
                                variant="primary"
                                id="sort-by-dropdown">
                                <label>
                                    {
                                        sortByList.find((list) =>
                                            sortBy.includes(list.id)
                                        ).name
                                    }
                                </label>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {sortByList.map((value, index) => {

                                        if(!value.display) {
                                            return;
                                        }

                                        return (
                                            <Dropdown.Item
                                                key={value.id}
                                                eventKey={value.id}>
                                                {value.name}
                                            </Dropdown.Item>
                                        );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown
                            as={ButtonGroup}
                            className="jobStatusDropdown"
                            onSelect={setOrderBy}>
                            <Button variant="primary" disabled>
                                Order By
                            </Button>

                            <Dropdown.Toggle
                                variant="primary"
                                id="order-by-dropdown">
                                <label>
                                    {
                                        orderByList.find(
                                            (list) => list.id == orderBy
                                        ).name
                                    }
                                </label>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {orderByList.map((value, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={value.id}
                                            eventKey={value.id}>
                                            {value.name}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    <Col
                        style={
                            isSmallDevice
                                ? {textAlign: 'left', marginTop: '15px'}
                                : {textAlign: 'right'}
                        }>
                        <CBCButton
                            iconName="Button-Add.svg"
                            style={isSmallDevice ? {marginLeft: '0'} : {}}
                            className="button-blue btn-sm btn-job-list"
                            asLink={!userProfile.inActiveManufacturer}
                            disabled={userProfile.inActiveManufacturer}
                            to={'/v2/job'}>
                            Create a New Job
                        </CBCButton>
                    </Col>
                </Row>
                <Row>
                    <JobTable
                        title="Jobs"
                        jobData={[]}
                        className="display-jobs"
                        columns={[
                            'id',
                            'name',
                            'roomCount',
                            'productCount',
                            'dateEnteredDateTime',
                            'cost',
                            'status',
                            'dateDelivery',
                            'dateUpdatedDateTime',
                        ]}
                        link=""
                        mobileRender={true}
                        tableOrder={0}
                        tablesWithValue={1}
                        fromApi={true}
                        api={`jobs/${selectedStatus.id}`}
                    />
                </Row>
            </Col>
        </Row>
    );
};
