import {Bench} from 'components/customer/BTM/entity/Bench';
import {IBenchOption} from 'components/customer/BTM/entity/IBenchOption';
import {
    SaveDimension,
    SaveJoin,
    SavePath,
} from 'components/customer/BTM/helper/saveHelpers';
import {Join} from 'components/customer/BTM/entity/Join';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {Point} from 'components/customer/BTM/entity/Point';
import {Path} from 'components/customer/BTM/entity/Path';
import {store} from 'store/customer/storeSetup';
import {BTMApi} from 'components/customer/BTM/store/btmApi';

/**
 * This function can be used to sort benchtop options
 * or any other array of objects with order property
 * in ascending order
 *
 * @param {IBenchOption} a Benchtop option with order
 * @param {IBenchOption} b Benchtop option with order
 * @return {boolean}
 */
export const sortOption = (a: IBenchOption, b: IBenchOption) =>
    a.order - b.order;

interface OptionParserReturnInterface {
    dimension: number[];
    joins: Join[];
    corners: Corner[];
    coordinates: Point[];
    paths: Path[];
}
/**
 * This function simply parses the data retrieved from backend while
 * editing the benchtop
 *
 * @param {Bench} bench Benchtop options which is provided by items api
 * @return {OptionParserReturnInterface}
 *
 */
export const getOptions = (bench: Bench): OptionParserReturnInterface => {
    let dimension: number[] = [];
    let joins: Join[] = [];
    let corners: Corner[] = [];
    let coordinates: Point[] = [];
    let paths: Path[] = [];

    bench?.options.forEach((option) => {
        switch (option.name) {
            case 'dimensions':
                dimension = option.value
                    .sort(sortOption)
                    .map((option: SaveDimension) => option.length);
                break;
            case 'joins':
                joins = option.value
                    .sort(sortOption)
                    .map((option: SaveJoin) => {
                        const join = {
                            index: option.order,
                            joinType: option.joinType,
                            orientation: option.orientation,
                            side: option.side,
                            points: option.points,
                            selected: option.selected,
                            disabled: option.disabled,
                            touched: false,
                        } as Join;

                        if ('value' in option) {
                            join.value = option.value;
                        }

                        if ('benchSide' in option) {
                            join.benchSide = option.benchSide;
                        }

                        if ('index' in option) {
                            join.index = option.index;
                        }

                        return join;
                    });
                break;
            case 'corners':
                corners = option.value.sort(sortOption) as Corner[];
                break;
            case 'coordinates':
                coordinates = option.value.sort(sortOption) as Point[];
                break;
            case 'profiles':
                paths = option.value
                    .sort(sortOption)
                    .map((option: SavePath) => ({
                        edged: option.edged,
                        points: option.points,
                        side: option.side,
                        direction: option.direction,
                        squaredPoints: option.squaredPoints,
                        profile: option.profile_id,
                    }));
                break;
        }
    });

    return {
        dimension,
        joins,
        corners,
        coordinates,
        paths,
    };
};

/**
 * This function manually calls rtk query to fetch edge profiles based on
 * supplied material id
 *
 * @param {number} materialId id of the material to use to fetch edge profiles
 * @return {Promise<BenchtopEdgeProfile[]>}
 */
export const fetchEdgeProfilesManually = async (materialId: number) => {
    const response = await store.dispatch(
        BTMApi.endpoints.listEdgeProfiles.initiate({materialId})
    );

    if (response.isError) throw new Error();

    return response?.data ? response.data.data : [];
};
