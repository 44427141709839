import React, {useCallback, useMemo} from 'react';
import {Corner} from 'components/customer/BTM/entity/Corner';
import styled from 'styled-components';
import {getOrigin} from 'components/customer/BTM/helper/getOrigin';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {useCorner} from 'components/customer/BTM/helper/useCorner';
import {JoinOrientation} from 'components/customer/BTM/entity/Join';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {v4} from 'uuid';

interface CornerSizeLabelInterface {
    corner: Corner;
    scale: number;
    center: number[];
    dimensions: number[];
    shape: Shape;
    index: number;
}

interface LabelInterface {
    $position: {
        top: number;
        left: number;
    };
    $vertical: boolean;
    $length?: number;
    $direction: Direction;
}

export const CornerSizeLabel = ({
    corner,
    scale,
    center,
    dimensions,
    shape,
    index: cornerIndex,
}: CornerSizeLabelInterface) => {
    const [oX, oY] = useMemo(() => {
        return getOrigin(dimensions, center, scale);
    }, [dimensions, center, scale]);
    const {checkHasButtJoin} = useCorner();

    const hideArcCornerLabels = useCallback(
        (index: number) => {
            if (shape == Shape.SQR) {
                return index != 0;
            } else if (shape == Shape.ANG) {
                if (corner.name == 'AB' || corner.name == 'BC') {
                    return index != 0;
                } else if (corner.name == 'EF' || corner.name == 'DE') {
                    return index == 0;
                }
            } else {
                return index == 0;
            }
        },
        [shape]
    );
    // need to show only 1 corner label for arc corners
    const hideCornerIfPreviousCornerWasArc = useMemo(() => {
        if (shape == Shape.SQR) {
            // Label on AB, CD is already printed in preview. No need to show for BC, DA
            return ['BC', 'DA'].includes(corner.name);
        } else if (shape == Shape.ANG) {
            return ['AB', 'EF'].includes(corner.name);
        } else {
            return ['CD', 'GH'].includes(corner.name);
        }
    }, [shape]);

    if (!corner.cutoff || corner.isJoin) {
        return null;
    }

    return (
        <>
            {corner.depths.map((depth, index) => {
                if (
                    (corner?.isArc && hideArcCornerLabels(index)) ||
                    (corner?.isArc && hideCornerIfPreviousCornerWasArc)
                ) {
                    return null;
                }
                let left = Number(corner.point.x) * scale + oX;
                let top = Number(corner.point.y) * scale + oY + 25;
                let direction: Direction;
                let pullUp = false;

                if (corner.direction == Direction.RIGHT_DOWN) {
                    if (index == 0) {
                        if (
                            (shape == Shape.SQR || shape == Shape.ANG) &&
                            checkHasButtJoin(Side.A, [
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.E,
                                    joinSide: JoinSide.LEFT,
                                },
                            ])
                        ) {
                            top -= 25;
                        } else if (
                            shape == Shape.USHAPE &&
                            checkHasButtJoin(Side.A, [
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.G,
                                    joinSide: JoinSide.LEFT,
                                },
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.C,
                                    joinSide: JoinSide.RIGHT,
                                },
                            ])
                        ) {
                            top -= 25;
                        }

                        top -= 60;
                        direction = Direction.DOWN;
                    } else if (index == 1) {
                        if (shape == Shape.USHAPE) {
                            if (
                                checkHasButtJoin(Side.H, [
                                    {
                                        joinOrientation:
                                            JoinOrientation.HORIZONTAL,
                                        adjacentSide: Side.I,
                                        joinSide: JoinSide.LEFT,
                                    },
                                ])
                            ) {
                                left -= 158;
                            } else {
                                left -= 128;
                            }
                        } else if (shape == Shape.ANG) {
                            if (
                                checkHasButtJoin(Side.F, [
                                    {
                                        joinOrientation:
                                            JoinOrientation.HORIZONTAL,
                                        adjacentSide: Side.B,
                                        joinSide: JoinSide.LEFT,
                                    },
                                ])
                            ) {
                                left -= 128;
                            } else {
                                left -= 100;
                            }
                        } else left -= 70;

                        direction = Direction.RIGHT;
                    }
                } else if (corner.direction == Direction.LEFT_DOWN) {
                    if (index == 0) {
                        if (
                            (shape == Shape.SQR || shape == Shape.ANG) &&
                            checkHasButtJoin(Side.A, [
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.E,
                                    joinSide: JoinSide.LEFT,
                                },
                            ])
                        ) {
                            top -= 25;
                        } else if (
                            shape == Shape.USHAPE &&
                            checkHasButtJoin(Side.A, [
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.G,
                                    joinSide: JoinSide.LEFT,
                                },
                                {
                                    joinOrientation: JoinOrientation.VERTICAL,
                                    adjacentSide: Side.C,
                                    joinSide: JoinSide.RIGHT,
                                },
                            ])
                        ) {
                            top -= 25;
                        }

                        top -= 60;
                        left -= depth * scale;
                        direction = Direction.DOWN;
                    } else if (index == 1) {
                        if (
                            shape == Shape.USHAPE &&
                            checkHasButtJoin(Side.B, [
                                {
                                    joinOrientation: JoinOrientation.HORIZONTAL,
                                    adjacentSide: Side.I,
                                    joinSide: JoinSide.RIGHT,
                                },
                            ])
                        ) {
                            left += 75;
                        } else {
                            left += 45;
                        }
                        direction = Direction.LEFT;
                    }
                } else if (corner.direction == Direction.RIGHT_UP) {
                    if (index == 0) {
                        if (shape == Shape.SQR) {
                            top += 15;
                        } else {
                            top += 40;
                        }
                        direction = Direction.UP;
                    } else if (index == 1) {
                        if (shape == Shape.SQR) {
                            left -= 70;
                        } else if (shape == Shape.ANG) {
                            pullUp = true;
                            if (
                                checkHasButtJoin(Side.F, [
                                    {
                                        joinOrientation:
                                            JoinOrientation.HORIZONTAL,
                                        adjacentSide: Side.B,
                                        joinSide: JoinSide.LEFT,
                                    },
                                ])
                            ) {
                                left -= 128;
                            } else {
                                left -= 100;
                            }
                        } else {
                            pullUp = true;
                            if (
                                checkHasButtJoin(Side.H, [
                                    {
                                        joinOrientation:
                                            JoinOrientation.HORIZONTAL,
                                        adjacentSide: Side.I,
                                        joinSide: JoinSide.LEFT,
                                    },
                                ]) &&
                                cornerIndex !== 3
                            ) {
                                left -= 158;
                            } else {
                                left -= 100;
                            }
                        }

                        top -= depth * scale;
                        direction = Direction.RIGHT;
                    }
                } else if (corner.direction == Direction.LEFT_UP) {
                    if (index == 0) {
                        if (shape == Shape.SQR) {
                            top += 15;
                        } else {
                            top += 40;
                        }
                        left -= depth * scale;
                        direction = Direction.UP;
                    } else if (index == 1) {
                        if (shape == Shape.USHAPE) {
                            pullUp = true;
                            if (
                                checkHasButtJoin(Side.B, [
                                    {
                                        joinOrientation:
                                            JoinOrientation.HORIZONTAL,
                                        adjacentSide: Side.I,
                                        joinSide: JoinSide.RIGHT,
                                    },
                                ]) &&
                                cornerIndex == 2
                            ) {
                                left += 75;
                            } else {
                                left += 45;
                            }
                        } else {
                            if (shape == Shape.ANG && cornerIndex != 2) {
                                pullUp = true;
                            }
                            left += 45;
                        }
                        top -= depth * scale;
                        direction = Direction.LEFT;
                    }
                }

                return (
                    <Label
                        $direction={direction}
                        $length={depth * scale}
                        $position={{top, left}}
                        $vertical={index == 1}
                        key={v4()}>
                        <div>
                            <Text $direction={direction} $pullUp={pullUp}>
                                {depth}mm
                            </Text>
                        </div>
                    </Label>
                );
            })}
        </>
    );
};

const Text = styled.div<{$direction: Direction; $pullUp: boolean}>`
    ${({$direction, $pullUp}) => {
        if ($direction == Direction.LEFT) {
            return `
                transform: rotate(90deg) translate(${$pullUp ? -70 : 0}%, -75%);
                transform-origin: top left;
            `;
        } else if ($direction == Direction.RIGHT) {
            return `
                transform: rotate(-90deg) translate(${
                    $pullUp ? 70 : 0
                }%, -100%);
                transform-origin: top right;
            `;
        }
    }}
`;

const Label = styled.div<LabelInterface>`
    position: absolute;
    top: ${({$position}) => $position.top}px;
    left: ${({$position}) => $position.left}px;

    > div {
        position: relative;
        font-weight: 500;
        font-size: 0.8em;

        ${({$direction, $length}) => {
            if ($direction == Direction.DOWN || $direction == Direction.UP) {
                return `
                ${
                    $direction == Direction.DOWN
                        ? 'border-bottom: 1px solid rgb(var(--primary_colour));'
                        : 'border-top: 1px solid rgb(var(--primary_colour));'
                }
                width: ${$length}px;
                text-align: center;
        
                :before {
                    position: absolute;
                    content: '';
                    height: 8px;
                    width: 1px;
                    left: 0;
                    background: rgb(var(--primary_colour));
                    top: ${$direction == Direction.DOWN ? '17px' : '-6px'};
                }
        
                :after {
                    position: absolute;
                    content: '';
                    height: 8px;
                    width: 1px;
                    right: 0;
                    background: rgb(var(--primary_colour));
                    top: ${$direction == Direction.DOWN ? '17px' : '-6px'};
                }
                `;
            } else {
                return `
                ${
                    $direction == Direction.RIGHT
                        ? 'border-right: 1px solid rgb(var(--primary_colour));'
                        : 'border-left: 1px solid rgb(var(--primary_colour));'
                }
                height: ${$length}px;
                padding-left: 5px;
                padding-top: ${$length / 2 - 9}px;
        
                :before {
                    position: absolute;
                    content: '';
                    height: 1px;
                    width: 8px;
                    top: 0;
                    background: rgb(var(--primary_colour));
                    ${$direction == Direction.RIGHT ? 'right: -5px' : ''};
                    ${$direction == Direction.LEFT ? 'left: -5px' : ''};
                }
        
                :after {
                    position: absolute;
                    content: '';
                    height: 1px;
                    width: 8px;
                    bottom: 0;
                    background: rgb(var(--primary_colour));
                    ${$direction == Direction.RIGHT ? 'right: -5px' : ''};
                    ${$direction == Direction.LEFT ? 'left: -5px' : ''};
                }
                `;
            }
        }}
    }
`;
