export enum Side {
    A,
    B,
    C,
    D,
    E,
    F,
    G,
    H,
    I,
}

interface SideSpec {
    key: string;
    value: number;
}

export const getSideSpec = (side: Side): SideSpec => {
    switch (side) {
        case Side.A:
            return {key: 'A', value: 0};
        case Side.B:
            return {key: 'B', value: 1};
        case Side.C:
            return {key: 'C', value: 2};
        case Side.D:
            return {key: 'D', value: 3};
        case Side.E:
            return {key: 'E', value: 4};
        case Side.F:
            return {key: 'F', value: 5};
        case Side.G:
            return {key: 'G', value: 6};
        case Side.H:
            return {key: 'H', value: 7};
        case Side.I:
            return {key: 'I', value: 8};
    }
};
