import React, {useEffect, useMemo, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import styled from 'styled-components';
import {OverlayTrigger} from 'shared';
import {OverlayType} from 'shared/components/OverlayTrigger';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import Excel from 'shared/Excel';
import {mapErrors} from 'shared/helpers/mapErrors';

interface FieldInterface {
    name: string;
    readOnly?: boolean;
    type?: string;
    autoFocus?: boolean;
    index: number;
    formField?: string;
    tabIndex?: string;
}

interface CustomFieldInterface {
    isInvalid?: boolean;
}

const InputField = styled.input<CustomFieldInterface>`
    width: 100%;
    height: 100%;
    border: 0;
    font-size: 1.5em;
    color: black;
    padding: 5px;
    background: ${({isInvalid}) => (isInvalid ? '#f7d0c2 !important' : 'none')};

    &:disabled {
        color: gray !important;
    }
`;

export const Field = ({
    name,
    readOnly = false,
    type = 'number',
    autoFocus = false,
    formField,
    index,
}: FieldInterface) => {
    const {values, setFieldValue} = useFormikContext<{
        cabinet_type: number;
        cabinet_width_door_1: number;
    }>();
    const [field, {error}, {setValue: setFormikValue, setTouched}] = useField<
        string | number
    >({name, type});
    const [disabled, setDisabled] = useState(false);
    const [value, setValue] = useState<string | number>(field.value);
    const {data: structure} = useGetQFPProductStructureQuery({
        cabinetType: values.cabinet_type,
    });

    const dispatchValue = () => {
        if (!readOnly && value != field.value) {
            if (formField) {
                void setFieldValue(formField, value);
            }
            void setFormikValue(value, true);
            void setTouched(true);
        }
    };

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!readOnly) {
            const value = e.target.value;

            setValue(value);
        }
    };

    useEffect(() => {
        if (field.value) {
            setValue(field.value);
        }
    }, [field.value]);

    const fieldData = useMemo(() => {
        if (name == 'width' && structure) {
            return structure.widthFieldData;
        }

        if (name == 'height' && structure) {
            return structure.heightFieldData;
        }
    }, [structure]);

    useEffect(() => {
        if (name == 'width' || name == 'height') {
            let enabled = true;

            try {
                enabled = Excel.calculate(fieldData.options.enabled, values);

                if (typeof enabled === 'undefined') {
                    enabled = true;
                }
            } catch (e) {
                //
            }

            setDisabled(!enabled);

            if (!enabled && Number(fieldData.value) >= 0) {
                void setFormikValue(fieldData.value);
                if (values[String(formField)] != fieldData.value) {
                    void setFieldValue(formField, fieldData.value);
                    void setTouched(true);
                }
            }
        }
    }, [fieldData, values]);

    useEffect(() => {
        if (name == 'width') {
            let enabled = true;
            try {
                enabled = Excel.calculate(fieldData.options.enabled, values);

                if (typeof enabled !== 'undefined' && !enabled) {
                    void setFormikValue(values.cabinet_width_door_1);
                    if (values[String(formField)] != fieldData.value) {
                        void setFieldValue(formField, fieldData.value);
                    }
                }
            } catch (e) {
                //
            }
        }
    }, [values.cabinet_width_door_1]);

    return (
        <td>
            <OverlayTrigger
                className="error-popover"
                as={OverlayType.Popover}
                trigger={['focus', 'hover']}
                overlay={mapErrors(error)}
                placement={'bottom'}>
                <InputField
                    id={`${index}-${name}`}
                    data-cy={`${name}_${index}`}
                    disabled={disabled}
                    value={typeof value === 'undefined' ? '' : value}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    onChange={changeHandler}
                    onBlur={dispatchValue}
                    isInvalid={typeof error != 'undefined'}
                />
            </OverlayTrigger>
        </td>
    );
};
