import {User} from 'components/customer/Auth/store/userSlice';
import {dialogHandler} from 'hooks';
import {PartialJob} from 'shared/types/PartialJob';
import {DateTime, DateType} from '@cabinetsbycomputer/datetime';

export const copyJobMessage = (job: PartialJob, userProfile: User) => {
    // Add reset price text only if dateupdated is less than maxAge set by manufacturer
    const updatedDate = DateTime.parse(job.dateUpdated, DateType.ISO);
    const {days} = updatedDate.diff(new Date(), 'days');

    const priceResetText =
        days >= userProfile.maxAgeJobPrice
            ? `Current job prices are not valid. Copying this job will calculate new prices for the new job.`
            : '';

    return dialogHandler(
        `This process can take up to a minute for larger jobs.`,
        false,
        [
            priceResetText,
            `This will create a copy of the selected job with a 'Processing' status.`,
            `Are you sure you want to copy this job?`,
        ]
    );
};
