import React, {useRef} from 'react';
import {useAppSelector} from 'store/customer';
import {
    selectMenu,
    selectHasFavourites,
    selectHasDoors,
    selectHasEdges,
    selectHasMaterials,
    selectHasThicknesses,
    thicknessSelect,
    selectThicknesses,
    selectMaterialFinishes,
    materialFinishesSelect,
    selectMaterialTypes,
    materialTypeSelect,
    materialFinishesEdgeSelect,
    selectMaterialFinishesEdge,
    selectBrowserType,
    BrowserType,
    materialBrandSelect,
    selectMaterialBrands,
    selectMaterialBrandsEdges,
    materialBrandsEdgeSelect,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {MainMenu} from 'components/customer/AdvancedMaterials/Menu/MainMenu';
import {shallowEqual} from 'react-redux';
import {Menu as MenuEnum} from 'components/customer/AdvancedMaterials/entity/Menu';
import styled from 'styled-components';

export const Menu = () => {
    const container = useRef<HTMLDivElement>(null);

    const browserType = useAppSelector(selectBrowserType);
    const materialFinishes = useAppSelector(
        selectMaterialFinishes,
        shallowEqual
    );
    const materialBrands = useAppSelector(selectMaterialBrands, shallowEqual);
    const edgeBrands = useAppSelector(selectMaterialBrandsEdges, shallowEqual);
    const edgeFinishes = useAppSelector(
        selectMaterialFinishesEdge,
        shallowEqual
    );

    const materialTypes = useAppSelector(selectMaterialTypes, shallowEqual);
    const thicknesses = useAppSelector(selectThicknesses, shallowEqual);
    const selectedMenu = useAppSelector(selectMenu, shallowEqual);
    const hasFavourites = useAppSelector(selectHasFavourites, shallowEqual);
    const hasDoors = useAppSelector(selectHasDoors, shallowEqual);
    const hasMaterials = useAppSelector(selectHasMaterials, shallowEqual);
    const hasEdges = useAppSelector(selectHasEdges, shallowEqual);
    const hasThicknesses = useAppSelector(selectHasThicknesses, shallowEqual);

    return (
        <Container ref={container}>
            {hasFavourites ? (
                <MainMenu
                    ref={container}
                    key={MenuEnum.FAVOURUTES}
                    text="Favourites"
                    id={MenuEnum.FAVOURUTES}
                    selected={selectedMenu}
                />
            ) : null}
            {browserType != BrowserType.BENCHTOP ? (
                <MainMenu
                    ref={container}
                    key={MenuEnum.DOOR_TYPE}
                    text={hasDoors ? 'Door Type' : 'Material Type'}
                    id={MenuEnum.DOOR_TYPE}
                    selected={selectedMenu}
                    submenu={materialTypes}
                    action={materialTypeSelect}
                />
            ) : null}
            {hasThicknesses ? (
                <MainMenu
                    ref={container}
                    key={MenuEnum.THICKNESSES}
                    text="Thickness"
                    id={MenuEnum.THICKNESSES}
                    selected={selectedMenu}
                    submenu={thicknesses}
                    action={thicknessSelect}
                />
            ) : null}
            {hasMaterials ? (
                <MainMenu
                    ref={container}
                    key={MenuEnum.MATERIAL}
                    text={
                        browserType == BrowserType.BENCHTOP
                            ? 'Colours & Finishes'
                            : 'Colours & Brands'
                    }
                    id={MenuEnum.MATERIAL}
                    selected={selectedMenu}
                    submenu={
                        browserType == BrowserType.BENCHTOP
                            ? materialFinishes
                            : materialBrands
                    }
                    action={
                        browserType == BrowserType.BENCHTOP
                            ? materialFinishesSelect
                            : materialBrandSelect
                    }
                />
            ) : null}
            {hasEdges ? (
                <MainMenu
                    ref={container}
                    key={MenuEnum.EDGE_MATERIAL}
                    text="Edge Materials"
                    id={MenuEnum.EDGE_MATERIAL}
                    selected={selectedMenu}
                    submenu={
                        browserType == BrowserType.BENCHTOP
                            ? edgeFinishes
                            : edgeBrands
                    }
                    action={
                        browserType == BrowserType.BENCHTOP
                            ? materialFinishesEdgeSelect
                            : materialBrandsEdgeSelect
                    }
                />
            ) : null}
        </Container>
    );
};

const Container = styled.div`
    padding: 15px 0 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
`;
