import React, {useMemo} from 'react';
import {useAppSelector} from 'store/customer';
import {
    BrowserType,
    selectBrowserType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import styled from 'styled-components';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';

export const Title = () => {
    const {selectedMenu} = useSelectedMenu();
    const colorBrowserType = useAppSelector(selectBrowserType);

    const title = useMemo(() => {
        if (colorBrowserType !== BrowserType.BENCHTOP) {
            if (selectedMenu == Menu.MATERIAL) {
                return 'Colours & Finishes';
            } else if (selectedMenu == Menu.EDGE_MATERIAL) {
                return 'Edge Materials';
            }
        }

        return '';
    }, [selectedMenu, colorBrowserType]);

    if (title != '') {
        return <StyledTitle>{title}</StyledTitle>;
    }
};

const StyledTitle = styled.h1`
    padding: 5px 15px !important;
    text-align: center !important;
`;
