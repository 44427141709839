import React from 'react';
import {Col} from 'react-bootstrap';
import {MobileRender} from 'components';
import {CBCTable} from 'shared';
import {Link} from 'react-router-dom';

type JobRender = {
    jobTableData: object,
    isSearch: boolean,
    mdValue: number,
}

const JobTableRender = ({jobTableData, isSearch = false, mdValue = 12}: JobRender) => {
    /**
     * Render Table
     */
    return (
        <>
            {jobTableData
                .filter((option) => option.length > 0 || option.tableOption.fromApi)
                .map((option, index) => {
                    return (
                        <Col
                            key={index}
                            className={`jobs ${option.className}`}
                            md={mdValue}>
                            <MobileRender
                                isVisible={option.mobileRender}>
                                {(!isSearch) &&
                                    <h2>{option.title}</h2>
                                }
                                <CBCTable
                                    options={option.tableOption}
                                />
                                {(option.link != '') &&
                                    <Link to={option.link}>See more</Link>
                                }
                            </MobileRender>
                        </Col>
                    );
            })}
        </>
    )   

}

export {JobTableRender}