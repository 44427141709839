import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Button, Col, ListGroup, Row} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {Icon} from 'shared/helpers';
import {deleteFile} from 'service';
import {useJobContext} from 'contexts';
import {MIMETypes} from 'shared';
import {extname, basename} from 'path';
import {ErrorHandler} from 'shared/components/ErrorHandler';
import FileSelector from './components/FileSelector';

export const FileUpload = ({fileUploadErrors, updateAttachmentsCount}) => {
    const {values, touched, setFieldValue} = useFormikContext();
    const [files, setFiles] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const {job} = useJobContext();
    const fileFormats = useRef(Object.keys(MIMETypes)).current;

    useEffect(() => {
        if (values.files && values.files.length) {
            setFiles(values.files);
        }

        setAttachments(job?.attachments ?? []);
    }, [values.files, job?.attachments]);

    const fileHandler = useCallback(
        (event) => {
            const selectedFiles = Array.from(event.target.files).filter(
                (file) => {
                    const selectedPrevious = files.map((file) => file.name);

                    return !selectedPrevious.includes(file.name);
                }
            );

            const allFiles = [...files, ...selectedFiles];

            setFieldValue('files', allFiles);
        },
        [files]
    );

    const fileSizeFormatter = useCallback((size) => {
        return size / 1024 > 1024
            ? `${(size / (1024 * 1024)).toFixed(2)} MB`
            : `${(size / 1024).toFixed(2)} KB`;
    }, []);

    const removeFileFromList = useCallback(
        (index) => {
            files.splice(index, 1);
            setFieldValue('files', files);
        },
        [files]
    );

    const getExtensionFromPath = useCallback((file) => {
        return extname(file).split('.').pop();
    }, []);

    const deleteUploadedFile = useCallback(
        async (index) => {
            const file = job?.attachments[index];
            const filename = basename(file);
            const response = await deleteFile(filename, job.id);
            if (response.success) {
                attachments.splice(index, 1);
                updateAttachmentsCount(attachments.length);
                setFieldValue('attachments', attachments);
            }
        },
        [job, attachments]
    );

    return (
        <div className="fileContainer">
            <FileSelector fileHandler={fileHandler} fileFormats={fileFormats} />
            <Row className="jobFormErrors">
                <Col>
                    <ErrorHandler
                        errors={fileUploadErrors}
                        touched={touched}
                        style={{marginTop: '15px'}}
                    />
                </Col>
            </Row>
            {attachments.length ? (
                <ListGroup>
                    {attachments.map((file, index) => {
                        return (
                            <ListGroup.Item
                                key={index}
                                className="fileListItem">
                                <span className="type">
                                    {getExtensionFromPath(file)}
                                </span>
                                <div className="nameSize">
                                    <div>{basename(file)}</div>
                                </div>
                                <div>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            deleteUploadedFile(index)
                                        }>
                                        <Icon iconName="Button-Cancel.svg" />
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            ) : (
                <></>
            )}
            {files.length ? (
                <ListGroup>
                    {files.map((file, index) => {
                        return (
                            <ListGroup.Item
                                key={index}
                                className="fileListItem">
                                <span className="type">
                                    {getExtensionFromPath(file.name)}
                                </span>
                                <div className="nameSize">
                                    <div>{file.name}</div>
                                    <div>{fileSizeFormatter(file.size)}</div>
                                </div>
                                <div>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            removeFileFromList(index)
                                        }>
                                        <Icon iconName="Button-Cancel.svg" />
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            ) : (
                <></>
            )}
        </div>
    );
};
