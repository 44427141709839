/**
 * Bootstrap file for the application. Everyting starts with this file.
 *
 * Loads scss and environment specific config from this file.
 */
import React from 'react';
import {createRoot} from 'react-dom/client';

import {store} from 'store/customer/storeSetup';
import {Provider} from 'react-redux';
import ThemeProvider from 'theme/CustomerTheme';
import {RouterProvider} from 'react-router-dom';
import {router} from 'routes/RouterConfig';
import {useWebVitals} from 'hooks/WebVitals';

import './assets/scss/app.scss';
import {CheckScriptVersion} from 'shared/reload/CheckScriptVersion';
import {Analytics} from 'shared';

useWebVitals();

const documentRoot: HTMLElement = document.getElementById('root');

if (documentRoot !== null) {
    const root = createRoot(documentRoot);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider>
                    <CheckScriptVersion />
                    <Analytics.Configuration />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    );
}
