/**
 * This function basically returns next available
 * roomCabinetNumber based on previous roomCabinet
 * number.
 *
 * @param {number} previousRoomCabinetNumber
 * @param {number[]} forbiddenNumbers
 * @return {number}
 */
export const getNextRoomCabinetNumber = (
    previousRoomCabinetNumber: number,
    forbiddenNumbers: number[]
): number => {
    let nextNumber = previousRoomCabinetNumber + 1;

    if (forbiddenNumbers.length == 0) {
        return nextNumber;
    }

    while (forbiddenNumbers.includes(nextNumber)) {
        nextNumber++;
    }

    return nextNumber;
};
