import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLayoutDesigner} from 'hooks';
import {Loader} from 'shared/helpers';

import '@cabinetsbycomputer/top-down-layout-designer/dev/css/tdld.css';
import './TDLD.scss';

/**
 * Functional component for FullscreenTDLDPreview including the extracted class-based component.
 * @return {JSX.Element}
 */
export const FullscreenTDLDPreview = () => {
    const [query] = useSearchParams();

    const jobId = query.get('jobId') ? Number(query.get('jobId')) : null;
    const roomId = query.get('roomId') ? Number(query.get('roomId')) : null;
    const {loader, dialog} = useLayoutDesigner(jobId, roomId);

    return (
        <div className="layout-designer">
            <Loader loader={loader} />
            <div
                className="designer"
                id="designer"
                style={{
                    marginBottom: 0,
                }}>
                <div className="designer-content">
                    <div
                        className="designer-main"
                        style={{
                            borderRadius: 0,
                            border: 'none',
                        }}>
                        <div className="tdld" id="tdld-container">
                            <input type="hidden" id="selected_jobCabinetId" />
                            <input type="hidden" id="selected_index" />
                        </div>
                    </div>
                </div>
            </div>
            {dialog}
        </div>
    );
};
