import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectMaterial,
    selectEdgeProfile,
    edgeProfileSet,
} from 'components/customer/BTM/store/btmSlice';
import {useLazyListEdgeProfilesQuery} from 'components/customer/BTM/store/btmApi';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {useEffect} from 'react';
import {genericMessageHandler} from 'shared/helpers';
import {useNotificationContext} from 'contexts';
import {FormFactor} from 'components/customer/BTM/entity/BenchtopMaterial';

export const useFetchEdgeProfile = () => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const material = useAppSelector(selectMaterial);
    const edgeProfile = useAppSelector(selectEdgeProfile);

    const [
        getEdgeProfiles,
        {
            data: {data: edgeProfiles} = {
                data: [] as BenchtopEdgeProfile[],
            },
            isLoading,
            isFetching,
            isUninitialized,
        },
    ] = useLazyListEdgeProfilesQuery();

    const fetchEdgeProfiles = async (materialId: number) => {
        try {
            const {
                data: {data: edgeProfiles},
            } = await getEdgeProfiles({materialId}, true);

            if (edgeProfiles) {
                let currentProfileIsAvailable = false;

                if (edgeProfile) {
                    currentProfileIsAvailable = edgeProfiles
                        .map((profile) => profile.id)
                        .includes(edgeProfile.id);

                    if (
                        edgeProfile.is_postformed_profile &&
                        material.formFactor == FormFactor.NONE
                    ) {
                        currentProfileIsAvailable = false;
                    }
                }

                if (!currentProfileIsAvailable) {
                    let defaultProfile = edgeProfiles[0];

                    if (material.formFactor == FormFactor.NONE) {
                        defaultProfile = edgeProfiles.find(
                            (edgeProfile) => !edgeProfile.is_postformed_profile
                        );
                    }

                    dispatch(edgeProfileSet(defaultProfile));
                }
            }
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch Edge profiles',
            });
        }
    };

    useEffect(() => {
        if (material) {
            void fetchEdgeProfiles(material.id);
        }
    }, [material]);

    return {edgeProfiles, isLoading, isFetching, isUninitialized};
};
