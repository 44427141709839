/**
 * @flow
 * TODO: DELETE THIS FILE
 * @export
 * @class GapSize
 */
export default class GapSize {
    baseBottomGap: number;
    baseTopGap: number;
    customerId: number;
    doorGap: number;
    drawerGap: number;
    id: number;
    left: number;
    name: string;
    right: number;
    upperBottomGap: number;
    upperTopGap: number;
    userLevel: number;
}
