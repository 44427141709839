import React, {useCallback, useEffect, useState} from 'react';
import {
    SearchLayout,
    MaterialListItem,
} from 'components/customer/Materials/SearchLayout';
import {ColorSwatch} from 'components/customer/Materials/ColorSwatch';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectMaterial,
    selectMaterialType,
    selectThickness,
    selectMaterialIsLoading,
    materialSet,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {useSearchMaterials} from 'components/customer/BTM/helper/useSearchMaterials';

export const Search = () => {
    const dispatch = useAppDispatch();
    const materialIsLoading = useAppSelector(selectMaterialIsLoading);
    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);
    const type = useAppSelector(selectMaterialType, shallowEqual);
    const thickness = useAppSelector(selectThickness);
    const [showPagination, setShowPagination] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [page, setPage] = useState(1);
    const [materials, setMaterials] = useState<BenchtopMaterial[]>([]);

    const {searchMaterials, isLoading, isFetching, isUninitialized} =
        useSearchMaterials();

    const selectHandler = useCallback(
        (material: BenchtopMaterial) => {
            dispatch(materialSet(material));
        },
        [materialSet, dispatch]
    );

    const searchMaterial = useCallback(
        async (keywords: string, currentPage = 1) => {
            const {materials, pagination} = await searchMaterials(
                type.id,
                thickness,
                currentPage,
                keywords
            );

            if (materials && materials.length > 0) {
                if (currentPage == 1) {
                    setMaterials(materials);
                } else {
                    setMaterials((currentMaterials) => [
                        ...currentMaterials,
                        ...materials,
                    ]);
                }

                setShowPagination(
                    pagination.current_page < pagination.page_count
                );
            } else {
                setMaterials([]);
                setShowPagination(false);
            }
        },
        [type, thickness, setMaterials, setShowPagination, searchMaterials]
    );

    const onFocusHandler = useCallback(() => {
        const hasMaterial = materials.find(
            (material) => selectedMaterial.id == material.id
        );

        if (selectedMaterial && !hasMaterial && keywords == '') {
            void searchMaterial(selectedMaterial.searchName);
        }
    }, [selectedMaterial, materials, keywords, searchMaterial]);

    const onSearchTextChange = useCallback((keywords: string) => {
        setPage(1);
        setKeywords(keywords);
    }, []);

    const moreButtonHandler = useCallback(() => {
        setPage((page) => page + 1);
        void searchMaterial(keywords, page + 1);
    }, [searchMaterial, keywords, page]);

    const onClickMaterialHandler = useCallback(
        (setShow: (show: boolean) => void, material: BenchtopMaterial) => {
            return () => {
                setShow(false);
                selectHandler(material);
            };
        },
        [selectHandler]
    );

    useEffect(() => {
        if (type && thickness && keywords.length > 0) {
            void searchMaterial(keywords);
        }
    }, [keywords]);

    useEffect(() => {
        if (typeof selectedMaterial === 'undefined') {
            setMaterials([]);
        }
    }, [selectedMaterial, type, thickness]);

    useEffect(() => {
        setKeywords('');
        setMaterials([]);
    }, [thickness]);

    return (
        <SearchLayout
            data={selectedMaterial}
            onFocus={onFocusHandler}
            className="material_search"
            keywordsDefault={keywords}
            placeholder="Search colours"
            showPagination={showPagination}
            onClickPagination={moreButtonHandler}
            isLoading={isLoading || isFetching || materialIsLoading}
            onSearchTextChange={onSearchTextChange}
            column="col-md-6"
            hasData={materials.length > 0 || !isUninitialized}>
            {({setShow}) => {
                if (
                    materials.length ||
                    isUninitialized ||
                    isLoading ||
                    isFetching
                ) {
                    return (
                        <>
                            {materials.map((material) => (
                                <MaterialListItem
                                    key={material.id}
                                    $active={
                                        material &&
                                        selectedMaterial &&
                                        material.id == selectedMaterial.id
                                            ? true
                                            : false
                                    }
                                    onClick={onClickMaterialHandler(
                                        setShow,
                                        material
                                    )}>
                                    <div>
                                        <ColorSwatch
                                            src={material?.image?.name}
                                        />
                                    </div>
                                    <div>
                                        {parseHtmlString(material.displayName)}
                                    </div>
                                </MaterialListItem>
                            ))}
                        </>
                    );
                } else {
                    return (
                        <MaterialListItem>
                            <strong>Type to search for materials</strong>
                        </MaterialListItem>
                    );
                }
            }}
        </SearchLayout>
    );
};
