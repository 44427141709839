import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {Icon, Loader} from 'shared/helpers';
import {useAppDispatch} from 'store/customer';
import {
    selectUserAccountAction,
    UserAccount,
} from 'components/customer/Auth/store/authSlice';
import {redirectUser} from 'shared/helpers/AuthHelpers';
import {Link, useSearchParams} from 'react-router-dom';
import {CBCButton} from 'components/customer/Auth/StyledElements';
import {Col, Row} from 'react-bootstrap';
import {useGetAccountsQuery} from 'components/customer/Auth/store/accountSlice';

export const Accounts = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>(null);
    const {data: accounts, isLoading, isError} = useGetAccountsQuery();
    const [searchParams] = useSearchParams({
        keepLoggedIn: '0',
    });

    const userAccountSelection = useCallback(
        (account: UserAccount, rememberMe: boolean) => async () => {
            try {
                await dispatch(
                    selectUserAccountAction({
                        id: account.id,
                        type: account.user_type,
                        rememberMe,
                    })
                ).unwrap();

                redirectUser(account);
            } catch (rejectValue) {
                setError(String(rejectValue));
            }
        },
        []
    );

    const keepLoggedIn = searchParams.get('keepLoggedIn') == '1';

    if (isLoading) {
        return <Loader loader={true} />;
    }

    return (
        <div style={{paddingTop: '120px'}}>
            <AccountsSelection>
                {isError || error != null ? (
                    <>
                        <Error>
                            Your session has timed out.
                            <br />
                            {error != null ? error : ''}
                        </Error>
                        <Row>
                            <Col xs={{span: 6, offset: 3}}>
                                <CBCButton as={Link} to="/v2/login">
                                    Back to Login Page
                                </CBCButton>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Header>Choose an account</Header>
                        {accounts.map((account, key) => {
                            return (
                                <List
                                    index={key}
                                    key={account.id}
                                    onClick={userAccountSelection(
                                        account,
                                        keepLoggedIn
                                    )}>
                                    <div
                                        style={{
                                            width: '20%',
                                            textAlign: 'center',
                                            marginRight: '15px',
                                        }}>
                                        <img
                                            style={{
                                                width: '80%',
                                                imageRendering:
                                                    '-webkit-optimize-contrast',
                                            }}
                                            alt={`${account.name} Logo`}
                                            src={account.logo}
                                        />
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                fontSize: '.75em',
                                            }}>
                                            {account.name}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '0.64em',
                                                color: '#858585',
                                            }}>
                                            {account.email}
                                        </div>
                                    </div>
                                </List>
                            );
                        })}
                    </>
                )}
                <List>
                    <Icon
                        style={{width: '50%', margin: 'auto'}}
                        iconName="powered-by-logo.svg"
                        noLogin={true}
                    />
                </List>
            </AccountsSelection>
        </div>
    );
};

const AccountsSelection = styled.div`
    width: 500px;
    border-radius: 8px;
    margin: auto;
    border: 1px solid #d9d9d9;
    padding: 80px 50px;
    font-size: 1.4em;
    max-height: 700px;
    overflow: auto;
`;

const Header = styled.div`
    background: white;
    text-align: center;
    font-weight: 400;
    color: black;
`;

const List = styled.div<{index?: number; onClick?: () => Promise<void>}>`
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #d9d9d9;
    padding: 15px 0;
`;

const Error = styled.div`
    background: #c92434;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 1rem;
`;
