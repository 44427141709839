import React, {useState, useEffect, useCallback} from 'react';
import {Alert, Col, Row} from 'react-bootstrap';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ManuSelector} from 'components';
import {useAppDispatch} from 'store/customer';
import {
    passwordResetAction,
    UserAccount,
} from 'components/customer/Auth/store/authSlice';
import {useVerifyTokenMutation} from 'components/customer/Auth/store/accountSlice';
import SetPasswordForm, {
    SetPasswordInterface,
} from 'components/customer/Auth/SetPasswordForm';
import {useTheme} from 'styled-components';

export const PasswordResetForm = (): JSX.Element => {
    const isSmallDevice = isDeviceSmall();
    const [queryParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        password: '',
        confirmPassword: '',
        id: '',
    });
    const [error, setError] = useState<string>(null);
    const [message, setMessage] = useState<string>(null);
    const [userAccount, setUserAccount] = useState<UserAccount>(null);
    const theme = useTheme();
    const [verifyToken, {data: accounts, isLoading, isSuccess, isError}] =
        useVerifyTokenMutation();

    const passwordResetHandler = useCallback(
        ({password, confirmPassword, id}: SetPasswordInterface) => {
            void (async () => {
                const token = queryParams.get('token');

                try {
                    await dispatch(
                        passwordResetAction({
                            id: parseInt(id),
                            token,
                            password,
                            confirmPassword,
                        })
                    ).unwrap();

                    navigate('/v2/login');

                    setMessage(message);
                } catch (e) {
                    setError(String(e));
                }
            })();
        },
        []
    );

    useEffect(() => {
        const token = queryParams.get('token');

        if (token) {
            verifyToken(token).catch(() => {
                //
            });
        }
    }, []);

    useEffect(() => {
        if (isError) {
            setError('Could not verify the token');
            return;
        }

        if (isSuccess && accounts && accounts.length) {
            setError(null);
            setUserAccount(accounts[0]);
            setInitialValues((initialValues) => {
                const newInitialValues = {...initialValues};
                newInitialValues.id = `${accounts[0].id}`;

                return newInitialValues;
            });
        }
    }, [accounts, isError, isSuccess]);

    if (isLoading) {
        return (
            <Row
                style={{
                    textAlign: 'center',
                    padding: isSmallDevice ? '0 15px' : 0,
                }}>
                <Col md={{offset: 3, span: 6}}>
                    <Alert variant="info">
                        Validating your password reset token
                    </Alert>
                </Col>
            </Row>
        );
    }

    return (
        <SetPasswordForm
            initialValues={initialValues}
            error={error}
            message={message}
            isLoading={isLoading}
            isSuccess={false}
            onSubmit={passwordResetHandler}
            userAccountSlot={
                accounts && accounts.length > 1 && userAccount != null ? (
                    <Row>
                        <Col md={6}>
                            <ManuSelector
                                id="account-dropdown"
                                primaryColour={theme.colors.primary.main}
                                userAccount={userAccount}
                                setAccount={setUserAccount}
                                userAccounts={accounts}
                            />
                        </Col>
                    </Row>
                ) : null
            }
        />
    );
};
