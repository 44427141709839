import React, {useCallback} from 'react';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {
    DropdownItem,
    IconStyle,
    Label,
    Laminated,
    Profiled,
    SquaredProfiles,
} from 'components/customer/BTM/Preview/EdgeToggle';
import {EdgeTogglePosition} from 'components/customer/BTM/helper/useEdgeTogglePosition';
import {Edge} from 'components/customer/BTM/entity/Edge';

export const EdgeToggleProfile = ({
    squaredProfile,
    position,
    handleProfile,
}: {
    squaredProfile: SquaredProfiles;
    position: EdgeTogglePosition;
    handleProfile: (edge: Edge, profile: BenchtopEdgeProfile) => void;
}) => {
    const clickHandler = useCallback(() => {
        handleProfile(Edge.EDGED, squaredProfile.profile);
    }, [squaredProfile]);

    return (
        <DropdownItem
            disabled={squaredProfile.disabled}
            key={squaredProfile.profile.id}
            onClick={clickHandler}>
            <Label $bold={squaredProfile.profile.id == position.profile}>
                <div>
                    <div>{squaredProfile.profile.name}</div>
                    {squaredProfile.disabled ? <em>(Not available)</em> : null}
                </div>
                {squaredProfile.profile.symbol ? (
                    <IconStyle
                        $disabled={squaredProfile.disabled}
                        iconName={`benchtop_profile_icons/${squaredProfile.profile.symbol}.svg`}
                    />
                ) : (
                    <Profiled
                        $disabled={squaredProfile.disabled}
                        $thickness={
                            squaredProfile.profile.edge_highlight ? true : false
                        }
                    />
                )}
            </Label>
        </DropdownItem>
    );
};
