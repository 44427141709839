import React, {useCallback, useMemo, useState} from 'react';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    cornerSet,
    cornerErrorSet,
    selectCorners,
    selectDimension,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';

import {Corner} from 'components/customer/BTM/entity/Corner';
import {BTMInputGroup} from 'components/customer/BTM/Preview/DimensionView';
import {FormControl} from 'react-bootstrap';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {isNumber} from 'mathjs';
import styled from 'styled-components';

export const EndProfileArcEnd = ({
    endOnlyProfile,
    corner,
}: {
    endOnlyProfile: BenchtopEdgeProfile;
    corner: {
        corner: Corner;
        index: number;
    };
}) => {
    const dispatch = useAppDispatch();
    const corners = useAppSelector(selectCorners, shallowEqual);
    const dimension = useAppSelector(selectDimension);
    const shape = useAppSelector(selectType);

    const [error, setError] = useState('');

    const [xValue, setXValue] = useState<number>(
        corner.corner.depths[0] ??
            endOnlyProfile.end_option.default_radius_start
    );
    const [yValue, setYValue] = useState<number>(
        corner.corner.depths[1] ?? endOnlyProfile.end_option.default_radius_end
    );

    const inputValueUsed = useMemo(() => {
        switch (shape.type) {
            case Shape.SQR:
                return xValue;
            case Shape.USHAPE:
                return yValue;
            case Shape.ANG:
                return corner.corner.name == 'BC' ? xValue : yValue;
        }
    }, [shape, xValue, yValue]);

    const checkCornerDepthValidation = (cornerDepth: number) => {
        setError('');
        const cornerError = {
            index: corner.index,
            name: corner.corner.name,
            isArc: true,
            x: '',
            y: '',
        };
        dispatch(cornerErrorSet({...cornerError}));

        if (shape.type == Shape.SQR) {
            if (cornerDepth > dimension[1] / 2) {
                setError(`Depth cannot be greater than ${dimension[1] / 2}`);
                cornerError.x = 'Not valid depth';
                dispatch(cornerErrorSet(cornerError));
                return false;
            }
        }

        if (shape.type == Shape.ANG) {
            if (corner.corner.name == 'BC' && cornerDepth > dimension[1] / 2) {
                setError(`Depth cannot be greater than ${dimension[1] / 2}`);
                cornerError.x = 'Not valid depth';
                dispatch(cornerErrorSet(cornerError));
                return false;
            }
            if (corner.corner.name == 'DE' && cornerDepth > dimension[4] / 2) {
                setError(`Depth cannot be greater than ${dimension[4] / 2}`);
                cornerError.x = 'Not valid depth';
                dispatch(cornerErrorSet(cornerError));
                return false;
            }
        }

        if (shape.type == Shape.USHAPE) {
            if (corner.corner.name == 'BC' && cornerDepth > dimension[2] / 2) {
                setError(`Depth cannot be greater than ${dimension[2] / 2}`);
                cornerError.x = 'Not valid depth';
                dispatch(cornerErrorSet(cornerError));
                return false;
            }
            if (corner.corner.name == 'FG' && cornerDepth > dimension[6] / 2) {
                setError(`Depth cannot be greater than ${dimension[6] / 2}`);
                cornerError.x = 'Not valid depth';
                dispatch(cornerErrorSet(cornerError));
                return false;
            }
        }
        return true;
    };

    const saveCorner = () => {
        const selectedCorner = corners.at(corner.index);

        if (
            shape.type == Shape.SQR ||
            (shape.type == Shape.ANG && corner.corner.name == 'BC')
        ) {
            dispatch(
                cornerSet(
                    {
                        ...selectedCorner,
                        depths: [
                            xValue,
                            selectedCorner.depths[1], // only horizontal length needs to be updated
                        ],
                    },
                    corner.index
                )
            );

            // when corner is arc corner then next/previous corner needs to be updated to maintain the radius value
            if (isNumber(selectedCorner?.relatedCornerIndex)) {
                // get next corner and update horizontal value also
                const nextCorner = corners.at(
                    selectedCorner.relatedCornerIndex
                );
                if (nextCorner && nextCorner.isArc) {
                    dispatch(
                        cornerSet(
                            {
                                ...nextCorner,
                                depths: [xValue, nextCorner.depths[1]],
                            },
                            selectedCorner.relatedCornerIndex
                        )
                    );
                }
            }
        } else {
            dispatch(
                cornerSet(
                    {
                        ...selectedCorner,
                        depths: [
                            selectedCorner.depths[0],
                            yValue, // only horizontal length needs to be updated
                        ],
                    },
                    corner.index
                )
            );

            // when corner is arc corner then next/previous corner needs to be updated to maintain the radius value
            if (isNumber(selectedCorner?.relatedCornerIndex)) {
                // get next corner and update horizontal value also
                const nextCorner = corners.at(
                    selectedCorner.relatedCornerIndex
                );
                if (nextCorner && nextCorner.isArc) {
                    dispatch(
                        cornerSet(
                            {
                                ...nextCorner,
                                depths: [nextCorner.depths[0], yValue],
                            },
                            selectedCorner.relatedCornerIndex
                        )
                    );
                }
            }
        }
    };

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            if (
                shape.type == Shape.SQR ||
                (shape.type == Shape.ANG && corner.corner.name == 'BC')
            ) {
                if (value != '') {
                    setXValue(Number(value));
                }
            } else {
                setYValue(Number(value));
            }
        },
        [setXValue, setYValue, shape, corner]
    );

    const updateValue = useCallback(() => {
        try {
            if (
                shape.type == Shape.SQR ||
                (shape.type == Shape.ANG && corner.corner.name == 'BC')
            ) {
                const valid = checkCornerDepthValidation(xValue);
                if (valid) {
                    saveCorner();
                }
            } else {
                const valid = checkCornerDepthValidation(yValue);
                if (valid) {
                    saveCorner();
                }
            }
        } catch (e) {
            //
        }
    }, [xValue, yValue, shape, corner]);

    if (corner.corner?.hideCornerInPreview) {
        return null;
    }
    return (
        <ArcEndBTMInputGroup $position={0} $vertical={false} $vOffset={null}>
            <FormControl
                type="number"
                min={1}
                step={0}
                style={{backgroundColor: error ? '#F4CFC0' : '#fff'}}
                title={error == '' ? String(inputValueUsed) : error}
                readOnly={false}
                value={inputValueUsed}
                onChange={handleOnChange}
                onBlur={updateValue}
            />
        </ArcEndBTMInputGroup>
    );
};

const ArcEndBTMInputGroup = styled(BTMInputGroup)`
    > .form-control {
        border-radius: 8px !important;
    }
`;
