export enum JobRedirectEnum {
    PRODUCT = 1,
    FAVOURITES = 2,
    BENCHTOP = 3,
    SUNDRIES = 4,
    LAYOUT = 5,
    DETAILED_CART = 6,
    QFP = 7,
}

export enum PricingDataFormEnum {
    JSON = 1,
    CSV = 2,
    XML = 3,
}

export const JobRedirectLabels = {
    [JobRedirectEnum.PRODUCT]: 'Add Product',
    [JobRedirectEnum.FAVOURITES]: 'Favourites',
    [JobRedirectEnum.BENCHTOP]: 'Add Benchtops',
    [JobRedirectEnum.SUNDRIES]: 'Add Sundries',
    [JobRedirectEnum.LAYOUT]: 'Add Layout',
    [JobRedirectEnum.DETAILED_CART]: 'Detailed Cart',
    [JobRedirectEnum.QFP]: 'Quick Flat Product',
};

export const PricingDataFormat = {
    [PricingDataFormEnum.JSON]: 'JSON',
    [PricingDataFormEnum.CSV]: 'CSV',
    [PricingDataFormEnum.XML]: 'XML',
};

export enum EmailNotificationTypeEnum {
    DISABLE = 0,
    ALL = 1,
    CUSTOMER = 2,
    MANUFACTURER = 3,
}

export const EmailNotificationTypeLabels = {
    [EmailNotificationTypeEnum.DISABLE]: 'Disable Emails',
    [EmailNotificationTypeEnum.ALL]: 'All Emails',
    [EmailNotificationTypeEnum.CUSTOMER]: 'Customer Emails Only',
    [EmailNotificationTypeEnum.MANUFACTURER]: 'Manufacturer Emails Only',
};

export enum RequestedDeliveryDateFrequencyEnum {
    JOB_SUBMISSION = 1,
    JOB_EDIT_AND_SUBMISSION = 2,
    NEVER = -1,
}

export const RequestedDeliveryDateFrequencyLabels = {
    [RequestedDeliveryDateFrequencyEnum.JOB_SUBMISSION]: 'On Job Submission',
    [RequestedDeliveryDateFrequencyEnum.JOB_EDIT_AND_SUBMISSION]:
        'On Order Editing & Job Submission',
    [RequestedDeliveryDateFrequencyEnum.NEVER]: 'Never',
};
