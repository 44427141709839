import React, {useCallback, useEffect, useState} from 'react';
import {useGetPredictions} from 'components/customer/Settings/helpers/useGetPredictions';
import {PredictionInterface} from 'components/customer/Settings/store/settingsApi';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {DefaultDeliveryAddress} from 'components/customer/Settings/address/DefaultDeliveryAddress';
import {useDebouncedCallback} from 'use-debounce';
import {useGetPredictionDetails} from 'components/customer/Settings/helpers/useGetPredictionDetails';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import {useAppContext} from 'contexts';
import {useFormikContext} from 'formik';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

export const Search = ({
    setAddressData,
    isShowAddress = false,
    isDisplayOnly = false,
    isNameVisible = true,
}: {
    setAddressData: (data: DeliveryAddress) => void;
    isShowAddress?: boolean;
    isDisplayOnly?: boolean;
    isNameVisible?: boolean;
}) => {
    const {userProfile, places} = useAppContext();
    const {getPredictions} = useGetPredictions();
    const [predicting, setPredicting] = useState(false);
    const [loader, setLoader] = useState(false);
    const {getPredictionDetails} = useGetPredictionDetails();
    const [predictions, setPredictions] = useState<PredictionInterface[]>([]);
    const [input, setInput] = useState('');
    const [showAddress, setShowAddress] = useState(isShowAddress);
    const [displayOnly, setDisplayOnly] = useState(isDisplayOnly);
    const [states, setStates] = useState([]);
    const {values} = useFormikContext();
    const isSmallDevice = isDeviceSmall();

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            setInput(inputValue);
            // Fetch autocomplete predictions from Google Places API
            void fetchPredictions(inputValue);
        },
        []
    );

    const handleClick = useCallback(
        async (event: React.MouseEvent<HTMLLIElement>) => {
            const inputValue = event.currentTarget.innerText;
            setInput(inputValue);
            setShowAddress(true);
            setDisplayOnly(true);
            setPredictions([]);
            setLoader(true);
            // Fetch prediction details from Google Places API
            const data = await getPredictionDetails(event.currentTarget.id);

            let currentState = states.find((state) => state.name == data.state);
            setAddressData({
                id: values && values.id ? values.id : -1,
                name: values && values.name ? values.name : '',
                city: data.city,
                street: [data.streetNumber, data.streetName]
                    .filter(Boolean)
                    .join(' '),
                suburb: data.suburb,
                postcode: parseInt(data.postcode),
                state: currentState.id,
            });
            setLoader(false);
        },
        [input, states, values]
    );

    const handleNotFound = useCallback(() => {
        setInput('');
        setAddressData({
            id: values && values.id ? values.id : null,
            name: values && values.name ? values.name : '',
            city: '',
            street: '',
            suburb: '',
            postcode: '',
            state: 1,
        });
        setPredictions([]);
        setShowAddress(true);
        setDisplayOnly(false);
    }, [input, values]);

    const fetchPredictions = useDebouncedCallback(async (input: string) => {
        const response = await getPredictions(input);
        if (response.length > 0) {
            setPredictions(response);
            setPredicting(false);
        } else {
            setPredictions(response);
            setPredicting(true);
        }
    }, 300);

    useEffect(() => {
        if (userProfile) {
            const country = userProfile.countryName;
            setStates(
                places.hasOwnProperty('Countries') &&
                    places.Countries.hasOwnProperty(country)
                    ? places.Countries[country]
                    : []
            );
        }
    }, [userProfile, places]);

    return (
        <div style={{position: 'relative'}}>
            {predictions.length > 0 ? (
                <Styledul>
                    {predictions.map((prediction) => (
                        <Styledli
                            key={prediction.id}
                            id={prediction.id}
                            onClick={handleClick}>
                            <Icon
                                iconName="Location-Pin-Drop.svg"
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    marginRight: '7px',
                                    cursor: 'pointer',
                                }}
                            />
                            {prediction.name}
                        </Styledli>
                    ))}
                    <StyledNotFoundli onClick={handleNotFound}>
                        Can&apos;t find the address? Add it manually
                    </StyledNotFoundli>
                </Styledul>
            ) : null}
            {input.length > 0 && predictions.length == 0 && predicting ? (
                <Styledul>
                    <StyledNotFoundli onClick={handleNotFound}>
                        No result found. Add it manually
                    </StyledNotFoundli>
                </Styledul>
            ) : null}
            <StyledSearchContainer $isMobile={isSmallDevice}>
                <input
                    type="text"
                    value={input}
                    className="form-control search-address-input"
                    onChange={handleInputChange}
                    placeholder="Search for address..."
                />
                <Icon iconName="search-black.svg" />
            </StyledSearchContainer>
            {showAddress ? (
                <DefaultDeliveryAddress
                    displayOnly={displayOnly}
                    loading={loader}
                    isNameVisible={isNameVisible}
                />
            ) : null}
        </div>
    );
};

const Styledli = styled.li`
    list-style-type: none;
    display: flex;
    padding: 9px;
    margin-left: 0px;
    :hover {
        background: #ced4da;
        cursor: pointer;
    }
`;

const StyledNotFoundli = styled.li`
    list-style-type: none;
    padding: 9px;
    margin-left: 0px;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
`;

const Styledul = styled.ul`
    padding: 0px;
    border: 1px solid #ced4da;
    position: absolute;
    padding-top: 3px;
    top: 35px;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;
`;

const StyledSearchContainer = styled.div<{$isMobile?: boolean}>`
    position: relative;
    ${({$isMobile}) => {
        if ($isMobile) return '';
        return 'margin-right: 15px;';
    }}
    img {
        width: 25px;
        position: absolute;
        right: 5px;
        top: 6px;
    }
`;
