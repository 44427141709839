import React, {useCallback, useMemo, useRef, useState} from 'react';
import {CBCButton, Icon} from 'shared/helpers';
import {Modal} from 'react-bootstrap';
import {
    CloseEventType,
    Footer,
} from 'components/customer/AdvancedMaterials/Footer';
import {CurrentSelections} from 'components/customer/AdvancedMaterials/CurrentSelections';
import {Menu} from 'components/customer/AdvancedMaterials/Menu';
import styled from 'styled-components';
import {Search} from 'components/customer/AdvancedMaterials/Search';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    BrowserType,
    clearAll,
    selectBrowserType,
    selectDoor,
    selectEdge,
    selectHasDoors,
    selectMaterial,
    selectMenu,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import {Body as Content} from 'components/customer/AdvancedMaterials/Body';
import {BodyContent} from 'components/customer/AdvancedMaterials/BodyContent';
import {Menu as MenuI} from 'components/customer/AdvancedMaterials/entity/Menu';
import {Doors} from 'components/customer/AdvancedMaterials/Doors';
import {MaterialAndDoorSelection} from 'components/customer/AdvancedMaterials/CurrentSelections/MaterialAndDoorSelection';
import {Door} from 'components/customer/Materials/entity';
import {Title} from 'components/customer/AdvancedMaterials/Title';
import {Finishes} from 'components/customer/AdvancedMaterials/BrandAndFinish/Finish';
import {Brands} from 'components/customer/AdvancedMaterials/BrandAndFinish/Brand';
import {Materials as BTMMaterials} from 'components/customer/AdvancedMaterials/Materials';
import Materials from 'components/customer/AdvancedMaterials/Materials/withMaterial';
import Edges from 'components/customer/AdvancedMaterials/Materials/withEdge';

interface AdvancedMaterialsInterface {
    onShow?: () => void;
    onApply?: (
        material: Material,
        door?: Door,
        edge?: Material
    ) => void | boolean;
    onClose?: () => void;
    onCancel?: () => void;
    checkBeforeClose?: boolean;
    className?: string;
    disableApply?: boolean;
    $width?: number;
}

const AdvancedMaterials = ({
    onShow,
    onApply,
    onCancel,
    className,
    checkBeforeClose = false,
    disableApply = false,
}: AdvancedMaterialsInterface) => {
    const container = useRef<HTMLDivElement>();

    const [show, setShow] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);

    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);
    const selectedDoor = useAppSelector(selectDoor, shallowEqual);
    const selectedEdge = useAppSelector(selectEdge, shallowEqual);
    const colorBrowserType = useAppSelector(selectBrowserType);
    const selectedMenu = useAppSelector(selectMenu);
    const hasDoors = useAppSelector(selectHasDoors);

    const dispatch = useAppDispatch();

    const handleShow = useCallback(() => {
        if (typeof onShow == 'function') {
            onShow();
        }

        setShow(true);
    }, [onShow]);

    const handleClose = useCallback(
        (event: CloseEventType) => () => {
            if (event == CloseEventType.APPLY && typeof onApply == 'function') {
                // NOTE: Add pass onApply from AdvanedMaterialsBenchtop.tsx and
                // possibly update these callbacks when selected material is updated.
                const closeCheck = onApply(
                    selectedMaterial,
                    selectedDoor,
                    selectedEdge
                );

                if (!checkBeforeClose || closeCheck) {
                    setShow(false);
                    dispatch(clearAll());
                }
            } else if (
                event == CloseEventType.CANCEL &&
                typeof onCancel == 'function'
            ) {
                onCancel();
                setShow(false);
                dispatch(clearAll());
            } else {
                setShow(false);
                dispatch(clearAll());
            }
        },
        [
            selectedMaterial,
            selectedDoor,
            selectedEdge,
            checkBeforeClose,
            onApply,
            onCancel,
        ]
    );

    const showMaterialsAndDoors = useMemo(() => {
        return (
            colorBrowserType != BrowserType.BENCHTOP &&
            ((hasDoors && selectedMenu != MenuI.DOOR_TYPE) || !hasDoors)
        );
    }, [hasDoors, selectedMenu, colorBrowserType]);

    const toggleMobileSidebar = useCallback(() => {
        setShowSidebar((showSidebar) => !showSidebar);
    }, []);

    return (
        <>
            <MaterialButton
                $applyMinimum={colorBrowserType != BrowserType.QFP}
                iconName="Button-Edit.svg"
                className={`button-blue simplified-material-button advanced_material_editor ${className}`}
                onClick={handleShow}>
                <strong>Browse Colours</strong>
            </MaterialButton>

            <CBCModal
                show={show}
                onHide={handleClose(CloseEventType.CANCEL)}
                animation={false}
                backdrop="static">
                <Header>
                    <HeaderMenu
                        onClick={toggleMobileSidebar}
                        iconName={showSidebar ? 'menu_open.svg' : 'menu.svg'}
                        style={{width: '24px'}}
                    />
                    <div>Material Selector</div>
                    <CloseButton
                        iconName="Button-Cancel.svg"
                        onClick={handleClose(CloseEventType.CANCEL)}
                        className="job-button button-light"
                        style={{color: '#8A8080'}}
                    />
                </Header>
                <Body>
                    <Sidebar $showSidebar={showSidebar}>
                        {colorBrowserType == BrowserType.BENCHTOP ? (
                            <CurrentSelections />
                        ) : null}
                        <Menu />
                    </Sidebar>
                    <Container ref={container}>
                        {showMaterialsAndDoors ? (
                            <MaterialAndDoorSelection />
                        ) : null}
                        <SearchLayout
                            $justification={
                                colorBrowserType == BrowserType.BENCHTOP ||
                                (hasDoors && selectedMenu == MenuI.DOOR_TYPE)
                                    ? 'flex-end'
                                    : 'space-between'
                            }
                            $alignment="flex-start"
                            $showTitle={
                                colorBrowserType != BrowserType.BENCHTOP &&
                                ((hasDoors &&
                                    selectedMenu != MenuI.DOOR_TYPE) ||
                                    !hasDoors)
                            }>
                            <Title />
                            <FinishesAndSearch>
                                <Finishes />
                                <Search />
                            </FinishesAndSearch>
                        </SearchLayout>
                        <Brands />
                        <Content>
                            <BodyContent
                                eventKey={
                                    hasDoors
                                        ? [MenuI.MATERIAL, MenuI.THICKNESSES]
                                        : [
                                              MenuI.MATERIAL,
                                              MenuI.THICKNESSES,
                                              MenuI.DOOR_TYPE,
                                          ]
                                }>
                                {colorBrowserType == BrowserType.BENCHTOP ? (
                                    <BTMMaterials />
                                ) : (
                                    <Materials />
                                )}
                            </BodyContent>
                            <BodyContent
                                eventKey={hasDoors ? MenuI.DOOR_TYPE : []}>
                                <Doors container={container} />
                            </BodyContent>
                            <BodyContent
                                eventKey={
                                    hasDoors
                                        ? MenuI.EDGE_MATERIAL
                                        : [MenuI.EDGE_MATERIAL, MenuI.DOOR_TYPE]
                                }>
                                <Edges />
                            </BodyContent>
                        </Content>
                    </Container>
                </Body>
                <Footer onClose={handleClose} disableApply={disableApply} />
            </CBCModal>
        </>
    );
};

export default AdvancedMaterials;

const HeaderMenu = styled((props) => <Icon {...props} />)`
    display: none;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        display: block;
    }
`;

const CBCModal = styled(Modal)`
    background: rgba(255, 255, 255, 0.5);

    .modal-dialog {
        width: 70vw;
        max-width: initial;
        height: calc(100% - 50px);
        margin: 25px auto;
    }

    .modal-content {
        border-radius: 20px;
        box-shadow: 0px 0px 10px 1px #616161bf;
        border: 0;
        height: 100%;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        overflow-y: hidden !important;

        .modal-dialog {
            width: 100vw;
            height: 100vh;
            margin: 0;
        }

        .modal-content {
            border-radius: 0 !important;
            height: 100%;
        }
    }
`;

export const Header = styled(Modal.Header)`
    background: rgb(var(--primary_colour));
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px;

    > div {
        text-align: center;
        width: 100%;
        font-weight: 900;
        color: white;
        font-size: 1em;
    }

    .close {
        display: none;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        border-radius: 0 !important;
    }
`;

const Sidebar = styled.div<{$showSidebar: boolean}>`
    width: 300px;
    background: #e6e7e9;
    padding: 0;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        position: absolute;
        top: 0;
        left: -300px;
        bottom: 0;
        background: white;
        z-index: 4;
        box-shadow: 0 0 4px 1px gray;

        ${({$showSidebar}) => {
            if ($showSidebar) {
                return `left: 0;`;
            }
        }}
    }
`;

const Container = styled.div`
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        padding: 0;
    }
`;

const Body = styled(Modal.Body)`
    padding: 0;
    display: flex;
    height: calc(100% - 45px - 71px);
`;

const SearchLayout = styled.div<{
    $showTitle?: boolean;
    $alignment: string;
    $justification: string;
}>`
    display: flex;
    justify-content: ${({$justification}) => $justification};
    align-items: ${({$alignment}) => $alignment};
    ${({$showTitle = false}) => {
        if ($showTitle) {
            return `border-top: 2px solid rgb(var(--secondary_colour));`;
        }
    }}

    > h1 {
        margin: 0;
        background: rgb(var(--secondary_colour));
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 15px !important;
        border-bottom-left-radius: 0;
        padding: 0px 50px;
        font-weight: 900;
        height: 30px;
        margin-top: -2px;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        border: 0;

        > h1 {
            display: none;
        }
    }
`;

const FinishesAndSearch = styled.div`
    display: flex;
    padding-top: 10px;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        padding: 5px 5px 0;
        gap: 5px;

        > div {
            width: calc(50% - 2px) !important;
            margin: 0 !important;

            > button {
                width: 100%;
            }
        }
    }
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
const MaterialButton = styled((props) => <CBCButton {...props} />)`
    width: 100% !important;
    font-size: 1em !important;
    min-width: ${({$applyMinimum}) => ($applyMinimum ? '150px' : 'initial')};

    > img {
        width: 29px !important;
    }
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
export const CloseButton = styled((props) => <CBCButton {...props} />)`
    color: rgb(138, 128, 128);
    background: transparent;
    border: 0 !important;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    img {
        width: 20px !important;
        margin: 0 !important;
    }
`;
