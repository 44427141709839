import DBAbstract from "./DBAbstract";

class HardwareRepository extends DBAbstract {
    constructor() {
        super();

        this.record = {
            category: 'sundry_category',
            brands: 'sundry_items_brands',
            items: 'sundry_items',
            search: 'sundry_items_search'
        };
    }

    async addCategories (data) { return await this.add(this.record.category, data) }
    async getCategories (parentId) { return await this.readById(this.record.category, parentId, 'parentCategoryId') }
    async getCategory (categoryId) { return await this.readById(this.record.category, categoryId) }
    async updateCategory (category) { return await this.add(this.record.category, category, true) }
    async deleteCategory (category) { return await this.delete(this.record.category, category.id) }

    async addBrands (data) { return await this.add(this.record.brands, data, true); }

    async addItems (data) {
        data = data.map( row => {
            row.favourites = row.hasOwnProperty('favourites') ? parseInt(row.favourites) : 0;

            return row;
        });
        return await this.add(this.record.items, data, true);
    }

    async getItem (itemId) { return await this.readById(this.record.items, itemId) }

    async getItems (category) {
        if (category)
            return await this.readById(this.record.items, category, 'sundryCategoryId');
        else 
            return await this.readAll(this.record.items);
    }

    async addSearch (data) { return await this.add(this.record.search, data, 'searchCategory', [ data.category, data.search ]) }
    async getSearch (category, search) { return await this.readById(this.record.search, [category, search], 'searchCategory', true) }
    async getItemsByIds (items) { return await this.readById(this.record.items, items) }

    async updateFavourite (data) { return await this.add(this.record.items, data, true) }
    async getFavourites () {
        try {
            const store = await this.getStore(this.record.items);
            if (store == null) return [];
            const index = store.index('favourites');

            return new Promise ((resolve, reject) => {
                const request = index.openCursor(IDBKeyRange.lowerBound(0, true));
                const records = [];

                request.onsuccess = () => {
                    let cursor = request.result;

                    if (cursor) {
                        records.push(cursor.value);
                        cursor.continue();
                    } else {
                        resolve(records);
                    }
                }

                request.onerror = (event) => {
                    reject(new Error(`Database Error: ${event.target.error.message}`));
                }
            });

        } catch (e) {
            throw e;
        }
    }
}

export default HardwareRepository;