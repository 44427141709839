import React, {useMemo} from 'react';
import {BodyContentProps} from 'components/customer/AdvancedMaterials/BodyContent';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';

interface BodyProps {
    children: React.ReactElement<BodyContentProps>[];
}

export const Body = ({children}: BodyProps) => {
    const {selectedMenu: activeKey} = useSelectedMenu();

    const child = useMemo(() => {
        return children.find((child) => {
            const {eventKey} = child?.props;

            if (
                React.isValidElement(child) &&
                eventKey &&
                ((Array.isArray(eventKey) && eventKey.includes(activeKey)) ||
                    eventKey == activeKey)
            ) {
                return child;
            }
        });
    }, [children, activeKey]);

    if (child) return child;
    return null;
};
