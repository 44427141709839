import React from 'react';
import {useAppSelector} from 'store/customer';
import {
    selectHasDoors,
    selectHasEdges,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import ProductAndRoomCurrentSelections from 'components/customer/AdvancedMaterials/CurrentSelections/ProductAndRoomCurrentSelections';
import BenchtopCurrentSelections from 'components/customer/AdvancedMaterials/CurrentSelections/BenchtopCurrentSelections';

export const CurrentSelections = () => {
    const hasEdges = useAppSelector(selectHasEdges);
    const hasDoors = useAppSelector(selectHasDoors);

    if (hasEdges || hasDoors) {
        return <ProductAndRoomCurrentSelections />;
    } else {
        return <BenchtopCurrentSelections />;
    }
};
