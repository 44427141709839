import {Path} from 'components/customer/BTM/entity/Path';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {restrictionsMap} from 'components/customer/BTM/helper/checkAdjacentRestriction';

/**
 *
 * @param {Path[]} paths all the paths of the benchtop
 * @param {BenchtopEdgeProfile} edgeProfile edge profile to check for restriction
 * @param {number} index index of the path to check
 * @param {Shape} shape Shape of the benchtop
 * @return {boolean}
 */
export const checkEndOnlyRestriction = (
    paths: Path[],
    edgeProfile: BenchtopEdgeProfile,
    index: number,
    shape: Shape
): boolean => {
    const endOnlyEdges = restrictionsMap[shape];
    if (endOnlyEdges.includes(paths[Number(index)].side)) {
        if (edgeProfile.is_end_only) {
            return false;
        }
        return (
            edgeProfile.is_postformed_profile || edgeProfile.restrict_adjacent
        );
    }
    return Boolean(edgeProfile.is_end_only);
};
