import React, {useMemo} from 'react';
import {Side} from 'components/customer/BTM/entity/Side';
import {Position} from 'components/customer/BTM/entity/Position';
import {
    selectButtJoinBySide,
    selectCenter,
    selectDimension,
    selectDimensionBySide,
    selectMaterial,
    selectScale,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {DimensionView} from 'components/customer/BTM/Preview/DimensionView';
import {useLabelPosition} from 'components/customer/BTM/helper/useLabelPosition';
import {cloneDeep} from 'lodash';
import {useButtJoin} from 'components/customer/BTM/helper/useButtJoin';

interface DimensionInterface {
    side: Side;
    position: Position;
    textOnly?: boolean;
    readOnly?: boolean;
    arrowStart?: boolean;
    arrowEnd?: boolean;
    isButtJoin?: boolean;
}

export const Dimension = ({
    side,
    position,
    textOnly = false,
    readOnly = false,
    arrowStart = false,
    arrowEnd = false,
    isButtJoin = false,
}: DimensionInterface) => {
    const material = useAppSelector(selectMaterial, shallowEqual);
    const buttJoins = useAppSelector(
        (store) => selectButtJoinBySide(store, side),
        shallowEqual
    );
    const center = useAppSelector(selectCenter, shallowEqual);
    const dimension = useAppSelector(selectDimension);
    const scale = useAppSelector(selectScale);
    const shape = useAppSelector(selectType);
    const value = useAppSelector((state) => selectDimensionBySide(state, side));
    const {getBenchParts} = useButtJoin();

    const vertical = useMemo(() => {
        switch (position) {
            case Position.Top:
            case Position.Bottom:
            case Position.BottomLeft:
            case Position.BottomRight:
            case Position.BottomInner:
            case Position.Inner:
                return false;

            case Position.Left:
            case Position.Right:
            case Position.LeftInner:
            case Position.RightInner:
                return true;
        }
    }, [position]);

    const {positionData} = useLabelPosition(
        textOnly,
        position,
        shape.type,
        center,
        dimension,
        scale,
        side,
        material && material.allowButtJoin && buttJoins.length > 0,
        isButtJoin
    );

    if (center[0] == 0 && center[1] == 0) return null;

    if (material && material.allowButtJoin && buttJoins && isButtJoin) {
        const benchParts = getBenchParts(side);

        if (benchParts) {
            return benchParts.map((part, index) => {
                const offset = benchParts
                    .filter((benchPart) => benchPart.index < part.index)
                    .reduce((acc, curr) => acc + curr.value + 1, 0);

                const newPositionData = cloneDeep(positionData);
                if (vertical) {
                    newPositionData.y += offset * scale;
                } else {
                    newPositionData.x += offset * scale;
                }

                return (
                    <DimensionView
                        key={part.index}
                        value={part.value}
                        vertical={vertical}
                        positionData={newPositionData}
                        scale={scale}
                        arrowStart={arrowStart}
                        arrowEnd={arrowEnd}
                        textOnly={textOnly}
                        readOnly={readOnly}
                        position={position}
                        side={side}
                        adjustValue={index < buttJoins.length - 1}
                        index={part.index}
                        benchParts={benchParts}
                    />
                );
            });
        }

        return null;
    }

    return (
        <DimensionView
            value={value}
            vertical={vertical}
            positionData={positionData}
            scale={scale}
            arrowStart={arrowStart}
            arrowEnd={arrowEnd}
            textOnly={textOnly}
            readOnly={readOnly}
            position={position}
            side={side}
        />
    );
};
