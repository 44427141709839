import React, {useRef} from 'react';
import {
    ThumbnailColour,
    ThumbnailColourEdgeLeft,
    ThumbnailColourEdgeRight,
    ThumbnailContainer,
} from 'components/customer/Room/ColorSwatches';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {shallowEqual} from 'react-redux';
import {useAppSelector} from 'store/customer';
import {selectMaterial} from 'components/customer/BTM/store/btmSlice';
import {Styled} from 'shared/types/Styled';

const thumbnailSize = 130;
const smallThumbnailSize = 70;

interface MaterialSwatchInterface extends Styled {
    smallPreview?: boolean;
    size?: number;
}

export const MaterialSwatch = ({
    className,
    smallPreview = false,
    size = thumbnailSize,
}: MaterialSwatchInterface) => {
    const isSmallDevice = isDeviceSmall();
    const colorThumbnailRef = useRef<HTMLDivElement>();
    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);

    return (
        <ThumbnailContainer
            className={className}
            ref={colorThumbnailRef}
            $size={isSmallDevice || smallPreview ? smallThumbnailSize : size}
            colorImage={selectedMaterial?.image?.name}
            edgeImage={selectedMaterial?.image?.name}
            isHorizontal={false}
            isSmallThumbnail={isSmallDevice}>
            <ThumbnailColourEdgeLeft />
            <ThumbnailColour />
            <ThumbnailColourEdgeRight />
        </ThumbnailContainer>
    );
};
