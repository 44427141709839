export enum Direction {
    RIGHT,
    DOWN,
    LEFT,
    UP,
    RIGHT_DOWN,
    LEFT_DOWN,
    RIGHT_UP,
    LEFT_UP,
}
