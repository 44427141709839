import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';
import styled from 'styled-components';

export enum CloseEventType {
    CANCEL = 'cancel',
    APPLY = 'apply',
}

interface FooterInterface {
    onClose: (event: CloseEventType) => () => void;
    disableApply?: boolean;
}

export const Footer = ({onClose, disableApply = false}: FooterInterface) => {
    const cancel = useCallback(() => {
        const event = onClose(CloseEventType.CANCEL);
        event();
    }, [onClose]);

    const apply = useCallback(() => {
        const event = onClose(CloseEventType.APPLY);
        event();
    }, [onClose]);

    return (
        <StyledFooter>
            <Button
                onClick={cancel}
                iconName="Button-Cancel.svg"
                className="job-button button-light"
                style={{color: '#8A8080'}}>
                Cancel
            </Button>
            <Button
                disabled={disableApply}
                style={{background: 'rgb(var(--secondary_colour))'}}
                onClick={apply}
                type="button"
                iconName="Button-Tick.svg"
                className="job-button button-blue">
                Apply
            </Button>
        </StyledFooter>
    );
};

const StyledFooter = styled(Modal.Footer)`
    background: rgb(var(--primary_colour));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 10px;
    justify-content: space-around;
    z-index: 1;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        border-radius: 0 !important;
        height: 130px;
        align-items: self-start;
    }
`;

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
const Button = styled((props) => <CBCButton {...props} />)`
    border-radius: 20px !important;
    padding: 5px 10px 5px 5px !important;
    width: 140px;
    font-size: 0.95em !important;
`;
