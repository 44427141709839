import {connect, FormikProps} from 'formik';
import {useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import {Product} from 'components/customer/Product/entity';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    productUpdate,
    selectShouldValidate,
    selectUpdated,
    updateSet,
    validateRemove,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {cloneDeep} from 'lodash';

interface QFPFormikReduxInterface {
    index: number;
    formik?: FormikProps<Product>;
    product?: Product;
}

const FormikReduxConnectComponent = ({
    index,
    formik,
}: QFPFormikReduxInterface): null => {
    const dispatch = useAppDispatch();
    const {values, touched, validateForm} = formik;
    const [debouncedProduct] = useDebounce<Product>(values, 500);

    const updated = useAppSelector((state) => selectUpdated(state, index));
    const shouldValidate = useAppSelector((state) =>
        selectShouldValidate(state, index)
    );

    useEffect(() => {
        if (!updated && Object.keys(touched).length > 0) {
            dispatch(updateSet(true, index));
        }
    }, [touched, updated]);

    useEffect(() => {
        if (shouldValidate) {
            void validateForm(values);
            dispatch(validateRemove(index));
        }
    }, [shouldValidate]);

    useEffect(() => {
        const updatedProduct = cloneDeep(debouncedProduct);

        dispatch(productUpdate(updatedProduct, index));
    }, [debouncedProduct]);

    return null;
};

export default connect(FormikReduxConnectComponent);
