import React, {useEffect, useState} from 'react';
import {Nav} from 'react-bootstrap';
import {useAppContext, useDashboardContext} from 'contexts';
import {Icon} from 'shared/helpers';
import {BaseConfig} from 'config';
import {MobileWrapper} from 'shared';

export const MobileRender = ({children, isVisible}) => {
    return (
        <MobileWrapper className={isVisible ? 'mobile-show' : 'mobile-hide'}>
            {children}
        </MobileWrapper>
    );
};

export const MobileContainerDecorator = ({children}) => {
    return (
        <MobileWrapper style={{paddingBottom: '123px'}}>
            {children}
        </MobileWrapper>
    );
};

export const MobileMenu = () => {
    const {
        setIsQuotedJobsVisibleMobile,
        setIsProductionJobsVisibleMobile,
        setIsLeadTimeVisibleMobile,
        setIsPendingJobsVisibleMobile,
        setIsPendingConfirmationJobsVisibleMobile,
        setIsPendingPaymentJobsVisibleMobile,
        setIsCompletedJobsVisibleMobile,
        setIsUpdateCenterJobsVisibleMobile,
        jobInfo,
        hasLeadTime,
    } = useDashboardContext();
    const {userLevel} = useAppContext();

    const [showAll, setShowAll] = useState(false);
    const [menu, setMenu] = useState([]);

    const toggleElements = (current) => {
        setIsUpdateCenterJobsVisibleMobile(false);
        setIsQuotedJobsVisibleMobile(false);
        setIsLeadTimeVisibleMobile(false);
        setIsProductionJobsVisibleMobile(false);
        setIsPendingJobsVisibleMobile(false);
        setIsPendingConfirmationJobsVisibleMobile(false);
        setIsPendingPaymentJobsVisibleMobile(false);
        setIsCompletedJobsVisibleMobile(false);

        switch (current) {
            case 'update-center':
                setIsUpdateCenterJobsVisibleMobile(true);
                break;
            case 'lead-times':
                setIsLeadTimeVisibleMobile(true);
                break;
            case 'quoted-jobs':
                setIsQuotedJobsVisibleMobile(true);
                break;
            case 'pending-confirmation-jobs':
                setIsPendingConfirmationJobsVisibleMobile(true);
                break;
            case 'production-jobs':
                setIsProductionJobsVisibleMobile(true);
                break;
            case 'pending-approval-jobs':
                setIsPendingJobsVisibleMobile(true);
                break;
            case 'pending-payment-jobs':
                setIsPendingPaymentJobsVisibleMobile(true);
                break;
            case 'completed-jobs':
                setIsCompletedJobsVisibleMobile(true);
                break;
        }
    };

    useEffect(() => {
        if (
            userLevel === 'USER_CUSTOMER' &&
            typeof BaseConfig[userLevel] !== 'undefined'
        ) {
            let menu = [...BaseConfig[userLevel]['dashboardMenu']];

            menu = menu.filter((item) => {
                if (item.id == 'lead-times') return hasLeadTime;
                else
                    return (
                        !jobInfo.hasOwnProperty(item.id) ||
                        (jobInfo.hasOwnProperty(item.id) &&
                            jobInfo[item.id] > 0)
                    );
            });

            if (menu.length > 5) {
                menu.splice(4, 0, {
                    toggleButton: true,
                    name: ['More Options', 'Less Options'],
                    icon: ['Button-Up-Table.svg', 'Button-Down-Table.svg'],
                });
            }

            setMenu(menu);
        } else {
            setMenu([]);
        }
    }, [userLevel, jobInfo, hasLeadTime]);

    const RenderMenu = () => {
        return menu.map((item, index) => {
            let icon = item.icon;
            let name = item.name;
            let action = () => toggleElements(item.id);
            let isToggleButton = false;

            if (item.hasOwnProperty('toggleButton') && item.toggleButton) {
                isToggleButton = true;
                action = () => {
                    setShowAll(!showAll);
                };
            }

            if (!showAll && index > 4) {
                return;
            }

            return (
                <Nav.Link key={index} onClick={action}>
                    <div>
                        <Icon
                            style={isToggleButton ? {width: '29px'} : {}}
                            iconName={
                                isToggleButton
                                    ? showAll
                                        ? icon[1]
                                        : icon[0]
                                    : icon
                            }
                        />
                    </div>
                    <label className="toggle">
                        {isToggleButton ? (showAll ? name[1] : name[0]) : name}
                    </label>
                </Nav.Link>
            );
        });
    };

    return (
        <div className="mobile-menu" id="mobile-menu">
            <Nav className="job-menu">
                <RenderMenu />
            </Nav>
        </div>
    );
};
