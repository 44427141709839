import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-bootstrap';
import {connect} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import styled from 'styled-components';
import {
    isThicknessSelected,
    materialLoadingSet,
    materialSet,
    selectMaterial,
    selectMaterialType,
    thicknessSet,
} from 'components/customer/BTM/store/btmSlice';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useSearchMaterials} from 'components/customer/BTM/helper/useSearchMaterials';
import {useConfirmationDialog} from 'shared';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {useGetMaterials} from 'components/customer/BTM/helper/useGetMaterials';

interface ThicknessInterface {
    size: number;
    isSelected?: boolean;
}

const ThicknessComponent = ({size, isSelected}: ThicknessInterface) => {
    const dispatch = useAppDispatch();
    const type = useAppSelector(selectMaterialType);
    const material = useAppSelector(selectMaterial);
    const {searchMaterials} = useSearchMaterials();
    const {dialog, showDialog} = useConfirmationDialog();
    const {getMaterials} = useGetMaterials();

    const selectHandler = useCallback(() => {
        const setThickness = async () => {
            dispatch(thicknessSet(size));

            if (type && material) {
                dispatch(materialLoadingSet(true));
                const {materials} = await searchMaterials(
                    type.id,
                    size,
                    1,
                    material.searchName
                );

                if (materials && materials.length > 0) {
                    dispatch(materialSet(materials[0]));
                } else {
                    // If the material is not available in the selected thickness
                    const materialWithDifferentNames = await getMaterials(
                        type.id,
                        size
                    );

                    if (
                        materialWithDifferentNames &&
                        materialWithDifferentNames.length > 0
                    ) {
                        dispatch(materialSet(materialWithDifferentNames[0]));
                    } else {
                        dispatch(materialSet(undefined));
                    }
                    showDialog({
                        title: 'Benchtop Material',
                        message: (
                            <>
                                Material{' '}
                                <strong>
                                    {parseHtmlString(material?.displayName)}
                                </strong>{' '}
                                is not available in thickness{' '}
                                <strong>{size}mm</strong>.
                                <br />
                                <br />
                                Please Browse Colours or Search to select a
                                different material.
                            </>
                        ),
                        hideYesButton: true,
                        hideNoButton: true,
                        buttons: [
                            {
                                show: true,
                                name: 'Okay',
                            },
                        ],
                    });
                }
                dispatch(materialLoadingSet(false));
            }
        };

        void setThickness();
    }, [
        thicknessSet,
        materialLoadingSet,
        materialSet,
        searchMaterials,
        type,
        material,
        size,
        getMaterials,
    ]);

    const className = useMemo(
        () => (isSelected ? 'selected' : ''),
        [isSelected]
    );

    return (
        <>
            {dialog}
            <ThicknessSelector className={className} onClick={selectHandler}>
                <Container>
                    <Shape className="shape" $size={size / 2} />
                    <Size className="size">
                        <Number className="number">{size}</Number>
                    </Size>
                </Container>

                <div className="cbc-radio">
                    <Form.Check
                        type="radio"
                        id={`boolean_${size}_yes`}
                        name={`thickness_${size}`}
                        checked={isSelected}
                        label={`${size}mm`}
                        readOnly={true}
                    />
                </div>
            </ThicknessSelector>
        </>
    );
};

export const Thickness = connect(
    (state: AppState, {size}: ThicknessInterface) => ({
        isSelected: isThicknessSelected(state, size),
    })
)(ThicknessComponent);

const Container = styled.div`
    width: 130px;
    height: 130px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    border: 1px solid rgb(var(--primary_colour));
`;

const Shape = styled.div<{$size: number}>`
    background: #b9b9b9;
    width: 200px;
    height: 200px;
    transform: rotate(45deg);
    position: absolute;
    left: -60px;
    top: -170px;
    transition: background 500ms;

    &:before {
        content: '';
        width: 200px;
        height: ${({$size}) => $size}px;
        background: #b9b9b9;
        position: absolute;
        bottom: -${({$size}) => $size + 2}px;
        transform: skew(45deg);
        left: ${({$size}) => $size * 0.55}px;
        transition: background 500ms;
    }

    &:after {
        content: '';
        width: ${({$size}) => $size}px;
        height: 200px;
        background: #b9b9b9;
        position: absolute;
        right: -${({$size}) => $size + 2}px;
        transform: skew(0deg, 45deg);
        top: ${({$size}) => $size * 0.55}px;
        transition: background 500ms;
    }
`;

const Size = styled.div`
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-weight: bold;
    font-size: 1.2rem;
    border-left: 1px solid #b9b9b9;
    padding-left: 5px;
    transition: border-color 500ms;
`;

const Number = styled.div`
    position: relative;
    color: #b9b9b9;
    transition: color 500ms;

    &:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 1px;
        top: 0;
        left: -15px;
        background: #b9b9b9;
        transition: background 500ms;
    }

    &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 1px;
        bottom: 0;
        left: -15px;
        background: #b9b9b9;
        transition: background 500ms;
    }
`;

const ThicknessSelector = styled.div`
    cursor: pointer;
    text-align: center;
    margin-right: 15px;

    &:last-of-type {
        margin-right: 0;
    }

    .cbc-radio {
        margin-top: 5px;
    }

    .cbc-radio .form-check label::before {
        left: 30px !important;
    }

    .cbc-radio .form-check label::after {
        left: 25px;
    }

    &.selected,
    :hover {
        .shape {
            background: rgb(var(--primary_colour));
        }

        .shape::before {
            background: rgb(var(--primary_colour));
        }

        .shape::after {
            background: rgb(var(--primary_colour));
        }

        .size {
            border-color: rgb(var(--primary_colour));

            .number {
                color: rgb(var(--primary_colour));
            }

            .number::before {
                background: rgb(var(--primary_colour));
            }

            .number::after {
                background: rgb(var(--primary_colour));
            }
        }
    }
`;
