import React from 'react';
import styled from 'styled-components';
import {CBCButton, formatPrice} from 'shared/helpers';
import {DispatchMethod} from 'hooks';
import {Card, Dropdown} from 'react-bootstrap';

import {
    NameContainer,
    CustomButton,
    Label,
    LabelBold,
    TD,
    TDLessPadding,
    TableRow,
} from 'components/customer/JobDashboard/JobPendingVariation';
import {useJobVariationFreightCost} from 'hooks/Job/JobVariationFreightCost';

const DropdownContainer = styled.div`
    display: flex;
    justify-content: space-around;
`;

const Toggle = styled(Dropdown.Toggle)`
    padding: 0px 5px;
    border: 1px solid rgb(var(--secondary_colour));
    font-size: 1em;
    font-weight: 400;
`;

const Menu = styled(Dropdown.Menu)`
    min-width: auto;
    padding: 8px 0;
    background: rgb(218, 222, 223);
`;

const Item = styled(Dropdown.Item)`
    background: rgb(218, 222, 223);
    padding: 0 15px;
    color: rgb(107, 111, 112);
    font-size: 0.95em;
    font-weight: 400;

    &:hover,
    &:focus,
    &:active {
        font-weight: 500;
        color: rgb(107, 111, 112);
        background: rgb(197, 201, 202);
    }
`;

const Button = styled((props) => <CBCButton {...props} />)`
    border-radius: 20px !important;
    padding: 5px 10px 5px 5px !important;
    height: 34px;
    margin: auto 0;
    width: fit-content !important;
    font-size: 0.95em !important;
    &:hover {
        border: 1px solid white;
    }

    img {
        height: 20px;
    }
`;

const JobVariationFreightCost: React.FC<{totalVariationCost: number}> = ({
    totalVariationCost,
}) => {
    const {
        cost,
        depotList,
        costFetching,
        getItemStreet,
        handlChangedDispatchMethod,
        toggleChange,
        handleChangeDepot,
        submitJob,
        handleChangeAddress,
        isSelectedAddressSameToJobAddress,
        selectedOption,
        isLoading,
        dispatchMethodOptions,
        allAddresses,
        userProfile,
    } = useJobVariationFreightCost();

    return (
        <>
            <TableRow>
                <TD />
                <TD>
                    <NameContainer>
                        <Label>
                            {selectedOption?.selected_dispatch_method?.id ==
                            DispatchMethod.PICKUP
                                ? 'Pickup'
                                : 'Freight'}
                        </Label>
                    </NameContainer>
                </TD>
                <TD>
                    {costFetching ? '...' : formatPrice(cost?.freightCost, {})}
                </TD>
                <TD>
                    <CustomButton
                        data-cy="edit-cancel-button"
                        type="button"
                        iconName={
                            selectedOption.toggle_change
                                ? 'Button-Cancel.svg'
                                : 'Options-Edit.svg'
                        }
                        className="job-button button-light"
                        onClick={toggleChange}>
                        {selectedOption.toggle_change ? 'Cancel' : 'Edit'}
                    </CustomButton>
                </TD>
            </TableRow>

            {selectedOption.toggle_change ? (
                <TableRow>
                    <TD />
                    <TDLessPadding colSpan={3}>
                        <DropdownContainer>
                            {userProfile.depotSelectable &&
                            userProfile.isDepotFunctionalityEnabled &&
                            depotList?.depots?.length ? (
                                <Dropdown onSelect={handleChangeDepot}>
                                    <div>{userProfile.depotIdentification}</div>
                                    <Toggle variant="light" id="dropdown-basic">
                                        {selectedOption?.selected_depot
                                            ?.label || 'Depot'}
                                    </Toggle>
                                    <Menu align="right">
                                        {depotList?.depots?.map((depot) => {
                                            return (
                                                <Item
                                                    selected={
                                                        depot.id ===
                                                        selectedOption
                                                            ?.selected_depot?.id
                                                    }
                                                    disabled={
                                                        depot.id ===
                                                        selectedOption
                                                            ?.selected_depot?.id
                                                    }
                                                    value={depot.id}
                                                    eventKey={depot.id}
                                                    key={depot.id}>
                                                    {depot.depot_name +
                                                        ` (${depot.depot_address})`}
                                                </Item>
                                            );
                                        })}
                                    </Menu>
                                </Dropdown>
                            ) : null}

                            <Dropdown>
                                <div>Preferred delivery?</div>
                                <Toggle variant="light" id="dropdown-basic">
                                    {selectedOption?.selected_dispatch_method
                                        ?.label || 'Select a method'}
                                </Toggle>
                                <Menu align="right">
                                    {dispatchMethodOptions.map((dispatch) => {
                                        return (
                                            <Item
                                                onClick={handlChangedDispatchMethod(
                                                    dispatch
                                                )}
                                                disabled={
                                                    dispatch.value ===
                                                    selectedOption
                                                        ?.selected_dispatch_method
                                                        ?.id
                                                }
                                                selected={
                                                    dispatch.value ===
                                                    selectedOption
                                                        ?.selected_dispatch_method
                                                        ?.id
                                                }
                                                value={dispatch.value}
                                                key={dispatch.id}>
                                                {dispatch.label}
                                            </Item>
                                        );
                                    })}
                                </Menu>
                            </Dropdown>

                            {parseInt(
                                selectedOption?.selected_dispatch_method?.id.toString() ??
                                    '0'
                            ) > 0 ? (
                                <Dropdown>
                                    <div>Select Address</div>
                                    <Toggle variant="light" id="dropdown-basic">
                                        {selectedOption?.selected_address
                                            ?.label || 'Select Address'}
                                    </Toggle>
                                    <Menu align="right">
                                        {allAddresses?.map((address) => {
                                            const selectedAddress =
                                                selectedOption?.selected_address
                                                    .delivery_address;
                                            const isSelected =
                                                getItemStreet(address) ==
                                                    getItemStreet(
                                                        selectedAddress
                                                    ) &&
                                                address.city ==
                                                    selectedAddress.city &&
                                                address.suburb ==
                                                    selectedAddress.suburb &&
                                                address.postcode ==
                                                    selectedAddress.postcode;

                                            return (
                                                <Item
                                                    onClick={handleChangeAddress(
                                                        address
                                                    )}
                                                    value={address.id}
                                                    disabled={isSelected}
                                                    selected={isSelected}
                                                    key={address.id}>
                                                    <Card.Text>
                                                        {address?.street},{' '}
                                                        {address?.city
                                                            ? `${address.city}, `
                                                            : ''}
                                                        {address?.suburb},
                                                        {address?.postcode}
                                                    </Card.Text>
                                                </Item>
                                            );
                                        })}
                                    </Menu>
                                </Dropdown>
                            ) : null}

                            <Button
                                onClick={submitJob}
                                disabled={isSelectedAddressSameToJobAddress()}
                                type="button"
                                iconName="Button-Tick.svg"
                                className="job-button button-blue">
                                {isLoading ? 'Updating...' : 'Update'}
                            </Button>
                        </DropdownContainer>
                    </TDLessPadding>
                </TableRow>
            ) : null}

            <TableRow>
                <TD />
                <TD>
                    <NameContainer>
                        <LabelBold>TOTAL</LabelBold>
                    </NameContainer>
                </TD>
                <TD>
                    <LabelBold>
                        {isLoading || costFetching
                            ? '....'
                            : formatPrice(
                                  totalVariationCost + (cost?.freightCost || 0),
                                  {}
                              )}
                    </LabelBold>
                </TD>
                <TD />
            </TableRow>
        </>
    );
};

export default JobVariationFreightCost;
