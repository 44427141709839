import {ListenerEffectAPI, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppState} from 'store/customer/storeSetup';
import {Side} from 'components/customer/BTM/entity/Side';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {
    buttJoinErrorSet,
    buttJoinPartsErrorClear,
    buttJoinsSet,
    dimensionErrorSet,
} from 'components/customer/BTM/store/btmSlice';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {ButtJoinError} from 'components/customer/BTM/entity/ButtJoinPartError';
import {cloneDeep} from 'lodash';

export const getPoints = (
    length: number,
    depth: number,
    horizontal = false,
    xOffset = 0,
    yOffset = 0
) => {
    if (horizontal) {
        return [
            {x: length + xOffset, y: yOffset},
            {x: length + xOffset, y: depth + yOffset},
        ];
    }

    return [
        {x: xOffset, y: length + yOffset},
        {x: depth + xOffset, y: length + yOffset},
    ];
};

export const recalculatePoints =
    (dimension: number[] = [], shape: Shape) =>
    (joins: Join[], side: Side) => {
        let depth = dimension[Side.B];
        let horizontal = true;
        let xOffset = 0;

        if (shape == Shape.ANG && side == Side.A) {
            depth = dimension[Side.B];
        } else if (shape == Shape.ANG && side == Side.F) {
            depth = dimension[Side.E];
            horizontal = false;
        } else if (shape == Shape.USHAPE && side == Side.A) {
            depth = dimension[Side.H] - dimension[Side.F];
        } else if (shape == Shape.USHAPE && side == Side.H) {
            depth = dimension[Side.G];
            horizontal = false;
        } else if (shape == Shape.USHAPE && side == Side.B) {
            depth = dimension[Side.C];
            horizontal = false;
            xOffset = dimension[Side.G] + dimension[Side.E];
        }

        return joins.map((join) => {
            join.points = getPoints(join.value, depth, horizontal, xOffset);

            return join;
        });
    };

export const calculateJoinPosition = (
    benchLength: number,
    materialLength: number,
    maxJoins = 2,
    joinDistanceFromEdge = 300,
    shape?: Shape,
    offset = 0
): number[] => {
    // maxJoins + 1 is the parts of the benchtop.
    // for example, if maxJoins is 2, the benchtop will be divided into 3 parts.
    // which means 3500mm bench can be used 3 times to create up to
    // 3500mm * 3 = 10500mm benchtop.
    if (benchLength > materialLength * (maxJoins + 1)) {
        throw new ButtJoinError('Bench length exceeds maximum material length');
    }

    const joins: number[] = [];

    while (
        joins.length <
        Math.floor(
            (benchLength % materialLength == 0
                ? benchLength - 1
                : benchLength) / materialLength
        )
    ) {
        const usedLength = joins.at(-1) || 0;

        let currentPosition = usedLength + materialLength;

        if (benchLength - currentPosition < joinDistanceFromEdge) {
            currentPosition =
                currentPosition -
                (joinDistanceFromEdge - (benchLength - currentPosition));
        }

        joins.push(currentPosition);
    }

    if (joins.length > 1 && shape == Shape.USHAPE && offset > 0) {
        const lastJoinIndex = joins.length - 1;

        if (
            joins[Number(lastJoinIndex)] >=
            benchLength - (joinDistanceFromEdge + offset)
        ) {
            joins[Number(lastJoinIndex)] =
                benchLength - joinDistanceFromEdge - offset;
        }
    }

    return joins;
};

export const hasMasonsJoin = (
    joins: Join[],
    joinOrientation = JoinOrientation.HORIZONTAL,
    side = JoinSide.LEFT
) => {
    return joins.some(
        (join) =>
            join.joinType == JoinType.MASONS_MITRE &&
            join.orientation == joinOrientation &&
            join.side == side &&
            join.selected
    );
};

export const mapValuesToJoinObject = (
    values: number[],
    depth: number,
    horizontal = false,
    hasJoinOffset = false,
    offset = 0,
    xOffset = 0
) => {
    return values
        .map((value) => {
            if (hasJoinOffset) {
                value += offset;
            }

            return value;
        })
        .map((value, index) => ({
            value,
            joinType: JoinType.BUTT_JOIN,
            selected: true,
            points: getPoints(value, depth, horizontal, xOffset),
            side: null,
            index,
            touched: false,
        }));
};

interface UpdateButtJoinByAdjacentSideParams {
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>;
    benchSide: Side;
    joins: Join[];
    shape: BenchtopType;
    dimensions: number[];
}

export const updateButtJoinByAdjacentSide = ({
    listenerApi,
    benchSide,
    joins,
    shape,
    dimensions,
}: UpdateButtJoinByAdjacentSideParams) => {
    const buttJoins = joins.filter(
        (join) =>
            join.joinType == JoinType.BUTT_JOIN && join.benchSide == benchSide
    );

    if (buttJoins.length > 0) {
        const updatedJoins = recalculatePoints(dimensions, shape.type)(
            cloneDeep(buttJoins),
            benchSide
        );

        listenerApi.dispatch(buttJoinsSet(updatedJoins, benchSide));
    }
};

interface UpdateButtJoinParams {
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>;
    side?: Side;
    benchLength?: number;
    joins: Join[];
    shape: BenchtopType;
    dimensions: number[];
    material: Partial<BenchtopMaterial>;
    minPartSize?: number;
}
export const updateButtJoin = ({
    listenerApi,
    side,
    benchLength,
    joins,
    shape,
    dimensions,
    material,
    minPartSize = 300,
}: UpdateButtJoinParams) => {
    if (shape.type == Shape.SQR) {
        if (side == Side.A) {
            if (benchLength > material.max_length) {
                const joins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        benchLength,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize
                    ),
                    dimensions[Side.B],
                    true
                );

                listenerApi.dispatch(buttJoinsSet(joins, Side.A));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.A));
            }
        } else if (side == Side.B) {
            const updatedJoins = recalculatePoints(dimensions, shape.type)(
                structuredClone(
                    joins.filter((join) => join.joinType == JoinType.BUTT_JOIN)
                ),
                Side.A
            );

            listenerApi.dispatch(buttJoinsSet(updatedJoins, Side.A));
        }
    } else if (shape.type == Shape.ANG) {
        if (side == Side.A) {
            const hasVerticalJoin = hasMasonsJoin(
                joins,
                JoinOrientation.VERTICAL
            );
            const length = hasVerticalJoin
                ? benchLength - dimensions[Side.E]
                : benchLength;

            if (length > material.max_length) {
                const buttJoins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        length,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize
                    ),
                    dimensions[Side.B],
                    true,
                    hasVerticalJoin,
                    dimensions[Side.E]
                );

                listenerApi.dispatch(buttJoinsSet(buttJoins, Side.A));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.A));
            }
        } else if (side == Side.E) {
            // updating this length will effect butt joins on both parts.
            // 1. it needs to recalculate points for butt joins on side F
            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.F,
                joins,
                shape,
                dimensions,
            });
        } else if (side == Side.F) {
            const hasHorizontalJoin = hasMasonsJoin(joins);
            const length = hasHorizontalJoin
                ? benchLength - dimensions[Side.B]
                : benchLength;

            if (length > material.max_length) {
                const buttJoins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        length,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize
                    ),
                    dimensions[Side.B],
                    false,
                    hasHorizontalJoin,
                    dimensions[Side.B]
                );

                listenerApi.dispatch(buttJoinsSet(buttJoins, Side.F));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.F));
            }
        } else if (side == Side.B) {
            // same logic as side E applies here
            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.A,
                joins,
                shape,
                dimensions,
            });
        }
    } else if (shape.type == Shape.USHAPE) {
        if (side == Side.H) {
            const hasHorizontalJoin = hasMasonsJoin(
                joins,
                JoinOrientation.HORIZONTAL,
                JoinSide.LEFT
            );
            const length = hasHorizontalJoin
                ? benchLength - (benchLength - dimensions[Side.F])
                : benchLength;

            if (length > material.max_length) {
                const buttJoins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        length,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize
                    ),
                    dimensions[Side.G],
                    false,
                    hasHorizontalJoin,
                    benchLength - dimensions[Side.F]
                );

                listenerApi.dispatch(buttJoinsSet(buttJoins, Side.H));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.H));
            }
        } else if (side == Side.A) {
            // this needs to update the butt join locations for part
            // touching side A. But editing this also affects the
            // points of joins touching part B as they will more horizontally.
            // we need to update that as well.

            // updating the butt join for part touching side A
            const hasVerticalJoinLeft = hasMasonsJoin(
                joins,
                JoinOrientation.VERTICAL
            );

            const hasVerticalJoinRight = hasMasonsJoin(
                joins,
                JoinOrientation.VERTICAL,
                JoinSide.RIGHT
            );
            let length = benchLength;
            length -= hasVerticalJoinLeft ? dimensions[Side.G] : 0;
            length -= hasVerticalJoinRight ? dimensions[Side.C] : 0;

            if (length > material.max_length) {
                const buttJoins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        length,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize,
                        Shape.USHAPE,
                        hasVerticalJoinRight ? 0 : dimensions[Side.C]
                    ),
                    dimensions[Side.H] - dimensions[Side.F],
                    true,
                    hasVerticalJoinLeft,
                    dimensions[Side.G]
                );

                listenerApi.dispatch(buttJoinsSet(buttJoins, Side.A));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.A));
            }

            // updating the points for joins touching part B
            // just points needs to be updated not the joins
            const partBJoins = joins.filter(
                (join) =>
                    join.joinType == JoinType.BUTT_JOIN &&
                    join.benchSide == Side.B
            );

            // only need to do this if there are joins
            if (partBJoins.length > 0) {
                const joins = mapValuesToJoinObject(
                    partBJoins.map((join) => join.value),
                    dimensions[Side.C],
                    false,
                    false,
                    0,
                    dimensions[Side.G] + dimensions[Side.E]
                );

                listenerApi.dispatch(buttJoinsSet(joins, Side.B));
            }
        } else if (side == Side.B) {
            const hasHorizontalJoin = hasMasonsJoin(
                joins,
                JoinOrientation.HORIZONTAL,
                JoinSide.RIGHT
            );
            const length = hasHorizontalJoin
                ? benchLength - (benchLength - dimensions[Side.D])
                : benchLength;

            if (length > material.max_length) {
                const buttJoins = mapValuesToJoinObject(
                    calculateJoinPosition(
                        length,
                        material.max_length,
                        material.maxButtJoin,
                        minPartSize
                    ),
                    dimensions[Side.C],
                    false,
                    hasHorizontalJoin,
                    benchLength - dimensions[Side.D],
                    dimensions[Side.G] + dimensions[Side.E]
                );

                listenerApi.dispatch(buttJoinsSet(buttJoins, Side.B));
            } else {
                listenerApi.dispatch(buttJoinsSet([], Side.B));
            }
        } else if (side == Side.G) {
            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.H,
                joins,
                shape,
                dimensions,
            });
        } else if (side == Side.I) {
            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.A,
                joins,
                shape,
                dimensions,
            });

            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.A,
                joins,
                shape,
                dimensions,
            });
        } else if (side == Side.C) {
            updateButtJoinByAdjacentSide({
                listenerApi,
                benchSide: Side.B,
                joins,
                shape,
                dimensions,
            });
        }
    }
};

export const updateButtJoinByDimension = (
    action: PayloadAction<number, string, {index: Side}>,
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>
) => {
    const state = listenerApi.getState();

    const material = state.btm.material;
    const joins = state.btm.joins;
    const shape = state.btm.type;
    const dimensions = state.btm.dimension;
    const minPartSize = state.btm.minButtJoinPartSize;

    const side = action.meta.index;
    const benchLength = action.payload;

    if (material.allowButtJoin) {
        updateButtJoin({
            listenerApi,
            side,
            benchLength,
            joins,
            shape,
            dimensions,
            material,
            minPartSize,
        });
        listenerApi.dispatch(buttJoinPartsErrorClear());
    }
};

export const updateButtJoinByJoin = (
    action: PayloadAction<boolean, string, {index?: number; side?: JoinSide}>,
    listenerApi: ListenerEffectAPI<AppState, AppDispatch>
) => {
    const state = listenerApi.getState();

    const material = state.btm.material;
    const joins = state.btm.joins;
    const shape = state.btm.type;
    const dimensions = state.btm.dimension;

    if (material.allowButtJoin) {
        if (shape.type == Shape.ANG) {
            try {
                updateButtJoin({
                    listenerApi,
                    side: Side.A,
                    benchLength: dimensions[Side.A],
                    joins,
                    shape,
                    dimensions,
                    material,
                });
                listenerApi.dispatch(dimensionErrorSet('', Side.A));
            } catch (e) {
                if (e instanceof Error) {
                    listenerApi.dispatch(
                        buttJoinErrorSet({
                            message: e.message,
                            benchSide: Side.A,
                        })
                    );
                }
            }

            try {
                updateButtJoin({
                    listenerApi,
                    side: Side.F,
                    benchLength: dimensions[Side.F],
                    joins,
                    shape,
                    dimensions,
                    material,
                });
                listenerApi.dispatch(dimensionErrorSet('', Side.F));
            } catch (e) {
                if (e instanceof Error) {
                    listenerApi.dispatch(
                        buttJoinErrorSet({
                            message: e.message,
                            benchSide: Side.F,
                        })
                    );
                }
            }

            listenerApi.dispatch(buttJoinPartsErrorClear());
        } else if (shape.type == Shape.USHAPE) {
            try {
                updateButtJoin({
                    listenerApi,
                    side: Side.A,
                    benchLength: dimensions[Side.A],
                    joins,
                    shape,
                    dimensions,
                    material,
                });
                listenerApi.dispatch(dimensionErrorSet('', Side.A));
            } catch (e) {
                if (e instanceof Error) {
                    listenerApi.dispatch(
                        buttJoinErrorSet({
                            message: e.message,
                            benchSide: Side.A,
                        })
                    );
                }
            }

            if (action?.meta?.side == JoinSide.LEFT) {
                try {
                    updateButtJoin({
                        listenerApi,
                        side: Side.H,
                        benchLength: dimensions[Side.H],
                        joins,
                        shape,
                        dimensions,
                        material,
                    });
                    listenerApi.dispatch(dimensionErrorSet('', Side.H));
                } catch (e) {
                    if (e instanceof Error) {
                        listenerApi.dispatch(
                            buttJoinErrorSet({
                                message: e.message,
                                benchSide: Side.H,
                            })
                        );
                    }
                }
            } else if (action?.meta?.side == JoinSide.RIGHT) {
                try {
                    updateButtJoin({
                        listenerApi,
                        side: Side.B,
                        benchLength: dimensions[Side.B],
                        joins,
                        shape,
                        dimensions,
                        material,
                    });
                    listenerApi.dispatch(dimensionErrorSet('', Side.B));
                } catch (e) {
                    if (e instanceof Error) {
                        listenerApi.dispatch(
                            buttJoinErrorSet({
                                message: e.message,
                                benchSide: Side.B,
                            })
                        );
                    }
                }
            }
            listenerApi.dispatch(buttJoinPartsErrorClear());
        }
    }
};
