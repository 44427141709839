import React, {useMemo} from 'react';
import {useAppSelector} from 'store/customer';
import styled from 'styled-components';
import {selectPaths} from 'components/customer/BTM/store/btmSlice';
import {useFetchEdgeProfile} from 'components/customer/BTM/helper/useFetchEdgeProfile';
import {Edge} from 'components/customer/BTM/entity/Edge';
import symbols from 'components/admin/BenchtopModule/EdgeProfiles/EdgeProfileSymbol';
import {Icon} from 'shared/helpers';
import {getSorroundingIndexes} from 'components/customer/BTM/helper/checkCutoutRestriction';

interface LegendItemInterface {
    $color?: 'PRIMARY' | 'SECONDARY';
    $thin?: boolean;
}

const NOT_VISIBLE = 'Not Visible';

export const EdgeProfileLegends = ({fullscreen}: {fullscreen: boolean}) => {
    const paths = useAppSelector(selectPaths);
    const {edgeProfiles} = useFetchEdgeProfile();

    const legendItems = useMemo(() => {
        const items: {
            name: string;
            edge: Edge;
            symbol: string;
            thickness: boolean;
            profileId: number;
            key: number;
        }[] = [];

        if (paths && edgeProfiles) {
            paths.forEach((path, index) => {
                let name = NOT_VISIBLE;
                let symbol = '';
                let thickness = false;
                let profileId = 0;

                if (path.side == null) {
                    const indexes = getSorroundingIndexes(index, paths.length);
                    let restrict = false;

                    indexes.forEach((checkIndex) => {
                        if (restrict) return;
                        const checkPath = paths[Number(checkIndex)];
                        const pathProfile = edgeProfiles.find(
                            (profile) => profile.id == checkPath.profile
                        );

                        if (pathProfile?.is_end_only) {
                            restrict = true;
                        }
                    });

                    if (restrict) return;
                }

                if (path.edged != Edge.NOT_EDGED) {
                    const profile = edgeProfiles.find(
                        (profile) => profile.id == path.profile
                    );

                    if (profile) {
                        const deriveIcon = symbols.find(
                            (icon) => icon.id == profile.symbol
                        );
                        name = profile.name;
                        symbol = deriveIcon
                            ? `benchtop_profile_icons/${deriveIcon.id}.svg`
                            : ``;
                        thickness = profile.edge_highlight;
                        profileId = profile.id;
                    }
                }

                const item = items.find((item) => item.name == name);

                if (typeof item == 'undefined') {
                    items.push({
                        name,
                        edge: path.edged,
                        symbol,
                        thickness,
                        profileId,
                        key: index,
                    });
                }
            });
        }

        return items;
    }, [paths, edgeProfiles]);

    return (
        <EdgeProfileLegendsContaienr $fullscreen={fullscreen}>
            {legendItems.map((item) => {
                return item.symbol ? (
                    <LegendIcon key={item.key}>
                        <Icon className="iconClass" iconName={item.symbol} />
                        <span>{item.name}</span>
                    </LegendIcon>
                ) : (
                    <LegendItem
                        $color={item.profileId ? 'SECONDARY' : 'PRIMARY'}
                        $thin={item.profileId == 0 || !item.thickness}
                        key={item.key}>
                        {item.name}
                    </LegendItem>
                );
            })}
        </EdgeProfileLegendsContaienr>
    );
};

const EdgeProfileLegendsContaienr = styled.div<{$fullscreen: boolean}>`
    position: absolute;
    right: ${({$fullscreen}) => ($fullscreen ? '10px' : '-90px')};
    bottom: ${({$fullscreen}) => ($fullscreen ? '10px' : '0')};
`;

const LegendItem = styled.div<LegendItemInterface>`
    position: relative;
    padding-left: 40px;
    font-size: 0.9em;
    font-weight: 400;
    color: #616161;

    :before {
        position: absolute;
        content: '';
        width: 30px;
        height: ${({$thin = false}) => ($thin ? '1' : '8')}px;
        background: rgb(
            var(
                ${({$color = 'PRIMARY'}) =>
                    $color == 'PRIMARY'
                        ? '--primary_colour'
                        : '--secondary_colour'}
            )
        );
        left: 0;
        top: ${({$thin = false}) => ($thin ? '11' : '8')}px;
    }
`;

const LegendIcon = styled.div`
    display: flex;
    font-size: 0.9em;
    justify-content: start;

    .iconClass {
        max-width: 25px;
    }
`;
