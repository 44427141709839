import React, {useCallback} from 'react';
import {useAppDispatch} from 'store/customer';
import styled from 'styled-components';
import {selectedMenuSet} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {ImageEditor} from 'components/customer/AdvancedMaterials/CurrentSelections/ImageEditor';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {Door} from 'components/customer/Materials/entity';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';

interface CurrentSelectionsProps {
    door?: Door;
    material?: Material;
    edge?: Material;
    hasEdges?: boolean;
    hasDoors?: boolean;
}

export const CurrentSelections = ({
    material,
    door,
    edge,
    hasDoors,
    hasEdges,
}: CurrentSelectionsProps) => {
    const dispatch = useAppDispatch();

    const onClickDoor = useCallback(() => {
        dispatch(selectedMenuSet(Menu.DOOR_TYPE, true));
    }, []);

    const onClickMaterial = useCallback(() => {
        dispatch(selectedMenuSet(Menu.MATERIAL, true));
    }, []);

    const onClickEdge = useCallback(() => {
        dispatch(selectedMenuSet(Menu.EDGE_MATERIAL, true));
    }, []);

    return (
        <Container>
            <h1>Current {hasEdges ? 'Selections' : 'Selection'}</h1>
            <ImageContainer>
                {hasDoors ? (
                    <FullWidthFlex>
                        <ImageEditor
                            image={door ? door.image?.name : null}
                            text="Door: "
                            showLargePreview={true}
                            onClickEdit={hasDoors ? onClickDoor : null}
                        />
                    </FullWidthFlex>
                ) : null}
                <FullWidthFlex>
                    <div>
                        <ImageEditor
                            image={material ? material.image : null}
                            text="Material: "
                            onClickEdit={
                                hasDoors || hasEdges ? onClickMaterial : null
                            }
                            showLargePreview={!hasEdges}
                        />
                    </div>
                    {hasEdges ? (
                        <div>
                            <ImageEditor
                                image={edge ? edge.image : null}
                                text="Edge Material: "
                                onClickEdit={hasEdges ? onClickEdge : null}
                            />
                        </div>
                    ) : null}
                </FullWidthFlex>
            </ImageContainer>

            <Selection>
                {material.name.replace(/\(.*?\)/g, '')} {material.finish.name} -{' '}
                {material.thickness}mm {material.brand.name}{' '}
                {material.substrate}
            </Selection>
        </Container>
    );
};

const Container = styled.section`
    border: 2px solid rgb(var(--secondary_colour));
    border-radius: 15px;
    margin: 15px 15px 0;
    background: white;

    > h1 {
        color: white;
        background: rgb(var(--secondary_colour));
        margin: 0;
        border-radius: 11px;
        border-bottom-left-radius: 0;
        text-align: center;
        display: block;
        padding: 5px;
        font-size: 1.1em;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    background: white;
    padding: 5px 45px 10px;
    gap: 15px;
`;

const FullWidthFlex = styled.div`
    flex: 1;
`;

const Selection = styled.div`
    font-weight: bold;
    background: white;
    padding: 0 15px;
    font-size: 0.95em;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
`;
