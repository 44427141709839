import {useField} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 1.3em;
`;

const InputTextField = styled.input`
    width: 100%;
    height: 100%;
    border: 0;
`;

const InputTextArea = styled.textarea`
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 150px;
    z-index: 3;
`;

export const Note = ({
    name,
    isActive,
    setActive,
    index,
}: {
    name: string;
    index: number;
    isActive: boolean;
    setActive: () => void;
}) => {
    const [field, , {setValue: setFormikValue, setTouched}] = useField<string>({
        name,
        type: 'text',
    });
    const [show, setShow] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (field.value) {
            setValue(field.value);
        }
    }, [field.value]);

    const updateValue = useCallback(() => {
        if (field.value != value) {
            void setFormikValue(value, true);
            void setTouched(true);
        }
    }, [value]);

    const showEditor = useCallback(() => {
        setShow(true);
        setActive();
    }, [isActive, index]);
    const hideEditor = useCallback(() => setShow(false), []);

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setValue(event.currentTarget.value);
        },
        []
    );

    const focusHandler = useCallback(
        (event: React.FocusEvent<HTMLTextAreaElement>) => {
            event.currentTarget.setSelectionRange(
                event.currentTarget.value.length,
                event.currentTarget.value.length
            );
        },
        []
    );

    return (
        <td>
            <InputContainer onFocus={showEditor} onBlur={hideEditor}>
                {show ? (
                    <InputTextArea
                        value={value}
                        onChange={changeHandler}
                        onFocus={focusHandler}
                        onBlur={updateValue}
                        autoFocus
                    />
                ) : (
                    <InputTextField
                        readOnly={true}
                        value={value}
                        data-cy={`${name}_${index}`}
                    />
                )}
            </InputContainer>
        </td>
    );
};
