export enum Tab {
    MATERIAL,
    EDGE_PROFILE,
    JOINS,
    CORNERS,
    OVERVIEW,
}

export const TabPage = {
    [Tab.MATERIAL]: 'material',
    [Tab.EDGE_PROFILE]: 'edge-profile',
    [Tab.JOINS]: 'joins',
    [Tab.CORNERS]: 'corners',
    [Tab.OVERVIEW]: 'overview',
};

export const getMessage = (tab: Tab) => {
    switch (tab) {
        case Tab.MATERIAL:
            return 'Adjust measurement as required';
        case Tab.EDGE_PROFILE:
            return 'Use cursor to select edges you wish to be profiled';
        case Tab.JOINS:
            return 'Use cursor to select preferred joining position';
        case Tab.CORNERS:
            return 'Use cursor to select corners to alter';
    }
};

export const PageTab = {
    material: Tab.MATERIAL,
    'edge-profile': Tab.EDGE_PROFILE,
    joins: Tab.JOINS,
    corners: Tab.CORNERS,
    overview: Tab.OVERVIEW,
};
