import React from 'react';
import {useAppSelector} from 'store/customer';
import {
    selectCorners,
    selectScale,
    selectCenter,
    selectDimension,
    selectTopOffset,
} from 'components/customer/BTM/store/btmSlice';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {getOrigin} from 'components/customer/BTM/helper/getOrigin';
import {shallowEqual} from 'react-redux';
import {Corner} from 'components/customer/BTM/Corners/Corner';

export const CornersToggle = () => {
    const corners = useAppSelector(selectCorners, shallowEqual);
    const scale = useAppSelector(selectScale);
    const center = useAppSelector(selectCenter);
    const dimension = useAppSelector(selectDimension);
    const topOffset = useAppSelector(selectTopOffset);

    if (corners) {
        return (
            <>
                {corners.map((corner, index) => {
                    const [oX, oY] = getOrigin(dimension, center, scale);
                    let x = Number(corner.point.x) * scale + oX;
                    let y = Number(corner.point.y) * scale + oY + topOffset;

                    if (corner.direction == Direction.RIGHT_DOWN) {
                        y -= 27;
                        x -= 25;
                    } else if (corner.direction == Direction.LEFT_DOWN) {
                        y -= 27;
                        x += 5;
                    } else if (corner.direction == Direction.RIGHT_UP) {
                        x -= 27;
                    } else {
                        x += 5;
                    }

                    if (corner.isJoin)
                        return <React.Fragment key={corner.name} />;

                    return (
                        <Corner
                            key={corner.name}
                            x={y}
                            y={x}
                            corner={corner}
                            index={index}
                        />
                    );
                })}
            </>
        );
    }

    return null;
};
