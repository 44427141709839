import React, {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectDoor,
    selectEdge,
    selectHasDoors,
    selectLoadingMatchingEdge,
    selectLoadingNewColour,
    selectMaterial,
    selectedMenuSet,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {
    Title,
    TitleContainer,
} from 'components/customer/AdvancedMaterials/Doors/MaterialType';
import styled from 'styled-components';
import {
    Card,
    DoorImage,
    Name,
} from 'components/customer/AdvancedMaterials/Doors/Door';
import {
    ImageContainer,
    MaterialImage,
    SubContainer,
    Name as MaterialName,
} from 'components/customer/AdvancedMaterials/Materials/Material';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {Icon} from 'shared/helpers';
import {
    Material as OGMaterial,
    MaterialEdge,
} from 'components/customer/Materials/entity';
import {NewSelectionPopover} from 'components/customer/AdvancedMaterials/CurrentSelections/NewSelectionPopover';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import {useSelectedMenu} from 'components/customer/AdvancedMaterials/helpers/useSelectedMenu';

export const MaterialAndDoorSelection = () => {
    const dispatch = useAppDispatch();
    const material = useAppSelector(
        selectMaterial,
        shallowEqual
    ) as Material<OGMaterial>;
    const door = useAppSelector(selectDoor, shallowEqual);
    const edge = useAppSelector(
        selectEdge,
        shallowEqual
    ) as Material<MaterialEdge>;

    const hasDoor = useAppSelector(selectHasDoors);
    const loadingNewColour = useAppSelector(selectLoadingNewColour);
    const loadingMatchingEdge = useAppSelector(selectLoadingMatchingEdge);
    const {selectedMenu} = useSelectedMenu();

    const onClickDoor = useCallback(() => {
        dispatch(selectedMenuSet(Menu.DOOR_TYPE, true));
    }, []);

    const onClickMaterial = useCallback(() => {
        dispatch(selectedMenuSet(Menu.MATERIAL, true));
    }, []);

    const onClickEdge = useCallback(() => {
        dispatch(selectedMenuSet(Menu.EDGE_MATERIAL, true));
    }, []);

    return (
        <>
            <LocalTitleContainer>
                <Title>New Selections</Title>
            </LocalTitleContainer>
            <Container>
                {hasDoor ? (
                    <LocalCard
                        onClick={onClickDoor}
                        $hover={true}
                        $active={selectedMenu == Menu.DOOR_TYPE}
                        $variant="primary">
                        <Label $selected={selectedMenu == Menu.DOOR_TYPE}>
                            <span>Door</span>
                            <NewSelectionPopover
                                wide={false}
                                image={door?.image?.name}
                                title="Door"
                                description={door?.name}>
                                <Icon iconName="zoom.svg" title="Zoom in" />
                            </NewSelectionPopover>
                        </Label>
                        <LocalDoorImage
                            src={door?.image?.name}
                            potrait={true}
                        />
                        <Name>{door?.name}</Name>
                    </LocalCard>
                ) : null}
                <LocalSubContainer
                    onClick={onClickMaterial}
                    $selected={selectedMenu == Menu.MATERIAL}
                    $dropdownOpen={false}
                    $previewOnly={true}>
                    <Label $selected={selectedMenu == Menu.MATERIAL}>
                        <span>Material</span>
                        <NewSelectionPopover
                            image={material.image}
                            title="Material"
                            description={material.data.displayName}>
                            <Icon iconName="zoom.svg" title="Zoom in" />
                        </NewSelectionPopover>
                    </Label>
                    <ImageContainer>
                        <MaterialImage src={material.image} />
                    </ImageContainer>
                    <MaterialName>
                        <span>
                            <strong>{material.thickness}mm</strong>{' '}
                            {parseHtmlString(material.name)}{' '}
                            <em>{material.finish.name}</em>
                        </span>
                    </MaterialName>
                    {loadingNewColour ? (
                        <LocalIcon iconName="tail-spin.svg" />
                    ) : null}
                </LocalSubContainer>
                <LocalSubContainer
                    onClick={onClickEdge}
                    $selected={selectedMenu == Menu.EDGE_MATERIAL}
                    $dropdownOpen={false}
                    $previewOnly={true}>
                    <Label $selected={selectedMenu == Menu.EDGE_MATERIAL}>
                        <span>Edge</span>
                        <NewSelectionPopover
                            image={edge.image}
                            title="Edge"
                            description={edge.data.displayName}>
                            <Icon iconName="zoom.svg" title="Zoom in" />
                        </NewSelectionPopover>
                    </Label>
                    <ImageContainer>
                        <MaterialImage src={edge.image} />
                    </ImageContainer>
                    <MaterialName>
                        <span>
                            {edge.thickness > 0 ? (
                                <strong>{edge.thickness}mm </strong>
                            ) : null}
                            {parseHtmlString(edge.name)}{' '}
                            <em>{edge.finish.name}</em>
                        </span>
                    </MaterialName>
                    {loadingMatchingEdge ? (
                        <LocalIcon iconName="tail-spin.svg" />
                    ) : null}
                </LocalSubContainer>
            </Container>
        </>
    );
};

const LocalIcon = styled((props) => <Icon {...props} />)`
    width: 25px !important;
    position: absolute;
    bottom: 3px;
    right: 5px;
`;

const LocalCard = styled(Card)`
    padding: 5px;
`;

const LocalDoorImage = styled(DoorImage)`
    width: 80px !important;
    height: 98px;
`;

const LocalTitleContainer = styled(TitleContainer)`
    margin: 0;
`;

const Container = styled.section`
    display: flex;
    margin: 5px 0;
    gap: 5px;
`;

const LocalSubContainer = styled(SubContainer)`
    position: relative;
    max-width: 210px;

    > div > img {
        max-width: 100% !important;
        margin-left: 0;
    }
`;

const Label = styled.div<{$selected: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 10px;
    color: white;
    font-weight: bold;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgb(var(--secondary_colour));
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    > div > img {
        width: 25px;
    }

    ${({$selected}) =>
        $selected &&
        `
        background: rgb(var(--primary_colour));
    `}
`;
