import React from 'react';
import {Button} from 'react-bootstrap';
import {useUpdatesPopup} from 'hooks';
import styled from 'styled-components';
import {isEmpty} from 'lodash';

export const UpdateCenter = () => {
    const {messages, contact, dialog, showUpdatesInfo, showContactsInfo} =
        useUpdatesPopup();

    return (
        <UpdateCenterContainer>
            <div>
                <MainSection>
                    <Title $extraTopPadding className="title">
                        Manufacturer Contact
                    </Title>
                    <Content className="content" $noBorderRadius>
                        <ContactInfo>{contact}</ContactInfo>
                        <ButtonWrapper>
                            <ViewMoreButton
                                variant="link"
                                onClick={showContactsInfo}
                                title="View more contact info">
                                View More
                            </ViewMoreButton>
                        </ButtonWrapper>
                    </Content>
                </MainSection>
            </div>
            <div>
                <MainSection>
                    <Title className="title" $noBorderRadius>
                        Manufacturer Updates
                    </Title>
                    <Content className="content">
                        {!isEmpty(messages) ? (
                            <>
                                <Updates>{messages}</Updates>
                                <ButtonWrapper>
                                    <ViewMoreButton
                                        variant="link"
                                        onClick={showUpdatesInfo}
                                        title="View more news">
                                        View More
                                    </ViewMoreButton>
                                </ButtonWrapper>
                            </>
                        ) : (
                            <PlaceholderWrapper>
                                <Placeholder>No updates available!</Placeholder>
                            </PlaceholderWrapper>
                        )}
                    </Content>
                </MainSection>
            </div>
            {dialog}
        </UpdateCenterContainer>
    );
};

const UpdateCenterContainer = styled.div<{$isMobile?: boolean}>`
    ${({$isMobile}) => ($isMobile ? 'margin: 0 8px !important;' : '')}
`;

const MainSection = styled.section`
    font-size: 0.7rem;
    background: #fff;
`;

const Title = styled.div<{
    $noBorderRadius?: boolean;
    $extraTopPadding?: boolean;
}>`
    color: #fff;
    padding: 7px 22px !important;
    ${({$extraTopPadding}) =>
        $extraTopPadding ? 'padding-top: 10px !important;' : ''}
    box-sizing: border-box;
    font-weight: 700;
    font-size: 12px !important;
    ${({$noBorderRadius}) =>
        $noBorderRadius ? 'border-radius: 0 !important;' : ''}
`;

const Content = styled.div<{$noBorderRadius?: boolean}>`
    border: 2px solid;
    ${({$noBorderRadius}) =>
        $noBorderRadius ? 'border-radius: 0 !important;' : ''}
`;

const ContactInfo = styled.ul`
    margin: 5px 0;
    line-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 60px;
    padding: 10px 12px;
`;

const ButtonWrapper = styled.div`
    text-align: center;
    font-style: oblique;
`;

const ViewMoreButton = styled(Button)`
    font-size: 0.8rem;
    font-weight: 500;
`;

const Updates = styled.ul`
    margin: 5px 0;
    line-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 0.5rem;
    max-height: 60px;
    padding: 10px 12px;
`;

const PlaceholderWrapper = styled.div`
    padding: 20px 12px;
`;

const Placeholder = styled.p`
    margin: 0;
    text-align: center;
    color: #bdbdbd;
    font-weight: 500;
`;
