import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {Shape} from 'components/customer/BTM/entity/Shape';

/**
 * Returns the mid coordinate of a line
 *
 * @param {Point} pointA first coordinate of a line
 * @param {Point} pointB second coordinate of a line
 * @return {Point}
 */
export const getMiddlePoint = (pointA: Point, pointB: Point) => {
    const midX = (Number(pointA.x) + Number(pointB.x)) / 2;
    const midY = (Number(pointA.y) + Number(pointB.y)) / 2;

    return {x: midX, y: midY} as Point;
};

/**
 * Translates the point to scaled point
 *
 * NOTE: Join label is only visible for U shape bench, so shape
 * information here is redundant, work on removing that.
 *
 * @param {Point} point Point around where join label should be positioned
 * @param {number} scale current Scale for the bench preview see btmSlice
 * @param {number[]} center current center of the bench preview
 * @param {number[]} dimension dimension of the benchtop
 * @param {Shape} shape Shape of the bench
 * @return {Point}
 */
export const translatePointToCanvas = (
    point: Point,
    scale: number,
    center: number[],
    dimension: number[],
    shape: BenchtopType
) => {
    const [x, y] = center;
    let width;
    let height;
    if (shape.type == Shape.ANG) {
        const [A, , , , , F] = dimension;
        width = A;
        height = F;
    } else if (shape.type == Shape.USHAPE) {
        const [A, B, , , , , , H] = dimension;
        width = A;
        height = H > B ? H : B;
    }

    return {
        x: x - (width / 2) * scale + Number(point.x) * scale + 15,
        y: y - (height / 2) * scale + Number(point.y) * scale + 28,
    } as Point;
};

/**
 * Returns the join label text and position of that join as coordinate
 *
 * @typedef {Object} JoinLabelPosition
 * @property {Point} midPoint
 * @property {string} text
 *
 * @param {number} scale current Scale for the bench preview see btmSlice
 * @param {number[]} center current center of the bench preview
 * @param {number[]} dimension dimension of the benchtop
 * @param {Shape} shape Shape of the bench
 *
 * @return {JoinLabelPosition}
 */
export const getJoinLabelPosition =
    (
        scale: number,
        center: number[],
        dimension: number[],
        shape: BenchtopType
    ) =>
    (join: Join) => {
        const pointA = join.points[0];
        const pointB = join.points[join.points.length - 1];

        let midPoint = getMiddlePoint(pointA, pointB);
        midPoint = translatePointToCanvas(
            midPoint,
            scale,
            center,
            dimension,
            shape
        );

        if (
            join.joinType == JoinType.MASONS_MITRE &&
            join.orientation == JoinOrientation.HORIZONTAL
        ) {
            midPoint.x = Number(midPoint.x) - 40;
            midPoint.y = Number(midPoint.y) + 40;
        }

        return {
            position: midPoint,
            text:
                join.joinType == JoinType.BUTT_JOIN
                    ? ''
                    : `Join #${join.side + 1}`,
        };
    };
