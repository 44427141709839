import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    edgeProfileSet,
    joinSet,
    profiledSet,
    selectDimension,
    selectEdgeProfile,
    selectJoins,
    selectMaterial,
    selectPaths,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {useCallback, useEffect} from 'react';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {shallowEqual} from 'react-redux';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Side} from 'components/customer/BTM/entity/Side';
import {getPathBySide} from 'components/customer/BTM/helper/setDefaultProfiles';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {findIndex} from 'lodash';
import {FormFactorIdentifier} from 'components/customer/BTM/entity/BenchtopFormFactor';
import {useFetchEdgeProfile} from 'components/customer/BTM/helper/useFetchEdgeProfile';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {Path} from 'components/customer/BTM/entity/Path';

const checkMaterialIsDoubleProfiled = (material: BenchtopMaterial) => {
    if (material.formfactors) {
        return material.formfactors.some(
            (formfactor) => formfactor.identifier == FormFactorIdentifier.DP
        );
    }

    return false;
};

interface SetProfiledStateFromShapesProps {
    dispatch: ReturnType<typeof useAppDispatch>;
    shape: BenchtopType;
    dimensions: number[];
    material: BenchtopMaterial;
    paths: Path[];
    edgeProfile: BenchtopEdgeProfile;
    joins: Join[];
}

export const setProfiledStateFromShapes = ({
    dispatch,
    shape,
    dimensions,
    material,
    paths,
    edgeProfile,
    joins,
}: SetProfiledStateFromShapesProps) => {
    if (shape.type == Shape.SQR) {
        const [, depth] = dimensions;

        // only profile side A if depth is same as material max depth
        const profiled =
            depth == material.max_depth &&
            checkMaterialIsDoubleProfiled(material);
        const pathA = getPathBySide(Side.A, paths);
        dispatch(
            profiledSet(
                profiled ? Edge.PROFILED : Edge.NOT_EDGED,
                profiled ? edgeProfile : null,
                pathA.index,
                Side.A
            )
        );

        const pathC = getPathBySide(Side.C, paths);
        dispatch(profiledSet(Edge.PROFILED, edgeProfile, pathC.index, Side.C));
    } else if (shape.type == Shape.ANG) {
        const [, depthA, , , depthF] = dimensions;

        const sides = [
            {
                side: Side.A,
                depth: depthA,
                joinOrientation: JoinOrientation.VERTICAL,
            },
            {
                side: Side.F,
                depth: depthF,
                joinOrientation: JoinOrientation.HORIZONTAL,
            },
        ];

        const isDoubleProfile = checkMaterialIsDoubleProfiled(material);
        sides.forEach(({side, depth, joinOrientation}) => {
            const profiled = depth == material.max_depth && isDoubleProfile;
            const path = getPathBySide(side, paths);

            if (profiled) {
                dispatch(
                    profiledSet(Edge.PROFILED, edgeProfile, path.index, side)
                );

                const join = joins.find(
                    (join) =>
                        join.side == JoinSide.LEFT &&
                        join.orientation == joinOrientation
                );

                if (join?.selected && profiled) {
                    // select a different join as this side is profiled
                    const index = findIndex(
                        joins,
                        (join) =>
                            join.joinType == JoinType.FULL_MITRE ||
                            join.joinType == JoinType.DOG_LEG_MITRE
                    );
                    dispatch(joinSet(true, index, JoinSide.LEFT));
                }
            } else {
                dispatch(profiledSet(Edge.NOT_EDGED, null, path.index, side));
            }
        });

        [Side.C, Side.D].forEach((side) => {
            const path = getPathBySide(side, paths);
            dispatch(profiledSet(Edge.PROFILED, edgeProfile, path.index, side));
        });
    } else if (shape.type == Shape.USHAPE) {
        const [, height, depthC, innerHeight, , , depthG] = dimensions;
        const depthI = height - innerHeight;
        const sides = [
            {
                side: Side.A,
                depth: depthI,
                joinSides: [JoinSide.RIGHT, JoinSide.LEFT],
                joinOrientation: JoinOrientation.VERTICAL,
            },
            {
                side: Side.B,
                depth: depthC,
                joinSides: [JoinSide.RIGHT],
                joinOrientation: JoinOrientation.HORIZONTAL,
            },
            {
                side: Side.H,
                depth: depthG,
                joinSides: [JoinSide.LEFT],
                joinOrientation: JoinOrientation.HORIZONTAL,
            },
        ];

        const isDoubleProfile = checkMaterialIsDoubleProfiled(material);
        sides.forEach(({side, depth, joinSides, joinOrientation}) => {
            const profiled = depth == material.max_depth && isDoubleProfile;
            const path = getPathBySide(side, paths);

            if (profiled) {
                dispatch(
                    profiledSet(Edge.PROFILED, edgeProfile, path.index, side)
                );

                joinSides.map((side) => {
                    const join = joins.find(
                        (join) =>
                            join.side == side &&
                            join.orientation == joinOrientation
                    );

                    if (join?.selected && profiled) {
                        // select a different join as this side is profiled
                        const index = findIndex(
                            joins,
                            (join) =>
                                join.side == side &&
                                (join.joinType == JoinType.FULL_MITRE ||
                                    join.joinType == JoinType.DOG_LEG_MITRE)
                        );

                        dispatch(joinSet(true, index, side));
                    }
                });
            } else {
                dispatch(profiledSet(Edge.NOT_EDGED, null, path.index, side));
            }
        });

        [Side.D, Side.E, Side.F].forEach((side) => {
            const path = getPathBySide(side, paths);
            dispatch(profiledSet(Edge.PROFILED, edgeProfile, path.index, side));
        });
    }
};

export const useBlank = () => {
    const dispatch = useAppDispatch();
    const shape = useAppSelector(selectType, shallowEqual);
    const material = useAppSelector(selectMaterial, shallowEqual);
    const dimensions = useAppSelector(selectDimension, shallowEqual);
    const edgeProfile = useAppSelector(selectEdgeProfile, shallowEqual);
    const paths = useAppSelector(selectPaths, shallowEqual);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const {edgeProfiles} = useFetchEdgeProfile();

    const handleEdgeProfile = useCallback(
        (
            material: BenchtopMaterial,
            dimensions: number[],
            edgeProfile: BenchtopEdgeProfile
        ) => {
            setProfiledStateFromShapes({
                dispatch,
                shape,
                dimensions,
                material,
                paths,
                edgeProfile,
                joins,
            });
        },
        [shape, paths, joins]
    );

    useEffect(() => {
        // NOTE: if the material is changed and the new material is a blank
        // need to set the selected profile to be the postformed profile.
        if (material && material.is_blank && edgeProfiles) {
            const postformedProfile = edgeProfiles.find(
                (profile) => profile.is_postformed_profile
            );

            if (postformedProfile) {
                dispatch(edgeProfileSet(postformedProfile));
            }
        }
    }, [material, edgeProfiles]);

    useEffect(() => {
        if (
            material &&
            material.is_blank &&
            edgeProfile &&
            edgeProfile.is_postformed_profile
        ) {
            handleEdgeProfile(material, dimensions, edgeProfile);
        }
    }, [material, edgeProfile]);
};
