import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Path} from 'components/customer/BTM/entity/Path';
import {fetchEdgeProfilesManually} from 'components/customer/BTM/helper/editHelpers';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {groupBy} from 'lodash';

interface SelectedEdgeProfile {
    edges: BenchtopEdgeProfile[];
    profile: BenchtopEdgeProfile;
}

/**
 * This functions identifies the selected benchtop edge profile
 * to mark it as selected on the selector intertface.
 *
 * @param {BenchtopEdgeProfile} material Material selected for the benchtop
 * @param {Path[]} paths Paths of said bench, see btmSlice
 * @return {Promise<SelectedEdgeProfile>}
 */
export const getSelectedEdgeProfile = async (
    material: BenchtopMaterial,
    paths: Path[]
): Promise<SelectedEdgeProfile> => {
    let profile: BenchtopEdgeProfile;
    let edges: BenchtopEdgeProfile[] = [];

    if (material) {
        edges = await fetchEdgeProfilesManually(material.id);

        // using for loop cause it is breakable
        for (const path of paths) {
            if (path.edged == Edge.PROFILED) {
                profile = edges.find((edge) => edge.id == path.profile);

                // Breaking as there cannot be more than one
                // kind of postformed profile applied to benchtop.
                // If this is not in available list of profiles.
                // chances are this is deleted or hidden by the
                // manufacturer
                break;
            }
        }

        // If none of the profiles are postformed
        if (typeof profile == 'undefined') {
            const profileGroups = groupBy(paths, 'profile');

            let previousCount = 0;
            for (const key in profileGroups) {
                if (key != 'undefined' && key != '0' && key != 'null') {
                    const count = profileGroups[String(key)].length;
                    if (count > previousCount) {
                        previousCount = count;
                        profile = edges.find((edge) => edge.id == Number(key));
                    }
                }
            }
        }
    }

    return {
        edges,
        profile,
    };
};
