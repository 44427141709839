import React from 'react';
import {shallowEqual} from 'react-redux';
import {useAppSelector} from 'store/customer';
import styled from 'styled-components';
import {selectMaterial} from 'components/customer/BTM/store/btmSlice';
import {Styled} from 'shared/types/Styled';
import {Card, Title, Body} from 'shared/components/Card';
import {Icon} from 'shared/helpers';
import {parseHtmlString} from 'shared/helpers/HTMLParser';

export const MaterialInformation = ({className}: Styled) => {
    const selectedMaterial = useAppSelector(selectMaterial, shallowEqual);

    if (typeof selectedMaterial == 'undefined') return null;

    return (
        <MaterialInformationContainer className={className}>
            <Card>
                <Title>
                    <Icon iconName="Material.svg" />
                    <span>Current Material Selection</span>
                </Title>
                <Body>
                    <Bold>Type: </Bold> {selectedMaterial.type.name}
                    <br />
                    <Bold>Material: </Bold>{' '}
                    {selectedMaterial
                        ? parseHtmlString(selectedMaterial.displayName, {
                              removeStrongTag: true,
                          })
                        : null}
                </Body>
            </Card>
        </MaterialInformationContainer>
    );
};

const Bold = styled.strong`
    color: rgb(var(--primary_colour));
`;

const MaterialInformationContainer = styled.div`
    padding-left: 15px;
    width: 100%;
    margin-top: 10px;
`;
