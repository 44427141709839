import {useJobContext} from 'contexts';
import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CBCButton, formatPrice} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {VariationStateProps} from 'components/customer/JobDashboard/JobVariations';
import {useLazyGetExtraVariationsQuery} from 'components/customer/Job/store/jobApi';
import JobVariationFreightCost from 'components/customer/JobDashboard/JobVariationFreightCost';

export const Table = styled.table<{width?: string}>`
    width: 100%;
    tr > td:first-child {
        width: 26%;
    }
    tr > td:nth-child(2) {
        width: ${(props) => (props.width ? props.width : '50%')};
    }
    tr > td:last-child {
        width: 10%;
    }
    tr:nth-child(odd) {
        background: rgb(var(--table_row_primary));
    }
    tr:nth-child(even) {
        background: rgb(var(--table_row_secondary));
    }
    tr:last-child > td:first-child {
        border-bottom-left-radius: 12px;
    }
    tr:last-child > td:last-child {
        border-bottom-right-radius: 12px;
    }
    tr td:empty:first-child {
        background: white;
    }
`;

export const LeftTh = styled.th`
    text-align: center;
    background: rgb(var(--secondary_colour));
    padding: 4px 0;
    color: white;
    border: 1px solid white;
    border-bottom: 0;

    &:first-child {
        border-top-left-radius: 12px;
    }

    &:not(:first-child) {
        border-top-left-radius: 0px;
    }

    &:last-child {
        border-top-right-radius: 0px;
    }
`;

export const RightTh = styled.th`
    text-align: center;
    background: rgb(var(--primary_colour));
    padding: 5px 0;
    border-top-right-radius: 12px;
    color: white;
    border: 1px solid white;
    border-bottom: 0;
    width: 10%;
`;

export const TD = styled.td`
    padding: 4px 50px;
    border-bottom: 1px solid white;
    @media (max-width: 1120px) {
        padding: 4px 30px;
    }
    @media (max-width: 770px) {
        padding: 4px 8px;
    }
`;

export const TDLessPadding = styled(TD)`
    padding: 4px 5px;
    text-align: center;
`;

export const CustomButton = styled((props) => <CBCButton {...props} />)`
    border: none !important;
    background: none !important;
    font-weight: 400;
    font-size: smaller;

    &:focus {
        box-shadow: none;
    }
`;

export const TableRow = styled.tr<{isRemoved?: boolean}>`
    ${(props) =>
        props.isRemoved &&
        `
        td:not(:last-child):not(:empty) {
            opacity: 0.2;
        }
    `}
`;

const ContentContainer = styled.div.attrs((props) => ({
    title: props.title || '',
}))`
    margin-left: 14px;
`;

const FlexedDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

export const Label = styled.label`
    display: block;
    font-weight: 300;
    font-style: italic;
`;

export const LabelBold = styled.label`
    display: block;
    font-weight: bold;
`;

export const BodyContainer = styled.div<{width?: string}>`
    width: ${(props) => props.width || '100%'};
    overflow-y: auto;
    max-height: 435px;
`;

export const TableContainer = styled.div`
    width: 100%;
    margin-bottom: 18px;
`;

export const TextContainer = styled.div<{align?: string}>`
    text-align: ${(props) => props.align || 'center'};
    color: #000000;
    font-weight: 600;
    font-size: 17px;
    margin: 10px 26px 5px;
    @media (max-width: 700px) {
        font-size: 12px;
    }
`;

export const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const NameTextContainer = styled.div.attrs((props) => ({
    title: props.title || '',
}))`
    width: 155px;
    @media (max-width: 1120px) {
        width: 75px;
    }
`;

const VARIATION_TYPE = {
    CABINETS: 1,
    BENCHTOP: 2,
    EXTRA_VARIATION: 3,
};

const JobPendingVariation: React.FC<VariationStateProps> = ({
    removedBenchIds,
    setRemovedBenchIds,
    removedJobCabinetIds,
    setRemovedJobCabinetIds,
    removedExtraVariationIds,
    setRemovedExtraVariationIds,
    totalVariationCost,
    setTotalVariationCost,
}) => {
    const {job} = useJobContext() as PartialJob;
    const benches = useFetchBenchesData();
    const [getExtraVariations, {data: extraVariationList}] =
        useLazyGetExtraVariationsQuery();
    const [totalExtraVariationCost, setTotalExtraVariationCost] =
        useState<number>();

    useEffect(() => {
        if (job && job.id) {
            void getExtraVariations({jobId: job.id});
        }
    }, [job]);

    const handleRemove = (id: number, type: number) => {
        let updatedTotal = totalVariationCost;
        if (VARIATION_TYPE.BENCHTOP == type) {
            setRemovedBenchIds((prevIds) => [...prevIds, id]);
        } else if (VARIATION_TYPE.CABINETS == type) {
            setRemovedJobCabinetIds((prevIds) => [...prevIds, id]);
        } else if (VARIATION_TYPE.EXTRA_VARIATION == type) {
            setRemovedExtraVariationIds((prevIds) => [...prevIds, id]);
        }

        updatedTotal = Number(updatedTotal.toFixed(2));
        setTotalVariationCost(updatedTotal);
    };

    const handleUndo = (id: number, type: number) => {
        if (VARIATION_TYPE.BENCHTOP == type) {
            setRemovedBenchIds((prevItems) =>
                prevItems.filter((item) => item !== id)
            );
        } else if (VARIATION_TYPE.CABINETS == type) {
            setRemovedJobCabinetIds((prevItems) =>
                prevItems.filter((item) => item !== id)
            );
        } else if (VARIATION_TYPE.EXTRA_VARIATION == type) {
            setRemovedExtraVariationIds((prevItems) =>
                prevItems.filter((item) => item !== id)
            );
        }
    };

    const handleRemoveCabinet = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleRemove(id, VARIATION_TYPE.CABINETS);
        },
        []
    );

    const handleRemoveBenchTop = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleRemove(id, VARIATION_TYPE.BENCHTOP);
        },
        []
    );

    const handleRemoveExtraVariations = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleRemove(id, VARIATION_TYPE.EXTRA_VARIATION);
        },
        [extraVariationList]
    );

    const handleUndoCabinet = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleUndo(id, VARIATION_TYPE.CABINETS);
        },
        []
    );

    const handleUndoBenchTop = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleUndo(id, VARIATION_TYPE.BENCHTOP);
        },
        []
    );

    const handleUndoExtraVariation = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleUndo(id, VARIATION_TYPE.EXTRA_VARIATION);
        },
        [extraVariationList]
    );

    useEffect(() => {
        if (job) {
            let totalVariationCost = 0;
            job.rooms?.forEach((room) => {
                room.jobCabinets?.forEach((item) => {
                    if (
                        item.variationCost > 0 &&
                        !removedJobCabinetIds.includes(item.id)
                    ) {
                        totalVariationCost += parseFloat(
                            item.variationCost.toFixed(2)
                        );
                    }
                });
            });

            // benchtop total variations cost
            benches.forEach((bench) => {
                if (
                    !bench.isOld &&
                    bench.variationCost > 0 &&
                    !removedBenchIds.includes(bench.id)
                ) {
                    totalVariationCost += parseFloat(
                        bench.variationCost.toFixed(2)
                    );
                }
            });

            // extraVariation total variations cost
            let extraTotalVariationCost = 0;
            extraVariationList?.forEach((extra) => {
                if (
                    extra.cost > 0 &&
                    !removedExtraVariationIds.includes(extra.id)
                ) {
                    extraTotalVariationCost += extra.cost;
                }
            });

            setTotalExtraVariationCost(extraTotalVariationCost);
            setTotalVariationCost(totalVariationCost);
        }
    }, [
        job,
        removedBenchIds,
        removedJobCabinetIds,
        removedExtraVariationIds,
        benches,
        extraVariationList,
    ]);

    return (
        <>
            <TextContainer>
                The following cost have been applied and require confirmation
            </TextContainer>
            <BodyContainer>
                <TableContainer key={1}>
                    <Table>
                        <thead>
                            <tr>
                                <LeftTh>Product</LeftTh>
                                <LeftTh>Requested Variation</LeftTh>
                                <LeftTh>Cost</LeftTh>
                                <RightTh>-</RightTh>
                            </tr>
                        </thead>
                        <tbody>
                            {job?.rooms?.flatMap((room, roomIndex) =>
                                room.jobCabinets
                                    ?.filter(
                                        (item) =>
                                            item.variationCost > 0 ||
                                            item.comment
                                    )
                                    .map((item, itemIndex) => {
                                        const isRemoved =
                                            removedJobCabinetIds.includes(
                                                item.id
                                            );

                                        const key = `${roomIndex}-${itemIndex}`;
                                        return (
                                            <TableRow
                                                key={key}
                                                isRemoved={isRemoved}>
                                                <TD>
                                                    <NameContainer>
                                                        <NameTextContainer>
                                                            {`${
                                                                roomIndex + 1
                                                            }-${
                                                                item.roomCabNumber
                                                            }`}{' '}
                                                            {item.typeName}
                                                        </NameTextContainer>
                                                    </NameContainer>
                                                </TD>
                                                <TD>
                                                    <FlexedDiv>
                                                        <ContentContainer>
                                                            <Label>
                                                                {item.comment}
                                                            </Label>
                                                        </ContentContainer>
                                                    </FlexedDiv>
                                                </TD>
                                                <TD>
                                                    {formatPrice(
                                                        item.variationCost,
                                                        {}
                                                    )}
                                                </TD>
                                                <TD>
                                                    {!isRemoved && (
                                                        <CustomButton
                                                            type="button"
                                                            iconName="Options-Delete.svg"
                                                            className="job-button button-light"
                                                            onClick={
                                                                handleRemoveCabinet
                                                            }
                                                            value={item.id}>
                                                            Delete
                                                        </CustomButton>
                                                    )}
                                                    {isRemoved ? (
                                                        <CustomButton
                                                            type="button"
                                                            iconName="Button-Rotate-Anti-Clockwise.svg"
                                                            className="job-button button-light"
                                                            onClick={
                                                                handleUndoCabinet
                                                            }
                                                            value={item.id}>
                                                            Undo
                                                        </CustomButton>
                                                    ) : null}
                                                </TD>
                                            </TableRow>
                                        );
                                    })
                            )}
                            {/* benchtops */}
                            {benches
                                .filter(
                                    (bench) =>
                                        !bench.isOld &&
                                        (bench.variationCost > 0 ||
                                            bench.comment)
                                )
                                .map((item, itemIndex) => {
                                    const isRemoved = removedBenchIds.includes(
                                        item.id
                                    );

                                    const key = `${itemIndex}-${item.jobBenchtopNumber}`;
                                    return (
                                        <TableRow
                                            key={key}
                                            isRemoved={isRemoved}>
                                            <TD>
                                                <NameContainer>
                                                    <NameTextContainer
                                                        title={item.comment}>
                                                        {item.jobBenchtopNumber}{' '}
                                                        {item.name}
                                                    </NameTextContainer>
                                                </NameContainer>
                                            </TD>
                                            <TD>
                                                <FlexedDiv>
                                                    <ContentContainer>
                                                        <Label>
                                                            {item.comment}
                                                        </Label>
                                                    </ContentContainer>
                                                </FlexedDiv>
                                            </TD>
                                            <TD>
                                                {formatPrice(
                                                    item.variationCost,
                                                    {}
                                                )}
                                            </TD>
                                            <TD>
                                                {!isRemoved && (
                                                    <CustomButton
                                                        type="button"
                                                        iconName="Options-Delete.svg"
                                                        className="job-button button-light"
                                                        onClick={
                                                            handleRemoveBenchTop
                                                        }
                                                        value={item.id}>
                                                        Delete
                                                    </CustomButton>
                                                )}
                                                {isRemoved ? (
                                                    <CustomButton
                                                        type="button"
                                                        iconName="Button-Rotate-Anti-Clockwise.svg"
                                                        className="job-button button-light"
                                                        onClick={
                                                            handleUndoBenchTop
                                                        }
                                                        value={item.id}>
                                                        Undo
                                                    </CustomButton>
                                                ) : null}
                                            </TD>
                                        </TableRow>
                                    );
                                })}

                            <TableRow>
                                <TD />
                                <TDLessPadding>
                                    <LabelBold>PRODUCTS SUBTOTAL</LabelBold>
                                </TDLessPadding>
                                <TD>
                                    <LabelBold>
                                        {formatPrice(totalVariationCost, {})}
                                    </LabelBold>
                                </TD>
                                <TD />
                            </TableRow>

                            {/* extra variations */}
                            {extraVariationList?.map((item) => {
                                const isRemovableExtra =
                                    item.removable !== null &&
                                    item.removable === true;

                                const isRemoved =
                                    removedExtraVariationIds.includes(item.id);

                                return (
                                    <TableRow
                                        key={item.id}
                                        isRemoved={
                                            isRemovableExtra && isRemoved
                                                ? true
                                                : null
                                        }>
                                        <TD />
                                        <TD>
                                            <Label>{item.name}</Label>
                                        </TD>
                                        <TD>{formatPrice(item.cost, {})}</TD>
                                        <TD>
                                            {isRemovableExtra && !isRemoved ? (
                                                <CustomButton
                                                    type="button"
                                                    iconName="Options-Delete.svg"
                                                    className="job-button button-light"
                                                    onClick={
                                                        handleRemoveExtraVariations
                                                    }
                                                    value={item.id}>
                                                    Delete
                                                </CustomButton>
                                            ) : null}
                                            {isRemovableExtra && isRemoved ? (
                                                <CustomButton
                                                    type="button"
                                                    iconName="Button-Rotate-Anti-Clockwise.svg"
                                                    className="job-button button-light"
                                                    onClick={
                                                        handleUndoExtraVariation
                                                    }
                                                    value={item.id}>
                                                    Undo
                                                </CustomButton>
                                            ) : null}
                                        </TD>
                                    </TableRow>
                                );
                            })}

                            <JobVariationFreightCost
                                totalVariationCost={
                                    totalVariationCost + totalExtraVariationCost
                                }
                            />
                        </tbody>
                    </Table>
                </TableContainer>
            </BodyContainer>
        </>
    );
};

export default JobPendingVariation;
