import React from 'react';
import {Materials} from 'components/customer/AdvancedMaterials/Materials';
import {useMaterials} from 'components/customer/AdvancedMaterials/helpers/useMaterials';
import {useAppSelector} from 'store/customer';
import {selectHasDoors} from 'components/customer/AdvancedMaterials/store/materialSlice';

const withMaterial = (Component: React.FC) => {
    const Materials = () => {
        const hasDoors = useAppSelector(selectHasDoors);
        useMaterials(hasDoors);

        return <Component />;
    };

    return Materials;
};

export default withMaterial(Materials);
