export const getUpdatedForbiddenNumbers = (
    deletedNumber: number,
    forbiddenNumbers: number[]
) => {
    if (forbiddenNumbers.length == 0) return forbiddenNumbers;

    const updatedForbiddenNumbers = forbiddenNumbers.map((value) => {
        if (value > deletedNumber) {
            return --value;
        }

        return value;
    });

    return updatedForbiddenNumbers;
};
