import React, {useCallback, useMemo} from 'react';
import {EdgeTogglePosition} from 'components/customer/BTM/helper/useEdgeTogglePosition';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    profiledSet,
    selectEdgeProfile,
} from 'components/customer/BTM/store/btmSlice';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Dropdown} from 'react-bootstrap';
import {
    DropdownMenu,
    ToggleButton,
} from 'components/customer/BTM/Corners/Corner';
import {useFetchEdgeProfile} from 'components/customer/BTM/helper/useFetchEdgeProfile';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Path} from 'components/customer/BTM/entity/Path';
import {checkCutoutRestriction} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {Tab} from 'components/customer/BTM/entity/Tab';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {isNumeric} from 'mathjs';
import {checkAdjacentRestriction} from 'components/customer/BTM/helper/checkAdjacentRestriction';
import {EdgeToggleProfile} from 'components/customer/BTM/Preview/EdgeToggleProfile';
import {checkEndOnlyRestriction} from 'components/customer/BTM/helper/checkEndOnlyRestriction';
import {useCornerFromEndOnlyProfile} from 'components/customer/BTM/helper/useCornerFromEndOnlyProfile';
import {EndProfileCurvedCorner} from 'components/customer/BTM/Corners/EndProfileCurvedCorner';
import {EndProfileArcEnd} from 'components/customer/BTM/Corners/EndProfileArcEnd';
import {checkCurveCornerRestriction} from 'components/customer/BTM/helper/checkCurveCornerRestriction';
import {shallowEqual} from 'react-redux';
import {useBlankMaterialProfileRestriction} from 'components/customer/BTM/helper/useBlankMaterialProfileRestriction';

export interface SquaredProfiles {
    profile: BenchtopEdgeProfile;
    disabled?: boolean;
}

export const EdgeToggle = ({
    position,
    paths,
    currentTab,
    shape,
}: {
    position: EdgeTogglePosition;
    paths: Path[];
    currentTab: Tab;
    shape: Shape;
}) => {
    const dispatch = useAppDispatch();
    const {edgeProfiles} = useFetchEdgeProfile();
    const {isCurrentEdgeACornerFromEndProfile, endOnlyProfile, getCornerIndex} =
        useCornerFromEndOnlyProfile({
            position,
            paths,
        });
    const selectedProfile = useAppSelector(selectEdgeProfile, shallowEqual);
    const {showToggle} = useBlankMaterialProfileRestriction(position, shape);

    const squaredProfiles = useMemo<SquaredProfiles[]>(() => {
        if (edgeProfiles) {
            const profiles = edgeProfiles.filter(
                (profile) => !profile.is_postformed_profile
            );

            if (selectedProfile) {
                return profiles
                    .filter((profile) => profile.id != selectedProfile.id)
                    .filter((profile) => {
                        // remove end only profile from non-end edges
                        return !checkEndOnlyRestriction(
                            paths,
                            profile,
                            position.index,
                            shape
                        );
                    })
                    .map((profile) => {
                        const disabled =
                            (profile.prefix &&
                                profile.prefix != selectedProfile.prefix) ||
                            checkCutoutRestriction(
                                paths,
                                profile,
                                position.index
                            ) ||
                            checkAdjacentRestriction(
                                paths,
                                profile,
                                position.index,
                                shape
                            ) ||
                            checkCurveCornerRestriction(
                                paths,
                                profile,
                                position.index,
                                shape
                            );

                        return {
                            profile,
                            disabled,
                        };
                    });
            }
        }

        return [];
    }, [edgeProfiles, selectedProfile, position, paths, shape]);

    const handleProfile = useCallback(
        (edge: Edge, profile: BenchtopEdgeProfile = null) => {
            dispatch(
                profiledSet(
                    edge,
                    profile,
                    position.index,
                    position.side,
                    edgeProfiles
                )
            );
        },
        [position, edgeProfiles]
    );

    const handleClickSelectedProfile = useCallback(() => {
        if (selectedProfile) {
            handleProfile(
                selectedProfile.is_postformed_profile
                    ? Edge.PROFILED
                    : Edge.EDGED,
                selectedProfile
            );
        }
    }, [selectedProfile, position]);

    const handleInvisibleProfile = useCallback(() => {
        handleProfile(Edge.NOT_EDGED);
    }, [position]);

    const currentEdgeProfile = useMemo(
        () =>
            edgeProfiles &&
            edgeProfiles.find(
                (currentProfile) => currentProfile.id == position.profile
            ),
        [edgeProfiles, position.profile]
    );

    const rotateClass = useMemo(() => {
        switch (shape) {
            case Shape.SQR:
                // side 1 will be 270deg and side 3 will be 90deg

                //          0
                //       _________
                //      |         |
                //  3   |         |  1
                //      |_________|
                //            2

                if (position.side == 1) {
                    return 'rotate_270';
                }
                if (position.side == 3) {
                    return 'rotate_90';
                }
                break;
            case Shape.ANG:
                // side 1, 3 will be 270 deg and 5 will be 90deg

                //          0
                //      __________
                //      |\        |
                //      | \       |  1
                //      |  \______|
                //    5 |   |  2
                //      |   |3
                //      |___|
                //        4
                if (position.side == 1 || position.side == 3) {
                    return 'rotate_270';
                }
                if (position.side == 5) {
                    return 'rotate_90';
                }
                break;
            case Shape.USHAPE:
                // 1 and 5 will be 270deg and 3, 7 will be 90deg

                //
                //         0
                //    ______________
                //   | \          / |
                //   |   \______/   |   1
                // 7 |   |  4   |   |
                //   |   |5   3 |_ _|
                //   |_ _|        2
                //     6
                if (position.side == 1 || position.side == 5) {
                    return 'rotate_270';
                }
                if (position.side == 3 || position.side == 7) {
                    return 'rotate_90';
                }
                break;
            default:
                return '';
        }
    }, [shape]);

    const symbolIcons = useMemo(() => {
        return currentEdgeProfile && currentEdgeProfile.symbol ? (
            <Symbol
                className={rotateClass}
                iconName={`benchtop_profile_icons/${currentEdgeProfile.symbol}.svg`}
            />
        ) : null;
    }, [currentEdgeProfile]);

    if (isNumeric(currentTab) && currentTab != Tab.EDGE_PROFILE) {
        return (
            <SymbolParent $top={position.top - 3} $left={position.left}>
                {symbolIcons}
            </SymbolParent>
        );
    }

    // when corner is created from end profile then no edge profile toggle icon will be shown if not arc options are not locked
    if (isCurrentEdgeACornerFromEndProfile) {
        if (endOnlyProfile && endOnlyProfile.end_option.is_locked) {
            return null;
        }
        // find the corner index which is being edited or painted
        const corners = getCornerIndex();
        if (corners) {
            if (corners.corner.isArc) {
                return (
                    <Toggle
                        $top={position.top}
                        $left={position.left}
                        $translucent={false}>
                        <EndProfileArcEnd
                            endOnlyProfile={endOnlyProfile}
                            corner={corners}
                        />
                    </Toggle>
                );
            }
            return (
                <Toggle $top={position.top} $left={position.left}>
                    <EndProfileCurvedCorner
                        endOnlyProfile={endOnlyProfile}
                        corner={corners}
                    />
                </Toggle>
            );
        }
    }

    if (!showToggle) {
        return (
            <Toggle $top={position.top} $left={position.left}>
                {symbolIcons}
            </Toggle>
        );
    }

    return (
        <Toggle $top={position.top} $left={position.left}>
            <EdgeDropdown>
                <DropdownToggleButton>
                    {symbolIcons}
                    <ToggleImage iconName="Button-Edit-Primary.svg" />
                </DropdownToggleButton>
                <EdgeDropdownMenu>
                    {!position.hideProfiled ? (
                        <DropdownItem
                            disabled={position.disableProfiled}
                            onClick={handleClickSelectedProfile}>
                            <Label
                                $bold={
                                    selectedProfile
                                        ? selectedProfile.id == position.profile
                                        : false
                                }>
                                <div>
                                    <div>
                                        {selectedProfile
                                            ? selectedProfile.name
                                            : ''}
                                    </div>
                                    {position.disableProfiled ? (
                                        <em>(Not available)</em>
                                    ) : null}
                                </div>
                                {selectedProfile && selectedProfile.symbol ? (
                                    <IconStyle
                                        $disabled={position.disableProfiled}
                                        iconName={`benchtop_profile_icons/${selectedProfile.symbol}.svg`}
                                    />
                                ) : (
                                    <Profiled
                                        $disabled={position.disableProfiled}
                                        $thickness={
                                            selectedProfile &&
                                            selectedProfile.edge_highlight
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Label>
                        </DropdownItem>
                    ) : null}
                    {squaredProfiles
                        ? squaredProfiles.map((data) => {
                              return (
                                  <EdgeToggleProfile
                                      key={data.profile.id}
                                      squaredProfile={data}
                                      position={position}
                                      handleProfile={handleProfile}
                                  />
                              );
                          })
                        : null}
                    <DropdownItem onClick={handleInvisibleProfile}>
                        <Label $bold={typeof position.profile == 'undefined'}>
                            <div>Not Visible</div>
                            <NotVisible />
                        </Label>
                    </DropdownItem>
                </EdgeDropdownMenu>
            </EdgeDropdown>
        </Toggle>
    );
};

interface ProfileItemInterface {
    $disabled?: boolean;
    $thickness?: boolean;
}

const Toggle = styled.div<{
    $top: number;
    $left: number;
    $translucent?: boolean;
}>`
    position: absolute;
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
    opacity: ${({$translucent = true}) => ($translucent ? 0.5 : 1)};

    :hover {
        opacity: 1;
    }
`;

export const ToggleImage = styled(Icon)`
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
`;

const SymbolParent = styled.div<{$top: number; $left: number}>`
    position: absolute;
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
`;
const Symbol = styled(Icon)`
    height: 30px;
    &.rotate_270 {
        transform: rotate(270deg);
    }
    &.rotate_90 {
        transform: rotate(90deg);
    }
`;

export const EdgeDropdown = styled(Dropdown)`
    width: 20px;
    height: 20px;
    line-height: 0px;
`;

export const DropdownToggleButton = styled(ToggleButton)`
    width: 20px;
    height: 20px;
    line-height: 0px;
`;

export const Profiled = styled.div<ProfileItemInterface>`
    width: 20px;
    height: ${({$thickness = false}) => ($thickness ? '5' : '2')}px;
    background: rgb(var(--secondary_colour));
    opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
`;

export const Laminated = styled.div<{$disabled: boolean}>`
    width: 20px;
    height: 5px;
    background: rgb(var(--primary_colour));
    opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
`;

const NotVisible = styled.div`
    width: 20px;
    height: 1px;
    background: rgb(var(--primary_colour));
`;

export const Label = styled.div<{$bold?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5em;
    font-size: 0.85em;
    font-weight: ${({$bold = false}) => ($bold ? 'bold' : 'normal')};

    > div {
        display: flex;
        flex-direction: column;
    }
`;

export const DropdownItem = styled(Dropdown.Item)`
    padding: 5px;
`;

export const EdgeDropdownMenu = styled(DropdownMenu)`
    width: 220px;
    max-height: 200px;
    overflow: auto;
`;

export const IconStyle = styled(Icon)<{$disabled: boolean}>`
    max-width: 25px;
    opacity: ${({$disabled}) => ($disabled ? 0.5 : 1)};
`;
