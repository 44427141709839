import {EdgeTogglePosition} from 'components/customer/BTM/helper/useEdgeTogglePosition';
import {Path} from 'components/customer/BTM/entity/Path';
import {getSorroundingIndexes} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {useFetchEdgeProfile} from 'components/customer/BTM/helper/useFetchEdgeProfile';
import {useMemo} from 'react';
import {isEmpty, join} from 'lodash';
import {Side} from 'components/customer/BTM/entity/Side';
import {shallowEqual} from 'react-redux';
import {useAppSelector} from 'store/customer';
import {selectCorners} from 'components/customer/BTM/store/btmSlice';

export const useCornerFromEndOnlyProfile = ({
    position,
    paths,
}: {
    position: EdgeTogglePosition;
    paths: Path[];
}) => {
    const {edgeProfiles} = useFetchEdgeProfile();
    const corners = useAppSelector(selectCorners, shallowEqual);
    const getCornerIndex = () => {
        const sorroundingPaths = getSorroundingIndexes(
            position.index,
            paths.length
        ).map((index) => paths[Number(index)]);

        // get side names
        const sideNames = sorroundingPaths.map((path) => Side[path.side]);
        return corners
            .map((corner, index) => ({corner, index}))
            .find((corner) => corner.corner.name == join(sideNames, ''));
    };

    const [isCurrentEdgeACornerFromEndProfile, endOnlyProfile] = useMemo(() => {
        if (position.side != null || isEmpty(edgeProfiles)) {
            // it's not a corner.
            return [false, null];
        }
        const sorroundingPaths = getSorroundingIndexes(
            position.index,
            paths.length
        ).map((index) => paths[Number(index)]);

        // check if any one of the edge profile is end only and has arc end or curved corner set on edge profile
        const previousPathAnEndProfile = edgeProfiles.find(
            (profile) => profile.id == sorroundingPaths[0].profile
        );
        const nextPathAnEndProfile = edgeProfiles.find(
            (profile) => profile.id == sorroundingPaths[1].profile
        );

        const cornerCreatedFromEndProfile =
            Boolean(
                previousPathAnEndProfile &&
                    previousPathAnEndProfile.is_end_only &&
                    previousPathAnEndProfile.end_id
            ) ||
            Boolean(
                nextPathAnEndProfile &&
                    nextPathAnEndProfile.is_end_only &&
                    nextPathAnEndProfile.end_id
            );
        if (previousPathAnEndProfile && previousPathAnEndProfile.end_id) {
            return [cornerCreatedFromEndProfile, previousPathAnEndProfile];
        }

        if (nextPathAnEndProfile && nextPathAnEndProfile.end_id) {
            return [cornerCreatedFromEndProfile, nextPathAnEndProfile];
        }
        return [cornerCreatedFromEndProfile, null];
    }, [edgeProfiles, position]);

    return {isCurrentEdgeACornerFromEndProfile, endOnlyProfile, getCornerIndex};
};
