import React from 'react';
import {ProductProvider, useAppContext} from 'contexts';
import {useFavourites} from 'hooks';
import {Icon} from 'shared/helpers';
import {Items} from 'shared';

export const Favourites = () => {
    const {loading, products, sundries} = useFavourites();
    const {userProfile} = useAppContext();

    return (
        <ProductProvider>
            <div className="hardware">
                <h1>
                    <Icon iconName="Headers-Add-Product.svg" />
                    <label>Product Favourites</label>
                </h1>
                <Items
                    loader={loading}
                    items={products}
                    isProduct={true}
                    noHr={true}
                />

                <h1>
                    <Icon iconName="Headers-Add-Hardware.svg" />
                    <label>{userProfile.sundryMenuText}</label>
                </h1>
                <Items loader={loading} items={sundries} noHr={true} />
            </div>
        </ProductProvider>
    );
};
