import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

interface Dimension {
    $width: number;
    $height: number;
    $inlinePreviewImageBorder?: boolean;
    $hide?: boolean;
}

const ColorSwatchElement = styled.img<Dimension>`
    width: ${({$width}) => $width}px;
    height: ${({$height}) => $height}px;
    display: ${({$hide}) => ($hide ? 'none' : 'block')};
    border: ${(props) =>
        props.hasOwnProperty('$inlinePreviewImageBorder') &&
        props.$inlinePreviewImageBorder == false
            ? '0'
            : '1px solid #6b6f70'};
`;

const ColorSwatchContainer = styled.div<Dimension>`
    width: ${(props) => props.$width + 2}px;
    height: ${(props) => props.$height + 2}px;
    box-sizing: border-box;
    margin: 0 1px 1px 0;
`;

interface ColorSwatchInterface {
    src: string;
    onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void;
    onMouseOut?: () => void;
    inlinePreviewImageBorder?: boolean;
    height?: number;
    width?: number;
}

export const ColorSwatch = ({
    src,
    onMouseOut,
    onMouseOver,
    inlinePreviewImageBorder,
    height = 21,
    width = 21,
}: ColorSwatchInterface) => {
    const [hide, setHide] = useState(false);
    const onImageError = () => {
        setHide(true);
    };

    useEffect(() => {
        setHide(false);
    }, [src]);

    return (
        <ColorSwatchContainer $height={height} $width={width}>
            <ColorSwatchElement
                src={src}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                $hide={hide}
                onError={onImageError}
                $inlinePreviewImageBorder={inlinePreviewImageBorder}
                $height={height} 
                $width={width}
            />
        </ColorSwatchContainer>
    );
};
