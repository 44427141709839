import React, {useCallback, useEffect, useState} from 'react';
import {ActionType} from 'components/customer/Materials/helper/useDispatchMaterial';
import {mapDoorBack} from 'components/customer/Materials/helper/doorHelper';
import {useAppDispatch} from 'store/customer';
import {validationDataSet} from 'components/customer/QFPRedux/store/qfpSlice';
import {Product, ProductForm} from 'components/customer/Product/entity';
import {getCabinetFields} from 'shared/helpers';
import {Page} from 'store/customer/entity/Page';
import {
    MaterialType,
    Material as MaterialInterface,
    Door,
    MaterialEdge,
} from 'components/customer/Materials/entity';
import {
    hasDoorSet,
    selectDefaultLoaded,
} from 'components/customer/Materials/store/materialSlice';
import MaterialSearch from 'components/customer/Materials/MaterialSearch';
import DoorSearch from 'components/customer/Materials/DoorSearch';
import EdgeSearch from 'components/customer/Materials/EdgeSearch';
import {useLazyGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {connect} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';

interface MaterialComponentInterface {
    index: number;
    product: Product;
    defaultLoaded?: boolean;
}

const MaterialComponent = ({
    index,
    product,
    defaultLoaded,
}: MaterialComponentInterface) => {
    const dispatch = useAppDispatch();
    const dispatchMaterial = useDispatchWithFormik({index, page: Page.QFP});
    const [getStructure] = useLazyGetQFPProductStructureQuery<ProductForm>();
    const [hasDoor, setHasDoor] = useState(true);

    const dispatchHasDoor = async () => {
        const {data: structure, isError} = await getStructure(
            {cabinetType: product.cabinet_type},
            true
        );

        if (!isError) {
            const productInfo = getCabinetFields(structure.originalStructure);

            setHasDoor(productInfo.hasExteriorDoor);
            dispatch(hasDoorSet(productInfo.hasExteriorDoor, index));
        }
    };

    useEffect(() => {
        void dispatchHasDoor();
    }, [product.cabinet_type]);

    const setMaterial = useCallback(
        (material: MaterialInterface) => {
            if (!defaultLoaded) return;

            dispatchMaterial(ActionType.ExteriorColour, material);
        },
        [defaultLoaded, dispatchMaterial]
    );

    const setDoor = useCallback(
        (door: Door) => {
            if (!defaultLoaded) return;

            dispatchMaterial(ActionType.Door, door);
            dispatch(
                validationDataSet(
                    {
                        cabinet_door: mapDoorBack(door),
                        isAdvanced: door.is_advanced,
                    },
                    index
                )
            );
        },
        [defaultLoaded, dispatchMaterial]
    );

    const setEdge = useCallback(
        (edge: MaterialEdge) => {
            if (!defaultLoaded) return;

            dispatchMaterial(ActionType.ExteriorEdgeColour, edge);
        },
        [defaultLoaded, dispatchMaterial]
    );

    return (
        <>
            <td>
                <MaterialSearch
                    index={index}
                    key={index}
                    materialType={MaterialType.EXTERIOR}
                    defaultPlaceholder="Search Materials"
                    hasDoor={hasDoor}
                    setMaterial={setMaterial}
                    isQFP={true}
                    cabinetType={`${product.cabinet_type}`}
                />
            </td>
            <td>
                {hasDoor ? (
                    <DoorSearch
                        index={index}
                        key={index}
                        materialType={MaterialType.EXTERIOR}
                        defaultPlaceholder="Search Doors"
                        setMaterial={setMaterial}
                        setDoor={setDoor}
                        isQFP={true}
                        cabinetType={`${product.cabinet_type}`}
                    />
                ) : (
                    <>N/A</>
                )}
            </td>
            <td>
                <EdgeSearch
                    index={index}
                    key={index}
                    materialType={MaterialType.EXTERIOR}
                    defaultPlaceholder="Search Edge Materials"
                    setEdge={setEdge}
                    isQFP={true}
                    cabinetType={`${product.cabinet_type}`}
                />
            </td>
        </>
    );
};

export const Material = connect(
    (state: AppState, {index}: MaterialComponentInterface) => ({
        defaultLoaded: selectDefaultLoaded(state, MaterialType.EXTERIOR, index),
    })
)(MaterialComponent);
