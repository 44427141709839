import React, {useMemo} from 'react';
import {useDeleteBenchMutation} from 'components/customer/BTM/store/btmApi';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {useNavigate} from 'react-router-dom';
import {genericMessageHandler} from 'shared/helpers';
import {useGetBenchId} from 'components/customer/BTM/helper/useGetBenchId';
import {invalidateJobCost} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';
import {clearAll} from 'components/customer/BTM/store/btmSlice';
import {
    useFetchBenchesData,
    BenchTableInterface as Bench,
} from 'components/customer/BTM/helper/useFetchBenchesData';
import {BenchtopDetail} from 'components/customer/JobDashboard/BenchtopDetail';
import {isMobile} from 'react-device-detect';
import {PartialJob} from 'shared/types/PartialJob';

export const useBenchtopTableConfigInDetailCart = () => {
    const dispatch = useAppDispatch();
    const {userProfile} = useAppContext();
    const {benchId} = useGetBenchId();
    const {notify} = useNotificationContext();
    const {
        job,
        isPageDirty,
        actionButtonsEnabled,
        showDialog,
        isJobProcessing,
        updateTotalProductCountManual,
    } = useJobContext() as PartialJob;
    const navigate = useNavigate();
    const benches = useFetchBenchesData();

    const [deleteBench] = useDeleteBenchMutation();

    const addBenchtopUrl = `/v2/job/${job.displayId}/benchtop-module`;

    // note: maybe sidebar and this hook can share some logic? :thinking-face
    const removeBenchtop = (
        bench: Bench,
        event: React.MouseEvent<HTMLElement>
    ) => {
        event.stopPropagation();
        const button = event.target.parentElement as HTMLButtonElement;
        showDialog({
            title: 'Delete benchtop',
            message:
                'Are you sure you want to delete this benchtop from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    await deleteBench({benchId: bench.id});
                    updateTotalProductCountManual(true, false);

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Benchtop removed from the job'},
                        'success'
                    );
                    dispatch(invalidateJobCost());

                    if (benchId == bench.id) {
                        navigate(`/v2/job/${job.displayId}/dashboard`);
                    }
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
        });
    };

    const editBenchtop = (row: Bench) => {
        dispatch(clearAll());
        navigate(
            `/v2/job/${job.displayId}/benchtop-module/type/${row.shape.type}/materialType/${row.type.id}/material?bench=${row.id}`
        );
    };

    const strongFormatter = (value: string, row: Bench) => {
        if (row.id == Number(benchId)) {
            return <strong>{value}</strong>;
        } else {
            return value;
        }
    };

    const noRoomAndVariationBenchtop = (variations: number) => {
        if (variations > 0) {
            return <>No Benchtops.</>;
        } else {
            return (
                <>
                    No Benchtops.{' '}
                    {job.displayId && (
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(addBenchtopUrl)}>
                            <strong>Click Add BenchTop</strong> to add one.
                        </span>
                    )}
                </>
            );
        }
    };

    const tableConfig = useMemo(() => {
        const tableConfig = {
            fields: [
                {
                    fieldName: 'jobBenchtopNumber',
                    title: '#',
                    width: 35,
                    format: (value: string) => <strong>{value}</strong>,
                },
                {
                    fieldName: 'name',
                    title: 'Benchtop',
                    format: (field, row: Bench) => (
                        <BenchtopDetail bench={row} />
                    ),
                },
                {
                    fieldName: 'dimension',
                    title: 'Dimensions (mm)',
                    mobile: false,
                    width: 150,
                    format: strongFormatter,
                },
                userProfile.show_pricing && {
                    fieldName: 'cost',
                    mobile: false,
                    title: (
                        <div>
                            Unit Price (ex.{' '}
                            {job.countryTaxRateName
                                ? job.countryTaxRateName
                                : ''}
                            )
                        </div>
                    ),
                    width: 150,
                    format: (value: string) => (
                        <div className="total">{value}</div>
                    ),
                },
                {
                    fieldName: 'quantity',
                    mobile: false,
                    title: 'Quantity',
                    width: 150,
                    format: strongFormatter,
                },
                userProfile.show_pricing && {
                    fieldName: 'cost',
                    title: 'TOTAL',
                    mobile: false,
                    width: 150,
                    format: strongFormatter,
                },
            ].filter((field) => field),
            data: benches.filter((bench) => !bench.isOld),
            noRecordsText: (
                <>{noRoomAndVariationBenchtop(job.variationsConfirmed)}</>
            ),
            options: {},
        };

        if (isJobProcessing && actionButtonsEnabled) {
            tableConfig.options = {
                title: 'Actions',
                width: isMobile ? 50 : 150,
                buttons: [
                    {
                        iconName: 'Options-Edit.svg',
                        rowAction: true,
                        title: 'Edit Benchtop',
                        action: editBenchtop,
                        disabled: (row: Bench) => row.id == benchId,
                    },
                    {
                        iconName: 'Options-Delete.svg',
                        title: 'Delete Benchtop',
                        action: removeBenchtop,
                        confirmChanges: isPageDirty.length > 0,
                    },
                ],
            };
        }

        if (!actionButtonsEnabled) {
            tableConfig.options = undefined;
        }

        return tableConfig;
    }, [
        benchId,
        job,
        benches,
        isPageDirty,
        actionButtonsEnabled,
        isJobProcessing,
    ]);

    return {
        tableConfig,
    };
};
