import React, {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    materialTypesSet,
    selectMaterialTypes,
    selectMenu,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import styled from 'styled-components';
import {CBCButton} from 'shared/helpers';
import {shallowEqual} from 'react-redux';

export const ShowAllDoors = () => {
    const dispatch = useAppDispatch();
    const selectedMenu = useAppSelector(selectMenu);
    const materialTypes = useAppSelector(selectMaterialTypes, shallowEqual);

    const makeAllDoorsVisible = useCallback(() => {
        if (materialTypes && materialTypes.length > 0) {
            dispatch(
                materialTypesSet(
                    materialTypes.map((type) => ({...type, selected: true}))
                )
            );
        }
    }, [materialTypes]);

    if (
        selectedMenu == Menu.DOOR_TYPE &&
        materialTypes &&
        materialTypes.length > 0
    ) {
        return (
            <Button
                onClick={makeAllDoorsVisible}
                iconName="Layout-View-Door.svg"
                className="job-button button-blue"
                type="button">
                View all doors
            </Button>
        );
    } else {
        return <div />;
    }
};

// NOTE: Jest is weird sometimes and cannot find component that
// is being imported. So, we need to use this workaround.
const Button = styled((props) => <CBCButton {...props} />)`
    height: 31px;
    padding: 17px 17px 17px 2px;
    font-size: 1.1em;
    justify-content: center;
    align-items: center;
    width: 165px;
    margin-top: 15px;
    font-weight: 500;

    > img {
        width: 29px !important;
    }

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        margin-left: 15px;
    }
`;
