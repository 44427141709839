import {Side} from 'components/customer/BTM/entity/Side';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {JoinOrientation, Join} from 'components/customer/BTM/entity/Join';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';

// This is just a predefined map of joins and sides.
// This just shows sides with joins that possibly touches it
const JOIN_SIDE_MAP = [
    {
        shape: Shape.ANG,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.HORIZONTAL,
        side: Side.F,
        joinSide: JoinSide.LEFT,
    },
    {
        shape: Shape.ANG,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.VERTICAL,
        side: Side.A,
        joinSide: JoinSide.LEFT,
    },
    {
        shape: Shape.USHAPE,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.HORIZONTAL,
        side: Side.H,
        joinSide: JoinSide.LEFT,
    },
    {
        shape: Shape.USHAPE,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.VERTICAL,
        side: Side.A,
        joinSide: JoinSide.LEFT,
    },
    {
        shape: Shape.USHAPE,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.VERTICAL,
        side: Side.A,
        joinSide: JoinSide.RIGHT,
    },
    {
        shape: Shape.USHAPE,
        joinType: JoinType.MASONS_MITRE,
        orientation: JoinOrientation.HORIZONTAL,
        side: Side.B,
        joinSide: JoinSide.RIGHT,
    },
];

/**
 * Returns the selected joins that touches the
 * side that is passed to the function.
 *
 * @param {Shape} shape The shape of the bench Rectangle, L or U
 * @param {Side} side The side that is being checked
 * @param {Join[]} joins The entire list of joins in that bench
 *
 * @return {Join[]} Returns array of selected joins
 */
export const getJoinsOnSide = (shape: Shape, side: Side, joins: Join[]) => {
    const selectedJoins: Join[] = [];

    for (const join of joins) {
        const check = JOIN_SIDE_MAP.find(
            (joinMap) =>
                joinMap.shape == shape &&
                joinMap.side == side &&
                joinMap.joinSide == join.side &&
                joinMap.joinType == join.joinType &&
                joinMap.orientation == join.orientation &&
                join.selected
        );

        if (check) {
            selectedJoins.push(join);
        }
    }

    return selectedJoins;
};
