import {ProductDefault, Product} from 'components/customer/Product/entity';
import {cloneDeep} from 'lodash';
import {getPanelValidation} from 'shared/helpers';
import {ProductMaterials} from 'components/customer/QFPRedux/helpers/materialHelpers';
import {PartialRoom} from 'shared/types/PartialRoom';

export const getDefaultProduct = (
    productDefaults: Product,
    productFormFieldDefaults: ProductDefault,
    heightField: string,
    widthField: string,
    materials: ProductMaterials,
    persisted: boolean,
    room: PartialRoom,
    hasDrawerFaceHeight: boolean
) => {
    const productWithDefaultValues = cloneDeep(productDefaults);
    if (
        productDefaults.hasOwnProperty('cabinet') &&
        productDefaults.cabinet.hasOwnProperty('attributes')
    ) {
        productWithDefaultValues.panelValidation = getPanelValidation(
            productDefaults.cabinet.attributes
        ); // remove before saving
    }
    productWithDefaultValues.cabinet_note = productDefaults.note
        ? productDefaults.note
        : '';
    productWithDefaultValues.material_type = productDefaults.ext_material.type;
    productWithDefaultValues.material_brand =
        productDefaults.ext_material.brand;
    productWithDefaultValues.finish = productDefaults.ext_material.finish;
    productWithDefaultValues.substrate = productDefaults.ext_material.substrate;
    productWithDefaultValues.colour = productDefaults.ext_material.id;
    productWithDefaultValues.edge_colour = productDefaults.ext_edge.id;
    productWithDefaultValues.cabinet_door = productDefaults.cabinet_door;
    productWithDefaultValues.unit_price = null;
    productWithDefaultValues.additional_door_width = null;
    productWithDefaultValues.hasAdvancedMaterialOptions = true;
    productWithDefaultValues.double_sided =
        productDefaults.ext_double_sided == 1;
    productWithDefaultValues.hor_grain = productDefaults.ext_hor_grain == 1;
    productWithDefaultValues.hor_grain_ext = productDefaults.ext_hor_grain == 1;
    productWithDefaultValues.ext_hor_grain = productDefaults.ext_hor_grain == 1;
    productWithDefaultValues.custom_colour = productDefaults.custom_colour_ext
        ? productDefaults.custom_colour_ext
        : '';
    productWithDefaultValues.glassSubPanels = [
        [false, false, false],
        [false, false, false],
        [false, false, false],
    ];

    if (
        productDefaults.hasOwnProperty('doors') &&
        productDefaults.doors.length
    ) {
        productWithDefaultValues.door_hang_type =
            productDefaults.doors[0].door_hang_type == 0
                ? 1
                : productDefaults.doors[0].door_hang_type;
        productWithDefaultValues.door_hang = productDefaults.doors[0].door_hang;
        productWithDefaultValues.border_width_top =
            productDefaults.doors[0].border_width_top;
        productWithDefaultValues.border_width_bottom =
            productDefaults.doors[0].border_width_bottom;
        productWithDefaultValues.border_width_left =
            productDefaults.doors[0].border_width_left;
        productWithDefaultValues.border_width_right =
            productDefaults.doors[0].border_width_right;
        productWithDefaultValues.hori_amount =
            productDefaults.doors[0].hori_amount;
        productWithDefaultValues.hori_height =
            productDefaults.doors[0].hori_height;
        productWithDefaultValues.vert_amount =
            productDefaults.doors[0].vert_amount;
        productWithDefaultValues.vert_width =
            productDefaults.doors[0].vert_width;
        productWithDefaultValues.no_vert_bars =
            productDefaults.doors[0].no_vert_bars;
        productWithDefaultValues.no_hori_bars =
            productDefaults.doors[0].no_hori_bars;
        productWithDefaultValues.bar_width = productDefaults.doors[0].bar_width;
        productWithDefaultValues.panel_edge_top =
            productDefaults.doors[0].panel_edge_top;
        productWithDefaultValues.panel_edge_bottom =
            productDefaults.doors[0].panel_edge_bottom;
        productWithDefaultValues.panel_edge_left =
            productDefaults.doors[0].panel_edge_left;
        productWithDefaultValues.panel_edge_right =
            productDefaults.doors[0].panel_edge_right;
        productWithDefaultValues.cabinet_edge_l1 =
            productDefaults.doors[0].panel_edge_top;
        productWithDefaultValues.cabinet_edge_l2 =
            productDefaults.doors[0].panel_edge_bottom;
        productWithDefaultValues.cabinet_edge_w1 =
            productDefaults.doors[0].panel_edge_left;
        productWithDefaultValues.cabinet_edge_w2 =
            productDefaults.doors[0].panel_edge_right;
        productWithDefaultValues.cabinet_panel_width = productDefaults.width;
        productWithDefaultValues.cabinet_width_door_1 = productDefaults.width;
        productWithDefaultValues.cabinet_width_door_2 = 0;
        if (productDefaults.hasOwnProperty('drill_only_hinge_details')) {
            productWithDefaultValues['drill_only_hinge'] =
                productDefaults.drill_only_hinge_details.drill_only_hinge;
            productWithDefaultValues['drill_only_hinge_name'] =
                productDefaults.drill_only_hinge_details.name;
        }

        productWithDefaultValues.glassSubPanelEnabledPositions = {
            sub_panel_1: false,
            sub_panel_enabled_1: false,
            sub_panel_2: false,
            sub_panel_enabled_2: false,
            sub_panel_3: false,
            sub_panel_enabled_3: false,
            sub_panel_4: false,
            sub_panel_enabled_4: false,
            sub_panel_5: false,
            sub_panel_enabled_5: true,
            sub_panel_6: false,
            sub_panel_enabled_6: false,
            sub_panel_7: false,
            sub_panel_enabled_7: false,
            sub_panel_8: false,
            sub_panel_enabled_8: false,
            sub_panel_9: false,
            sub_panel_enabled_9: false,
        };

        if (productDefaults.doors[0].sub_panel1 == 1) {
            productWithDefaultValues.glassSubPanels[0][0] = true;
        }
        if (productDefaults.doors[0].sub_panel2 == 1) {
            productWithDefaultValues.glassSubPanels[0][1] = true;
        }
        if (productDefaults.doors[0].sub_panel3 == 1) {
            productWithDefaultValues.glassSubPanels[0][2] = true;
        }
        if (productDefaults.doors[0].sub_panel4 == 1) {
            productWithDefaultValues.glassSubPanels[1][0] = true;
        }
        if (productDefaults.doors[0].sub_panel5 == 1) {
            productWithDefaultValues.glassSubPanels[1][1] = true;
        }
        if (productDefaults.doors[0].sub_panel6 == 1) {
            productWithDefaultValues.glassSubPanels[1][2] = true;
        }
        if (productDefaults.doors[0].sub_panel7 == 1) {
            productWithDefaultValues.glassSubPanels[2][0] = true;
        }
        if (productDefaults.doors[0].sub_panel8 == 1) {
            productWithDefaultValues.glassSubPanels[2][1] = true;
        }
        if (productDefaults.doors[0].sub_panel9 == 1) {
            productWithDefaultValues.glassSubPanels[2][2] = true;
        }

        if (
            productDefaults.doors[0].hasOwnProperty('horizontal_positions') &&
            productDefaults.doors[0].horizontal_positions.length > 0
        ) {
            productDefaults.doors[0].horizontal_positions.forEach(
                (position, index) => {
                    productWithDefaultValues[`rail_hori_${index + 1}`] =
                        position;
                }
            );
        }
        productWithDefaultValues.hori_mid_rail_positions =
            productDefaults.doors[0].horizontal_positions;

        if (
            productDefaults.doors[0].hasOwnProperty('vertical_positions') &&
            productDefaults.doors[0].vertical_positions.length > 0
        ) {
            productDefaults.doors[0].vertical_positions.forEach(
                (position, index) => {
                    productWithDefaultValues[`rail_vert_${index + 1}`] =
                        position;
                }
            );
        }

        productWithDefaultValues.vert_mid_rail_positions =
            productDefaults.doors[0].vertical_positions;
    }

    productWithDefaultValues.drawer_face_height =
        productDefaults.drawer_face_height
            .filter(Boolean)
            .map((face_height) =>
                face_height % 1 != 0
                    ? parseFloat(face_height)
                    : parseInt(face_height)
            );

    if (
        hasDrawerFaceHeight &&
        productWithDefaultValues.drawer_face_height.length == 0
    ) {
        const height = Number(productDefaults[String(heightField)]);

        if (height) {
            productWithDefaultValues.drawer_face_height = [height];
        }
    }

    productWithDefaultValues.inQfpSummary = 1;
    productWithDefaultValues.doorSizesOptions = {
        minimumHeight: null,
        maximumHeight: null,
        minimumWidth: null,
        maximumWidth: null,
    };

    productWithDefaultValues.drillings = [];
    if (productDefaults.drillings && productDefaults.drillings.length) {
        productWithDefaultValues.drillings = productDefaults.drillings.map(
            (drilling) => ({
                drilling_offset_y: drilling.offset_y,
            })
        );
    }

    if (productDefaults.width2 >= 0) {
        productWithDefaultValues.cabinet_width_door_2 = productDefaults.width2;
    }

    if (materials.door) {
        productWithDefaultValues.cabinet_door = materials.door.id;

        if (!persisted) {
            productWithDefaultValues.panel_edge_top =
                materials.door.default_edge_finish_top;
            productWithDefaultValues.panel_edge_bottom =
                materials.door.default_edge_finish_bottom;
            productWithDefaultValues.panel_edge_left =
                materials.door.default_edge_finish_left;
            productWithDefaultValues.panel_edge_right =
                materials.door.default_edge_finish_right;
            productWithDefaultValues.panel_edge_join =
                materials.door.default_edge_finish_join;

            if (
                materials.door.default_border_width_top != null &&
                materials.door.default_border_width_top >= 0
            ) {
                productWithDefaultValues.border_width_top =
                    materials.door.default_border_width_top;
            }

            if (
                materials.door.default_border_width_bottom != null &&
                materials.door.default_border_width_bottom >= 0
            ) {
                productWithDefaultValues.border_width_bottom =
                    materials.door.default_border_width_bottom;
            }

            if (
                materials.door.default_border_width_left != null &&
                materials.door.default_border_width_left >= 0
            ) {
                productWithDefaultValues.border_width_left =
                    materials.door.default_border_width_left;
            }

            if (
                materials.door.default_border_width_right != null &&
                materials.door.default_border_width_right >= 0
            ) {
                productWithDefaultValues.border_width_right =
                    materials.door.default_border_width_right;
            }

            if (
                materials.door.default_rails_vertical_width != null &&
                materials.door.default_rails_vertical_width >= 0
            ) {
                productWithDefaultValues.vert_width =
                    materials.door.default_rails_vertical_width;
            }

            if (
                materials.door.default_rails_horizontal_height != null &&
                materials.door.default_rails_horizontal_height >= 0
            ) {
                productWithDefaultValues.hori_height =
                    materials.door.default_rails_horizontal_height;
            }
        }
    }

    if (materials.exterior) {
        productWithDefaultValues.material_type = materials.exterior.type.id;
        productWithDefaultValues.material_brand = materials.exterior.brand.id;
        productWithDefaultValues.finish = materials.exterior.finish;
        productWithDefaultValues.substrate = materials.exterior.substrate;
        productWithDefaultValues.colour = materials.exterior.id;
        productWithDefaultValues.cabinet_ext_colour = materials.exterior.id;
    }

    if (materials.edgeExterior) {
        productWithDefaultValues.edge_colour = materials.edgeExterior.id;
        productWithDefaultValues.cabinet_ext_edge_colour =
            materials.edgeExterior.id;
    }

    if (
        !persisted &&
        productFormFieldDefaults &&
        productFormFieldDefaults.hasOwnProperty('cabinet_form_fields') &&
        productFormFieldDefaults.hasOwnProperty('cabinet_form_field_defaults')
    ) {
        Object.keys(productFormFieldDefaults.cabinet_form_fields).forEach(
            (field) => {
                const optionValue =
                    productFormFieldDefaults.cabinet_form_fields[String(field)];
                const defaultValue =
                    productFormFieldDefaults.cabinet_form_field_defaults.hasOwnProperty(
                        field
                    )
                        ? Number(
                              productFormFieldDefaults
                                  .cabinet_form_field_defaults[String(field)]
                          )
                        : undefined;

                if ((optionValue == 1 || optionValue == 2) && defaultValue) {
                    // NOTE: 1 is use default value
                    // and 2 is force default value

                    // These are supposed to override any values
                    // set in room defaults and customer defaults.
                    // These are absolute values.
                    if (productWithDefaultValues.hasOwnProperty(field)) {
                        productWithDefaultValues[field] = defaultValue;
                    }

                    if (
                        field.includes('panel_edge') ||
                        field.includes('cabinet_edge')
                    ) {
                        productWithDefaultValues[field] = defaultValue;
                    }

                    if (field.includes('supply_hardware')) {
                        productWithDefaultValues.cabinet_include_hardware =
                            defaultValue;
                    }

                    if (field == 'drawer_amount' && defaultValue == 1) {
                        const height = Number(productDefaults[heightField]);

                        if (height) {
                            productWithDefaultValues.drawer_face_height = [
                                height,
                            ];
                        }
                    }
                } else if (optionValue == 3 || optionValue == 4) {
                    // NOTE: 3 is use room default value
                    // and 4 is force room default value

                    // INFO: these form field selection should not
                    // override any room or customer defaults.

                    // This piece of code is only overriding door
                    // borders applied above in code block starting
                    // line 221. If the room default door and selected
                    // door are the same and room default has overridden
                    // the border width values.
                    // This same behaviour can also be observed
                    // when switching door from material search and
                    // color browser.

                    if (
                        field == 'border_width_bottom' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorBorderWidthBottom != 'undefined' &&
                        room.doorBorderWidthBottom != null
                    ) {
                        productWithDefaultValues.border_width_bottom =
                            room.doorBorderWidthBottom;
                    }
                    if (
                        field == 'border_width_right' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorBorderWidthRight != 'undefined' &&
                        room.doorBorderWidthRight != null
                    ) {
                        productWithDefaultValues.border_width_right =
                            room.doorBorderWidthRight;
                    }
                    if (
                        field == 'border_width_top' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorBorderWidthTop != 'undefined' &&
                        room.doorBorderWidthTop != null
                    ) {
                        productWithDefaultValues.border_width_top =
                            room.doorBorderWidthTop;
                    }
                    if (
                        field == 'border_width_left' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorBorderWidthLeft != 'undefined' &&
                        room.doorBorderWidthLeft != null
                    ) {
                        productWithDefaultValues.border_width_left =
                            room.doorBorderWidthLeft;
                    }
                    if (
                        field == 'hori_height' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorAdvancedRailHeight != 'undefined' &&
                        room.doorAdvancedRailHeight != null
                    ) {
                        productWithDefaultValues.hori_height =
                            room.doorAdvancedRailHeight;
                    }
                    if (
                        field == 'vert_width' &&
                        room &&
                        room.door &&
                        room.door.advanced == 1 &&
                        room.door.id == materials.door.id &&
                        typeof room.doorAdvancedRailWidth != 'undefined' &&
                        room.doorAdvancedRailWidth != null
                    ) {
                        productWithDefaultValues.vert_width =
                            room.doorAdvancedRailWidth;
                    }
                }

                if (field.includes('sub_panel')) {
                    const subPanelIndex = parseInt(field.match(/\d+/g)[0]);
                    const row = Math.floor((subPanelIndex - 1) / 3);
                    const col = (subPanelIndex - 1) % 3;

                    productWithDefaultValues.glassSubPanels[Number(row)][
                        Number(col)
                    ] =
                        Number(
                            productFormFieldDefaults
                                .cabinet_form_field_defaults[String(field)]
                        ) == 1;
                }
            }
        );
    }

    return productWithDefaultValues;
};
