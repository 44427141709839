import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useGetProductTypeQuery} from 'components/customer/QFPRedux/store/qfpApi';
import {useField, useFormikContext} from 'formik';
import {useAppDispatch} from 'store/customer';
import {
    clearMaterials,
    defaultLoadedExteriorSet,
} from 'components/customer/Materials/store/materialSlice';
import {OverlayTrigger} from 'shared';
import {OverlayType} from 'shared/components/OverlayTrigger';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {initialisedSet} from 'components/customer/QFPRedux/store/qfpSlice';
import Select, {Option} from 'shared/components/Forms/CustomSelect';

interface ProductTypeProps {
    index: number;
    colorSwatchImageUrl: string;
}

export const ProductType = ({index, colorSwatchImageUrl}: ProductTypeProps) => {
    const dispatch = useAppDispatch();
    const {data} = useGetProductTypeQuery();
    const {errors, setFieldValue} = useFormikContext();
    const [field, , {setValue, setTouched}] = useField<number>({
        name: 'cabinet_type',
    });
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const {data: structure} = useGetQFPProductStructureQuery({
        cabinetType: field.value,
    });
    const tableCell = useRef<HTMLDivElement>(null);

    const onSelectHandler = async (option: Option) => {
        dispatch(defaultLoadedExteriorSet(false, index));
        dispatch(clearMaterials(1, index));
        dispatch(initialisedSet(false, index));
        // NOTE: setting type_change to true will ensure effect
        // does right thing in useDefaultProduct hook.
        await setFieldValue('type_change', true);
        await setTouched(true);
        await setValue(parseInt(option.value));
    };

    useEffect(() => {
        if (Object.values(errors).length > 0) {
            setErrorMessages(
                Object.keys(errors).map((fieldName) => {
                    if (
                        structure.fieldDisplayNameMap.hasOwnProperty(fieldName)
                    ) {
                        return `<strong>${
                            structure.fieldDisplayNameMap[String(fieldName)]
                        }</string>: ${errors[String(fieldName)]}`;
                    }

                    return `${errors[String(fieldName)]}`;
                })
            );
        } else {
            setErrorMessages([]);
        }
    }, [errors]);

    const title = useMemo(() => {
        if (data) {
            const option = data.find((option) => option.type == field.value);

            if (option) {
                return option.name;
            }
        }

        return '';
    }, [data, field.value]);

    if (errorMessages.length == 0) {
        return (
            <td>
                <div target={tableCell.current}>
                    <Select
                        ref={tableCell}
                        title={title}
                        isInvalid={errorMessages.length > 0}
                        value={field.value}
                        fieldName={`cabinet_type_${index}`}
                        options={data?.map((d) => ({
                            label: d.name,
                            value: d.type.toString(),
                            image: d.image_url
                                ? `/${d.image_url}`
                                : `/uploads/cabinet_images/${d.image}`,
                            allowBackground: !d.image_url,
                        }))}
                        onSelect={(option) => {
                            void onSelectHandler(option);
                        }}
                        backgroundImageUrl={colorSwatchImageUrl}
                    />
                </div>
            </td>
        );
    }

    return (
        <td>
            <OverlayTrigger
                className="error-popover"
                as={OverlayType.Popover}
                trigger={['focus', 'hover']}
                overlay={
                    <>
                        {errorMessages.length > 0 ? (
                            errorMessages.map((message, index) => (
                                <li key={index}>{parseHtmlString(message)}</li>
                            ))
                        ) : (
                            <></>
                        )}
                    </>
                }
                placement={'right'}>
                <Select
                    title={title}
                    isInvalid={errorMessages.length > 0}
                    value={field.value}
                    fieldName={`cabinet_type_${index}`}
                    options={data?.map((d) => ({
                        label: d.name,
                        value: d.type.toString(),
                        image: d.image_url
                            ? `/${d.image_url}`
                            : `/uploads/cabinet_images/${d.image}`,
                    }))}
                    onSelect={(option) => {
                        void onSelectHandler(option);
                    }}
                    backgroundImageUrl={colorSwatchImageUrl}
                />
            </OverlayTrigger>
        </td>
    );
};
