import {Fieldset, Product, Structure} from 'components/customer/Product/entity';
import {useGetProductDefaultQuery} from 'components/customer/Product/helpers/useGetProductDefaultQuery';
import {useFetchStructure} from 'components/customer/QFPRedux/helpers/useFetchStructure';
import Excel from 'shared/Excel';

const HIDDEN_FORCE_ROOM_DEFAULT_VALUE = 4;
const HIDDEN_FORCE_DEFAULT = 2;
const DISABLED = 0;

const getDrawerFaceHeightFieldset = (structures: Structure[]) => {
    let drawerFieldset: Fieldset;
    structureLoop: for (const structure of structures) {
        if (structure.name == 'Drawers') {
            fieldsetLoop: for (const fieldset of structure.fieldsets) {
                if (fieldset.name === 'drawer_face_heights') {
                    drawerFieldset = fieldset;
                    break fieldsetLoop;
                }
            }
            if (typeof drawerFieldset !== 'undefined') {
                break structureLoop;
            }
        }
    }

    return drawerFieldset;
};

export const useMapDrawerFaceType = () => {
    const {fetchProductDefaultValues} = useGetProductDefaultQuery();
    const fetchStructure = useFetchStructure();

    return async (product: Partial<Product>) => {
        const structure = await fetchStructure(product.cabinet_type);
        const productDefault = await fetchProductDefaultValues(
            product.cabinet_type
        );

        if (productDefault.cabinet_form_fields) {
            const formFields = productDefault.cabinet_form_fields;
            let drawerAmount = product.drawer_amount;
            let drawerBottom = product.cabinet_drawer_bottom;
            let drawerTop = product.cabinet_drawer_top;

            if (formFields.cabinet_drawer_top == DISABLED) {
                drawerTop = 0;
            }

            if (formFields.cabinet_drawer_bottom == DISABLED) {
                drawerBottom = 0;
            }
            if (formFields.drawer_amount == DISABLED) {
                drawerAmount = 0;
            }

            if (drawerAmount > 0) {
                if (
                    formFields.drawer_face_height ==
                    HIDDEN_FORCE_ROOM_DEFAULT_VALUE
                ) {
                    const drawerFieldset = getDrawerFaceHeightFieldset(
                        structure.originalStructure
                    );

                    if (
                        drawerFieldset &&
                        drawerFieldset?.options &&
                        drawerFieldset.options?.totalHeight
                    ) {
                        const drawerHeight = Excel.calculate<number>(
                            drawerFieldset.options.totalHeight,
                            product
                        );

                        const availableHeight =
                            drawerHeight - drawerTop - drawerBottom;

                        product.drawer_face_height = new Array(drawerAmount)
                            .fill(undefined)
                            .map(() => availableHeight / drawerAmount);
                    }
                } else if (
                    formFields.drawer_face_height == HIDDEN_FORCE_DEFAULT
                ) {
                    if (
                        'drawer_face_height' in
                        productDefault.cabinet_form_field_defaults
                    ) {
                        const faceHeight =
                            productDefault.cabinet_form_field_defaults
                                .drawer_face_height;

                        product.drawer_face_height = new Array(drawerAmount)
                            .fill(undefined)
                            .map(() => Number(faceHeight));
                    }
                }
            }
        }

        return product;
    };
};
