import {Bench} from 'components/customer/BTM/entity/Bench';
import {IBenchOption} from 'components/customer/BTM/entity/IBenchOption';
import {BenchOption} from 'components/customer/BTM/entity/BenchOption';
import {getShape, Shape} from 'components/customer/BTM/entity/Shape';
import {
    mapButtJoin,
    mapFormFactors,
    mapMaterialDimension,
} from 'components/customer/BTM/helper/materialMappers';

export const mapMaterialFormFactor = (bench: Bench) => {
    bench.material = bench?.material ? mapFormFactors(bench.material) : null;

    return bench;
};

export const mapMaterialChange = (bench: Bench) => {
    bench.material = mapMaterialDimension(bench.material);

    return bench;
};

export const mapOptionBack = (bench: Bench) => {
    bench.options = bench.options.map(
        (option) =>
            ({
                ...option,
                value: JSON.parse(String(option.value)) as IBenchOption,
            } as BenchOption<IBenchOption>)
    );

    return bench;
};

export const mapShape = (bench: Bench) => {
    if (bench.shape) {
        bench.shape.type = getShape(bench.shape.identifier);
    }

    return bench;
};

interface DimensionOption extends IBenchOption {
    length: number;
}
export const mapDimension = (bench: Bench) => {
    const dimensions = bench.options.find(
        (option) => option.name == 'dimensions'
    );

    if (dimensions) {
        const sides = dimensions.value.map(
            (dimension: DimensionOption) => dimension.length
        );

        if (bench?.shape?.type == Shape.SQR) {
            bench.dimension = `${sides[0]} x ${sides[1]}`;
        } else if (bench?.shape?.type == Shape.ANG) {
            const [width, , , , , height] = sides;
            bench.dimension = `${height} x ${width}`;
        } else if (bench?.shape?.type == Shape.USHAPE) {
            const [width, rHeight, , , , , , lHeight] = sides;
            bench.dimension = `${lHeight} x ${width} x ${rHeight}`;
        }
    }

    return bench;
};

export const mapMaterialButtJoin = (bench: Bench) => {
    bench.material = mapButtJoin(bench.material);
    return bench;
};
