import DBAbstract from './DBAbstract';
/**
 *
 *
 * @class JobRepository
 * @extends {DBAbstract}
 */
class JobRepository extends DBAbstract {
    /**
     * Creates an instance of JobRepository.
     * @memberof JobRepository
     */
    constructor() {
        super();

        this.record = {
            job: 'job',
        };
    }

    /**
     *
     *
     * @param {*} data
     * @return {*}
     * @memberof JobRepository
     */
    async addJob(data) {
        return await this.add(this.record.job, data, true);
    }

    /**
     *
     *
     * @param {*} jobId
     * @return {*}
     * @memberof JobRepository
     */
    async deleteJob(jobId) {
        return await this.delete(this.record.job, jobId);
    }
}

export default JobRepository;
