import React from 'react';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';

export interface BodyContentProps {
    eventKey: Menu | Menu[];
    children: React.ReactNode;
}

export const BodyContent = ({children}: BodyContentProps) => {
    return children;
};
