import {store} from 'store/customer/storeSetup';
import {MaterialApi} from 'components/customer/Materials/store/materialApi';
import {Material} from 'components/customer/Materials/entity';

const matchMaterial = (material: Material, materials: Material[]) => {
    // Match all param
    let matchingMaterial = materials.find(
        (match) =>
            match.name == material.name &&
            match.brand.name == material.brand.name &&
            match.finish == material.finish &&
            match.thickness == material.thickness
    );

    if (matchingMaterial) return matchingMaterial;

    matchingMaterial = materials.find(
        (match) =>
            match.name == material.name &&
            match.brand.name == material.brand.name &&
            match.finish == material.finish
    );

    if (matchingMaterial) return matchingMaterial;

    matchingMaterial = materials.find(
        (match) =>
            match.name == material.name &&
            match.brand.name == material.brand.name
    );

    if (matchingMaterial) return matchingMaterial;

    matchingMaterial = materials.find((match) => match.name == material.name);

    if (matchingMaterial) return matchingMaterial;
};

export const getMaterial = async (
    id: string,
    cabinetType: number,
    manufacturerId: number,
    index: number
) => {
    try {
        const {data} = await store.dispatch(
            MaterialApi.endpoints.getMaterial.initiate(
                {
                    keywords: id,
                    cabinetType,
                    manufacturerId,
                },
                {forceRefetch: false}
            )
        );

        if (data && data.data) {
            return data.data;
        }

        const state = store.getState();
        const materialData = state.materials.materials[Number(index)];

        if (materialData) {
            const currentMaterial = materialData.material;

            if (currentMaterial) {
                const {data} = await store.dispatch(
                    MaterialApi.endpoints.searchMaterials.initiate(
                        {
                            keywords: currentMaterial.name,
                            cabinetType,
                        },
                        {
                            forceRefetch: false,
                        }
                    )
                );

                if (data && data.data && data.data.length) {
                    const material = matchMaterial(currentMaterial, data.data);

                    if (material) return material;
                }
            }
        }

        const {data: materialList} = await store.dispatch(
            MaterialApi.endpoints.listMaterials.initiate(
                {
                    cabinetType,
                    manufacturerId,
                },
                {
                    forceRefetch: false,
                }
            )
        );

        if (materialList && materialList.data && materialList.data.length > 0) {
            return materialList.data[0];
        }
    } catch (e) {
        console.log(e);
    }
};
