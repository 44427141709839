import React from 'react';
import styled from 'styled-components';
import {Thicknesses} from 'components/customer/BTM/Material/Thicknesses';
import {MaterialSearch} from 'components/customer/BTM/Material/MaterialSearch';

export const Title = styled.h1`
    margin: 0;
    background: none !important;
    color: rgb(var(--primary_colour));
    padding: 0 !important;
    border-radius: 0 !important;
`;

const MaterialLayout = styled.div`
    padding: 30px 0 30px 30px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .material-search {
        flex: 1;
    }
`;

export const Material = () => {
    return (
        <MaterialLayout>
            <Thicknesses />
            <MaterialSearch />
        </MaterialLayout>
    );
};
