import {useEffect} from 'react';
import {useAppSelector, useAppDispatch} from 'store/customer';
import {
    selectMaterialType,
    selectThickness,
    defaultThicknessSet,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {useLazyGetThicknessQuery} from 'components/customer/BTM/store/btmApi';
import {genericMessageHandler} from 'shared/helpers';
import {useNotificationContext} from 'contexts';

export const useFetchThickness = () => {
    const {notify} = useNotificationContext();
    const dispatch = useAppDispatch();
    const materialType = useAppSelector(selectMaterialType, shallowEqual);
    const [getThicknesses, {data: thicknesses}] = useLazyGetThicknessQuery();
    const thickness = useAppSelector(selectThickness);

    const fetchThicknesses = async (type: number) => {
        try {
            const {data: thicknesses} = await getThicknesses(
                {materialType: type},
                true
            );

            if (thicknesses && thicknesses.length > 0) {
                // NOTE: WE are simply checking if 33 is in the list
                // and if it is, we set it as the default thickness.
                // We might want this to be more dynamic in the future
                // like manufacturers can actually select default thickness
                // and we can store it in the database.
                const thickness33 = thicknesses.find(
                    (thickness) => thickness === 33
                );

                if (thickness33) {
                    dispatch(defaultThicknessSet(thickness33));
                } else {
                    dispatch(defaultThicknessSet(thicknesses[0]));
                }
            }
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch thicknesses.',
            });
        }
    };

    useEffect(() => {
        if (materialType) {
            void fetchThicknesses(materialType.id);
        }
    }, [materialType, thickness]);

    return {
        thickness,
        thicknesses,
    };
};
