import DBAbstract from './DBAbstract';

/**
 *
 * NOTE: Delete this when User.ts changes has been made
 * and this class is no longer required.
 *
 * @class UserRepository
 * @extends {DBAbstract}
 */
class UserRepository extends DBAbstract {
    /**
     * Creates an instance of UserRepository.
     * @memberof UserRepository
     */
    constructor() {
        super();

        this.record = 'customer';
    }
}

export default UserRepository;
