import {Join} from 'components/customer/BTM/entity/Join';
import {Colors, getColour} from 'components/customer/BTM/paper/draw';
import {cloneDeep} from 'lodash';
import {canvasPoint} from 'components/customer/BTM/paper/shape';
import {Path as PaperPath, Color} from 'paper';
import {joinSet} from 'components/customer/BTM/store/btmSlice';
import {store} from 'store/customer/storeSetup';
import {JoinType} from 'components/customer/BTM/entity/JoinType';

/**
 * This function that draws the join in the preview.
 *
 * @param {HTMLCanvasElement} canvas The canvas that holds the bench preview.
 * need this object to display mouse pointer as cursor when user mouseover the join
 * @param {Join[]} joins The joins data, stored in btmSlice
 * @param {number} scale Scale for the preview calculated in draw.ts
 * @param {boolean} drawJoins Whether to draw all the joins or not. If this is false
 * only selected join is drawn in the preview
 * @param {boolean} invertColor When this is true selected join is displayed as gray
 * @param {boolean} showPointer Determines whether to show mouse as a pointer or not
 * in the preview while customer hovers over the join
 * line
 * @param {Colors} colors The colors object that holds the primary and secondary color
 */
export default (
    canvas: HTMLCanvasElement,
    joins: Join[],
    scale: number,
    drawJoins: boolean,
    invertColor: boolean,
    showPointer: boolean,
    colors: Colors
) => {
    if (typeof joins === 'undefined') {
        return;
    }

    const secondaryColor = getColour(colors.secondary);
    cloneDeep(joins)
        // Mapping the index in join object before any filtering so that
        // we can reference to the correct join later
        .map((join, index) => {
            join.index = index;

            return join;
        })
        // If drawJoins is true get all joins, get only selected join if it is not
        .filter((join) => drawJoins || (!drawJoins && join.selected))
        // Do not draw join if it is disbaled
        .filter(
            (join) =>
                typeof join.disabled == 'undefined' || join.disabled == false
        )
        // Sorting the join based on selected status, as we need to draw
        // selected join on top of others
        .sort((a, b) => {
            return Number(a.selected) - Number(b.selected);
        })
        .forEach((join) => {
            const points = join.points.map((point) =>
                canvasPoint(point, scale)
            );

            const gray = new Color(0.88, 0.88, 0.88);
            // This is the actual join line
            const join_ = new PaperPath(points);

            // Drawing transparent thick line over joins to for proper
            // event handling.

            // The stroke joins will not add event on the gaps in between
            // dashes and also the join is very thin to accurately activate
            // event. Hence, this thick and invisible line above join to
            // effectively handle events.
            const joinBackground = new PaperPath(points);
            joinBackground.strokeColor = new Color(1, 1, 1, 0.001);
            joinBackground.strokeWidth = 10;

            // Paper js does not have style on line as it uses canvas.
            // This trickery actually modifies the canvase's style to
            // show mouse pointer when user puts mouse over the line.
            joinBackground.onMouseEnter = () => {
                if (showPointer && join.joinType != JoinType.BUTT_JOIN) {
                    canvas.style.cursor = 'pointer';
                }
            };

            // Reverse mouseEnter event to reset the pointer
            joinBackground.onMouseLeave = () => {
                if (showPointer && join.joinType != JoinType.BUTT_JOIN) {
                    canvas.style.cursor = 'initial';
                }
            };

            // Event to select the join
            joinBackground.onClick = () => {
                if (showPointer && join.joinType != JoinType.BUTT_JOIN) {
                    store.dispatch(joinSet(true, join.index, join.side));
                }
            };

            if (join.selected) {
                join_.strokeColor = invertColor ? gray : secondaryColor;
            } else {
                join_.strokeColor = gray;
            }
            join_.strokeWidth = 4.25;
            join_.dashArray = [12, 9];
            join_.strokeCap = 'round';
        });
};
