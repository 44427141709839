// @flow
import DB from './DB';
/**
 *
 *
 * @export
 * @class DBAbstract
 */
export default class DBAbstract {
    /**
     * Creates an instance of DBAbstract.
     * @memberof DBAbstract
     */
    constructor() {
        if (this.constructor === DBAbstract) {
            throw new Error('Cannot instantiate abstract class');
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {boolean} [write=false]
     * @return {*}
     * @memberof DBAbstract
     */
    async getStore(record: string, write: boolean = false): Promise<any> {
        try {
            const database = await DB.getInstance();
            if (database == null) return null;

            const transaction = database.transaction(
                [record],
                write ? 'readwrite' : 'readonly'
            );

            transaction.onerror = (event) => {
                throw new Error(
                    `Database error: ${event.target.error.message}`
                );
            };

            transaction.oncomplete = () => {
                database.close();
            };

            return transaction.objectStore(record);
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} store
     * @param {*} data
     * @param {*} index
     * @param {*} indexValue
     * @memberof DBAbstract
     */
    genericAdd(
        store: IDBObjectStore,
        data: any,
        index: string | boolean,
        indexValue: string
    ) {
        if (!index) {
            store.add(data);
        } else {
            let storeIndex;
            let request;

            if (index !== true) {
                storeIndex = store.index(index);
                request = storeIndex.get(indexValue);
            } else request = store.get(data.id);

            request.onsuccess = () => {
                const dataFromDb = request.result ? request.result : {};

                store.put({...dataFromDb, ...data});
            };

            request.onerror = (event) => {
                throw new Error(
                    `Database Error: ${event.target.error.message}`
                );
            };
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {*} data
     * @param {boolean} [index=false]
     * @param {*} indexValue
     * @return {*}
     * @memberof DBAbstract
     */
    async add(
        record: any,
        data: any,
        index: boolean = false,
        indexValue: string
    ): Promise<any> {
        try {
            const database = await DB.getInstance();
            if (database == null) return true;

            const transaction = database.transaction([record], 'readwrite');
            const store = transaction.objectStore(record);

            if (Array.isArray(data))
                data.forEach((row) => {
                    this.genericAdd(store, row, index, indexValue);
                });
            else {
                this.genericAdd(store, data, index, indexValue);
            }

            return new Promise((resolve, reject) => {
                transaction.oncomplete = () => {
                    resolve(true);
                    database.close();
                };

                transaction.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @return {*}
     * @memberof DBAbstract
     */
    async readAll(record: string): Promise<any> {
        try {
            const store = await this.getStore(record);
            if (store == null) return [];

            const request = store.getAll();

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {*} search
     * @param {boolean} [index=false]
     * @param {boolean} [singleValue=false]
     * @return {*}
     * @memberof DBAbstract
     */
    async readById(
        record: string,
        search: string,
        index: boolean | string = false,
        singleValue: boolean = false
    ): Promise<any> {
        try {
            let store = await this.getStore(record);
            if (store == null) return [];
            if (index) store = store.index(index);

            return new Promise((resolve, reject) => {
                let request;

                if (!singleValue) {
                    if (Array.isArray(search)) {
                        search = search.sort();
                        request = store.openCursor();
                    } else {
                        request = store.openCursor(IDBKeyRange.only(search));
                    }
                } else {
                    request = store.openCursor(search, 'next');
                }

                const records = [];

                request.onsuccess = () => {
                    const cursor = request.result;

                    if (cursor) {
                        if (
                            Array.isArray(search) &&
                            !singleValue &&
                            ((index && !search.includes(cursor.value[index])) ||
                                (!index && !search.includes(cursor.value.id)))
                        ) {
                            cursor.continue();
                            return;
                        }

                        records.push(cursor.value);
                        cursor.continue();
                    } else {
                        resolve(records);
                    }
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {*} id
     * @return {*}
     * @memberof DBAbstract
     */
    async delete(record: string, id: any): Promise<any> {
        try {
            const store = await this.getStore(record, true);
            if (store == null) return true;
            const request = store.delete(id);

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    resolve(id);
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {*} key
     * @param {*} value
     * @return {*}
     * @memberof DBAbstract
     */
    async deleteByKey(record: string, key: string, value: any): Promise<any> {
        try {
            const store = await this.getStore(record, true);
            if (store == null) return true;
            const index = store.index(key);

            const request = index.openCursor(IDBKeyRange.only(value));
            const allJobs = [];

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    const cursor = request.result;

                    if (cursor) {
                        allJobs.push(cursor.value.id);

                        cursor.continue();
                    } else {
                        if (allJobs.length) {
                            for (let i = 0; i < allJobs.length; i++) {
                                const deleteRequest = store.delete(allJobs[i]);

                                if (i == allJobs.length - 1) {
                                    deleteRequest.onsuccess = () =>
                                        resolve(true);
                                }
                            }
                        } else resolve(true);
                    }
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @return {*}
     * @memberof DBAbstract
     */
    async truncate(record: string): Promise<void> {
        try {
            const store = await this.getStore(record, true);
            if (store == null) return;
            const request = store.clear();

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }

    /**
     *
     *
     * @param {*} record
     * @param {boolean} [value=false]
     * @param {boolean} [index=false]
     * @return {*}
     * @memberof DBAbstract
     */
    async count(
        record: string,
        value: string | boolean = false,
        index: string | boolean = false
    ): Promise<any> {
        try {
            let store = await this.getStore(record, true);
            if (store == null) return;

            if (index) store = store.index(index);

            let request;

            if (!value) request = store.count();
            else request = store.count(value);

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = (event) => {
                    reject(
                        new Error(
                            `Database Error: ${event.target.error.message}`
                        )
                    );
                };
            });
        } catch (error) {
            throw error;
        }
    }
}
