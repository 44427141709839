import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Path} from 'components/customer/BTM/entity/Path';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {profiledSet} from 'components/customer/BTM/store/btmSlice';
import {isPointOnPath} from 'components/customer/BTM/helper/joinRestrictions';
import {store} from 'store/customer';
import {cloneDeep} from 'lodash';

/**
 * This function checks if the side supplied are predefined
 * side of the bench that cannot be profiled unless enroll
 * is enabled on selected profile
 *
 * @param {Side} side side to check
 * @param {Shape} shape shape to check
 * @return {boolean}
 */
const checkSide = (side: Side, shape: Shape) => {
    if (shape == Shape.SQR) {
        return side == Side.B || side == Side.D;
    }

    if (shape == Shape.ANG) {
        return side == Side.B || side == Side.E;
    }

    if (shape == Shape.USHAPE) {
        return side == Side.C || side == Side.G;
    }

    return false;
};

/**
 * This function only runs for postformed profiled sides.
 * This function resets the side of the bench if new
 * pofile is not enroll enabled. Otherwise, it simply
 * resets the profile.
 *
 * @param {Path} path Path of the side to reset profile on
 * @param {BenchtopEdgeProfile} profile New profile to set on the side
 * @param {number} index index of the side in paths array
 * @param {Side} side side from {Side} associated on the path
 */
export const resetPostformedProfile = (
    path: Path,
    profile: BenchtopEdgeProfile,
    index: number,
    side: Side
) => {
    const state = store.getState();
    const shape = state.btm.type;

    if (
        profile.restrict_adjacent &&
        !profile.is_end_roll_available &&
        checkSide(side, shape.type)
    ) {
        store.dispatch(
            profiledSet(Edge.NOT_EDGED, undefined, index, path.side)
        );
    } else {
        store.dispatch(profiledSet(Edge.PROFILED, profile, index, path.side));
    }
};

/**
 * This function runs before corner is deleted from the bench.
 * Basically finds the sides that is touching the corner and
 * sets the side to Not edged if profile is postformed.
 *
 * @param {Corner} corner corner of the bench that's beind deleted
 */
export const resetPostformedProfileOnCornerDelete = (corner: Corner) => {
    const state = store.getState();
    const paths = state.btm.paths;
    const shape = state.btm.type;

    const touchingPaths = cloneDeep(paths)
        .map((path, index) => ({...path, index}))
        .filter((path) => path.side != null)
        .filter((path) => isPointOnPath(corner.point, path));

    if (touchingPaths) {
        touchingPaths.forEach((path) => {
            if (
                checkSide(path.side, shape.type) &&
                path.edged == Edge.PROFILED
            ) {
                store.dispatch(
                    profiledSet(
                        Edge.NOT_EDGED,
                        undefined,
                        path.index,
                        path.side
                    )
                );
            }
        });
    }
};
