/**
 * @flow
 * TODO: DELETE THIs
 * @export
 * @class ProductSize
 */
export default class ProductSize {
    baseDepth: number;
    baseHeight: number;
    customerId: number;
    id: number;
    name: string;
    tallDepth: number;
    tallHeight: number;
    upperDepth: number;
    upperHeight: number;
    userLevel: number;
}
