import {useAppSelector} from 'store/customer';
import {
    selectBrandsEdgeIds,
    selectDoor,
    selectMaterialType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {
    useLazyListEdgeBrandsQuery,
    useLazyListEdgeFinishesQuery,
} from 'components/customer/Materials/store/materialApi';
import {useDebounce, useDebouncedCallback} from 'use-debounce';
import {useCallback, useEffect, useRef} from 'react';
import {Door} from 'components/customer/Materials/entity';
import {Type} from 'components/customer/AdvancedMaterials/entity/Type';

export const useEdgeBrandsAndFinishes = (
    show: boolean,
    hasDoor: boolean,
    cabinetType: number
) => {
    const door = useAppSelector(selectDoor, shallowEqual);
    const selectedBrands = useAppSelector(selectBrandsEdgeIds, shallowEqual);
    const materialType = useAppSelector(selectMaterialType, shallowEqual);

    const initiated = useRef(false);

    const [debouncedBrands] = useDebounce(selectedBrands, 1000);

    const [
        getbrands,
        {
            data: brandsData,
            isLoading: isLoadingBrands,
            isFetching: isFetchingBrands,
        },
    ] = useLazyListEdgeBrandsQuery();
    const [
        getFinishes,
        {
            data: finishesData,
            isLoading: isLoadingFinishes,
            isFetching: isFetchingFinishes,
        },
    ] = useLazyListEdgeFinishesQuery();

    const fetchBrands = useDebouncedCallback(
        useCallback(
            (door: Door, materialType: Type) => {
                if (((hasDoor && door) || !hasDoor) && materialType) {
                    void getbrands(
                        {
                            doorFilter:
                                hasDoor && door ? door.filter_name : undefined,
                            cabinetType,
                            materialType: materialType?.id,
                        },
                        true
                    );
                }
            },
            [hasDoor, cabinetType]
        ),
        1000
    );

    const fetchFinishes = useDebouncedCallback(
        useCallback(
            (door: Door, materialType: Type, brands: string[]) => {
                if (((hasDoor && door) || !hasDoor) && materialType) {
                    void getFinishes(
                        {
                            doorFilter:
                                hasDoor && door ? door.filter_name : undefined,
                            cabinetType,
                            brands:
                                brands && brands.length ? brands : undefined,
                            materialType: materialType?.id,
                        },
                        true
                    );
                }
            },
            [hasDoor, cabinetType]
        ),
        1000
    );

    useEffect(() => {
        if (initiated.current && !show) {
            return;
        }

        if (!initiated.current) {
            if (((hasDoor && door) || !hasDoor) && materialType) {
                initiated.current = true;
            }
        }

        fetchBrands(door, materialType);
        fetchFinishes(door, materialType, debouncedBrands);
    }, [
        materialType,
        hasDoor,
        door,
        debouncedBrands,
        fetchBrands,
        fetchFinishes,
        show,
    ]);

    return {
        brands: brandsData?.data,
        isLoadingBrands,
        isFetchingBrands,
        finishes: finishesData?.data,
        isLoadingFinishes,
        isFetchingFinishes,
    };
};
