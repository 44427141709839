import {Corner} from 'components/customer/BTM/entity/Corner';
import {Path} from 'components/customer/BTM/entity/Path';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {store} from 'store/customer/storeSetup';
import {isPointOnPath} from 'components/customer/BTM/helper/joinRestrictions';
import {cloneDeep} from 'lodash';
import {BTMApi} from 'components/customer/BTM/store/btmApi';

/**
 * This function basically updates corner data with disabled information
 * based on the side that touches the corner.
 *
 * @param {Corner[]} corners Corners data of the bench see btmSlice
 * @param {Path[]} paths Paths data of the bench see btmSlice
 * @return {Corner[]}
 */
export const cornerRestrictions = async (corners: Corner[], paths: Path[]) => {
    const state = store.getState();
    const material = state.btm.material;

    if (material && corners) {
        const response = await store.dispatch(
            BTMApi.endpoints.listEdgeProfiles.initiate({
                materialId: material.id,
            })
        );

        if (response.isError) {
            console.log('No edge profiles');
            return corners;
        }

        const edgeProfiles = response?.data?.data;

        if (edgeProfiles && edgeProfiles.length > 0) {
            const updatedCorners = cloneDeep(corners).map((corner) => {
                // If corner is join this usually does not have notch and clips
                // at least, we are not handling this now.
                // if corner added through end profile then it must have corner and clips restriction
                if (corner.isJoin || corner?.addedThroughEndProfile) {
                    return corner;
                }
                corner.disabled = {
                    notch: false,
                    clip: false,
                };

                const point = corner.point;
                // Only needs to test against the sides the corner touches
                const touchingPaths = paths.filter((path) =>
                    isPointOnPath(point, path)
                );

                if (touchingPaths) {
                    touchingPaths.forEach((path) => {
                        if (path.edged == Edge.EDGED) {
                            // If the touching side is squared check the default edge profile restriction
                            const squaredEdge = edgeProfiles.find(
                                (profile) => profile.id == path.profile
                            );
                            if (squaredEdge.restrict_corner_clip) {
                                corner.disabled.clip = true;
                            }

                            if (squaredEdge.restrict_corner_notch) {
                                corner.disabled.notch = true;
                            }
                        }
                    });
                }

                return corner;
            });

            return updatedCorners;
        }
    }

    return corners;
};
