import {useCallback, useState} from 'react';
import {useConfirmationDialog} from 'shared';
import {useEventProcessor} from 'shared/events/useEventProcessor';
import {useHandleEmptyRooms} from 'components/customer/Job/helpers/useHandleEmptyRooms';
import {useJobPDFVerification} from 'components/customer/Job/helpers/useJobPDFVerification';
import {useRecessRailsValidation} from 'components/customer/Job/helpers/useRecessRailsValidation';
import {useRequestedDeliveryDateHandler} from 'shared/helpers/useRequestedDeliveryDateHandler';
import {useSubmitJob} from 'components/customer/Job/helpers/useSubmitJob';
import {useQFPproductPrompt} from 'components/customer/Job/helpers/useQFPProductPrompt';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';

export const useJobSubmitHandler = () => {
    const {notify} = useNotificationContext();
    const [loader, setLoader] = useState(false);
    const {setMessage, setTitle, dialog, showDialog, hideDialog} =
        useConfirmationDialog();

    const events = [
        useQFPproductPrompt(showDialog, hideDialog),
        useRequestedDeliveryDateHandler(showDialog, hideDialog),
        useRecessRailsValidation(showDialog, hideDialog),
        useHandleEmptyRooms(showDialog, hideDialog),
        useJobPDFVerification(showDialog, hideDialog, setTitle, setMessage),
        useSubmitJob(),
    ];

    const {processEvents} = useEventProcessor(events);

    const handleJobSubmission = useCallback(
        async (event: React.MouseEvent) => {
            setLoader(true);
            try {
                await processEvents(event);
            } catch (error) {
                // let google analytics know that an error occurred
                // and what it is.
                // eslint-disable-next-line no-console
                console.error('Job submission error: ', error);
                genericMessageHandler(notify, {
                    message:
                        'An error occurred while submitting the job please try again later.',
                });
            } finally {
                setLoader(false);
            }
        },
        [processEvents]
    );

    return {
        loader,
        dialog,
        handleJobSubmission,
    };
};
