import React from 'react';
import {Outlet} from 'react-router-dom';
import {useAppSelector} from 'store/customer';
import {selectRedirecting} from 'components/customer/Auth/store/authSlice';
import {Loader} from 'shared/helpers';

export const AuthGuard = () => {
    const isRedirecting = useAppSelector(selectRedirecting);
    if (isRedirecting) {
        // display a loader rather continuing to load the actual page when location.replace is triggered
        return <Loader loader={true} />;
    }

    return <Outlet />;
};
