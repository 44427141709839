import {useJobContext, useProductContext} from 'contexts';
import {useFormikContext} from 'formik';
import {useDefaultMaterials} from 'components/customer/Materials/helper/useDefaultMaterials';
import {getCabinetFields} from 'shared/helpers';
import {ExteriorNonSupply} from 'components/customer/Materials/entity';

const useDoorFacesHelper = (
    setFieldValue: (name: string, value: any) => void
) => {
    const {loadDefaultMaterialData} = useDefaultMaterials();
    const {setMaterialFace, formStructure} = useProductContext();
    const {room} = useJobContext();
    const {values} = useFormikContext();

    const handleDoorFaceRadioChange = async (targetValue: boolean) => {
        const productInfo = getCabinetFields(formStructure);

        const materialDefaults = {
            hasExterior: true,
            hasCarcase: false,
            hasDoor: productInfo.hasExteriorDoor,
            exteriorColour: room.extColour,
            edgeExteriorColour: room.extEdgeColour,
            door: room.doorStyle,
            cabinetType: values.cabinet_type,
        };

        if (!targetValue) {
            materialDefaults.exteriorColour = ExteriorNonSupply.MATERIAL_ID;
            materialDefaults.edgeExteriorColour = ExteriorNonSupply.EDGE_ID;
            materialDefaults.door = ExteriorNonSupply.DOOR_ID;
        }

        await loadDefaultMaterialData(materialDefaults);
        setFieldValue('include_drawer_faces', targetValue);
        setFieldValue('include_drawer_faces_ext', targetValue);
        setMaterialFace(targetValue);
    };

    return {handleDoorFaceRadioChange};
};

export default useDoorFacesHelper;
