import {genericMessageHandler} from 'shared/helpers';
import {
    PredictionDetailsInterface,
    useLazyGetPredictionDetailsQuery,
} from 'components/customer/Settings/store/settingsApi';
import {useNotificationContext} from 'contexts';

export const useGetPredictionDetails = () => {
    const {notify} = useNotificationContext();
    const [fetchPredictionDetails, {isLoading, isUninitialized}] =
        useLazyGetPredictionDetailsQuery();

    const getPredictionDetails = async (
        placeId: string
    ): Promise<PredictionDetailsInterface> => {
        try {
            const {data} = await fetchPredictionDetails(
                {
                    placeId: placeId,
                },
                true
            );

            if (data) {
                return data;
            }
        } catch (e) {
            genericMessageHandler(notify, {
                message: 'Could not fetch address',
            });
        }

        return null;
    };

    return {
        isLoading,
        isUninitialized,
        getPredictionDetails,
    };
};
