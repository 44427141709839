import {ValidStructureInterface} from 'components/customer/Product/entity/ValidStructureInterface';

export const mapBooleans = (validStructure: ValidStructureInterface) => {
    // TODO: remove this once qfp is consilidated later
    validStructure.hasBottomBorders =
        validStructure.hasBottomBorders == true ||
        validStructure.hasBottomBorders == '1';
    validStructure.hasEdging =
        validStructure.hasEdging == true || validStructure.hasEdging == '1';
    validStructure.hasCutout =
        validStructure.hasCutout == true || validStructure.hasCutout == '1';
    validStructure.hasDoorType =
        validStructure.hasDoorType == true || validStructure.hasDoorType == '1';
    validStructure.hasDoorHinge =
        validStructure.hasDoorHinge == true ||
        validStructure.hasDoorHinge == '1';
    validStructure.hasDoorHang =
        validStructure.hasDoorHang == true || validStructure.hasDoorHang == '1';
    validStructure.hasHorizontalGrain =
        validStructure.hasHorizontalGrain == true ||
        validStructure.hasHorizontalGrain == '1';
    validStructure.hasDoubleSided =
        validStructure.hasDoubleSided == true ||
        validStructure.hasDoubleSided == '1';
    validStructure.hasCustomColour =
        validStructure.hasCustomColour == true ||
        validStructure.hasCustomColour == '1';

    return validStructure;
};
