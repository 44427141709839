import {Material} from 'components/customer/Materials/entity';

export const getAllDoorFilters = (material: Material) => {
    let doorFilters: string[] = [];

    if (material.door_filters && material.door_filters.length > 1) {
        doorFilters = material.door_filters.map(
            (material) => material.door_filter
        );
    } else {
        doorFilters = [material.door_filter];
    }

    return doorFilters;
};
