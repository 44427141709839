import {Path} from 'components/customer/BTM/entity/Path';
import {getSorroundingIndexes} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';

export const restrictionsMap = {
    [Shape.SQR]: [Side.B, Side.D],
    [Shape.ANG]: [Side.B, Side.E],
    [Shape.USHAPE]: [Side.C, Side.G],
};

/**
 * This function simply returns the adjacent paths of the path at index
 * in the direction specified by clockwise parameter.
 *
 * Adjacent paths are all the paths that before or after path at index
 * till it reaches a side.
 *
 * For example: In t he below rectangle benchtop. If we are checking at index 0
 * which is the path at the top left corner, the adjacent paths clockwise are
 * path at index 1 and 2. and anticlockwise is just 6.
 *  _________
 *  |        \
 * --         |
 * |          |
 *  -----------
 *
 * clockwise = true -> returns the adjacent paths in the clockwise direction
 * clockwise = false -> returns the adjacent paths in the anti-clockwise direction
 *
 * @param {number} index index of the path to check
 * @param {Path[]} paths all the paths of the benchtop
 * @param {boolean} clockwise in which direction to look for the adjacent paths
 * @return {Path[]} adjacent paths
 */
export const getAdjacentPaths = (
    index: number,
    paths: Path[],
    clockwise = true
): Path[] => {
    const adjacentPaths: Path[] = [];

    let checkComplete = false;
    let checkIndex = index + 1;
    if (!clockwise) {
        checkIndex = index - 1;
    }

    while (!checkComplete) {
        if (checkIndex >= paths.length && clockwise) {
            checkIndex = 0;
        }

        if (checkIndex <= 0 && !clockwise) {
            checkIndex = paths.length - 1;
        }

        const path = paths[Number(checkIndex)];

        if (path != null) {
            adjacentPaths.push(path);

            if (path.side != null) {
                checkComplete = true;
            }
        }

        if (clockwise) {
            checkIndex += 1;
        } else {
            checkIndex -= 1;
        }
    }

    return adjacentPaths;
};

/**
 * This function basically checks if the path at index is a side
 * or cutoffs corresponding to the restricting sides.
 *
 * @param {number} index index of the path to check
 * @param {Path[]} paths all the paths of the benchtop
 * @param {Shape} shape Shape of the benchtop
 * @return {boolean}
 */
export const checkPath = (
    index: number,
    paths: Path[],
    shape: Shape
): boolean => {
    const side = paths[Number(index)].side;
    const restrictions = restrictionsMap[shape];

    // If the path is a side, check if it is a restricting side
    if (typeof side !== 'undefined' && side != null) {
        return restrictions.includes(side);
    }

    // Extract the paths before and after the path at index
    const surroundingIndexes = getSorroundingIndexes(index, paths.length);
    const [beforeIndex, afterIndex] = surroundingIndexes;
    const pathBefore = paths[Number(beforeIndex)];
    const pathAfter = paths[Number(afterIndex)];

    let pathsToCheck = [pathBefore, pathAfter];

    // If the path before is a cutoff, basically looping until
    // we get to a side.
    if (pathBefore.side == null) {
        pathsToCheck = [
            ...pathsToCheck,
            ...getAdjacentPaths(beforeIndex, paths, false),
        ];
    }

    // If the path after is a cutoff, basically looping until
    // we get to a side.
    if (pathAfter.side == null) {
        pathsToCheck = [
            ...pathsToCheck,
            ...getAdjacentPaths(afterIndex, paths, true),
        ];
    }

    // Checking if on of the side that touches the cutoff is a restricting side
    const sidesToCheck = pathsToCheck
        .map((path) => path.side)
        .filter((side) => typeof side !== 'undefined');

    return sidesToCheck.some((side) => restrictions.includes(side));
};

/**
 *
 * @param {Path[]} paths all the paths of the benchtop
 * @param {BenchtopEdgeProfile} edgeProfile edge profile to check for restriction
 * @param {number} index index of the path to check
 * @param {Shape} shape Shape of the benchtop
 * @return {boolean}
 */
export const checkAdjacentRestriction = (
    paths: Path[],
    edgeProfile: BenchtopEdgeProfile,
    index: number,
    shape: Shape
): boolean => {
    if (checkPath(index, paths, shape)) {
        return (
            !edgeProfile.is_postformed_profile && edgeProfile.restrict_adjacent
        );
    }

    return false;
};
