import {Structure} from 'components/customer/Product/entity';
import {useMemo} from 'react';

export const useRotatedFields = (validStructure: Structure[]) => {
    return useMemo(() => {
        if (validStructure) {
            const tabset = validStructure.find((tabset) => {
                const edgeFieldset = tabset.fieldsets.find((fieldset) => {
                    return (
                        (fieldset.name === 'door_edges' ||
                            fieldset.name === 'cabinet_edges') &&
                        fieldset.options.hasOwnProperty(
                            'qfpRotateBorderFields'
                        ) &&
                        fieldset.options.qfpRotateBorderFields == 1
                    );
                });

                return typeof edgeFieldset != 'undefined';
            });

            return typeof tabset != 'undefined';
        }
    }, [validStructure]);
};
