import {cloneDeep} from 'lodash';
import {toFixed} from 'shared/helpers';

export const getQFPFaceHeights = (
    quantity: number,
    totalHeight: number,
    gap: number,
    currentGap: number,
    manualUpdates: number[],
    currentFaceHeights: number[]
) => {
    let faceHeightValues = cloneDeep(currentFaceHeights);
    let updateGap = false;
    let clearManualEntries = false;
    const faceHeightSum = Math.round(
        currentFaceHeights.reduce((a, b) => a + b, 0)
    );

    if (currentGap != gap && manualUpdates.length > 0) {
        updateGap = true;
        const availableHeight =
            totalHeight -
            currentFaceHeights
                .filter((v, index) => manualUpdates.includes(index))
                .reduce((a, b) => a + b);
        const freeFaces = quantity - manualUpdates.length;

        faceHeightValues = currentFaceHeights.map((faceHeight, index) => {
            if (manualUpdates.includes(index)) {
                return faceHeight;
            }

            return toFixed(availableHeight / freeFaces, 2);
        });
    } else if (
        quantity != faceHeightValues.length ||
        faceHeightSum != totalHeight
    ) {
        faceHeightValues = Array.from(Array(quantity)).map(() =>
            toFixed(totalHeight / quantity, 2)
        );
        clearManualEntries = true;
    }

    return {
        faceHeightValues,
        updateGap,
        clearManualEntries,
    };
};
