import {ValidStructureInterface} from 'components/customer/Product/entity/ValidStructureInterface';
import {QFPPreviewInterface} from 'components/customer/QFPRedux/store/qfpSlice';

export const getProductPreview = (structure: ValidStructureInterface) => {
    return {
        hasBorders: structure.hasBorders,
        hasBottomBorders: structure.hasBottomBorders,
        hasEdging: structure.hasEdging,
        preview: structure.preview,
        hasCutout: structure.hasCutout,
        hasDoorType: structure.hasDoorType,
        isPanel: structure.isPanel,
        hasDoorHinge: structure.hasDoorHinge,
        hasDoorHang: structure.hasDoorHang,
        drawer_amount: structure.drawer_amount,
    } as QFPPreviewInterface;
};
