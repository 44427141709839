/**
 *
 *
 * @class DB
 */
class DB {
    /**
     * Creates an instance of DB.
     * @memberof DB
     */
    constructor() {
        if (this.constructor === DB) {
            throw new Error('No need to instantiate this class');
        }
    }

    /**
     *
     *
     * @static
     * @return {*}
     * @memberof DB
     */
    static getInstance() {
        if (!('indexedDB' in window)) {
            console.warn("This browser doesn't support IndexedDB");

            return null;
        }

        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = (event) => {
                resolve(null);
            };

            request.onsuccess = () => {
                // DB.setup is check if db needs to be setup
                if (!DB.setup || typeof DB.setup === 'undefined') {
                    resolve(request.result);
                }
            };

            request.onupgradeneeded = function (event) {
                DB.setup = true;

                const database = event.target.result;
                const existingStores = Array.from(database.objectStoreNames);
                const transaction = event.target.transaction;
                const stores = DB_STORES;

                stores &&
                    stores.forEach((store) => {
                        let storeObject;
                        const index = existingStores.indexOf(store.name);

                        if (index > -1) {
                            if (store.delete) {
                                database.deleteObjectStore(store.name);
                                existingStores.splice(index, 1);
                                return;
                            } else {
                                storeObject = transaction.objectStore(
                                    store.name
                                );
                            }
                        } else {
                            storeObject = database.createObjectStore(
                                store.name,
                                store.hasOwnProperty('options')
                                    ? store.options
                                    : {keyPath: 'id'}
                            );

                            existingStores.push(store.name);
                        }

                        if (
                            store.hasOwnProperty('indexes') &&
                            store.indexes.length
                        ) {
                            store.indexes.forEach((index) => {
                                if (
                                    !storeObject.indexNames.contains(index.name)
                                )
                                    storeObject.createIndex(
                                        index.name,
                                        index.keyPath,
                                        index.options
                                    );
                            });
                        }
                    });

                transaction.oncomplete = () => {
                    DB.setup = false;
                    resolve(database);
                };

                transaction.onerror = (event) =>
                    reject(
                        new Error(
                            `Database error: ${event.target.error.message}`
                        )
                    );
            };
        });
    }
}

export default DB;
