import {store} from 'store/customer';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Path} from 'components/customer/BTM/entity/Path';
import {Side} from 'components/customer/BTM/entity/Side';
import {setClippedCornersFromEndProfile} from 'components/customer/BTM/helper/setCorners';
import {cornerSet, pathsSet} from 'components/customer/BTM/store/btmSlice';
import {Point} from 'components/customer/BTM/entity/Point';

/**
 * This function is used to add or remove cutoffs
 * based on the profiles selected on benchtop.
 *
 * This also returns a boolean value if the path is profiled.
 *
 * @param {Path[]} paths
 * @param {BenchtopEdgeProfile[]} profiles
 * @param {Corber[]} corners
 * @param {Side} side
 * @param {Side[]} checkSides
 * @param {string[]} cornerNames
 * @param {number[]} cornerIndices
 * @param {Point[]} cornerPositions
 * @param {Direction[]} directions
 * @param {boolean} horizontal
 *
 */
export const addRemoveCutoffs = (
    paths: Path[],
    profiles: BenchtopEdgeProfile[],
    corners: Corner[],
    side: Side,
    checkSides: Side[],
    cornerNames: string[],
    cornerIndices: number[],
    cornerPositions: Point[],
    directions: Direction[],
    horizontal = true
) => {
    const path = paths.find((path) => path.side == side);
    if (path && profiles) {
        const profilesToCheck = checkSides.map((side) => {
            const pathCheck = paths.find((path) => path.side == side);
            const profile = profiles.find(
                (profile) => profile.id == pathCheck.profile
            );

            return profile;
        });

        profilesToCheck.forEach((profile, index) => {
            if (profile && profile.end_option && !profile.end_option.is_arc) {
                if (path.edged == Edge.PROFILED) {
                    // If the path is profiled and the adjacent sides
                    // is curved corner, apply cutoff to the corners
                    let radiuses = [
                        profile.end_option.default_radius_end,
                        profile.end_option.default_radius_start,
                    ];

                    if (!horizontal) {
                        radiuses = [
                            profile.end_option.default_radius_start,
                            profile.end_option.default_radius_end,
                        ];
                    }

                    setClippedCornersFromEndProfile(
                        cornerIndices[Number(index)],
                        cornerPositions[Number(index)],
                        radiuses,
                        directions[Number(index)],
                        cornerNames[Number(index)],
                        CornerType.Clip,
                        path.edged == Edge.PROFILED
                    );
                } else {
                    // If the path is not profiled, remove the cutoff
                    // if the adjacent sides is curved corner
                    const corner = corners.find(
                        (corner) => corner.name == cornerNames[Number(index)]
                    );
                    store.dispatch(
                        cornerSet(
                            {
                                ...corner,
                                isArc: undefined,
                                addedThroughEndProfile: undefined,
                                type: CornerType.None,
                                cutoff: false,
                            },
                            cornerIndices[Number(index)]
                        )
                    );
                }
            }
        });
    }
};

/**
 * Basically resets the end profiles if both sides
 * touching the end profiles are not postformed.
 *
 * @param {Path[]} paths
 * @param {Side} oppositeSide
 * @param {Side[]} restrictedSides
 */
export const resetEndProfileByOppositeSide = (
    paths: Path[],
    oppositeSide: Side,
    restrictedSides: Side[]
) => {
    const path = paths.find((path) => path.side == oppositeSide);
    if (path && path.edged != Edge.PROFILED) {
        const updatedPaths = paths.map((path) => {
            if (restrictedSides.includes(path.side)) {
                return {
                    ...path,
                    edgeHighlight: false,
                    edged: Edge.NOT_EDGED,
                    profile: undefined,
                };
            }

            return path;
        });

        store.dispatch(pathsSet(updatedPaths));
    }
};
