/**
 * @flow
 * TODO:: DELETE this file
 * @export
 * @class CabinetTop
 */
export default class CabinetTop {
    id: number;
    image: string;
    name: string;
    visibleInRoom: number;
}
