import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import AdvancedMaterials from 'components/customer/AdvancedMaterials';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectMaterialSearch,
    currentPageSet,
    loadingSet,
    materialBrandsSet,
    materialFinishesSet,
    materialSet,
    materialTypeSet,
    materialsAdd,
    materialsSet,
    propertiesSet,
    selectBrandIds,
    selectCurrentPage,
    selectThickness as selectFilterThickness,
    selectFinishIds,
    selectedMenuSet,
    thicknessesSet,
    Loader,
    totalRecordsSet,
    totalPagesSet,
    selectPageSize,
    BrowserType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {
    selectMaterial,
    selectThickness,
    materialSet as btmMaterialSet,
    thicknessSet,
} from 'components/customer/BTM/store/btmSlice';
import {shallowEqual} from 'react-redux';
import {
    mapBenchtopMaterial,
    mapBenchtopBrand,
    mapBenchtopFinish,
} from 'components/customer/AdvancedMaterials/helpers/mappers';
import {
    Menu,
    MenuItem,
} from 'components/customer/AdvancedMaterials/entity/Menu';
import {
    useLazyGetThicknessQuery,
    useLazyListBTMMaterialsQuery,
    useLazyListMaterialBrandsQuery,
    useLazySearchBTMMaterialsQuery,
    useLazyListFinishesQuery,
} from 'components/customer/BTM/store/btmApi';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';

const withBenchtop = (Component: typeof AdvancedMaterials) => {
    const advancedMaterialComponent = () => {
        const dispatch = useAppDispatch();
        const [getBrands] = useLazyListMaterialBrandsQuery();
        const [getThicknesses] = useLazyGetThicknessQuery();
        const [getMaterials] = useLazyListBTMMaterialsQuery();
        const [getFinishes] = useLazyListFinishesQuery();
        const [query] = useLazySearchBTMMaterialsQuery();

        const searchText = useAppSelector(selectMaterialSearch);
        const currentPage = useAppSelector(selectCurrentPage);
        const selectedThickness = useAppSelector(selectThickness, shallowEqual);
        const selectedBrands = useAppSelector(
            (store) => selectBrandIds(store),
            shallowEqual
        );
        const selectedFinishes = useAppSelector(
            (store) => selectFinishIds(store),
            shallowEqual
        );
        const selectedThicknessBrowser = useAppSelector(
            (store) => selectFilterThickness(store),
            shallowEqual
        );
        const material = useAppSelector(selectMaterial, shallowEqual);
        const pageSize = useAppSelector(selectPageSize);

        const selectedThicknessFilter = useMemo(() => {
            if (typeof selectedThicknessBrowser === 'undefined')
                return selectedThickness;

            return selectedThicknessBrowser;
        }, [selectedThickness, selectedThicknessBrowser]);

        const initialized = useRef(false);

        const {notify} = useNotificationContext();

        // This is called when Actual material search component
        // is first rendered. fired from effect there.
        const onShow = useCallback(() => {
            dispatch(
                propertiesSet({
                    hasMaterials: true,
                    hasThicknesses: true,
                    browserType: BrowserType.BENCHTOP,
                })
            );
            dispatch(selectedMenuSet(Menu.MATERIAL));

            const mappedMaterial = mapBenchtopMaterial(material);
            dispatch(materialSet(mappedMaterial));
            dispatch(materialTypeSet(mappedMaterial.type));

            void listThicknesses();
            void listBrands();
            void listFinishes();
            if (initialized.current) {
                void listMaterials();
            }
        }, [material, selectedThickness]);

        // This function is only called once and is responisble
        // for loading available material thicknesses for the filters.
        const listThicknesses = useCallback(async () => {
            try {
                const {data: thicknesses} = await getThicknesses(
                    {
                        materialType: material.type.id,
                    },
                    true
                );

                if (thicknesses) {
                    dispatch(
                        thicknessesSet(
                            thicknesses.map((thickness) => {
                                return {
                                    id: thickness,
                                    name: `${thickness}mm`,
                                    selected: thickness === selectedThickness,
                                } as MenuItem;
                            })
                        )
                    );

                    return selectedThickness;
                }
            } catch (error) {
                genericMessageHandler(notify, {
                    message: 'Error loading thicknesses',
                });
            }
        }, [material, selectedThickness]);

        // This function is only called once and is responsible
        // for loading available brands for the filters.
        const listBrands = useCallback(async () => {
            try {
                const {data: brands} = await getBrands(
                    {type: material.type.id},
                    true
                );

                if (brands) {
                    dispatch(materialBrandsSet(brands.map(mapBenchtopBrand)));
                }
            } catch (error) {
                dispatch(materialBrandsSet([]));
            }
        }, [material]);

        // this function is only called once and is responsible
        // for loading available finishes for the filters.
        const listFinishes = useCallback(async () => {
            try {
                const {data: finishes} = await getFinishes(
                    {type: material.type.id},
                    true
                );

                if (finishes) {
                    dispatch(
                        materialFinishesSet(finishes.map(mapBenchtopFinish))
                    );
                }
            } catch (error) {
                dispatch(materialFinishesSet([]));
            }
        }, [material]);

        // This function is used to list the materials based on
        // selected thickness and brands.
        const listMaterials = useCallback(
            async (
                text = '',
                brands: string[] = [],
                finishes: string[] = [],
                thickness?: number,
                currentPage = 1,
                pageSize = 35
            ) => {
                try {
                    let loading = Loader.LOADING_INITIAL;
                    if (initialized.current) {
                        if (currentPage == 1) {
                            loading = Loader.LOADING_DATA;
                        } else {
                            loading = Loader.LOADING_PAGINATION;
                        }
                    }

                    dispatch(loadingSet(loading));

                    let data;
                    if (text.length > 0) {
                        data = await query(
                            {
                                thickness,
                                type: material.type.id,
                                brands,
                                finishes,
                                currentPage,
                                pageSize,
                                keywords: text,
                            },
                            true
                        );
                    } else {
                        data = await getMaterials(
                            {
                                thickness:
                                    typeof thickness !== 'undefined'
                                        ? thickness
                                        : selectedThickness,
                                type: material.type.id,
                                brands,
                                finishes,
                                currentPage,
                                pageSize,
                            },
                            true
                        );
                    }

                    const {
                        data: {data: materials, groupCount, pagination},
                    } = data;

                    if (materials && materials.length > 0) {
                        if (currentPage === 1) {
                            dispatch(totalPagesSet(pagination.page_count));
                            dispatch(totalRecordsSet(groupCount));
                            dispatch(
                                materialsSet(materials.map(mapBenchtopMaterial))
                            );
                        } else {
                            dispatch(
                                materialsAdd(materials.map(mapBenchtopMaterial))
                            );
                        }
                    } else {
                        dispatch(totalPagesSet(0));
                        dispatch(totalRecordsSet(groupCount));
                        dispatch(currentPageSet(1));
                        dispatch(materialsSet([]));
                    }
                } catch (error) {
                    genericMessageHandler(notify, {
                        message: 'Error loading materials',
                    });
                } finally {
                    dispatch(loadingSet(Loader.IDLE));
                    initialized.current = true;
                }
            },
            [material, selectedThickness]
        );

        // This applies the selected material to benchtop material
        const onApply = useCallback(
            (selectedMaterial: Material) => {
                const material = selectedMaterial.data as BenchtopMaterial;

                if (material) {
                    dispatch(btmMaterialSet(material));
                    dispatch(thicknessSet(material.thickness));
                }
            },
            [btmMaterialSet, thicknessSet]
        );

        // Effect fired when selected brands or thickness changes
        useEffect(() => {
            void listMaterials(
                searchText,
                selectedBrands,
                selectedFinishes,
                Number(selectedThicknessFilter),
                currentPage,
                pageSize
            );
        }, [
            selectedBrands,
            selectedFinishes,
            selectedThicknessFilter,
            searchText,
            currentPage,
            pageSize,
        ]);

        // Init effect, this initiates everything.
        useEffect(() => {
            return () => {
                dispatch(propertiesSet({}));
            };
        }, []);

        return <Component onShow={onShow} onApply={onApply} />;
    };

    return advancedMaterialComponent;
};

export const AdvancedMaterialsBenchtop = withBenchtop(AdvancedMaterials);
