import {useAppDispatch, useAppSelector} from 'store/customer';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {
    buttJoinPartErrorSet,
    selectDimension,
    selectJoins,
    selectMaterial,
    selectMinButtJoinPartSize,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {
    hasMasonsJoin,
    mapValuesToJoinObject,
} from 'components/customer/BTM/helper/updateButtJoin';
import {Side} from 'components/customer/BTM/entity/Side';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {shallowEqual} from 'react-redux';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {ButtJoinError} from 'components/customer/BTM/entity/ButtJoinPartError';
import {sum, uniq} from 'lodash';
import {produce} from 'immer';
import {useCallback} from 'react';

export const getTotalLength = (
    dimensions: number[],
    shape: Shape,
    side: Side
) => {
    let totalLength = dimensions[Number(side)];
    let startOffset = 0;
    let endOffset = 0;

    if (shape == Shape.ANG) {
        if (side == Side.A) {
            totalLength -= dimensions[Side.E];
            startOffset = dimensions[Side.E];
        } else if (side == Side.F) {
            totalLength -= dimensions[Side.B];
            startOffset = dimensions[Side.B];
        }
    } else if (shape == Shape.USHAPE) {
        if (side == Side.A) {
            totalLength -= dimensions[Side.C] + dimensions[Side.G];
            startOffset = dimensions[Side.G];
            endOffset = dimensions[Side.C];
        } else if (side == Side.H) {
            totalLength = dimensions[Side.F];
            startOffset = dimensions[Side.H] - dimensions[Side.F];
        } else if (side == Side.B) {
            totalLength = dimensions[Side.D];
            startOffset = dimensions[Side.B] - dimensions[Side.D];
        }
    }

    return [totalLength, startOffset, endOffset];
};

export const getPartsByJoinPositions = (
    joins: number[],
    totalLength: number
) => {
    const parts: number[] = [];

    joins.forEach((join) => {
        const usedParts = sum(parts);
        const part = join - usedParts;

        parts.push(part);
    });

    parts.push(totalLength - sum(parts));

    return parts;
};

export const getJoins = (
    positions: number[],
    dimensions: number[],
    shape: Shape,
    side: Side
) => {
    if (shape == Shape.SQR) {
        return mapValuesToJoinObject(positions, dimensions[Side.B], true);
    } else if (shape == Shape.ANG) {
        if (side == Side.A) {
            return mapValuesToJoinObject(positions, dimensions[Side.B], true);
        } else if (side == Side.F) {
            return mapValuesToJoinObject(positions, dimensions[Side.E]);
        }
    } else if (shape == Shape.USHAPE) {
        if (side == Side.H) {
            return mapValuesToJoinObject(positions, dimensions[Side.G]);
        } else if (side == Side.B) {
            return mapValuesToJoinObject(
                positions,
                dimensions[Side.C],
                false,
                false,
                0,
                dimensions[Side.G] + dimensions[Side.E]
            );
        } else if (Side.A == side) {
            return mapValuesToJoinObject(
                positions,
                dimensions[Side.H] - dimensions[Side.F],
                true
            );
        }
    }
};

export const getHasMasonsJoin = (
    joins: Join[],
    shape: Shape,
    side: Side,
    joinSide = JoinSide.LEFT
) => {
    if (shape == Shape.ANG) {
        if (side == Side.A) {
            return hasMasonsJoin(joins, JoinOrientation.VERTICAL);
        } else if (side == Side.F) {
            return hasMasonsJoin(joins);
        }
    } else if (shape == Shape.USHAPE) {
        if (side == Side.A) {
            return hasMasonsJoin(joins, JoinOrientation.VERTICAL, joinSide);
        } else if (side == Side.H) {
            return hasMasonsJoin(joins);
        } else if (side == Side.B) {
            return hasMasonsJoin(
                joins,
                JoinOrientation.HORIZONTAL,
                JoinSide.RIGHT
            );
        }
    }

    return false;
};

interface JoinError {
    index: number;
    message: string;
}

const updateJoins = (
    joins: number[],
    totalLength: number,
    index: number,
    joinDistance: number,
    materialLength: number,
    maxJoins: number,
    dimensions: number[],
    startOffset = 0,
    newJoinCount = 0,
    errors: JoinError[] = []
): {joins: number[]; errors: JoinError[]} => {
    const newButtJoins: number[] = [];
    const parts = getPartsByJoinPositions(joins, totalLength);
    let i = 0;

    for (const part of parts) {
        let truePartLength = part;
        if (i == 0 && startOffset > 0) {
            truePartLength += startOffset;
        }

        if (truePartLength < joinDistance) {
            errors.push({
                index: i,
                message: `Butt join must be ${joinDistance}mm away from any joins or edges`,
            });
        } else if (truePartLength > materialLength) {
            if (
                joins.length + newButtJoins.length + newJoinCount < maxJoins &&
                i > index
            ) {
                const sumLength = sum(
                    parts.filter((_, index) => index < i).map((part) => part)
                );

                if (totalLength - (sumLength + materialLength) < joinDistance) {
                    newButtJoins.push(totalLength - joinDistance);
                } else {
                    newButtJoins.push(sumLength + materialLength);
                }

                break;
            } else {
                errors.push({
                    index: i,
                    message: `Cannot use this size without exceeding material length on part #${
                        i + 1
                    }`,
                });
            }
        }

        i++;
    }

    if (i < parts.length - 1) {
        if (i < joins.length) {
            const test = [...joins];
            test.splice(i, 0, newButtJoins[0]);

            return updateJoins(
                test,
                totalLength,
                index,
                joinDistance,
                materialLength,
                maxJoins,
                dimensions,
                startOffset,
                newJoinCount + 1,
                errors
            );
        }
    }

    return {
        joins: [...joins, ...newButtJoins],
        errors,
    };
};

export const updateBenchPart =
    (
        dimensions: number[],
        shape: Shape,
        joins: Join[],
        material: Partial<BenchtopMaterial>,
        joinDistance = 300
    ) =>
    (
        index: number,
        newLength: number,
        side: number
    ): {joins: Join[]; errors: JoinError[]} => {
        const materialLength = material.max_length;
        const maxJoins = material.maxButtJoin;

        const hasJoin = getHasMasonsJoin(joins, shape, side);
        const [totalLength, startOffset, endOffset] = getTotalLength(
            dimensions,
            shape,
            side
        );

        // need to deduct the start offset from input at 0 index
        // need to deduct the end offset from input at last index
        let length = newLength;
        if (index == 0) {
            length -= startOffset;
        }

        const errors: JoinError[] = [];

        const buttJoins = joins.filter(
            (join) =>
                join.joinType == JoinType.BUTT_JOIN && join.benchSide == side
        );

        if (length > materialLength) {
            errors.push({
                index,
                message: `Length exceeds maximum material length ${materialLength}mm`,
            });
        } else if (startOffset > 0 && !hasJoin && index == 0) {
            if (length > materialLength - startOffset) {
                errors.push({
                    index,
                    message: `Length exceeds maximum material length ${materialLength}mm`,
                });
            }
        }

        if (length < 0 || length < joinDistance) {
            errors.push({
                index,
                message: `Butt join must be ${joinDistance}mm away from any joins or edges`,
            });
        }

        if (length > totalLength) {
            throw new ButtJoinError('Length exceeds total bench length');
        }

        const updatedJoinPositions = produce(
            buttJoins.map((join) => join.value - startOffset),
            (draft) => {
                const previousPosition = draft[index - 1] || 0;

                if (length + previousPosition == totalLength) {
                    if (index == buttJoins.length - 1) {
                        delete draft[Number(index)];
                    }

                    return;
                }

                if (endOffset > 0) {
                    if (
                        length - endOffset + previousPosition === totalLength &&
                        index == buttJoins.length
                    ) {
                        delete draft[Number(index)];
                        return;
                    } else if (
                        length - endOffset + previousPosition >
                        totalLength
                    ) {
                        throw new ButtJoinError(
                            'Length exceeds total bench length'
                        );
                    }
                }

                draft[Number(index)] = length + previousPosition;
            }
        );

        const hasLeftMasonsJoin = getHasMasonsJoin(
            joins,
            shape,
            side,
            JoinSide.LEFT
        );

        let {joins: updatedButtJoinPositions, errors: joinErrors} = updateJoins(
            updatedJoinPositions.filter(Number),
            totalLength,
            index,
            joinDistance,
            materialLength,
            maxJoins,
            dimensions,
            hasLeftMasonsJoin ? 0 : startOffset
        );

        if (updatedButtJoinPositions.length > maxJoins) {
            throw new ButtJoinError(
                'Cannot use this size without exceeding total number of allowed joins'
            );
        }

        const hasRightMasonsJoin = getHasMasonsJoin(
            joins,
            shape,
            side,
            JoinSide.RIGHT
        );

        if (endOffset > 0 && !hasRightMasonsJoin) {
            // info validate end part size of the bench for U shape bench
            const joinCount = updatedButtJoinPositions.length;
            if (
                totalLength +
                    endOffset -
                    updatedButtJoinPositions[joinCount - 1] >
                materialLength
            ) {
                joinErrors.push({
                    index,
                    message: `Cannot use this size without exceeding material length on part #${
                        joinCount + 1
                    }`,
                });
            }
        }

        if (startOffset) {
            updatedButtJoinPositions = updatedButtJoinPositions.map(
                (join) => join + startOffset
            );
        }

        return {
            joins: getJoins(updatedButtJoinPositions, dimensions, shape, side),
            errors: [...errors, ...joinErrors],
        };
    };

export const updateJoinPosition =
    (
        dimensions: number[],
        shape: Shape,
        joins: Join[],
        material: BenchtopMaterial,
        minButtJoinPartSize: number
    ) =>
    (join: Join, value: number) => {
        let partSize = value;
        let partIndex = join.index;

        const buttJoins = joins.filter(
            (j) =>
                j.joinType == JoinType.BUTT_JOIN &&
                j.benchSide == join.benchSide
        );
        if (join.index > 0) {
            const previousJoin = buttJoins[join.index - 1];
            partSize = value - previousJoin.value;
            partIndex = join.index;
        }

        const {joins: updatedJoins, errors} = updateBenchPart(
            dimensions,
            shape,
            joins,
            material,
            minButtJoinPartSize
        )(partIndex, partSize, join.benchSide);

        if (errors && errors.length > 0) {
            updatedJoins[join.index].error = uniq(
                errors.map((error) => error.message)
            ).join('\r\n');
        }

        return updatedJoins;
    };

export const getBenchParts =
    (dimensions: number[], joins: Join[]) => (side: Side) => {
        const buttJoins = joins.filter((join) => {
            return (
                join.benchSide == side && join.joinType == JoinType.BUTT_JOIN
            );
        });

        if (buttJoins.length > 0) {
            const parts: {
                value: number;
                index: number;
            }[] = [];

            let index = 0;
            while (parts.length <= buttJoins.length) {
                const usedLength = parts.reduce(
                    (acc, part) => acc + part.value,
                    0
                );
                const join = buttJoins[Number(index)];
                let joinLength = dimensions[Number(side)];

                if (join) {
                    joinLength = join.value;
                }

                parts.push({
                    index,
                    value: joinLength - usedLength,
                });

                index++;
            }

            return parts;
        }

        return null;
    };

export const useButtJoin = () => {
    const dispatch = useAppDispatch();
    const dimensions = useAppSelector(selectDimension, shallowEqual);
    const shape = useAppSelector(selectType, shallowEqual);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const minButtJoinPartSize = useAppSelector(selectMinButtJoinPartSize);
    const material = useAppSelector(selectMaterial, shallowEqual);

    const validateParts = useCallback(
        (value: number, side: Side) => {
            const sideMaps = [
                {
                    shape: Shape.ANG,
                    side: Side.E,
                    checkSides: [Side.A],
                },
                {
                    shape: Shape.ANG,
                    side: Side.B,
                    checkSides: [Side.F],
                },
                {
                    shape: Shape.USHAPE,
                    side: Side.G,
                    checkSides: [Side.A],
                },
                {
                    shape: Shape.USHAPE,
                    side: Side.I,
                    checkSides: [Side.H, Side.B],
                },
            ];

            sideMaps.forEach((map) => {
                if (shape.type == map.shape && map.side == side) {
                    map.checkSides.forEach((checkSide) => {
                        const buttJoins = joins.filter(
                            (join) => join.benchSide == checkSide
                        );

                        if (buttJoins.length > 0) {
                            const firstValue = buttJoins[0].value;

                            const updatedDimension = produce(
                                dimensions,
                                (draft) => {
                                    if (map.checkSides.length > 1) {
                                        draft[Side.D] = draft[Side.B] - value;
                                        draft[Side.F] = draft[Side.H] - value;
                                    } else {
                                        draft[Number(side)] = value;
                                    }
                                }
                            );
                            try {
                                updateBenchPart(
                                    updatedDimension,
                                    shape.type,
                                    joins,
                                    material,
                                    minButtJoinPartSize
                                )(0, firstValue, checkSide);

                                dispatch(
                                    buttJoinPartErrorSet({
                                        message: '',
                                        index: 0,
                                        side: checkSide,
                                    })
                                );
                            } catch (error) {
                                if (error instanceof ButtJoinError) {
                                    dispatch(
                                        buttJoinPartErrorSet({
                                            message: error.message,
                                            index: 0,
                                            side: checkSide,
                                        })
                                    );
                                } else {
                                    throw error;
                                }
                            }
                        }
                    });
                }
            });

            if (shape.type == Shape.USHAPE && side == Side.C) {
                const buttJoins = joins.filter(
                    (join) => join.benchSide == Side.A
                );

                if (buttJoins.length > 0) {
                    const valueIndex = buttJoins.length;
                    const lastJoin = buttJoins[valueIndex - 1];
                    const partSize = dimensions[Side.A] - lastJoin.value;

                    const updatedDimension = produce(dimensions, (draft) => {
                        draft[Number(Side.E)] =
                            draft[Number(Side.E)] -
                            (value - draft[Number(side)]);
                        draft[Number(side)] = value;
                    });
                    try {
                        const {errors} = updateBenchPart(
                            updatedDimension,
                            shape.type,
                            joins,
                            material,
                            minButtJoinPartSize
                        )(valueIndex, partSize, Side.A);

                        if (errors.length > 0) {
                            dispatch(
                                buttJoinPartErrorSet({
                                    message: errors
                                        .map((error) => error.message)
                                        .join(', '),
                                    index: valueIndex,
                                    side: Side.A,
                                })
                            );
                        } else {
                            dispatch(
                                buttJoinPartErrorSet({
                                    message: '',
                                    index: valueIndex,
                                    side: Side.A,
                                })
                            );
                        }
                    } catch (error) {
                        if (error instanceof ButtJoinError) {
                            dispatch(
                                buttJoinPartErrorSet({
                                    message: error.message,
                                    index: valueIndex,
                                    side: Side.A,
                                })
                            );
                        } else {
                            throw error;
                        }
                    }
                }
            }
        },
        [shape, dimensions, joins, material, minButtJoinPartSize]
    );

    return {
        updateJoinPosition: updateJoinPosition(
            dimensions,
            shape.type,
            joins,
            material,
            minButtJoinPartSize
        ),
        getBenchParts: getBenchParts(dimensions, joins),
        updateSide: updateBenchPart(
            dimensions,
            shape.type,
            joins,
            material,
            minButtJoinPartSize
        ),
        validateParts,
    };
};
