import React from 'react';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {CornerError} from 'components/customer/BTM/store/btmSlice';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';

const modificationOptions = [
    {
        name: 'None',
        type: CornerType.None,
    },
    {
        name: 'Notch',
        type: CornerType.Notch,
        image: 'Notch.svg',
    },
    {
        name: 'Clip',
        type: CornerType.Clip,
        image: 'Clip.svg',
    },
];

const ModifierIcon = styled(Icon)`
    width: 30px;
    height: 30px;
`;

const ModifierItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    > label {
        margin: 0;
    }

    > label > input[type='checkbox'] {
        margin-right: 10px;
    }
`;

export const Container = styled.div<{$inline: boolean}>`
    display: flex;
    flex-direction: ${({$inline}) => ($inline ? 'row' : 'column')};

    ${({$inline}) => {
        if ($inline) {
            return `
            align-items: center;

            > div {margin-right: 15px;}
            
            > div img {margin-left: 15px;}

            `;
        }
    }}
`;

export const CornerButton = styled(Button)`
    padding: 3px 15px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 8px;
    background: rgb(var(--primary_colour));
    border: 0;
    align-self: flex-end;

    :hover {
        background: rgb(var(--secondary_colour));
    }
`;

export interface CornerModificationInterface {
    nextPage?: () => void;
    previousPage?: () => void;
    type?: CornerType;
    setType?: (type: CornerType) => void;
    xValue?: number;
    setXValue?: (value: number) => void;
    yValue?: number;
    setYValue?: (value: number) => void;
    saveCorner?: () => void;
    inline?: boolean;
    corner?: Corner;
    error?: CornerError;
}

export const CornerModification = ({
    nextPage,
    type,
    setType,
    saveCorner,
    inline = false,
    corner,
}: CornerModificationInterface) => {
    const handleCheckboxChange = (type: CornerType) => {
        setType(type);
    };

    const handleButtonClick = () => {
        if (type == CornerType.None) {
            saveCorner();
        } else {
            nextPage();
        }
    };

    return (
        <Container $inline={inline}>
            {modificationOptions.map((mod, index) => {
                if (inline && mod.type == CornerType.None) {
                    return <React.Fragment key={index} />;
                }

                return (
                    <ModifierItem key={index}>
                        <MiniRadio
                            $checked={type == mod.type}
                            $disabled={
                                (mod.type == CornerType.Notch &&
                                    corner?.disabled?.notch) ||
                                (mod.type == CornerType.Clip &&
                                    corner?.disabled?.clip)
                            }>
                            <input
                                type="radio"
                                name={mod.name}
                                checked={type == mod.type}
                                onChange={() => handleCheckboxChange(mod.type)}
                                disabled={
                                    (mod.type == CornerType.Notch &&
                                        corner?.disabled?.notch) ||
                                    (mod.type == CornerType.Clip &&
                                        corner?.disabled?.clip)
                                }
                            />
                            {mod.name}
                        </MiniRadio>
                        {mod.image ? (
                            <ModifierIcon iconName={mod.image} />
                        ) : (
                            <></>
                        )}
                    </ModifierItem>
                );
            })}

            {!inline ? (
                <CornerButton variant="primary" onClick={handleButtonClick}>
                    {type == CornerType.None ? 'Save' : 'Next'}
                </CornerButton>
            ) : (
                <></>
            )}
        </Container>
    );
};
