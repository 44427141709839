import {useMemo} from 'react';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Path} from 'components/customer/BTM/entity/Path';
import {useAppSelector} from 'store/customer';
import {
    selectCenter,
    selectCorners,
    selectMaterial,
    selectEdgeProfile,
    selectJoins,
    selectScale,
    selectTopOffset,
} from 'components/customer/BTM/store/btmSlice';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Side} from 'components/customer/BTM/entity/Side';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Restriction, SIDE_MAP} from 'components/customer/BTM/helper/sideMap';
import {isPointOnPath} from 'components/customer/BTM/helper/joinRestrictions';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Join} from 'components/customer/BTM/entity/Join';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import Excel from 'shared/Excel';
import {
    checkCutoutRestriction,
    getSorroundingIndexes,
} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {isResultSet} from 'mathjs';
import {checkAdjacentRestriction} from 'components/customer/BTM/helper/checkAdjacentRestriction';
import {join} from 'lodash';

export interface EdgeTogglePosition {
    edged: Edge;
    index: number;
    side: Side;
    top?: number;
    left?: number;
    disableProfiled?: boolean;
    hideProfiled?: boolean;
    disableMessages?: string[];
    profile?: number;
    path: Path;
}

/**
 * This function calculates and checks the restriction based on side and shape
 *
 * @param {number} index index of the side goes from 0 to n from top
 * @param {Path[]} paths all paths information in the dimension, look in btmSlice
 * @param {Restriction[]} checks Restrictions to check for this side
 * @param {BenchtopMaterial} material Selected material information
 * @param {BenchtopEdgeProfile} profile Selected profile information
 * @return {boolean} Whether to restrict or not the postform option e.g. If false
 * restrict the option
 */
const calculateRestriction = (
    index: number,
    paths: Path[],
    checks: Restriction[],
    material: BenchtopMaterial,
    profile: BenchtopEdgeProfile
) => {
    const scope = {
        material,
        profile,
    };

    const restrictionsChecks = checks.map((restriction) => {
        if (restriction.rules) {
            const ruleChecks = restriction.rules.map((rule) => {
                if (typeof rule == 'string') {
                    const result = Excel.calculate<boolean>(rule, scope);

                    if (typeof result === 'boolean') {
                        return result;
                    } else {
                        if (isResultSet(result) && 'entries' in result) {
                            if (
                                Array.isArray(result['entries']) &&
                                result['entries'].length > 0
                            ) {
                                return result['entries'][0];
                            }
                        }

                        return false;
                    }
                } else {
                    let path: Path;
                    if (rule.side >= 0) {
                        path = paths.find((path) => path.side == rule.side);
                    } else if (typeof rule.index == 'string') {
                        let checkIndex = Excel.calculate<number>(rule.index, {
                            index,
                        });

                        if (checkIndex < 0) {
                            checkIndex = paths.length - 1;
                        } else if (checkIndex > paths.length - 1) {
                            checkIndex = 0;
                        }

                        path = paths[Number(checkIndex)];
                    }

                    if (path) {
                        return Excel.calculate(rule.check, {
                            ...scope,
                            side: path,
                        });
                    }
                }

                return false;
            });

            return ruleChecks.every(Boolean);
        }

        return false;
    });

    return restrictionsChecks.some(Boolean);
};

/**
 * Calculates the position for all the edge toggle buttons in the preview
 *
 * @param {number} width width of the bench
 * @param {number} height height of the bench, in case of u shape it must be longer height
 * @param {number} scale scale to resize the dimensions, look at btmSlice
 * @param {number[]} center center of the canvas. It is same for canvas and the image preview
 * @param {Path[]} paths all paths information in the dimension, look in btmSlice
 * @param {BenchtopEdgeProfile} profile Selected profile information
 * @param {Shape} shape Selected shape of the bench
 * @param {Corner[]} corners All the corner information, look in btmSlice
 * @param {Joins[]} joins all joins information in the dimension, look in btmSlice
 * @param {BenchtopMaterial} material Selected material information
 * @param {number[]} dimensions dimensions of the bench
 * @param {number} topOffset offset to add from top of the preview
 * @return {EdgeTogglePosition[]} positions for all edge toggle buttons *
 */
const getPositions = (
    width: number,
    height: number,
    scale: number,
    center: number[],
    paths: Path[],
    profile: BenchtopEdgeProfile,
    shape: Shape,
    corners: Corner[],
    joins: Join[],
    material: BenchtopMaterial,
    dimensions: number[],
    topOffset: number
) => {
    const realCenter = [center[0], center[1] + topOffset];
    const restrictions = SIDE_MAP.filter((side) => side.shape == shape);

    return paths.map((path, index) => {
        // all notches and clips can only have squared and not visible options if
        // the profile is postformed.
        const position: EdgeTogglePosition = {
            path,
            edged: path.edged,
            index,
            top: realCenter[1],
            left: realCenter[0],
            side: path.side,
            hideProfiled:
                typeof profile === 'undefined'
                    ? true
                    : profile.is_postformed_profile
                    ? path.side == null
                    : false,
            disableProfiled: false,
            profile: path.profile,
        };

        if (profile) {
            // Only working on restrictions on sides.
            if (path.side != null) {
                const restriction = restrictions.find(
                    (restriction) => restriction.side == path.side
                );

                if (restriction) {
                    const check = calculateRestriction(
                        index,
                        paths,
                        restriction.checks,
                        material,
                        profile
                    );

                    if (!check) {
                        position.disableProfiled = true;
                    }
                }

                // if profile has notch and clip restriction and this side
                // 1) touches one of the notched corner
                if (profile && profile.restrict_corner_notch) {
                    // Need to check If a corner is cutoff for reverse notch restriction
                    const notchedCorners = corners.filter(
                        (corner) =>
                            corner.cutoff &&
                            !corner.isJoin &&
                            corner.type == CornerType.Notch
                    );

                    if (notchedCorners) {
                        const touches = notchedCorners.map((corner) =>
                            isPointOnPath(corner.point, path)
                        );

                        if (touches.some(Boolean)) {
                            position.disableProfiled = true;
                        }
                    }
                }

                // 2) touches one of the clipped corner
                if (profile && profile.restrict_corner_clip) {
                    // Need to check If a corner is cutoff for reverse clip restriction
                    const clippedCorners = corners.filter(
                        (corner) =>
                            corner.cutoff &&
                            !corner.isJoin &&
                            corner.type == CornerType.Clip
                    );

                    if (clippedCorners) {
                        const touches = clippedCorners.map((corner) =>
                            isPointOnPath(corner.point, path)
                        );

                        if (touches.some(Boolean)) {
                            position.disableProfiled = true;
                        }
                    }
                }

                // restriction only for postformed profile
                // If join touches the side, that side cannot be profiled.
                if (profile && profile.is_postformed_profile && joins) {
                    const checks = joins
                        .filter(
                            (join) => join.joinType == JoinType.MASONS_MITRE
                        )
                        .map((join) => {
                            return (
                                join.selected &&
                                join.points
                                    .map(
                                        (point, index) =>
                                            // Not checking the first point as first point touches both inner sides
                                            index > 0 &&
                                            isPointOnPath(point, path)
                                    )
                                    .some(Boolean)
                            );
                        });

                    if (checks.some(Boolean)) {
                        position.disableProfiled = true;
                    }
                }
            } else {
                let restriction = checkCutoutRestriction(paths, profile, index);

                if (!restriction) {
                    restriction = checkAdjacentRestriction(
                        paths,
                        profile,
                        index,
                        shape
                    );
                }
                if (restriction) {
                    position.disableProfiled = true;
                }
            }
        }

        const {x: x1, y: y1} = path.points[0];
        const {x: x2, y: y2} = path.points[1];

        const originX = realCenter[0] - width / 2 - 13;
        const originY = realCenter[1] - height / 2 - 13;

        position.left = originX + ((Number(x1) + Number(x2)) / 2) * scale;
        position.top = originY + ((Number(y1) + Number(y2)) / 2) * scale;

        // check if current path is a arc cllip
        if (path.side == null) {
            const sorroundingPaths = getSorroundingIndexes(
                position.index,
                paths.length
            ).map((index) => paths[Number(index)]);

            // get side names
            const sideNames = sorroundingPaths.map((path) => Side[path.side]);
            const selectedCorner = corners
                .map((corner, index) => ({corner, index}))
                .find((corner) => corner.corner.name == join(sideNames, ''));

            if (
                selectedCorner &&
                selectedCorner.corner &&
                selectedCorner.corner.addedThroughEndProfile &&
                selectedCorner.corner.isArc &&
                !selectedCorner.corner.hideCornerInPreview
            ) {
                if (selectedCorner.corner.name == 'AB') {
                    position.top = originY + height / 2 - 3;
                    position.left = originX + width - 80;
                } else if (selectedCorner.corner.name == 'CD') {
                    position.top = originY + height / 2 - 3;
                    position.left = originX + 50;
                } else if (selectedCorner.corner.name == 'DE') {
                    // This will only be the case for L shaped bench
                    const [, , , , bottom, height] = dimensions;

                    position.top = originY + height * scale - 13 - 40;
                    position.left = originX + (bottom * scale) / 2 - 15;
                } else if (selectedCorner.corner.name == 'BC') {
                    if (shape == Shape.USHAPE) {
                        // This will only be the case for U shaped bench
                        const [, rightHeight, rightBottom] = dimensions;

                        position.top = originY + rightHeight * scale - 13 - 40;
                        position.left =
                            originX + (width - (rightBottom * scale) / 2) - 15;
                    } else {
                        const [, right] = dimensions;

                        position.top = originY + (right * scale) / 2 - 3;
                        position.left = originX + width - 80;
                    }
                } else if (selectedCorner.corner.name == 'FG') {
                    // This will only be the case for U shaped bench
                    const [, , , , , , bottomLeft, leftHeight] = dimensions;

                    position.top = originY + leftHeight * scale - 13 - 40;
                    position.left = originX + (bottomLeft * scale) / 2 - 15;
                }
            }
        }

        return position;
    });
};

export const useEdgeTogglePosition = (
    dimensions: number[],
    shape: Shape,
    paths: Path[]
) => {
    const scale = useAppSelector(selectScale);
    const center = useAppSelector(selectCenter);
    const profile = useAppSelector(selectEdgeProfile);
    const corners = useAppSelector(selectCorners);
    const joins = useAppSelector(selectJoins);
    const material = useAppSelector(selectMaterial);
    const topOffset = useAppSelector(selectTopOffset);

    return useMemo(() => {
        if (paths && material) {
            let width;
            let height;

            if (shape == Shape.SQR) {
                [width, height] = dimensions;
            } else if (shape == Shape.ANG) {
                [width, , , , , height] = dimensions;
            } else if (shape == Shape.USHAPE) {
                const [top, right, , , , , , left] = dimensions;
                height = right > left ? right : left;
                width = top;
            }

            return getPositions(
                width * scale,
                height * scale,
                scale,
                center,
                paths,
                profile,
                shape,
                corners,
                joins,
                material,
                dimensions,
                topOffset
            );
        }
    }, [
        dimensions,
        shape,
        paths,
        scale,
        center,
        profile,
        corners,
        joins,
        material,
        topOffset,
    ]);
};
