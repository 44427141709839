// @flow
/**
 *
 * TODO:: DELETE this file
 * @class Test
 */
export default class HardwareOption {
    adjLegs: boolean;
    assembly: boolean;
    description: string;
    image: string;
    name: string;
    supplyHardware: boolean;
    value: number;
    id: number;
}
