import React, {useState, useCallback, useEffect} from 'react';
import {Icon, Loader} from 'shared/helpers';
import {Product} from './Product';
import {Product as ProductType, ProductStyle, ProductSubStyle} from 'IndexedDB';
import {ProductSearchCategories} from 'hooks/QuickProduct/ProductSearch';
import useUI from 'shared/useUI';

export const ProductList = ({
    categories,
    subCategories,
    products,
    loading,
    productListVisible,
    selectedCategories,
    hideProductSearch,
    categoryClickHandler,
    callBack,
    setFavourite,
    addButtonText,
    embedded = false,
}: {
    categories: ProductStyle[];
    subCategories: ProductSubStyle[];
    products: ProductType[];
    loading: boolean;
    productListVisible: boolean;
    selectedCategories: ProductSearchCategories;
    hideProductSearch: () => void;
    categoryClickHandler: (categories: ProductSearchCategories) => void;
    callBack: (product: ProductType, width: number) => Promise<void>;
    setFavourite: (itemId: number, favourite: boolean) => Promise<void>;
    addButtonText: string;
    embedded: boolean;
}) => {
    const [addingStatus, setAddingStatus] = useState<number>();
    const [location, setLocation] = useState({
        top: '0',
        right: '0',
    });
    const {isSmallScreenLayout} = useUI();

    const addToCartHandler = useCallback(
        async (product: ProductType, width: number) => {
            if (callBack) {
                setAddingStatus(product.id);
                await callBack(product, width);
                setAddingStatus(undefined);
            }
        },
        [callBack]
    );

    useEffect(() => {
        const searchButton = document
            .querySelector('#productSearchButton')
            .getBoundingClientRect();
        const body = document.body.getBoundingClientRect();

        setLocation({
            top: `${searchButton.top + searchButton.height + 5}px`,
            right: isSmallScreenLayout
                ? '15px'
                : `${body.right - searchButton.right}px`,
        });
    }, []);

    const subCategoryRenderer = (
        data: ProductSubStyle[],
        category: ProductStyle
    ) => {
        return (
            <ul className="categories">
                {data.map((subCategory, index) => {
                    const isSelected =
                        selectedCategories.length > 1 &&
                        selectedCategories[1].subStyleId ==
                            subCategory.subStyleId;

                    return (
                        <li key={index}>
                            <a
                                href="#"
                                className={isSelected ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    categoryClickHandler([
                                        category,
                                        subCategory,
                                    ]);
                                }}>
                                {subCategory.subStyleName}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const categoryRenderer = (data: ProductStyle[]) => {
        return (
            <ul className="categories">
                {data.map((category, index) => {
                    const isSelected =
                        selectedCategories.length > 0 &&
                        selectedCategories[0].id == category.id;

                    return (
                        <li key={index}>
                            <a
                                href="#"
                                className={isSelected ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    categoryClickHandler([category]);
                                }}>
                                {category.styleName}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div
            className={
                embedded
                    ? 'kdmax-product-list-container product-list-container'
                    : 'product-list-container'
            }
            style={{
                display: productListVisible ? 'block' : 'none',
                top: location.top,
                right: location.right,
            }}>
            <div className="product-list">
                <Loader loader={loading} relative={true}>
                    {categoryRenderer(categories)}
                    {subCategories.length > 0 &&
                        subCategoryRenderer(
                            subCategories,
                            selectedCategories[0]
                        )}

                    <ul className="products">
                        {products.map((product, index) => {
                            return (
                                <Product
                                    product={product}
                                    key={index}
                                    addingStatus={product.id == addingStatus}
                                    addToCartHandler={addToCartHandler}
                                    setFavourite={setFavourite}
                                    addButtonText={addButtonText}
                                    embedded={embedded}
                                />
                            );
                        })}
                    </ul>
                </Loader>
                {!embedded ? (
                    <div className="close-popup">
                        <Icon
                            id="close-product-popup"
                            iconName="Button-Error-Gray.svg"
                            onClick={hideProductSearch}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
