import React from 'react';
import {Image} from 'react-bootstrap';
import styled, {useTheme} from 'styled-components';
import {PickUp} from 'assets';
import {getColoredImage} from 'shared/helpers';
import {User} from 'components/customer/Auth/store/userSlice';
import {Depot} from 'components/manufacturer/DepotSettings/depotApi';

interface PickupLocationProps {
    userProfile: User;
    depotSelected: Depot;
    states: {name: string; id: number}[];
}

const PickupLocation: React.FC<PickupLocationProps> = ({
    depotSelected,
    userProfile,
    states,
}) => {
    const theme = useTheme();
    const state = states.find(
        (state) => state.id == userProfile.manufacturerState
    );

    return (
        <PickupLocationContainer>
            <ImageContainer>
                <Image
                    src={getColoredImage(
                        String(PickUp),
                        '#204380',
                        theme.colors.primary.main
                    )}
                />
            </ImageContainer>
            <Description>
                <p>
                    <strong>Pickup Location</strong>
                </p>
                <p>
                    {depotSelected ? (
                        depotSelected.depot_address
                    ) : (
                        <>
                            {userProfile.manufacturerAddress},{' '}
                            {userProfile.manufacturerSuburb},{' '}
                            {userProfile.manufacturerPostCode}
                            {state ? `, ${state.name}` : ''}
                        </>
                    )}
                </p>
            </Description>
        </PickupLocationContainer>
    );
};

const PickupLocationContainer = styled.div<{$primaryColor?: string}>`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid
        ${({$primaryColor}) => $primaryColor ?? 'rgb(32, 67, 129)'};
    margin-bottom: 15px;
`;

const ImageContainer = styled.div`
    flex: 0 0 auto;
    img {
        width: 50px;
    }
`;

const Description = styled.div`
    flex-grow: 1;
    p,
    label {
        margin: 0;
        word-wrap: break-word;
    }
`;

export default PickupLocation;
