import React from 'react';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {Image} from 'shared/Image';

interface ImageEditorInterface {
    image: string;
    text: string | JSX.Element;
    onClickEdit?: () => void | null;
    showLargePreview?: boolean;
    loading?: boolean;
}

export const ImageEditor = ({
    image,
    text,
    onClickEdit,
    showLargePreview = false,
    loading = false,
}: ImageEditorInterface) => {
    return (
        <Container>
            <div>{text}</div>
            <ImageContainer $bg={showLargePreview}>
                <Image src={image} alt={image} />
            </ImageContainer>
            {onClickEdit == null ? null : (
                <EditButton
                    title="Change selection"
                    onClick={onClickEdit ? onClickEdit : null}>
                    {loading ? (
                        <Icon iconName="tail-spin.svg" />
                    ) : (
                        <Icon iconName="Options-Edit.svg" />
                    )}
                </EditButton>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    margin-bottom: 5px;
    font-size: 0.85em;
    font-weight: 500;
    color: #535353;
`;

const EditButton = styled.div`
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 5px;
    right: 5px;
    background: white;
    border-radius: 22px;
    border: 1px solid rgb(var(--secondary_colour));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > img {
        width: 15px;
        height: 15px;
    }
`;

const ImageContainer = styled.div<{$bg: boolean}>`
    height: ${({$bg}) => ($bg ? '84px' : '30px')};
    border-radius: 2px;
    overflow: hidden;

    > img {
        width: ${({$bg}) => ($bg ? '100%' : '100%')};
        height: ${({$bg}) => ($bg ? '100%' : 'inherit')};
    }
`;
