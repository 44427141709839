import Paper from 'paper';
import drawShape from 'components/customer/BTM/paper/shape';
import drawJoins from 'components/customer/BTM/paper/join';
import {store} from 'store/customer/storeSetup';
import {centerSet, scaleSet} from 'components/customer/BTM/store/btmSlice';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Path} from 'components/customer/BTM/entity/Path';
import {Join} from 'components/customer/BTM/entity/Join';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {Tab} from 'components/customer/BTM/entity/Tab';
import {hexToRgb} from 'shared/helpers';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';

export interface Colors {
    primary: string;
    secondary: string;
}

/**
 * This function simply converts the hex color string to Paper.Color object.
 *
 * @param {string} color Name of the color e.g. primary_colour, secondary_colour
 * @return {Paper.Color} The color as Paper.Color object
 */
export const getColour = (color: string) => {
    if (color) {
        const rgb = hexToRgb(color);
        const [r, g, b] = rgb
            .split(',')
            .map((a) => a.trim())
            .map(Number);

        return new Paper.Color(r / 255, g / 255, b / 255);
    }
};

const updateCenter = () =>
    store.dispatch(centerSet([Paper.view.center.x, Paper.view.center.y]));

/**
 * This function returns a decimal value which is used to make sure that the bench
 * preview fits inside the available canvas in the UI.
 *
 * @param {number} canvasWidth Width of the canvas
 * @param {number} canvasHeight Height of the calvas
 * @param {number} width Width of the bench
 * @param {number} height Height of the bench
 * @param {boolean} fullScreen Whether the preview is fullscreen
 *
 * @return {number} The value to which the bench needs to be scaled
 */
const getScale = (
    canvasWidth: number,
    canvasHeight: number,
    width: number,
    height: number,
    fullScreen: boolean
) => {
    const widthRatio = canvasWidth / width;
    const heightRatio = (canvasHeight - 80) / height;

    let scale = Math.min(widthRatio, heightRatio);

    if (fullScreen) {
        if (scale * width + 175 >= canvasWidth) {
            scale *= 0.85;
        }

        if (scale * height + 100 >= canvasHeight) {
            scale *= 0.95;
        }
    } else {
        if (scale * width >= canvasWidth) {
            scale *= 0.85;
        }
    }

    return scale;
};

interface SizeInterface {
    width: number;
    height: number;
}

/**
 *
 * @param {Shape} shape
 * @param {number[]} dimension
 *
 * @return {SizeInterface} size information for the specidied bench
 */
const getWidthHeight = (shape: Shape, dimension: number[]): SizeInterface => {
    if (shape == Shape.SQR) {
        const [width, height] = dimension;

        return {width, height};
    } else if (shape == Shape.ANG) {
        const [width, , , , , height] = dimension;

        return {width, height};
    } else {
        const [width, heightRight, , , , , , heightLeft] = dimension;

        return {
            width,
            height: heightRight < heightLeft ? heightLeft : heightRight,
        };
    }
};

interface DrawInterface {
    currentTab: Tab;
    canvas: HTMLCanvasElement;
    container: HTMLDivElement;
    paths: Path[];
    dimension: number[];
    shape: Shape;
    joins: Join[];
    corners: Corner[];
    materialType: BenchtopMaterialType;
    fullScreen: boolean;
    edgeProfiles: BenchtopEdgeProfile[];
    colors: Colors;
}

/**
 * Basically draws the preview based on the information
 * provided in the parameters. It also calculates center
 * and scale to relatively position elements around the
 * preview which are not drawn using Paper.js
 *
 * @param {DrawInterface} drawoptions Informations required to draw the benchin the preview.
 */
const draw = ({
    currentTab,
    canvas,
    paths,
    dimension,
    shape,
    joins,
    corners,
    materialType,
    fullScreen,
    edgeProfiles,
    container,
    colors,
}: DrawInterface) => {
    const contaierDimension = container.getBoundingClientRect();
    Paper.setup(canvas);
    Paper.view.viewSize.width = contaierDimension.width;
    Paper.view.viewSize.height = contaierDimension.height - 80;

    const canvasDimension = canvas.getBoundingClientRect();
    const {width, height} = getWidthHeight(shape, dimension);
    const scale = getScale(
        canvasDimension.width,
        canvasDimension.height,
        width,
        height,
        fullScreen
    );

    if (scale != 1) {
        store.dispatch(scaleSet(scale));
    }

    // update paths with edge highlight
    const pathsWithEdgeHighlights = paths.map((path) => {
        if (path.profile == 0) {
            return {...path, edgeHighlight: false};
        }
        const currentProfile = edgeProfiles.find((ep) => ep.id == path.profile);
        return {...path, edgeHighlight: currentProfile?.edge_highlight};
    });
    drawShape(pathsWithEdgeHighlights, scale, corners, shape, colors);
    drawJoins(
        canvas,
        materialType.is_join_allowed ? joins : undefined,
        scale,
        currentTab == Tab.JOINS,
        currentTab == Tab.EDGE_PROFILE || currentTab == Tab.MATERIAL,
        currentTab == Tab.JOINS,
        colors
    );

    Paper.view.center = new Paper.Point(
        (width * scale) / 2,
        (height * scale) / 2
    );

    store.dispatch(
        centerSet([canvasDimension.width / 2, canvasDimension.height / 2])
    );
};

export default draw;
