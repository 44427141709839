import React, {useRef} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {HardwareItem} from 'components';
import {Breadcrumbs} from 'components/customer/Product';
import {ProductProvider, useAppContext} from 'contexts';
import {CategoriesAndItems, Search} from 'shared';
import {useSearchParams} from 'react-router-dom';

import './Hardware.scss';

export const Hardware = () => {
    const [searchParams] = useSearchParams();
    const scrollToTop = useRef<HTMLLabelElement>(null);
    const {userProfile} = useAppContext();
    const sundry = searchParams.get('sundry') || false;

    return (
        <ProductProvider>
            <div className="hardware">
                <Row>
                    <Col>
                        <h1>
                            <Icon iconName="Headers-Add-Hardware.svg" />
                            <label ref={scrollToTop}>
                                {userProfile?.sundryMenuText}
                            </label>
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <section className="hardware-content">
                            <div className="search-info">
                                <Breadcrumbs isHardware={true} />
                            </div>

                            <div>
                                {!sundry ? (
                                    <div className="productSearch">
                                        <label>Search:</label>

                                        <div className="productSearchField">
                                            <Search />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="productsAndCategories">
                                    {!sundry ? (
                                        <CategoriesAndItems />
                                    ) : (
                                        <HardwareItem
                                            scrollToTop={scrollToTop}
                                        />
                                    )}
                                </div>
                            </div>
                        </section>
                    </Col>
                </Row>
            </div>
        </ProductProvider>
    );
};
