import {Join} from 'components/customer/BTM/entity/Join';
import {Path} from 'components/customer/BTM/entity/Path';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {flatten} from 'lodash';
import {lineIntersect} from 'components/customer/BTM/helper/joins';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';

/**
 * This function checks if the supplied point lies on the line.
 *
 * @param {Point} point point to check if it lies between the line
 * @param {Path} path Path information of the line
 * @return {boolean}
 */
export const isPointOnPath = (point: Point, path: Path) => {
    const {x: pointX, y: pointY} = point;
    const {x: lineX1, y: lineY1} = path.points[0];
    const {x: lineX2, y: lineY2} = path.points[1];

    if (lineY1 === lineY2) {
        // point on horizontal line
        if (lineY1 == pointY) {
            // checking if point in between line
            if (lineX1 < lineX2) {
                return lineX1 <= pointX && lineX2 >= pointX;
            } else {
                return lineX2 <= pointX && lineX1 >= pointX;
            }
        }
    } else if (lineX1 === lineX2) {
        // point on vertical line
        if (pointX === lineX1) {
            // checking if point in between line
            if (lineY1 < lineY2) {
                return lineY1 <= pointY && lineY2 >= pointY;
            } else {
                return lineY2 <= pointY && lineY1 >= pointY;
            }
        }
    }

    // Neither horizontal nor vertical line
    return false;
};

export const joinRestrictions = (
    joins: Join[],
    paths: Path[],
    edgeProfile: BenchtopEdgeProfile
) => {
    if (paths) {
        // We only need to restrict join intersecting profiled sides
        const sides = paths.filter(
            (path) =>
                path.side >= 0 &&
                path.edged == Edge.PROFILED &&
                edgeProfile.is_postformed_profile
        );
        joins.forEach((join) => {
            // Dog leg never intersects side
            if (join.joinType == JoinType.MASONS_MITRE) {
                if (sides.length > 0) {
                    // checking if at least one of the point in the join is on profiled side
                    const checks = join.points.map((point, index) => {
                        // Should not check first point as it is where
                        // join originates and it does not matter if this
                        // point touches profile or edged side.
                        if (index == 0) return false;

                        return sides.map((path) => isPointOnPath(point, path));
                    });

                    join.disabled = flatten(checks).some(Boolean);
                } else {
                    join.disabled = false;
                }
            }
        });

        // truncating join points to terminate drawing the join in the preview
        const corners = paths.filter((path) => path.side == null);
        joins.forEach((join) => {
            if (corners.length > 0) {
                let index = 0;
                joinPointLoop: for (const point of join.points) {
                    const next = index < join.points.length - 1 ? index + 1 : 0;
                    let intersects = false;

                    cornerLoop: for (const corner of corners) {
                        const {x: x1, y: y1} = corner.points[0];
                        const {x: x2, y: y2} = corner.points[1];

                        const {x: x3, y: y3} = point;
                        const {x: x4, y: y4} = join.points[Number(next)];

                        const intersection = lineIntersect(
                            Number(x1),
                            Number(y1),
                            Number(x2),
                            Number(y2),
                            Number(x3),
                            Number(y3),
                            Number(x4),
                            Number(y4)
                        );

                        if (intersection) {
                            intersects = true;

                            // removing the points after current index and
                            // adding in the intersection point as the last point
                            join.points.length = index + 1;
                            join.points.push(intersection);

                            break cornerLoop;
                        }
                    }

                    if (intersects) break joinPointLoop;
                    index++;
                }
            }
        });
    }

    return joins;
};
