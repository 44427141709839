import React, {useState, useCallback} from 'react';
import {Button, Popover, Overlay, Tooltip} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';
import {useQFPContext} from 'components/customer/QFPRedux/QFPContext';
import {CopyQuantity} from 'components/customer/QFPRedux/CopyQuantity';

const CopyButton = styled.div`
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 2px;
`;

const PopoverBody = styled(Popover.Content)`
    padding: 5px;
`;

const CustomPopover = styled(Popover)`
    box-shadow: 1px 1px 6px 1px #cdcdcd;
`;

export const CopyAction = () => {
    const {fieldIndex} = useQFPContext();
    const [show, setShow] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);

    const [target, setTarget] = useState<HTMLElement>(null);

    const copyHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();

        setShow((show) => !show);
        if (e.target instanceof HTMLElement) setTarget(e.target);
    }, []);

    const mouseOverHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowToolTip(true);
        if (e.target instanceof HTMLElement) setTarget(e.target);
    }, []);

    const mouseOutHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShowToolTip(false);
    }, []);

    const hidePopover = useCallback(() => setShow(false), []);

    return (
        <CopyButton>
            <Button
                title="Copy Product"
                variant="link"
                onClick={copyHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
                className="copy-qfp">
                <Icon iconName="Options-Copy.svg" />
            </Button>

            <Overlay
                target={target}
                show={showToolTip}
                placement="auto"
                flip={true}
                transition={false}>
                <Tooltip id={`tooltip_${fieldIndex}`}>Copy Product</Tooltip>
            </Overlay>

            <Overlay
                show={show}
                target={target}
                placement="bottom"
                transition={false}
                rootClose={true}
                onHide={hidePopover}>
                <CustomPopover id={`popover_${fieldIndex}`}>
                    <PopoverBody>
                        <CopyQuantity index={fieldIndex} setShow={setShow} />
                    </PopoverBody>
                </CustomPopover>
            </Overlay>
        </CopyButton>
    );
};
