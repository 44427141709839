import React, {useEffect, useMemo, useRef, useState, useCallback} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    cornerSet,
    cornerErrorSet,
    selectCornerError,
} from 'components/customer/BTM/store/btmSlice';
import {Corner as CornerEntity} from 'components/customer/BTM/entity/Corner';
import styled from 'styled-components';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {Icon} from 'shared/helpers';
import {Overlay, Popover, PopoverTitle, Dropdown} from 'react-bootstrap';
import {CornerModification} from 'components/customer/BTM/Corners/CornerModification';
import {
    CornerSpecs,
    DEFAULT_MINIMUM_CORNER_DEPTH,
} from 'components/customer/BTM/Corners/CornerSpecs';
import {useDebounce} from 'use-debounce';
import {resetPostformedProfileOnCornerDelete} from 'components/customer/BTM/helper/resetPostformedProfile';

const NOTCH_DEFAULT = 100;
const CLIP_DEFAULT = 35;

const CORNER_SPECS_LABEL = {
    [CornerType.Clip as string]: 'Clip Specs',
    [CornerType.Notch as string]: 'Notch Specs',
};

interface CornerInterface {
    corner: CornerEntity;
    index: number;
    x?: number;
    y?: number;
    inline?: boolean;
}

export const Corner = ({
    corner,
    index,
    x = 0,
    y = 0,
    inline = false,
}: CornerInterface) => {
    const dispatch = useAppDispatch();
    const [screen, setScreen] = useState(0);
    const error = useAppSelector((state) => selectCornerError(state, index));

    const toggleButton = useRef<HTMLButtonElement>();

    const [show, setShow] = useState(false);
    const [previewContainer, setPreviewContainer] = useState<HTMLDivElement>();
    const [target, setTarget] = useState<HTMLButtonElement>(null);
    const [type, setType] = useState<CornerType>(corner.type);
    const [xValue, setXValue] = useState(corner?.depths[0]);
    const [yValue, setYValue] = useState(corner?.depths[1]);

    const updatedCorner = useMemo(() => {
        return {
            ...corner,
            cutoff: type != CornerType.None,
            type,
            depths: [xValue, yValue],
        } as CornerEntity;
    }, [xValue, yValue, type]);

    const [debouncedCorner] = useDebounce(updatedCorner, 200);

    const nextPage = useCallback(() => {
        if (corner.type == CornerType.None) {
            if (type == CornerType.Clip) {
                setXValue(CLIP_DEFAULT);
                setYValue(CLIP_DEFAULT);
            } else if (type == CornerType.Notch) {
                setXValue(NOTCH_DEFAULT);
                setYValue(NOTCH_DEFAULT);
            }
        }

        setScreen(1);
    }, [corner, type]);

    const previousPage = useCallback(() => setScreen(0), []);

    const handleOnHide = useCallback(() => setShow(false), []);

    const handleToggle = useCallback((e: React.MouseEvent) => {
        setTarget(e.target as HTMLButtonElement);
        setShow(!show);
    }, []);

    const updateCorner = (updatedCorner: CornerEntity) => {
        const cornerError = {
            index,
            name: corner.name,
            x: '',
            y: '',
        };

        if (updatedCorner.depths[0] < DEFAULT_MINIMUM_CORNER_DEPTH) {
            cornerError.x = `Depth cannot be less than ${DEFAULT_MINIMUM_CORNER_DEPTH}`;
        }

        if (updatedCorner.depths[1] < DEFAULT_MINIMUM_CORNER_DEPTH) {
            cornerError.y = `Depth cannot be less than ${DEFAULT_MINIMUM_CORNER_DEPTH}`;
        }

        dispatch(cornerErrorSet(cornerError));
        dispatch(cornerSet(updatedCorner, index));

        if (updatedCorner.type == CornerType.None) {
            resetPostformedProfileOnCornerDelete(updatedCorner);
        }
    };

    useEffect(() => {
        if (inline) {
            updateCorner(debouncedCorner);
        }
    }, [debouncedCorner]);

    useEffect(() => {
        setType(corner.type);
        setXValue(corner.depths[0]);
        setYValue(corner.depths[1]);
    }, [corner]);

    useEffect(() => {
        const previewContainer: HTMLDivElement =
            document.querySelector('#btm-preview');

        if (previewContainer) {
            setPreviewContainer(previewContainer);
        }
    }, []);

    const saveCorner = useCallback(() => {
        previousPage();
        toggleButton.current.click();

        updateCorner({
            ...corner,
            cutoff: type != CornerType.None,
            type,
            depths: [xValue, yValue],
        });
    }, [corner, type, xValue, yValue, toggleButton]);

    const deleteCorner = useCallback(() => {
        updateCorner({
            ...corner,
            cutoff: false,
            type: CornerType.None,
            depths: [20, 20],
        });
    }, [corner]);

    if (inline) {
        {
            return (
                <InlineContainer>
                    <strong>{corner.name} : </strong>
                    <div>
                        <CornerModification
                            type={type}
                            setType={setType}
                            saveCorner={saveCorner}
                            nextPage={nextPage}
                            inline={inline}
                            corner={corner}
                        />
                        <CornerSpecs
                            xValue={xValue}
                            yValue={yValue}
                            setXValue={setXValue}
                            setYValue={setYValue}
                            previousPage={previousPage}
                            saveCorner={saveCorner}
                            inline={inline}
                            type={type}
                            error={error}
                        />
                    </div>
                    <div>
                        <DeleteButton
                            title="Delete this Cutoff"
                            onClick={deleteCorner}>
                            Delete
                        </DeleteButton>
                    </div>
                </InlineContainer>
            );
        }
    }

    if (corner?.disabled?.clip && corner?.disabled?.notch) {
        // If both are disabled, no need to show anything
        return null;
    }

    return (
        <CornerElement $top={x} $left={y}>
            <ToggleButton ref={toggleButton} onClick={handleToggle}>
                <CornerIcon iconName="Button-Edit-Primary.svg" />
            </ToggleButton>
            <Overlay
                show={show}
                target={target}
                container={previewContainer}
                placement="bottom"
                flip={true}
                rootClose={true}
                onHide={handleOnHide}
                containerPadding={20}>
                <CustomPopover id={`popover-${index}`}>
                    <PopoverTitle as="h3">
                        {screen === 1 && type.toString() in CORNER_SPECS_LABEL
                            ? CORNER_SPECS_LABEL[type.toString()]
                            : 'Corner Modification'}
                    </PopoverTitle>
                    <Popover.Content>
                        {screen == 0 ? (
                            <CornerModification
                                type={type}
                                setType={setType}
                                saveCorner={saveCorner}
                                nextPage={nextPage}
                                corner={corner}
                            />
                        ) : null}
                        {screen == 1 ? (
                            <CornerSpecs
                                xValue={xValue}
                                yValue={yValue}
                                setXValue={setXValue}
                                setYValue={setYValue}
                                previousPage={previousPage}
                                saveCorner={saveCorner}
                                type={type}
                                error={error}
                            />
                        ) : null}
                    </Popover.Content>
                </CustomPopover>
            </Overlay>
        </CornerElement>
    );
};

export const DropdownMenu = styled(Dropdown.Menu)`
    padding: 10px;
    border: 0px;
    box-shadow: rgb(169 169 169) 1px 1px 4px 0px;
    width: 200px;
`;

export const ToggleButton = styled(Dropdown.Toggle)`
    background-color: none !important;
    background: none !important;
    border: 0;
    padding: 0;

    &:hover,
    &:focus {
        background: none;
    }

    &:after {
        display: none;
    }
`;

const InlineContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > strong {
        display: block;
        margin-right: 15px;
        width: 40px;
        color: rgb(var(--primary_colour));
    }

    > div:first-of-type {
        font-size: 0.9em;
        flex: 1;

        h1 {
            font-size: 0.9em;
        }
    }
`;

const DeleteButton = styled.div`
    background: rgb(var(--secondary_colour));
    color: white;
    border-radius: 8px;
    padding: 5px;
    font-weight: bold;
    font-size: 0.9em;
    width: fit-content;
    cursor: pointer;
`;

const CustomPopover = styled(Popover)`
    width: 180px;
`;

const CornerIcon = styled(Icon)`
    width: 20px;
    cursor: pointer;
`;

const CornerElement = styled.div<{$top: number; $left: number}>`
    position: absolute;
    top: ${({$top}) => `${$top}px`};
    left: ${({$left}) => `${$left}px`};
`;
