import {Side} from 'components/customer/BTM/entity/Side';
import {Path} from 'components/customer/BTM/entity/Path';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {store} from 'store/customer';
import {profiledSet} from 'components/customer/BTM/store/btmSlice';

/**
 * Finds the path by side. Also, adds right index to the
 * path object to track adjacent paths
 *
 * @param {Side} side side of the bench
 * @param {Path[]} paths All paths of the bench from btmSlice
 *
 * @return {Path}
 */
export const getPathBySide = (side: Side, paths: Path[]) =>
    paths
        .map((path, index) => ({...path, index}))
        .find((path) => path.side == side);

/**
 * Sets supplied edge profile on predefined sides based on
 * shape of the bench
 *
 * @param {BenchtopEdgeProfile} edgeProfile Edge profile to be set
 * @param {Path[]} paths All the paths in the bench see btmSlice
 * @param {Shape} shape Shape of the bench
 */
export const setDefaultProfiles = (
    edgeProfile: BenchtopEdgeProfile,
    paths: Path[],
    shape: Shape
) => {
    const path = getPathBySide(Side.C, paths);
    if (shape == Shape.SQR) {
        // Shape of bench is Rectangle
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                path.index,
                Side.C
            )
        );
    } else if (shape == Shape.ANG) {
        // Shape of bench is L
        const pathC = getPathBySide(Side.C, paths);
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                pathC.index,
                Side.C
            )
        );

        const pathD = getPathBySide(Side.D, paths);
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                pathD.index,
                Side.D
            )
        );
    } else if (shape == Shape.USHAPE) {
        // Shape of bench is U
        const pathD = getPathBySide(Side.D, paths);
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                pathD.index,
                Side.D
            )
        );

        const pathE = getPathBySide(Side.E, paths);
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                pathE.index,
                Side.E
            )
        );

        const pathF = getPathBySide(Side.F, paths);
        store.dispatch(
            profiledSet(
                edgeProfile.is_postformed_profile ? Edge.PROFILED : Edge.EDGED,
                edgeProfile,
                pathF.index,
                Side.F
            )
        );
    }
};
