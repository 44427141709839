import React from 'react';
import styled from 'styled-components';

const NoPreview3DPlaceholder = () => {
    return (
        <Preview3DContainer>
            <PlaceholderText>
                Preview for this item is <br /> coming soon!
            </PlaceholderText>
        </Preview3DContainer>
    );
};

const Preview3DContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 365px;
    display: flex;
    border-radius: 8px;
    border: 2px solid rgb(var(--primary_colour));
    padding: 35px;
`;

const PlaceholderText = styled.h4`
    font-weight: 600;
    text-align: center;
    color: #757575;
    font-size: 22px;
`;

export default NoPreview3DPlaceholder;
