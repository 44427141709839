import {Shape} from 'components/customer/BTM/entity/Shape';
import {Point} from 'components/customer/BTM/entity/Point';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {CornerType} from 'components/customer/BTM/entity/CornerType';
import {getSideSpec, Side} from 'components/customer/BTM/entity/Side';

/**
 * Generates coordinates based on supplied dimension
 *
 * @param {number[]} dimensions dimension of a rectangle benchtop
 * @return {Point[]}
 */
export const getRectCoordinates = (dimensions: number[]) => {
    if (dimensions.length < 2) {
        throw new Error('Wrong dimension for this Rectangular shape');
    }

    const [width, height] = dimensions;

    const paths: Point[] = [{x: 0, y: 0, side: Side.A}];
    paths.push({x: width, y: 0, side: Side.B});
    paths.push({x: width, y: height, side: Side.C});
    paths.push({x: 0, y: height, side: Side.D});

    return paths;
};

/**
 * Generates coordinates based on supplied dimension
 *
 * @param {number[]} dimensions dimension of a rectangle benchtop
 * @return {Point[]}
 */
export const getAngCoordinates = (dimensions: number[]) => {
    if (dimensions.length < 4) {
        throw new Error('Wrong dimension for this L shape');
    }

    const [width, rightHeight, , , bottomWidth, height] = dimensions;

    const paths: Point[] = [{x: 0, y: 0, side: Side.A}];
    paths.push({x: width, y: 0, side: Side.B});
    paths.push({x: width, y: rightHeight, side: Side.C});
    paths.push({
        x: bottomWidth,
        y: rightHeight,
        side: Side.D,
    });
    paths.push({x: bottomWidth, y: height, side: Side.E});
    paths.push({x: 0, y: height, side: Side.F});

    return paths;
};

/**
 * Generates coordinates based on supplied dimension
 *
 * @param {number[]} dimensions dimension of a rectangle benchtop
 * @return {Point[]}
 */
export const getUshapeCoordinates = (dimensions: number[]) => {
    if (dimensions.length < 8) {
        throw new Error('Wrong dimension for this L shape');
    }

    const [
        width,
        rightHeight,
        ,
        innerRightHeight,
        innerWidth,
        ,
        leftWidth,
        leftHeight,
    ] = dimensions;

    const paths: Point[] = [{x: 0, y: 0, side: Side.A}];
    paths.push({x: width, y: 0, side: Side.B});
    paths.push({x: width, y: rightHeight, side: Side.C});
    paths.push({
        x: leftWidth + innerWidth,
        y: rightHeight,
        side: Side.D,
    });
    paths.push({
        x: leftWidth + innerWidth,
        y: rightHeight - innerRightHeight,
        side: Side.E,
    });
    paths.push({
        x: leftWidth,
        y: rightHeight - innerRightHeight,
        side: Side.F,
    });
    paths.push({x: leftWidth, y: leftHeight, side: Side.G});
    paths.push({x: 0, y: leftHeight, side: Side.H});

    return paths;
};

/**
 * Returns name of the corner e.g. Corner that touches line A and B is AB
 *
 * @param {Side} sideA One side of the corner
 * @param {Side} sideB Other side of the corner
 * @return {stiring}
 */
export const getCornerName = (sideA: Side, sideB: Side) => {
    const sideASpec = getSideSpec(sideA);
    const sideBSpec = getSideSpec(sideB);

    if (sideASpec && sideBSpec) {
        return `${sideASpec.key}${sideBSpec.key}`;
    }
};

/**
 * Takes points array and constructs corners with default values
 *
 * @param {Point[]} points Coordinates of the bench top seel btmSlice
 * @return {Corner[]}
 */
export const getCorners = (points: Point[]) => {
    const corners: Corner[] = [];

    points.forEach((point, index) => {
        const previousIndex = index == 0 ? points.length - 1 : index - 1;
        const name = getCornerName(previousIndex, index);

        corners.push({
            name,
            cutoff: false,
            type: CornerType.None,
            depths: [20, 20],
        });
    });

    return corners;
};

/**
 * Basically takes shape and dimension and returns coordinates
 * and corners based on the data provided
 *
 * @typedef {Object} Return
 * @property {Point[]} coordinates
 * @property {Corner[]} corners
 *
 * @param {Shape} shape Shape of the benchtop
 * @param {number[]} dimensions dimension of the benchtop
 * @return {Return}
 */
export const getCoordinates = (shape: Shape, dimensions: number[]) => {
    let coordinates: Point[] = [];
    switch (shape) {
        case Shape.SQR:
            coordinates = getRectCoordinates(dimensions);
            break;

        case Shape.ANG:
            coordinates = getAngCoordinates(dimensions);
            break;

        case Shape.USHAPE:
            coordinates = getUshapeCoordinates(dimensions);
            break;
    }

    const corners = getCorners(coordinates);

    return {
        coordinates,
        corners,
    };
};
