import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

const benchParameterName = 'bench';

export const useGetBenchId = () => {
    const [query, setQuery] = useSearchParams();
    const [benchId, setBenchId] = useState<number>(
        query.get(benchParameterName)
            ? Number(query.get(benchParameterName))
            : null
    );

    useEffect(() => {
        const id = query.get(benchParameterName);

        if (id != null) {
            setBenchId(Number(id));
        } else {
            setBenchId(null);
        }
    }, [query]);

    const setBench = (id: number) => {
        setQuery({[benchParameterName]: String(id)});
    };

    return {benchId, setBench};
};
