import React, {useCallback, useEffect} from 'react';
import {
    Material,
    Door,
    MaterialEdge,
    MaterialType,
} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import EdgeSearch from 'components/customer/Materials/EdgeSearch';
import MaterialSearch from 'components/customer/Materials/MaterialSearch';
import DoorSearch from 'components/customer/Materials/DoorSearch';
import {Col, Row} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectShowProductTutorial,
    selectShowRoomTutorial,
    showProductTutorialSet,
    showRoomTutorialSet,
    selectDefaultLoaded,
} from 'components/customer/Materials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {ActionType} from 'components/customer/Materials/helper/useDispatchMaterial';
import {useDispatchWithFormik} from 'components/customer/Materials/helper/useDispatchMaterialFormik';
import {MaterialSpecs} from './MaterialSpecs';
import {MaterialFormFieldOptions} from 'shared/types/materials';
import MaterialDetails from 'components/customer/Materials/MaterialDetails';
import {useProductContext, useTutorialContext} from 'contexts';
import {useTour} from '@reactour/tour';
import {Tutorial} from 'contexts/TutorialContext';
import {AppState} from 'store/customer/storeSetup';
import {useDebouncedCallback} from 'use-debounce';
import {useSearchParams} from 'react-router-dom';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {stripSizeFromString} from 'components/customer/Materials/helper';
import {
    MaterialSearchRequest,
    useLazySearchMaterialsQuery,
} from 'components/customer/Materials/store/materialApi';

interface SearchInterface {
    materialType?: MaterialType;
    hasDoor?: boolean;
    field: MaterialFormFieldOptions;
    page?: Page;
}

const materialPlaceholder = 'Search materials';
const edgeMaterialPlaceholder = 'Search edge materials';
const carcasePlaceholder = 'Search carcase materials';
const edgeCarcasePlaceholder = 'Search edge carcase materials';
const doorPlaceholder = 'Select door';

export const Search = ({
    materialType = MaterialType.EXTERIOR,
    hasDoor = false,
    field,
    page = Page.PRODUCT,
}: SearchInterface): JSX.Element => {
    const {tutorial, addMaterialTutorial, clearSteps} = useTutorialContext();
    const {steps, setIsOpen, setCurrentStep} = useTour();
    const {currentTab} = useProductContext<{currentTab: number}>(page);
    const [searchParams] = useSearchParams();
    const [search] = useLazySearchMaterialsQuery();

    const dispatch = useDispatchWithFormik({page, index: 0});
    const dispatchApp = useAppDispatch();
    const showTutorial = useAppSelector(
        page == Page.PRODUCT
            ? selectShowProductTutorial
            : selectShowRoomTutorial,
        shallowEqual
    );
    const isDefaultLoaded = useAppSelector<boolean>((store: AppState) =>
        selectDefaultLoaded(store, materialType)
    );
    const selectedMaterial = useAppSelector((state) =>
        getMaterial(state, MaterialType.EXTERIOR, 0)
    );

    const loadMaterialDoorFilters = useDebouncedCallback(
        useCallback(async () => {
            if (materialType == MaterialType.EXTERIOR) {
                const options: MaterialSearchRequest = {
                    keywords: stripSizeFromString(selectedMaterial.displayName),
                    currentPage: 1,
                    materialType: MaterialType.EXTERIOR,
                };

                if (searchParams.has('product')) {
                    options.cabinetType = parseInt(searchParams.get('product'));
                }

                const {data} = await search(options);

                if (data?.data) {
                    dispatch(
                        ActionType.ExteriorColour,
                        {
                            ...selectedMaterial,
                            door_filters: data.data[0]?.door_filters || [],
                        },
                        false,
                        false
                    );
                }
            }
        }, [materialType, searchParams, selectedMaterial, dispatch]),
        1000
    );

    const setMaterial = (
        material: Material,
        updateValue: boolean,
        updateValueOnly: boolean
    ) => {
        if (!isDefaultLoaded) return;

        if (materialType == MaterialType.EXTERIOR)
            dispatch(
                ActionType.ExteriorColour,
                material,
                updateValue,
                updateValueOnly
            );
        else dispatch(ActionType.CarcaseColour, material);
    };

    const setEdgeMaterial = (materialEdge: MaterialEdge) => {
        if (!isDefaultLoaded) return;

        if (materialType == MaterialType.EXTERIOR) {
            dispatch(ActionType.ExteriorEdgeColour, materialEdge);
        } else {
            dispatch(ActionType.CarcaseEdgeColour, materialEdge);
        }
    };

    const setDoor = (door: Door) => {
        if (!isDefaultLoaded) return;

        dispatch(ActionType.Door, door);
    };

    useEffect(() => {
        if (showTutorial) {
            addMaterialTutorial();
            if (page == Page.PRODUCT && currentTab == 1) {
                dispatchApp(showProductTutorialSet(false));
            } else if (page == Page.ROOM) {
                dispatchApp(showRoomTutorialSet(false));
            }
        }

        return () => {
            if (!showTutorial) {
                clearSteps();
            }
        };
    }, [showTutorial, currentTab]);

    useEffect(() => {
        if (
            tutorial == Tutorial.MATERIAL &&
            steps.length &&
            ((page == Page.PRODUCT && currentTab == 1) || page == Page.ROOM)
        ) {
            setCurrentStep(0);
            setIsOpen(true);
        }

        return () => setIsOpen(false);
    }, [tutorial, steps, currentTab]);

    useEffect(() => {
        if (page !== Page.ROOM) {
            void loadMaterialDoorFilters();
        }
    }, [page]);

    return (
        <Col className="align-self-center">
            <Row className="material_search_container">
                <MaterialSearch
                    key={`search-material-${materialType}`}
                    materialType={materialType}
                    defaultPlaceholder={
                        materialType == MaterialType.EXTERIOR
                            ? materialPlaceholder
                            : carcasePlaceholder
                    }
                    setMaterial={setMaterial}
                    hasDoor={hasDoor}
                />
                <EdgeSearch
                    defaultPlaceholder={
                        materialType == MaterialType.EXTERIOR
                            ? edgeMaterialPlaceholder
                            : edgeCarcasePlaceholder
                    }
                    setEdge={setEdgeMaterial}
                    materialType={materialType}
                />
                <MaterialSpecs field={field} type={materialType} />
                {hasDoor ? (
                    <DoorSearch
                        defaultPlaceholder={doorPlaceholder}
                        setDoor={setDoor}
                        setMaterial={setMaterial}
                        materialType={materialType}
                    />
                ) : null}
            </Row>
            <MaterialDetails materialType={materialType} hasDoor={hasDoor} />
        </Col>
    );
};
