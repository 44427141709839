import React, {useCallback, useEffect, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    Status,
    clearErrors,
    selectQFPs,
    selectStatus,
    selectTotalSaving,
    statusSet,
    totalSavingSet,
    triggerSave,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {shallowEqual} from 'react-redux';
import {Button, ProgressBar as OGProgressBar} from 'react-bootstrap';
import styled from 'styled-components';
import {useNavigate, useParams} from 'react-router-dom';

interface ProgressBarProps {
    showConfirmation: boolean;
    hideDialog: () => void;
    resolve: (value: boolean | PromiseLike<boolean>) => void;
}

export const ProgressBar = ({
    showConfirmation,
    hideDialog,
    resolve,
}: ProgressBarProps) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const {jobId, roomId} = useParams();

    const status = useAppSelector(selectStatus);
    const totalSaving = useAppSelector(selectTotalSaving);
    const qfps = useAppSelector(selectQFPs, shallowEqual);

    const errors = useMemo(() => {
        if (qfps) {
            return qfps
                .filter((qfp) => typeof qfp.error != 'undefined')
                .map((qfp, index, self) => {
                    return (
                        <div key={qfp.product.room_cab_number}>
                            <strong>
                                Could not save product #
                                {qfp.product.room_cab_number}
                            </strong>
                            <br />
                            {qfp.error}
                            {index !== self.length - 1 && <hr />}
                        </div>
                    );
                });
        }

        return [];
    }, [qfps]);

    const savingProgress = useMemo(() => {
        if (totalSaving == 0) return [];

        return qfps
            .map((qfp, index) => ({...qfp, index}))
            .filter((qfp) => !qfp?.deleted)
            .map((qfp) => {
                if (qfp.persisted) {
                    return (
                        <OGProgressBar
                            striped
                            variant="success"
                            now={100 / totalSaving}
                            key={qfp.index}
                        />
                    );
                } else if (qfp.error) {
                    return (
                        <OGProgressBar
                            striped
                            variant="danger"
                            now={100 / totalSaving}
                            key={qfp.index}
                        />
                    );
                }
            });
    }, [qfps, totalSaving]);

    const navigateToDashboard = useCallback(
        (productId?: number) => {
            let query = '';
            if (typeof productId !== 'undefined') query = `?pid=${productId}`;

            navigate(`/v2/job/${jobId}/room/${roomId}/dashboard${query}`);
        },
        [navigate, jobId, roomId]
    );

    const continueEditing = useCallback(() => {
        dispatch(triggerSave(false));
        dispatch(totalSavingSet(0));
        dispatch(statusSet(Status.IDLE));
        dispatch(clearErrors());
        hideDialog();
    }, []);

    const goToDashboard = useCallback(() => {
        continueEditing();
        navigateToDashboard();
    }, [continueEditing, navigateToDashboard]);

    useEffect(() => {
        if (status == Status.DONE) {
            resolve(errors.length == 0);
        }
    }, [status]);

    return (
        <>
            <strong>
                {status == Status.SAVING && errors.length == 0
                    ? 'Saving products...'
                    : ''}
                {status == Status.DONE && errors.length == 0
                    ? 'All changes are saved successfully.'
                    : ''}
            </strong>
            {errors}
            {status == Status.IDLE || status == Status.SAVING ? (
                <OGProgressBar>{savingProgress}</OGProgressBar>
            ) : null}

            {status == Status.DONE &&
            (showConfirmation || errors.length > 0) ? (
                <>
                    <FooterLine />

                    <FauxFooter>
                        <Button
                            title="Continue editing"
                            variant="primary"
                            onClick={continueEditing}>
                            Continue Editing
                        </Button>
                        <Button
                            title="Close"
                            variant="primary-color"
                            onClick={goToDashboard}>
                            Go to Dashboard
                        </Button>
                    </FauxFooter>
                </>
            ) : null}
        </>
    );
};

const FooterLine = styled.div`
    border-top: 1px solid #dee2e6;
    height: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
`;

const FauxFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    > button {
        border: 0;
        border-radius: 10px;
        font-size: 1em;
        font-weight: 500;
        padding: 5px 10px;
    }
`;
