import {Position} from 'components/customer/BTM/entity/Position';
import {useEffect, useState} from 'react';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';
import {useAppSelector} from 'store/customer';
import {selectTopOffset} from 'components/customer/BTM/store/btmSlice';

export interface PositionInterface {
    x: number;
    y: number;
    width?: number;
    height?: number;
    placement?: 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT';
}

export const useLabelPosition = (
    textOnly: boolean,
    position: Position,
    shape: Shape,
    center: number[],
    dimension: number[],
    scale: number,
    side: Side,
    allowButtJoin = false,
    isButtJoin = false
) => {
    const paddingTop = useAppSelector(selectTopOffset);
    const labelThickness = 25;

    const [positionData, setPositionData] = useState<PositionInterface>({
        x: 0,
        y: 0,
    });

    useEffect(() => {
        if (typeof dimension == 'undefined') return;

        const [x, y] = center;

        if (shape == Shape.SQR) {
            const [width, height] = dimension;

            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                // NOTE: + paddingTop - 20 puts the label right at the top right corner. - 10
                // makes it 10px away from the top line of the bench preview
                const yVal = y - (height / 2) * scale + paddingTop - 20 - 10;

                setPositionData({
                    x: xVal,
                    y: yVal - (allowButtJoin && !isButtJoin ? 25 : 0),
                    width: width * scale,
                    placement: 'TOP',
                });
            } else {
                // putting the label 10px away from the right line of the bench preview
                const xVal = x + (width / 2) * scale + 10;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: height * scale,
                    placement: 'RIGHT',
                });
            }
        } else if (shape == Shape.ANG) {
            const [width, rightHeight, , , bottomWidth, height] = dimension;

            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                const yVal = y - (height / 2) * scale + paddingTop - 20 - 10;

                // the text fields takes tad bit more space,
                // so if the componet renders input field, give it
                // bit more room
                setPositionData({
                    x: xVal,
                    y:
                        yVal -
                        (allowButtJoin && !isButtJoin
                            ? textOnly
                                ? 25
                                : 30
                            : 0),
                    width: width * scale,
                    placement: 'TOP',
                });
            } else if (position == Position.Left) {
                // NOTE: putting the label 10px away from the left line of the bench preview
                let xVal = x - (width / 2) * scale - 10 - labelThickness;
                const yVal = y - (height / 2) * scale + paddingTop;

                if (allowButtJoin && !isButtJoin) {
                    // NOTE: putting the label 5px away from the butt join part labels
                    xVal -= labelThickness + 5;
                }

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: height * scale,
                    placement: 'LEFT',
                });
            } else if (position == Position.Right) {
                // putting the label 10px away from the right line of the bench preview
                const xVal = x + (width / 2) * scale + 10;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: height * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.BottomInner) {
                const xVal = x - (width / 2) * scale + bottomWidth * scale + 10;
                const yVal =
                    y -
                    (height / 2) * scale +
                    paddingTop +
                    rightHeight * scale +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    width: (width - bottomWidth) * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.LeftInner) {
                const xVal = x - (width / 2) * scale + bottomWidth * scale + 10;
                const yVal =
                    y -
                    (height / 2) * scale +
                    rightHeight * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: (height - rightHeight) * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.Bottom) {
                const xVal = x - (width / 2) * scale;
                const yVal = y + (height / 2) * scale + paddingTop + 10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    width: bottomWidth * scale,
                    placement: 'BOTTOM',
                });
            }
        } else if (shape == Shape.USHAPE) {
            const [
                width,
                rightHeight,
                rightBottom,
                rightInner,
                inner,
                leftInner,
                leftBottom,
                leftHeight,
            ] = dimension;

            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                // NOTE: + paddingTop - 20 puts the label right at the top right corner. - 10
                // makes it 10px away from the top line of the bench preview
                const yVal =
                    y -
                    (Math.max(rightHeight, leftHeight) / 2) * scale +
                    paddingTop -
                    20 -
                    10;

                // same rule here as in L shape bench.
                setPositionData({
                    x: xVal,
                    y:
                        yVal -
                        (allowButtJoin && !isButtJoin
                            ? textOnly
                                ? 25
                                : 30
                            : 0),
                    width: width * scale,
                    placement: 'TOP',
                });
            } else if (position == Position.Right) {
                // putting the label 10px away from the right line of the bench preview
                let xVal = x + (width / 2) * scale + 10;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    paddingTop;

                if (allowButtJoin && !isButtJoin) {
                    xVal += labelThickness + 5;
                }

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: rightHeight * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.BottomRight) {
                const xVal = x + (width / 2) * scale - rightBottom * scale;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    rightHeight * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: rightBottom * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.RightInner) {
                const xVal =
                    x +
                    (width / 2) * scale -
                    rightBottom * scale -
                    labelThickness -
                    10;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: rightInner * scale,
                    placement: 'LEFT',
                });
            } else if (position == Position.Inner) {
                const xVal = x - (width / 2) * scale + leftBottom * scale + 10;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: inner * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.LeftInner) {
                const xVal = x - (width / 2) * scale + leftBottom * scale + 10;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: leftInner * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.BottomLeft) {
                const xVal = x - (width / 2) * scale;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    leftHeight * scale +
                    paddingTop +
                    10;

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: leftBottom * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.Left) {
                // putting the label 10px away from the left line of the bench preview
                let xVal = x - (width / 2) * scale - 10 - labelThickness;
                const yVal =
                    y -
                    (Math.max(leftHeight, rightHeight) / 2) * scale +
                    paddingTop;

                if (allowButtJoin && !isButtJoin) {
                    // putting the label 5px away from the butt join part labels
                    let multiple = 1;
                    if (allowButtJoin && !isButtJoin) {
                        multiple = 2;
                    }
                    xVal -= (labelThickness + 5) * multiple;
                }

                if (side == Side.I) {
                    xVal -= labelThickness + 5;
                }

                setPositionData({
                    x: xVal,
                    y: yVal,
                    height: leftHeight * scale,
                    placement: 'LEFT',
                });
            }
        }
    }, [
        textOnly,
        center,
        scale,
        dimension,
        allowButtJoin,
        isButtJoin,
        paddingTop,
    ]);

    return {
        positionData,
    };
};
