import {useJobContext, useNotificationContext} from 'contexts';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {submitJob} from 'service';
import {genericMessageHandler} from 'shared/helpers';
import {sendAnalyticsEvent} from 'shared/helpers/Analytics';
import {PartialJob} from 'shared/types/PartialJob';
import {Event} from 'shared/events/Event';
import {AxiosError} from 'axios';

export const useSubmitJob = (): Event => {
    const navigate = useNavigate();
    const {notify} = useNotificationContext();
    const {job, setJob, setRoom} = useJobContext() as PartialJob;

    const action = useCallback(async () => {
        if (job.totalProductCount == 0) {
            throw new Error('Job should have at least one product in it');
        }

        sendAnalyticsEvent('submitted', 'co_job');
        try {
            const response = await submitJob(job.id);
            response.map((m) =>
                genericMessageHandler(notify, {message: m}, 'success')
            );
            setJob({});
            setRoom({});
            navigate('/v2/');
        } catch (error) {
            let errors = ['Error submitting the job. Please try again later.'];

            if (typeof error === 'object' && error !== null) {
                const axiosError = error as AxiosError;
                if ('response' in axiosError) {
                    if (
                        'data' in axiosError.response &&
                        typeof axiosError.response.data == 'object'
                    ) {
                        if (
                            'errors' in axiosError.response.data &&
                            Array.isArray(axiosError.response.data.errors)
                        ) {
                            errors = axiosError.response.data
                                .errors as string[];
                        } else if ('error' in axiosError.response.data) {
                            errors = [axiosError.response.data.error as string];
                        }
                    }
                } else if ('message' in axiosError) {
                    errors = [axiosError.message];
                }
            }

            if (!Array.isArray(errors)) {
                errors = [errors];
            }

            errors.forEach((error) => {
                genericMessageHandler(notify, {message: error});
            });
        }
    }, [job]);

    return {
        action,
    };
};
